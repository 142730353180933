<div class="item-content">
    <div class="item-title">
        <ejs-radiobutton [label]='itemTitle' name="optionGroup" (change)="onChange()" [checked]="itemChecked"></ejs-radiobutton>
    </div>
    <div class="item-details">
        <div class="image-wrapper">
            <img [src]="imgSrc">
        </div>
        <div class="description">
            <span>{{description}}</span>
        </div>
    </div>

</div>
