<viega-collapsible-panel>
    <!-- Header -->
    <div class="panel-content" panel-content>

            <viega-panel-header
                stepText="&nbsp;"
                [stepTitle]="stepTitle"
                [stepBackLabel]="stepBack"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>

        <div class="content-wrapper">
            <div class="top-content-wrapper">
                <div class="top-panel-content">
                    <p class="description-text" *ngIf="stepDescription" >
                        <span [innerHTML]="stepDescription"></span>&nbsp;
                    </p>
                </div>
                <div class="mid-panel-content">
                    <div >
                        <div class="panel-text-wrapper">
                            <div class="value-title text-tooltip-wrapper">
                                <span>{{'PanelHeadline60Pwh_T' | translate}}</span>
                                <app-help-icon hinweisId="Dte.Abgesenkt.Standardbetrieb"></app-help-icon>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'TemperaturPwh_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getPwh()}}</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'Bedarfsvolumenstrom_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getBedarfsvolumenstrom()}}</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'BenoetigteVorlauftemperatur_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>
                                    {{getVorlaufErgebnis()}}
                                </span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'DruckverlustStrecke_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span *ngIf="selectedDTE">{{getDruckverlustStrecke()}}</span>
                                <span *ngIf="!selectedDTE">-</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'DruckverlustGesamt_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span *ngIf="selectedDTE">{{getDruckverlustGesamt()}}</span>
                                <span *ngIf="!selectedDTE">-</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper" style="margin-top: 1.5em;">
                            <div class="value-title text-tooltip-wrapper">
                                <span>{{'PanelHeadlinePwhAbgesenkt_T' | translate: getPwhAbgesenkt()}}</span>
                                <app-help-icon hinweisId="Dte.Abgesenkt.AbgesenkterBetrieb"></app-help-icon>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'TemperaturPwhAbgesenkt_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getPwhAbgesenkt()}}</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'BedarfsvolumenstromAbgesenkt_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span *ngIf="selectedDTE">{{getBedarfsvolumenstromAbgesenkt()}}</span>
                                <span *ngIf="!selectedDTE">-</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'BenoetigteVorlauftemperaturAbgesenkt_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>
                                    {{getVorlaufAbgesenktErgebnis()}}
                                </span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'DruckverlustStreckeAbgesenkt_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span *ngIf="selectedDTE">{{getDruckverlustStreckeAbgesenkt()}}</span>
                                <span *ngIf="!selectedDTE">-</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'DruckverlustGesamtAbgesenkt_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span *ngIf="selectedDTE">{{getDruckverlustGesamtAbgesenkt()}}</span>
                                <span *ngIf="!selectedDTE">-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bot-panel-content">
                <div class="error-message" *ngIf='!selectedDTE'>
                    <i class="icon-warning1"></i><p class="error-text">
                        {{'ErrorGeringeVorlauftemperatur_D' | translate}}</p>
                </div>
                <div class="error-message" *ngIf='!isBedarfsvolumenstromValid()'>
                    <i class="icon-warning1"></i><p class="error-text">
                        {{'ErrorBedarfsvolumenstrom_D' | translate}}</p>
                </div>


                <div class="result-box">
                    <span class="result-title">{{'Result_T' | translate}}</span>
                    <span class="result-content" *ngIf="selectedDTE">{{getDteName()}}</span>
                    <span class="resutl-content" *ngIf="!selectedDTE">-</span>
                    <button
                        (click)="navigateNext()"
                        [disabled]="loadedDteIsInvalid || !selectedDTE || !isBedarfsvolumenstromValid()"
                        style="justify-content: center; flex-flow: row;"
                        psp-button
                        class="e-btn e-small"
                        >{{'UebernehmenBtn_T' | translate}}</button>
                </div>
            </div>
        </div>

    </div>
    <div class="content" content>
        <div style="margin-left: 1em">
            <div class="text-tooltip-wrapper" style="margin-bottom: 1em;">
                <h2>{{'Headline_T' | translate}}</h2>
                <app-help-icon hinweisId="Dte.ErmittlungMitTemperaturabsenkung"></app-help-icon>
            </div>
            <div class="image-container">
                <app-dte-schematic
                    [dte]="getDteName()"
                    [energiespeicherVorlauftemperatur]="getVorlaufAbgesenktErgebnis()"
                    [bedarfsvolumenstrom]="getBedarfsvolumenstromAbgesenkt()"
                    [maxAbgesenkteTemperaturPwhC]="getMaxAbgesenkteTemperatur()"
                ></app-dte-schematic>
            </div>
            <div style="margin-top: 2.5em;">
                <div class="radioButtonText">
                    <div class="vorlaufLabel">
                        <span>{{'VorlauftemperaturSlider_T' | translate}}</span>
                    </div>
                    <div>
                        <app-slider-text-combo
                            class="slider-text-combo"
                            style="margin-left: 0em;"
                            [sliderMin]="vorlauftemperaturSliderMin"
                            [sliderMax]="90"
                            [sliderStep]="1"
                            [textboxMin]="vorlauftemperaturSliderMin"
                            [textboxMax]="90"
                            [textboxStep]="1"
                            [textboxDecimals]="0"
                            [textboxClass]="'slider-input'"
                            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                            [showLabel]='false'
                            [(value)]='vorlauftemperatur'
                            (valueChange)="onChangeSlider($event)"
                        ></app-slider-text-combo>
                    </div>
                    <!--<div style="width: 9em;">
                        <ejs-slider (change)="onChangeSlider($event)" [min]=65 [max]=90 [(value)]="vorlauftemperatur" ></ejs-slider>
                    </div>
                    <div style="width: 6em;">
                        <ejs-textbox (change)="onChangeSlider($event)" min=65 max=90 class="slider-input" type="number" [(value)]="vorlauftemperatur" floatLabelType="Never" style=" border-color: white !important; color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>
                    </div>-->
                     <span style="margin-left: 1.5em;">{{'#Generic.Unit.DegreeCelsius_T' | translate}}</span>
                     <div>
                         <span *ngIf="benoetigteVorlauftemperatur && benoetigteVorlauftemperatur > 0" class="radioButtonText" style="margin-left: 1em; white-space:nowrap;">{{'VorlaufTemperatur_T' | translate: getVorlaufErgebnis()}}</span>
                         <span *ngIf="!benoetigteVorlauftemperatur || benoetigteVorlauftemperatur == 0" class="radioButtonText" style="margin-left: 1em; white-space:nowrap;">{{'BenVorlaufTemperaturNull_T' | translate}}</span>
                     </div>
                </div>

                <div class="radioButtonText" >
                    <div class="vorlaufLabel">
                        <span >{{'AbgesenkteTemperaturSlider_T' | translate}}</span>
                    </div>
                    <app-slider-text-combo
                    class="slider-text-combo"
                    style="margin-left: 0em;"
                                            [sliderMin]="45"
                                            [sliderMax]="54"
                                            [sliderStep]="1"
                                            [textboxMin]="45"
                                            [textboxMax]="54"
                                            [textboxStep]="1"
                                            [textboxDecimals]="0"
                                            [textboxClass]="'slider-input'"
                                            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                            [showLabel]='false'
                                            [(value)]='maxAbgesenkteTemperatur'
                                            (valueChange)="onChangeSlider($event)"
                                        ></app-slider-text-combo>
                    <!--<div style="width: 9em;">
                        <ejs-slider #option1 (change)="onChangeSlider($event)" [min]=45 [max]=54 [(value)]="maxAbgesenkteTemperatur" ></ejs-slider>
                    </div>
                    <div style="width: 6em;">
                        <ejs-textbox min=45 (change)="onChangeSlider($event)" max=54 class="slider-input" type="number" [(value)]="maxAbgesenkteTemperatur" floatLabelType="Never" style=" border-color: white !important; color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>
                    </div>-->
                     <span style="margin-left: 1.5em;">{{'#Generic.Unit.DegreeCelsius_T' | translate}}</span>
                     <div style="height: 1.5em">
                         <div>
                             <span *ngIf="benoetigteVorlauftemperaturAbgesenkt && benoetigteVorlauftemperaturAbgesenkt > 0" class="radioButtonText" style="margin-left: 1em;">{{'VorlauftemperaturAbgesenkt_T' | translate: getVorlaufAbgesenktErgebnis()}}</span>
                             <span *ngIf="!benoetigteVorlauftemperaturAbgesenkt || benoetigteVorlauftemperaturAbgesenkt == 0" class="radioButtonText" style="margin-left: 1em;">{{'BenVorlaufTemperaturAbgesenktNull_T' | translate}}</span>
                         </div>

                         <div>
                            <span class="radioButtonText" style="margin-left: 1em;">{{'TemperaturPwhAbgesenktInline_T' | translate: getPwhAbgesenkt()}}</span>
                         </div>

                         <div *ngIf="maxAbgesenkteTemperatur < 50" class="text-tooltip-wrapper">
                            <span style="margin-left: 1em; white-space:nowrap;">{{'AnforderungenPruefen_SD' | translate}}</span>
                            <app-help-icon hinweisId="Dte.AnforderungenMindesttemperatur"></app-help-icon>
                         </div>
                     </div>


                </div>
            </div>

        </div>

        <div>
            <div class="text-tooltip-wrapper">
                <span class="titletext">{{'AuswahlDteHeadline_T' | translate}}</span>
                <app-help-icon hinweisId="Dte.AuswahlDte"></app-help-icon>
            </div>
            <div class="row">
                <button id=dte40 psp-button class="dteButton " iconPosition="none" [disabled]="isDteDisabled('40')" (click)="selectDte('40', true)" >{{getDte40Btn()}}</button>
                <button id=dte70 psp-button class="dteButton " iconPosition="none" [disabled]="isDteDisabled('70')" (click)="selectDte('70', true)">{{getDte70Btn()}}</button>
                <button id=dte100 psp-button class="dteButton" iconPosition="none" [disabled]="isDteDisabled('100')" (click)="selectDte('100', true)">{{getDte100Btn()}}</button>
            </div>
        </div>

        <div style="margin-top: 1em;">
            <div class="text-tooltip-wrapper">
                <span class="titletext" style="font-size: 16px;">{{'LeistungsdiagrammHeadline_T' | translate}}</span>
                <app-help-icon hinweisId="Dte.Leistungsdiagramm.Absenkung"></app-help-icon>
            </div>
            <div style="height: 30em; max-width: 85em;">
                <ejs-chart height="100%" width="85%" [tooltip]="tooltipBetriebspunkt" [primaryYAxis]='primaryYAxis' [primaryXAxis]='primaryXAxis' [palettes]='palette'  [legendSettings]='legendSettings'>
                    <e-series-collection>
                        <e-series [enableTooltip]="false" [dataSource]='chartDataPWH60' dashArray="2" type='Line' xName='Bedarfsvolumenstrom' yName='Vorlauftemperatur' width=2 [name]="'PWH ' + getPwh60()" [animation]='animation' dashArray=3></e-series>
                        <e-series [enableTooltip]="false" [dataSource]='chartDataPWHAbgesenkt' dashArray="2" type='Line' xName='Bedarfsvolumenstrom' yName='Vorlauftemperatur' width=2 [name]="pwhName" [animation]='animation' dashArray=3></e-series>
                        <e-series [tooltipFormat]="tooltipformatBetriebspunkt" [dataSource]='chartDataPWH60Betriebspunkt' type='Line' xName='Bedarfsvolumenstrom' yName='Vorlauftemperatur' width=2  [animation]='animation'></e-series>
                        <e-series [tooltipFormat]="tooltipformatBetriebspunkt" [dataSource]='chartDataPWHAbgesenktBetriebspunkt' type='Line' xName='Bedarfsvolumenstrom' yName='Vorlauftemperatur' width=2  [animation]='animation'></e-series>
                        <e-series [dataSource]="chartDataBedarfsvolumenstrom" [enableTooltip]="false" dashArray="7" type="Line" xName="Bedarfsvolumenstrom" yName='Vorlauftemperatur' width="2" name="{{'BedarfsvolumenstromPwh60' | translate: getPwh60()}}" [animation]="animation"></e-series>
                    </e-series-collection>
                </ejs-chart>
            </div>
        </div>

    </div>
</viega-collapsible-panel>
