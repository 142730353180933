import { enableProdMode } from '@angular/core';

import { RuntimeService } from './core-lib/ej2/services/RuntimeService';
import { LangService } from './core-lib/ej2/services/LangService';
import { AppModule } from './app/app.module';
import { log } from './core-lib/logging';
import { environment } from './environments/environment';
import './core-lib/ej2/extensions';

// note that imported json files are bundled into the compiled javascript by webpack
import * as labeldata_deDE from './labels/labels.de-DE.json';
import * as labeldata_enUS from './labels/labels.en-US.json';
import * as labeldata_enGB from './labels/labels.en-GB.json';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GuidService } from './core-lib/ej2/services/GuidService';

// set to true to overlay the loading skeleton with the actual content
let debugOverlaySkeleton: boolean = false;

// set to zero to disable load time simulation
let debugSimulatedLoadingTimeMs: number = 0;
// when the angular app starts and loads the route there is more data to retrieve
// and content to render. to hide this from the user the skeleton keeps overlaying
// the actual app for the given duration
let additionalRenderTimeMs: number = 100;

const isProdEnvironment = environment.buildEnvironment === 'Release';
if (isProdEnvironment)
{
    debugOverlaySkeleton = false;
    debugSimulatedLoadingTimeMs = 0;
    enableProdMode();
}

const langService = new LangService();
const guidService = new GuidService();
const runtimeService = new RuntimeService();
document.cookie = "pspappsid=" + guidService.newGuid();

langService.setLabelData(labeldata_deDE['default'], 'de-DE');
langService.setLabelData(labeldata_enUS['default'], 'en-US');
langService.setLabelData(labeldata_enGB['default'], 'en-GB');

const desiredLocale = { result: 'de-DE', mappedBy: 'constant' }; //langService.getDesiredLocale();
log.information(desiredLocale, 'Desired locale has been determined.');

const setLanguagePromise = langService.setLanguage(desiredLocale.result);
const runtimeInfoPromise = runtimeService.refreshRuntimeInfo();

Promise.all([setLanguagePromise, runtimeInfoPromise])
    .then(() =>
    {
        if (debugSimulatedLoadingTimeMs > 0)
            return new Promise(resolve => setTimeout(() => resolve(''), debugSimulatedLoadingTimeMs));
        else
            return Promise.resolve();
    }).then(() =>
    {
        hideSkeleton();
        return platformBrowserDynamic().bootstrapModule(AppModule);
    }).then(ref => {
        // Ensure Angular destroys itself on hot reloads.
        if (window['ngRef'])
            window['ngRef'].destroy();

        window['ngRef'] = ref;
    })
    .catch(err => log.fatal(err, 'Bootstrapping the Angular app has failed.'));

function hideSkeleton(): void
{
    if (debugOverlaySkeleton)
    {
        document.getElementById('psp-loading-skeleton').style.background = 'transparent';
    }
    else
    {
        // simulate a bit more loading time to prevent flickering
        new Promise(resolve =>
        {
            setTimeout(() => resolve(''), additionalRenderTimeMs);
        }).then(() =>
        {
            document.body.removeChild(document.getElementById('psp-loading-skeleton'));
            document.body.classList.add('app-loaded');
        });
    }
}
