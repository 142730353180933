import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { RuntimeService } from 'src/core-lib/ej2/services/RuntimeService';
import { log } from 'src/core-lib/logging';
import { DataModelFile } from './DteufcAggregatorServiceApi/datamodelfile.complex';
import { ImageAssetRow } from './DteufcAggregatorServiceApi/imageassetrow.complex';

@Injectable()
export class AggregatorDataService
{
    private _cachedAggregatorData: DataModelFile;
    private _cachedImageMap: { [assetId: string]: ImageAssetRow };

    public get aggregatorData(): DataModelFile
    {
        return this._cachedAggregatorData;
    }

    public get imageMap(): { [assetId: string]: ImageAssetRow }
    {
        return this._cachedImageMap;
    }

    constructor(
        private langService: LangService,
        private runtimeService: RuntimeService,
        private httpClient: HttpClient
    ) {}

    /**
     * Sets the current name of the application tab in the application shell.
     */
    public async refreshDataModel(): Promise<DataModelFile>
    {
        const urlRaw: string = this.runtimeService.getConfigValue("AggregatorDataService:DatabaseUrlFormat");
        const desiredLocale: string = this.langService.currentLocale;
        const dbUrlForLocale: string =  urlRaw.replace('{Locale}', desiredLocale);
        log.verbose({ fromUrl: dbUrlForLocale, forLocale: desiredLocale }, `Retrieving data model.`);

        this._cachedAggregatorData = await this.httpClient.get<DataModelFile>(dbUrlForLocale).toPromise();

        this._cachedImageMap = {};
        this._cachedAggregatorData.Tables.ImageAssetRows
            .forEach(asset => this._cachedImageMap[asset.ImageId] = asset);

        this.extendLabels(this._cachedAggregatorData);

        return this._cachedAggregatorData;
    }

    /** Extends lang service with the labels received from the aggregator. */
    private extendLabels(aggregatorData: DataModelFile): void
    {
        const labelDataMap: { [labelKey: string]: string } = {};
        aggregatorData.Tables.LabelRows.forEach(row => labelDataMap[row.LabelId] = row.DisplayNameLoc);

        this.langService.setLabelData(labelDataMap, this.langService.currentLocale, true, true);
    }
}
