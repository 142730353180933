import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { ProjectEntity } from "./BackendApi/projectentity.entity";
import { ProjectDataService } from "./project-data.service";

@Injectable({providedIn: 'root'})
export class BuildingTypeResolver implements Resolve<string> {
    // Constructors
    constructor(
        private readonly router: Router
    ){ } 
    
    // Methods
    public resolve(ars: ActivatedRouteSnapshot): string {
        return ars.paramMap.get('buildingType');
    }
}