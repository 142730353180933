import { Injectable } from '@angular/core';
import { DteRow } from '../DteufcAggregatorServiceApi/dterow.complex';
import { MathLogger } from './MathLogger';

export interface IDtesFromTemperaturesParams
{
    dteRows: DteRow[];
}

export interface IRecommendedDteParams
{
    /** Usually 60 °C or abgesenkte temperatur */
    bedarfstemperaturPwh: number;

    bedarfsvolumenStromLMin: number;

    dteRows: DteRow[];
}

export interface IFlowTemperatureFromVsParams
{
    bedarfsvolumenstromLMin: number;
    bedarfstemperaturPwh: number;
    dteRow: DteRow;
}

export interface IDteResult
{
    dte: '40' | '70' | '100';

    /**
     * The mimum bedarfsvolumenstrom that this dte can provide under the given circumstances.
     */
    minBedarfsvolumenstromLMin: number;

    /**
     * The maximum bedarfsvolumenstrom that this dte can provide under the given circumstances.
     */
    maxBedarfsvolumenstromLMin: number;
}

export interface IVsIntermediateResult
{
    /**
     * If interpolation between two precalculated bedarfsvolumenströmen is required, this value as the facotr in the multiplication.
     */
    interpolationsSchrittwert: number;
    bedarfsvolumenstrom: number;
}

/**
 * Cached data for one DTE / pwh combination.
 */
export interface IDtePwhParams
{
    dteRow: DteRow;

    /**
     * Normally all dte's support all vorlautemperaturen, however the max. volumetric flow rate
     * defined for a dte defines a cap where increasing the flow rate has no more effect.
     */
    maxEffectiveVorlauftemperatur: number;
    minEffectiveVorlauftemperatur: number;
    minEffectiveBedarfsvolumenstrom: number;

    /**
     * A map containing all bedarfsvolumenstroms for each relevant heizungsvorlauftemperatur.
     */
    heizungsvorlaufVsMap: { [heizungsvorlaufTemp: number]: number };
}

/**
 * Dte (Druchflusstrinkwasser-Erwärmer) related mathematic functions.
 *
 * Sources of mathematical formulas:
 * - 201126-KR Schema Auswahl DTE V1.1.xlsx
 * - 211117-KR Schema Auswahl DTE V1.2.xlsx
 *
 * rauch@klaus-rauch.de
 *
 * How to draw a DTE performance chart using methods of this class:
 *   - Values on the X-Axis: min, max and step size can be taken from DteRow
 *   - Values on the Y-Axis: min, max and step size can be taken from DteRow
 *   - Points in the diagram:
 *     - volauftemperaturenMesspunkteFromDte() provides the "y" values. dteVsMaxFromBedarfstemp() will calculate the "x" value for each "y".
 *     - for DTE in Standardbetrieb (PWH 60 °C) you want to pass a "Bedarfstemperatur" of DteMath.pwh to dteVsMaxFromBedarfstemp()
 *     - for DTE in abgesenktem Betrieb (PWH X °C) you want to pass X as the "Bedarfstemperatur". Where X is determined ßby pwhAbgesenktFromPwhC()
 *   - The diagram may contain up to two "Betriebspunkte" (a special point in the diagram visually highlighted by extra lines on the x and y axis)
 *     - for DTE in Standardbetrieb (PWH 60 °C) you can pass the configured "Vorlauftemperatur" which is the y value to dteVsMaxFromBedarfstemp() again to get x
 *     - for DTE in abgesenktem Betrieb (PWH X °C) you have to caluclate the x value first using bedarfsvolumenstromAbgesenktFromBedarfsvolumenstrom() and then use this in benoetigteVorlauftemperaturFromBedarfsvolumenstrom() to determine y
 *
 * To know which DTEs are available / work with the given setup at all use dtesFromBedarfsvolumenstrom()
 */
@Injectable()
export class DteMath
{
    private static readonly allDteTypes = Object.freeze(['40', '70', '100']);

    /** PWH - Bedarfstemperatur */
    public static readonly pwh = 60;

    /** PWH-C bei 60 °C (DTE im Standardbetrieb) */
    public static readonly pwhc60 = 55;

    /** PWC (Kaltwasser) */
    public static readonly pwc = 10;

    public static readonly TemperaturspreizungPwhPwhc = 5; // K
    public static readonly Umgebungstemperatur = 23; // °C

    /**
     * We often determine DTEs by using the same bedarfstemperatur and vorlauftemperatur, so we can cache the results.
     */
    private dteTemperaturesVsCache: { [key: string]: IDteResult[] } = {};

    private cachedDteParams: { [dtePwh: string]: IDtePwhParams } = {};

    /**
     * We can cache some of the intermediate results for further calculations.
     */
    private dteCachedVsMap:
    {
        [dte: string]:
        {
            [heizungsvorlaufTemperatur: number]:
            {
                [pwh: number]: IVsIntermediateResult
            }
        }
    } = {};

    public bedarfsvolumenstromAbgesenktFromBedarfsvolumenstrom(bedarfsvolumenStromLMin: number, pwhAbgesenkt: number)
    {
        return bedarfsvolumenStromLMin * (60 - DteMath.pwc) / (pwhAbgesenkt - DteMath.pwc);
    }

    /**
     * Abgesenkte Bedarfstemperatur from PWH-C (Zirkulationstemperatur) abgesenkt.
     */
    public pwhAbgesenktFromPwhC(pwhCAbgesenktTemperatur: number): number
    {
        const logger = new MathLogger('PwhAbgesenktFromPwhC');
        logger.logInputs(
        {
            pwhCAbgesenktTemperatur,
            temperaturspreizungPwhPwhc: DteMath.TemperaturspreizungPwhPwhc,
            umgebungTemp: DteMath.Umgebungstemperatur
        });

        const reducedVerlustleistungFactor: number = (pwhCAbgesenktTemperatur - DteMath.Umgebungstemperatur) / (DteMath.pwh - DteMath.TemperaturspreizungPwhPwhc - DteMath.Umgebungstemperatur);
        const temperaturSpreizungAbgesenkt: number = DteMath.TemperaturspreizungPwhPwhc * reducedVerlustleistungFactor;
        const pwhAbgesenkt: number = pwhCAbgesenktTemperatur + temperaturSpreizungAbgesenkt;

        logger.logResult({ pwhAbgesenkt });
        return pwhAbgesenkt;
    }

    /**
     * This is should always return a dte unless no dte works with the configured bedarfsvolumenstrom at all.
     * The recommended dte should be the default selection in the GUI.
     */
    public recommendedDte(params: IRecommendedDteParams): DteRow | undefined
    {
        const maxDesiredVorlauftemperatur = 75; // normwert
        const possibleDtes: DteRow[] = this.dtesFromBedarfsvolumenstrom(params.bedarfsvolumenStromLMin, params)
            .map(dteResult => params.dteRows.find(dteRow => dteRow.Dte === dteResult.dte))
            .sort((a, b) => a.MinBedarfsvolumenstromLMin - b.MinBedarfsvolumenstromLMin);

        return possibleDtes.filter(dte =>
            this.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({ bedarfsvolumenstromLMin: params.bedarfsvolumenStromLMin, dteRow: dte, bedarfstemperaturPwh: params.bedarfstemperaturPwh }) < maxDesiredVorlauftemperatur)
            [0] || possibleDtes[possibleDtes.length - 1];
    }

    /**
     * This is should always return a dte unless no dte works with the configured bedarfsvolumenstrom at all.
     * The recommended dte should be the default selection in the GUI.
     */
    public recommendedDteForAbgesenkt(params: IRecommendedDteParams): DteRow | undefined
    {
        const maxDesiredVorlauftemperatur = 75; // normwert
        const possibleDtes: DteRow[] = this.dtesFromBedarfsvolumenstromForAbgesenkt(params.bedarfsvolumenStromLMin, params)
            .map(dteResult => params.dteRows.find(dteRow => dteRow.Dte === dteResult.dte))
            .sort((a, b) => a.MinBedarfsvolumenstromLMin - b.MinBedarfsvolumenstromLMin);

        return possibleDtes.filter(dte =>
            this.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({ bedarfsvolumenstromLMin: params.bedarfsvolumenStromLMin, dteRow: dte, bedarfstemperaturPwh: params.bedarfstemperaturPwh }) < maxDesiredVorlauftemperatur)
            [0] || possibleDtes[possibleDtes.length - 1];
    }

    /** Determine available DTEs for temperaturabsenkung. */
    public dtesFromBedarfsvolumenstromForAbgesenkt(bedarfsvolumenStromLMin: number, temperatureParams: IDtesFromTemperaturesParams): IDteResult[]
    {
        const dtesForStandardbetrieb: IDteResult[] = this.dtesFromBedarfsvolumenstrom(bedarfsvolumenStromLMin, temperatureParams);
        const highestAbgesenktPwhc = 54;
        const pwhAbgesenktMax = this.pwhAbgesenktFromPwhC(highestAbgesenktPwhc);
        const bedarfsvolumenstromAbgesenktMin = this.bedarfsvolumenstromAbgesenktFromBedarfsvolumenstrom(bedarfsvolumenStromLMin, pwhAbgesenktMax);
        const dtesForAbgesenktBetrieb: IDteResult[] = dtesForStandardbetrieb.filter(dte =>
            bedarfsvolumenstromAbgesenktMin <= dte.maxBedarfsvolumenstromLMin);

        return dtesForAbgesenktBetrieb;
    }

    /**
     * Determines the lowest possible PWH-C value to operate this dte with.
     */
    public optimalPwhC(dteRow: DteRow, bedarfsvolumenstromLMin: number): number
    {
        const lowestAbgesenktPwhc = 45;
        const highestAbgesenktPwhc = 54;

        let pwhcOptimal = undefined;
        for (let i = lowestAbgesenktPwhc; i <= highestAbgesenktPwhc; i++)
        {
            const bedarfstemperaturPwh = this.pwhAbgesenktFromPwhC(i);
            const bedarfsvolumenstromAbgesenktLMin = this.bedarfsvolumenstromAbgesenktFromBedarfsvolumenstrom(bedarfsvolumenstromLMin, bedarfstemperaturPwh);
            if (bedarfsvolumenstromAbgesenktLMin <= dteRow.MaxBedarfsvolumenstromLMin)
            {
                pwhcOptimal = i;
                break;
            }
        }
        return pwhcOptimal;
    }

    public dtesFromBedarfsvolumenstrom(bedarfsvolumenStromLMin: number, temperatureParams: IDtesFromTemperaturesParams): IDteResult[]
    {
        const logger = new MathLogger('PassendeDtesFuerBedarfsvolumenstrom');
        logger.logInputs({
            bedarfsvolumenStromLMin,
        });

        const dteRowMap: { [dte: string]: DteRow } = {};
        temperatureParams.dteRows.forEach(row => dteRowMap[row.Dte] = row);

        const resultDtes: IDteResult[] = DteMath.allDteTypes
            .map(dte =>
            {
                const dteRow: DteRow = dteRowMap[dte];
                if (!dteRow)
                    throw new Error(`Expected table row for dte "${dte}" to exists.`);

                return {
                    dte,
                    minBedarfsvolumenstromLMin: dteRow.MinBedarfsvolumenstromLMin,
                    maxBedarfsvolumenstromLMin: dteRow.MaxBedarfsvolumenstromLMin
                } as IDteResult;
            }).filter(resultDte =>
                bedarfsvolumenStromLMin <= resultDte.maxBedarfsvolumenstromLMin &&
                bedarfsvolumenStromLMin >= resultDte.minBedarfsvolumenstromLMin);

        logger.logResult({ dtes: resultDtes });
        return resultDtes;
    }

    /**
     * Calculates the maximum bedarfsvolumenstrom a dte can support with the given heizungsvorlaufTemperatur.
     * In DTE performance charts, this is the value of x for a given heizungsvorlaufTemperatur y.
     */
    private dteVsFromBedarfstempInternal(
        dteRow: DteRow,
        heizungsvorlaufTemperatur: number,
        bedarfstemperaturPwh: number
    ): number
    {
        const dteVsMap = this.getVsMap(dteRow.Dte as any, heizungsvorlaufTemperatur);
        if (dteVsMap[bedarfstemperaturPwh])
            return dteVsMap[bedarfstemperaturPwh].bedarfsvolumenstrom;
        else
        {
            // if we have no precalculated value available, we will interpolate between the existing values
            const nextKnownBedarfstemperatur: number = Object.keys(dteVsMap)
                .map(pwhString => parseInt(pwhString, 10))
                .filter(pwh => pwh < bedarfstemperaturPwh)
                .sort((a, b) => b - a)[0];

            if (!nextKnownBedarfstemperatur)
                throw new Error('Can not interpolate because there are no formulas defined for bedarfstemperatur ' + bedarfstemperaturPwh);

            const pwhDelta = bedarfstemperaturPwh - nextKnownBedarfstemperatur;
            return dteVsMap[nextKnownBedarfstemperatur].bedarfsvolumenstrom + (dteVsMap[nextKnownBedarfstemperatur].interpolationsSchrittwert * pwhDelta);
        }
    }

    /**
     * Use this to calculate the druckverlust values. Pass either DteRow.KvsSecondary or DteRow.KvsCirculationFlow to calculate both values.
     */
    public dteDruckverlust(bedarfsvolumenstrom: number, kvsValue: number): number
    {
        const logger = new MathLogger('DteDruckverlust');
        logger.logInputs({ bedarfsvolumenstrom, kvsValue });

        const druckverlustHPa = Math.pow(bedarfsvolumenstrom * (60 / 1000 / kvsValue), 2) * 1000;
        logger.logResult({ druckverlustHPa });
        return druckverlustHPa;
    }

    /**
     * Gets the maximum bedarfsvolumenstrom a dte can support with the given heizungsvorlaufTemperatur.
     * In DTE performance charts, this is the value of x for a given heizungsvorlaufTemperatur y.
     */
    public dteVsFromBedarfstemp(
        dteRow: DteRow,
        heizungsvorlaufTemperatur: number,
        bedarfstemperaturPwh: number
    ): number
    {
        const dteParams: IDtePwhParams = this.getDtePwhParams(dteRow, bedarfstemperaturPwh);
        if (heizungsvorlaufTemperatur > dteParams.maxEffectiveVorlauftemperatur)
            heizungsvorlaufTemperatur = dteParams.maxEffectiveVorlauftemperatur;

        let vs: number;
        if (dteParams.heizungsvorlaufVsMap[heizungsvorlaufTemperatur])
            vs = dteParams.heizungsvorlaufVsMap[heizungsvorlaufTemperatur];
        else
            vs = this.dteVsFromBedarfstempInternal(dteRow, heizungsvorlaufTemperatur, bedarfstemperaturPwh);

        return Math.max(Math.min(vs, dteRow.MaxBedarfsvolumenstromLMin), dteParams.minEffectiveBedarfsvolumenstrom);
    }

    public benoetigteVorlauftemperaturFromBedarfsvolumenstrom(params: IFlowTemperatureFromVsParams): number
    {
        const logger = new MathLogger('BenoetigteVorlauftemperaturFromBedarfsvolumenstrom');
        logger.logInputs(params);

        const dteParams: IDtePwhParams = this.getDtePwhParams(params.dteRow, params.bedarfstemperaturPwh);
        const vsMap = dteParams.heizungsvorlaufVsMap;

        const maxVsPoints = Object.keys(vsMap).map(vorlauftemperatur => ({ vs: vsMap[vorlauftemperatur], vorlauftemperatur: parseFloat(vorlauftemperatur) }));

        const pointIdx = maxVsPoints.findIndex(p => params.bedarfsvolumenstromLMin <= p.vs);
        const point = maxVsPoints[pointIdx];
        // if bedarfsvolumenstrom is greater than the dte can support
        if (pointIdx === -1)
        {
            return this.benoetigteVorlauftemperaturFromBedarfsvolumenstrom(
            {
                bedarfstemperaturPwh: params.bedarfstemperaturPwh,
                bedarfsvolumenstromLMin: params.dteRow.MaxBedarfsvolumenstromLMin,
                dteRow: params.dteRow
            });
        }
        // if bedarfsvolumenstrom would result in less than minEffectiveVorlauftemperatur
        if (pointIdx === 0)
            return dteParams.minEffectiveVorlauftemperatur;

        if (point.vs === params.bedarfsvolumenstromLMin)
        {
            logger.logResult({ benoetigteVorlauftemperatur: point.vorlauftemperatur });
            return point.vorlauftemperatur;
        }
        else
        {
            const prevPoint = maxVsPoints[pointIdx - 1];
            const vsDiff = point.vs - params.bedarfsvolumenstromLMin;
            const vsPointsDiff = point.vs - prevPoint.vs;
            const factor = (vsDiff / vsPointsDiff);
            const temperatureDiff = point.vorlauftemperatur - prevPoint.vorlauftemperatur;
            const result = point.vorlauftemperatur - temperatureDiff * factor;

            logger.logIntermediateResult({ prevPoint, factor, tempDelta: temperatureDiff, result });

            logger.logResult({ benoetigteVorlauftemperatur: result });

            return result;
        }
    }

    public getDtePwhParams(dteRow: DteRow, bedarfstemperaturPwh: number): IDtePwhParams
    {
        const cacheKey = dteRow.Dte + bedarfstemperaturPwh;
        if (this.cachedDteParams[cacheKey])
            return this.cachedDteParams[cacheKey];

        const relevanteVorlauftemperaturen: number[] = this.volauftemperaturenMesspunkteFromDte(dteRow, bedarfstemperaturPwh);
        const heizungsvorlaufVsMap: { [heizungsvorlaufTemp: number]: number } = {};
        let maxEffectiveVorlauftemperatur = 0;
        let previousVs = 0;
        relevanteVorlauftemperaturen.forEach(vorlaufTemp =>
        {
            const vs = this.dteVsFromBedarfstempInternal(dteRow, vorlaufTemp, bedarfstemperaturPwh);
            if (vs > dteRow.MaxBedarfsvolumenstromLMin && maxEffectiveVorlauftemperatur === 0)
            {
                const vsDiff = vs - previousVs;
                const relDiff = vs - dteRow.MaxBedarfsvolumenstromLMin;
                const interpolationFactor = -(relDiff / vsDiff);
                const interpolationTemperature = 0.5 * interpolationFactor;

                maxEffectiveVorlauftemperatur = vorlaufTemp + interpolationTemperature;
            }
            heizungsvorlaufVsMap[vorlaufTemp] = Math.min(vs, dteRow.MaxBedarfsvolumenstromLMin);
            previousVs = vs;
        });

        const lowestTemperature = relevanteVorlauftemperaturen[0];
        heizungsvorlaufVsMap[lowestTemperature];

        const dteParams: IDtePwhParams = {
            dteRow,
            maxEffectiveVorlauftemperatur,
            minEffectiveVorlauftemperatur: lowestTemperature,
            minEffectiveBedarfsvolumenstrom: heizungsvorlaufVsMap[lowestTemperature],
            heizungsvorlaufVsMap
        };
        return this.cachedDteParams[cacheKey] = dteParams;
    }

    /**
     * Calculates the vorlauftemperaturen for that we draw points in dte performance charts.
     * Vorlauftemperaturen resemble the y components of the points in the performance chart.
     */
    public volauftemperaturenMesspunkteFromDte(dte: DteRow, bedarfstemperaturPwh: number): number[]
    {
        // the precision of the graph in the diagram representations
        const step: number = 0.5;
        const pwhHvlDifference: number = 5;

        const min: number = bedarfstemperaturPwh + pwhHvlDifference;
        const max: number = dte.DiagramEndVorlauftemperatur + 5;

        const results: number[] = [];
        for (let i = min; i < (max + step); i += step)
            results.push(i);

        return results;
    }

    private getVsMap(dte: '40' | '70' | '100', heizungsvorlaufTemperatur: number): { [pwh: string]: IVsIntermediateResult }
    {
        let cacheMap: { [heizungsvorlaufTemperatur: number]: { [pwh: string]: IVsIntermediateResult } };
        cacheMap = this.dteCachedVsMap[dte] || (this.dteCachedVsMap[dte] = {});

        const logger = new MathLogger('DteBedarfsvolumenstromMaximalwerte');
        logger.logInputs({
            Dte: dte,
            HeizungsvorlaufTemperatur: heizungsvorlaufTemperatur,
        });

        let result: { [pwh: string]: IVsIntermediateResult };
        if (cacheMap[heizungsvorlaufTemperatur])
            result = cacheMap[heizungsvorlaufTemperatur];
        else
            result = cacheMap[heizungsvorlaufTemperatur] = this.calculateVsMap(dte, heizungsvorlaufTemperatur);

        logger.logResult({ maximalwerte: result });
        return result;
    }

    // formulas to caclulate the bedarfsvolumenstrom are predefined. source: 201126-KR Schema Auswahl DTE V1.1.xlsx
    // we simply interpolate between each formula
    private calculateVsMap(dte: '40' | '70' | '100', heizungsvorlaufTemperatur: number): { [pwh: string]: IVsIntermediateResult }
    {
        let vsPwh60: number = 0;
        let vsPwh55: number = 0;
        let vsPwh50: number = 0;
        let vsPwh48: number = 0;

        if (dte === '40')
        {
            vsPwh60 =
                0.0000026484 * Math.pow(heizungsvorlaufTemperatur, 4) -
                0.0007876027 * Math.pow(heizungsvorlaufTemperatur, 3) +
                0.0783145120 * Math.pow(heizungsvorlaufTemperatur, 2) -
                1.9315716343 * Math.pow(heizungsvorlaufTemperatur, 1) -
                0.0000034876;

            vsPwh55 =
                0.0000222355 * Math.pow(heizungsvorlaufTemperatur, 4) -
                0.0065356749 * Math.pow(heizungsvorlaufTemperatur, 3) +
                0.7095239180 * Math.pow(heizungsvorlaufTemperatur, 2) -
                32.710368183 * Math.pow(heizungsvorlaufTemperatur, 1) +
                569.874234371;

            vsPwh50 =
                0.0001330580 * Math.pow(heizungsvorlaufTemperatur, 3) -
                0.0307657840 * Math.pow(heizungsvorlaufTemperatur, 2) +
                3.4275573636 * Math.pow(heizungsvorlaufTemperatur, 1) -
                78.6210466186;

            vsPwh48 =
                0.0000137401 * Math.pow(heizungsvorlaufTemperatur, 4) -
                0.0028822628 * Math.pow(heizungsvorlaufTemperatur, 3) +
                0.2044846751 * Math.pow(heizungsvorlaufTemperatur, 2) -
                4.0524921094 * Math.pow(heizungsvorlaufTemperatur, 1) +
                0.000019622;
        }
        else if (dte === '70')
        {
            vsPwh60 =
                0.00002359670 * Math.pow(heizungsvorlaufTemperatur, 4) -
                0.00628730560 * Math.pow(heizungsvorlaufTemperatur, 3) +
                0.56070671160 * Math.pow(heizungsvorlaufTemperatur, 2) -
                15.2383250091 * Math.pow(heizungsvorlaufTemperatur, 1) -
                0.0000295289;

            vsPwh55 =
                0.00057971000 * Math.pow(heizungsvorlaufTemperatur, 3) -
                0.16567287780 * Math.pow(heizungsvorlaufTemperatur, 2) +
                17.4010351967 * Math.pow(heizungsvorlaufTemperatur, 1) -
                496.7246376814;

            vsPwh50 =
                0.0006666667 * Math.pow(heizungsvorlaufTemperatur, 3) -
                0.1814285714 * Math.pow(heizungsvorlaufTemperatur, 2) +
                17.919047619 * Math.pow(heizungsvorlaufTemperatur, 1) -
                468.7142857143;

            vsPwh48 =
                -0.0021514905 * Math.pow(heizungsvorlaufTemperatur, 3) +
                0.28741837190 * Math.pow(heizungsvorlaufTemperatur, 2) -
                7.75025285410 * Math.pow(heizungsvorlaufTemperatur, 1) -
                0.000234901;
        }
        else if (dte === '100')
        {
            vsPwh60 =
                -0.00000237910 * Math.pow(heizungsvorlaufTemperatur, 5) +
                0.000866584600 * Math.pow(heizungsvorlaufTemperatur, 4) -
                0.114520072900 * Math.pow(heizungsvorlaufTemperatur, 3) +
                6.576614856700 * Math.pow(heizungsvorlaufTemperatur, 2) -
                137.6798019409 * Math.pow(heizungsvorlaufTemperatur, 1) +
                0.005174294;

            vsPwh55 =
                -0.00016445740 * Math.pow(heizungsvorlaufTemperatur, 4) +
                0.052248572700 * Math.pow(heizungsvorlaufTemperatur, 3) -
                6.175618244200 * Math.pow(heizungsvorlaufTemperatur, 2) +
                324.3973607553 * Math.pow(heizungsvorlaufTemperatur, 1) -
                6287.9257361156;

            vsPwh50 =
                0.00394202900 * Math.pow(heizungsvorlaufTemperatur, 3) -
                0.84770186340 * Math.pow(heizungsvorlaufTemperatur, 2) +
                62.7455486543 * Math.pow(heizungsvorlaufTemperatur, 1) -
                1442.5590062124;

            vsPwh48 =
                0.00771546030 * Math.pow(heizungsvorlaufTemperatur, 3) -
                1.48313799260 * Math.pow(heizungsvorlaufTemperatur, 2) +
                97.8258216701 * Math.pow(heizungsvorlaufTemperatur, 1) -
                2066.2739251174;
        }
        else
            throw new Error(`No formula for dte ${dte}`);

        return {
            60: {
                bedarfsvolumenstrom: vsPwh60,
                interpolationsSchrittwert: 0
            },
            55: {
                bedarfsvolumenstrom: vsPwh55,
                interpolationsSchrittwert: (vsPwh60 - vsPwh55) / 5
            },
            50: {
                bedarfsvolumenstrom: vsPwh50,
                interpolationsSchrittwert: (vsPwh55 - vsPwh50) / 5
            },
            48: {
                bedarfsvolumenstrom: vsPwh48,
                interpolationsSchrittwert: (vsPwh50 - vsPwh48) / 2
            },
        };
    }
}
