// labels to improve the default localization of dropdowns
export const localization = {
    'de': {
        'dropdowns': {
            'noRecordsTemplate': 'Keine Ergebnisse',
            'overflowCountTemplate': '+${count} weitere...',
            'selectAllText': 'Alles auswählen',
            'unSelectAllText': 'Alles auswählen',
        },
        'drop-down-list': {
            'noRecordsTemplate': 'Keine Datensätze gefunden',
            'virtualAllCountriesItemDisplayName': 'Alle Länder',
            'virtualAllCountriesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/country-flags-svg-small/all.svg',
            'virtualAllLocalesItemDisplayName': 'Jede Sprache',
            'virtualAllLocalesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/locale-flags-svg-small/all.svg',
        },
        'combo-box': {
            'noRecordsTemplate': 'Keine Ergebnisse',
        },
        'auto-complete': {
            'noRecordsTemplate': 'Keine Ergebnisse',
        },
        'multi-select': {
            'noRecordsTemplate': 'Keine Datensätze gefunden',
            'actionFailureTemplate': 'Die Anfrage ist fehlgeschlagen',
            'overflowCountTemplate': '+${count} weitere...',
            'selectAllText': 'Alles auswählen',
            'unSelectAllText': 'Alles auswählen',
            'virtualAllCountriesItemDisplayName': 'Alle Länder',
            'virtualAllCountriesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/country-flags-svg-small/all.svg',
            'virtualAllLocalesItemDisplayName': 'Jede Sprache',
            'virtualAllLocalesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/locale-flags-svg-small/all.svg',
        }
    },
    'en-US': {
        'drop-down-list': {
            'virtualAllCountriesItemDisplayName': 'All Countries',
            'virtualAllCountriesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/country-flags-svg-small/all.svg',
            'virtualAllLocalesItemDisplayName': 'Any Language',
            'virtualAllLocalesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/locale-flags-svg-small/all.svg',
        },
        'multi-select': {
            'virtualAllCountriesItemDisplayName': 'Alle Länder',
            'virtualAllCountriesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/country-flags-svg-small/all.svg',
            'virtualAllLocalesItemDisplayName': 'Jede Sprache',
            'virtualAllLocalesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/locale-flags-svg-small/all.svg'
        }
    },
    'en-GB': {
        'drop-down-list': {
            'virtualAllCountriesItemDisplayName': 'All Countries',
            'virtualAllCountriesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/country-flags-svg-small/all.svg',
            'virtualAllLocalesItemDisplayName': 'Any Language',
            'virtualAllLocalesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/locale-flags-svg-small/all.svg',
        },
        'multi-select': {
            'virtualAllCountriesItemDisplayName': 'Alle Länder',
            'virtualAllCountriesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/country-flags-svg-small/all.svg',
            'virtualAllLocalesItemDisplayName': 'Jede Sprache',
            'virtualAllLocalesItemFlagUrl': 'https://pspstoragepublicassets.blob.core.windows.net/public-assets-production/locale-flags-svg-small/all.svg'
        }
    }
};
