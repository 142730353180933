import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './routes/home/home.module';
import { GenericDialogService } from 'src/core-lib/ej2/services/GenericDialogService';
import { ServiceLocator } from 'src/core-lib/ej2/services/ServiceLocator';
import { PspHelpNotifyIconModule } from 'src/core-lib/angular/components/navigation/psp-help-notify-icon/psp-help-notify-icon.module';
import { PspUserNotifyIconModule } from 'src/core-lib/angular/components/navigation/psp-user-notify-icon/psp-user-notify-icon.module';
import { DteComponent } from './routes/dte/dte.component';
import { CategoryService, ColumnSeriesService, DateTimeService, LegendService, LineSeriesService, TooltipService } from '@syncfusion/ej2-angular-charts';
import { DteTemperatureComponent } from './routes/dte-temperature/dte-temperature.component';
import { FurtherDetailsComponent } from './routes/further-details/further-details.component';
import { OverviewModule } from './routes/overview/overview.module';


import { ResultComponent } from './routes/result/result.component';
import { DailyLoadProfileComponent } from './routes/daily-load-profile/daily-load-profile.component';
import { PipelineModule } from './routes/pipeline/pipeline.module';
import { HotWaterDemandModule } from './routes/hot-water-demand/hot-water-demand.module';
import { CirculationVolumetricFlowComponent } from './routes/circulation-volumetric-flow/circulation-volumetric-flow.component';
import { HeatGeneratingTypesComponent } from './routes/heat-generating-types/heat-generating-types.component';
import { SelectCalculationUfcComponent } from './routes/select-calculation-ufc/select-calculation-ufc.component';
import { SelectCalculationReservoirComponent } from './routes/select-calculation-reservoir/select-calculation-reservoir.component';
import { ResizeService, SearchService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { PspLoadingSpinnerModule } from 'src/core-lib/angular/components/utility/psp-loading-spinner/psp-loading-spinner.module';
import { UserService } from './core/UserService';
import { BaseDataDialogModule } from './routes/base-data-dialog/base-data-dialog.module';
import { StructureDialogComponent } from './routes/structure-dialog/structure-dialog.component';
import { ConfigDteDialogComponent } from './routes/config-dte-dialog/config-dte-dialog.component';
import { ConfigDetailsDialogComponent } from './routes/config-details-dialog/config-details-dialog.component';
import { ProjectViewModule } from './routes/project-view/project-view.module';
import { SelectionItemRadioComponent } from './routes/selection-item-radio/selection-item-radio.component';
import { ExamplePageComponent } from './routes/example-page/example-page.component';
import { PspNotifyIcon } from 'src/core-lib/ej2/components/navigation/psp-notify-icon/PspNotifyIcon';
import { PspNotifyIconModule } from 'src/core-lib/angular/components/navigation/psp-notify-icon/psp-notify-icon.module';
import { AggregatorDataService } from './core/AggregatorDataService';
import { HeatGeneratorPowerComponent } from './routes/heat-generator-power/heat-generator-power.component';
import { DemandVolumetricFlowResidentialComponent } from './routes/demand-volumetric-flow-residential/demand-volumetric-flow-residential.component';
import { DemandVolumetricFlowOtherComponent } from './routes/demand-volumetric-flow-other/demand-volumetric-flow-other.component';
import { DemandVolumetricFlowOtherModule } from './routes/demand-volumetric-flow-other/demand-volumetric-flow-other.module';
import { DemandVolumetricFlowResidentialModule } from './routes/demand-volumetric-flow-residential/demand-volumetric-flow-residential.module';
import { SliderTextComboModule } from './shared/slider-text-combo-component/slider-text-combo.module';
import { SliderTextComboComponent } from './shared/slider-text-combo-component/slider-text-combo.component';
import { WebTrekkService } from './core/WebTrekkService';
import { PspSiteHeaderModule } from "../core-lib/angular/components/navigation/psp-site-header/psp-site-header.module";
import { PspRoofSiteHeaderModule } from "../core-lib/angular/components/navigation/psp-roof-site-header/psp-roof-site-header.module";

@NgModule({
    declarations: [
        AppComponent,
        // DemandVolumetricFlowComponent,
        DteComponent,
        DteTemperatureComponent,
        FurtherDetailsComponent,
        ResultComponent,
        CirculationVolumetricFlowComponent,
        HeatGeneratingTypesComponent,
        DailyLoadProfileComponent,
        SelectCalculationUfcComponent,
        SelectCalculationReservoirComponent,
        ExamplePageComponent,
        SelectionItemRadioComponent,
        HeatGeneratorPowerComponent,
    ],
    providers: [
        GenericDialogService,
        LineSeriesService,
        LegendService,
        SortService,
        DateTimeService,
        CategoryService,
        ColumnSeriesService,
        ResizeService,
        SearchService,
        TooltipService,
        WebTrekkService,
        ToolbarService,
        {
            provide: APP_INITIALIZER,
            deps: [Injector, AggregatorDataService, UserService],
            useFactory: (injector: Injector, aggregatorDataService: AggregatorDataService, userService: UserService) => {
                // configure out static service locator for our custom Syncfusion components and core services
                ServiceLocator.setInjector(injector);
                return () => Promise.all([userService.login(), aggregatorDataService.refreshDataModel()]);
            },
            multi: true,
        }
    ],
    bootstrap: [AppComponent],
    imports: [
        // angular
        RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected', useHash: true }),
        BrowserModule,
        // app global
        CoreModule.forRoot(),
        SharedModule,
        HomeModule,
        PspHelpNotifyIconModule,
        PspUserNotifyIconModule,
        PspNotifyIconModule,
        // routes
        HomeModule,
        OverviewModule,
        PipelineModule,
        HotWaterDemandModule,
        PspLoadingSpinnerModule,
        ProjectViewModule,
        DemandVolumetricFlowOtherModule,
        DemandVolumetricFlowResidentialModule,
        PspSiteHeaderModule,
        PspRoofSiteHeaderModule
    ]
})
export class AppModule { }
