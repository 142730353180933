import { Injectable } from '@angular/core';
import { PipeSpecRow } from '../DteufcAggregatorServiceApi/pipespecrow.complex';
import { PipeSystemRow } from '../DteufcAggregatorServiceApi/pipesystemrow.complex';
import { MathLogger } from './MathLogger';

export interface IDimensioningParams
{
    /** VolumetricFlowLMin */
    volumenstromLMin: number;
    /** Temperature */
    temperatur: number;
    /** PipeLengthMeters */
    rohrlaengeMeter: number;
    /** PipeBends */
    anzahlBoegen: number;
    /** AdditionalPressureLoss */
    zusaetzlicherDruckverlust: number;

    pipeSystem: PipeSystemRow;
    pipeSpecs: PipeSpecRow[];
}

export interface IRohrdimensionFromAuslegungsparameterResults
{
    nennweite: number;
    druckverlustHpa: number;
}

export interface IDruckverlustGesamtParams
{
    druckverlustHpaVorlauf: number;
    druckverlustHpaRlMitte: number;
    druckverlustHpaRlUnten: number;
}

interface IPipeDiameterPrecalc
{
    volumenstromLs: number;
    dichte: number;
    kinViskositaet: number;
    rohrrauhigkeit: number;
    deltaPProLeitung: number;
    rGesProLeitung: number;
}

/**
 * Pipe diameter (Rohrdurchmesser) related mathematic functions.
 *
 * Sources of mathematical formulas:
 * - DTE Auslegung Rohrleitung 2-10_2022-01-11.xlsx
 *
 * Stefan.Grote@viega.de
 */
@Injectable()
export class PipeDiameterMath
{
    /** deltaPPreset */
    private static readonly deltaPVorgabe = 150.0;
    /** lambdaIterationCount */
    private static readonly lambdaIterationen = 10;

    private static readonly maxRohrgefaelleHpaMeter = 10;
    private static readonly maxGeschwindigkeitMeterSekunde = 1.7;

    private mathLogger: MathLogger;

    public constructor()
    {
        this.mathLogger = new MathLogger('Rohrdimension');
    }

    /** pipeDiameterFromDimensioningParams */
    public rohrdimensionFromAuslegungsparameter(params: IDimensioningParams): IRohrdimensionFromAuslegungsparameterResults
    {
        const constants = {
            deltaPVorgabe: PipeDiameterMath.deltaPVorgabe,
            maxRohrgefaelleHpaMeter: PipeDiameterMath.maxRohrgefaelleHpaMeter,
            maxGeschwindigkeitMeterSekunde: PipeDiameterMath.maxGeschwindigkeitMeterSekunde,
        };
        this.mathLogger.logInputs(Object.assign({}, params, constants));

        if (params.rohrlaengeMeter == 0)
        {
            const result: IRohrdimensionFromAuslegungsparameterResults =
            {
                druckverlustHpa: 0,
                nennweite: null
            };
            this.mathLogger.logResult(result);

            return result;
        }

        const deltaPProLeitung = PipeDiameterMath.deltaPVorgabe / 2 - params.zusaetzlicherDruckverlust;
        let preCalcResults: IPipeDiameterPrecalc =
        {
            volumenstromLs: params.volumenstromLMin / 60,
            dichte: 1 / (1 + Math.pow(Math.abs((params.temperatur - 4) / 582), 1.74)) * 1000,
            kinViskositaet: (0.073 + Math.pow((0.7625 + params.temperatur / 73.3), -2)) / 1000000,
            rohrrauhigkeit: params.pipeSystem.Rohrrauhigkeit,
            deltaPProLeitung: deltaPProLeitung,
            rGesProLeitung: deltaPProLeitung / params.rohrlaengeMeter,
        };
        this.mathLogger.logIntermediateResult(preCalcResults);

        const result: IRohrdimensionFromAuslegungsparameterResults = this.rohrdimensionAndDruckverlust(params, preCalcResults);
        this.mathLogger.logResult(result);

        return result;
    }

    private rohrdimensionAndDruckverlust(
        params: IDimensioningParams,
        preCalcResults: IPipeDiameterPrecalc
    ): IRohrdimensionFromAuslegungsparameterResults | null
    {
        const rohrEigenschaften: PipeSpecRow[] =
            params.pipeSpecs.filter(row => row.PipeSystemId === params.pipeSystem.PipeSystemId).sort((a, b) => a.NennweiteDN - b.NennweiteDN);

        this.mathLogger.logIntermediateResult({ IterierbareRohrEigenschaften: rohrEigenschaften });

        const druckverlustVerfuegbar = PipeDiameterMath.deltaPVorgabe / 2;
        this.mathLogger.logIntermediateResult({ druckverlustVerfuegbar });

        for (const rohrDimEigenschaften of rohrEigenschaften)
        {
            this.mathLogger.logIntermediateResult({ fuerDN: rohrDimEigenschaften.NennweiteDN });

            const rohrDi = (rohrDimEigenschaften.Aussendurchmesser - 2 * rohrDimEigenschaften.Wandstaerke) / 1000;
            this.mathLogger.logIntermediateResult({ rohrDi: rohrDi });

            const geschwindigkeitMeterSek = 4 * (preCalcResults.volumenstromLs / 1000) / (Math.pow(rohrDi, 2) * Math.PI);
            this.mathLogger.logIntermediateResult({ geschwindigkeitMeterSek: geschwindigkeitMeterSek });

            // not required?
            this.mathLogger.logIntermediateResult({ reynoldszahlUnused: geschwindigkeitMeterSek * rohrDi / preCalcResults.kinViskositaet });

            const reibungswert = this.reibungswert(preCalcResults, rohrDi, geschwindigkeitMeterSek);
            this.mathLogger.logIntermediateResult({ reibungswert });

            const druckverlustRohrMeter = reibungswert / rohrDi * Math.pow(geschwindigkeitMeterSek, 2) * preCalcResults.dichte / 2 / 100;
            this.mathLogger.logIntermediateResult({ druckverlustRohrMeter });

            // check for matching nennweite
            if (
                geschwindigkeitMeterSek > PipeDiameterMath.maxGeschwindigkeitMeterSekunde ||
                druckverlustRohrMeter > PipeDiameterMath.maxRohrgefaelleHpaMeter
            )
                continue;

            const deltaPRhpA = params.rohrlaengeMeter * druckverlustRohrMeter;
            this.mathLogger.logIntermediateResult({ deltaPRhpA });

            const zBogen = params.anzahlBoegen * rohrDimEigenschaften.ZetaBogen90 * Math.pow(geschwindigkeitMeterSek, 2) * preCalcResults.dichte / 2 / 100;
            this.mathLogger.logIntermediateResult({ zBogen });

            const deltaPGesamtProLeitung = deltaPRhpA + zBogen + params.zusaetzlicherDruckverlust;
            this.mathLogger.logIntermediateResult({ deltaPGesamtProLeitung });

            // check for matching nennweite
            if (deltaPGesamtProLeitung < druckverlustVerfuegbar)
                return { nennweite: rohrDimEigenschaften.NennweiteDN, druckverlustHpa: deltaPGesamtProLeitung };
        };

        return null;
    }

    private reibungswert(
        preCalcResults: IPipeDiameterPrecalc,
        rohrDi: number,
        geschwindigkeitMeterSek: number
    ): number
    {
        let reibungswert = 0;
        for (let i = 0; i < PipeDiameterMath.lambdaIterationen; i++)
        {
            const z1 = (3.71 * (rohrDi * 1000));
            const z2 = (geschwindigkeitMeterSek * rohrDi / preCalcResults.kinViskositaet);
            const z3 = z2 * (0.0000000000001 + Math.sqrt(reibungswert));
            reibungswert = Math.pow(1 / (-2 * Math.log10(preCalcResults.rohrrauhigkeit / z1 + 2.51 / z3)), 2);
        }

        return reibungswert;
    }

    public druckverlustGesamt(params: IDruckverlustGesamtParams): number
    {
        const mathLogger = new MathLogger('DruckverlustGesamt');
        mathLogger.logInputs(params);

        const result = Math.max(params.druckverlustHpaVorlauf + params.druckverlustHpaRlMitte, params.druckverlustHpaVorlauf + params.druckverlustHpaRlUnten);
        mathLogger.logResult(result);
        return result;
    }
}
