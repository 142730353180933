<viega-collapsible-panel>
    <div class="panel-content" panel-content>
            <viega-panel-header
                [stepText]="stepText"
                [stepTitle]="stepTitle"
                [stepBackLabel]="stepBack"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>

            <div class="content-wrapper">
                <!-- Top-Wrapper -->
                <div class="top-content-wrapper">
                    <!-- Top -->
                    <div class="top-panel-content">
                            <p class="description-text" *ngIf="stepDescription" >
                                <span [innerHTML]="stepDescription"></span>&nbsp;
                            </p>
                    </div>
                    <!-- Middle -->
                    <div class="mid-panel-content">

                    </div>
                </div>
                <!-- Bot -->
                <div class="bot-panel-content">
                    <div class="result-box">
                        <div class="text-tooltip-wrapper text-tooltip-wrapper-result">
                            <span class="result-title">{{'PanelResultHeadline_T' | translate}}</span>
                            <app-help-icon hinweisId="Warmwasser.Tagesbedarf.Ergebnis"></app-help-icon>
                        </div>
                        <span class="result-content">{{'PanelResultWarmwassertagesbedarf_T' | translate}} {{getWarmwasserLTag()}}</span>
                        <button psp-button class="e-btn" (click)="navigateNext()" [disabled]='!editData.WarmwasserErgebnisLTag'>{{'WeiterBtn_T' | translate}}</button>
                    </div>
                </div>
            </div>
    </div>

    <div class="content" content>
            <div class="text-tooltip-wrapper text-tooltip-wrapper-content-title">
                <span class="content-title">{{'Headline_T' | translate}}</span>
                <app-help-icon hinweisId="Warmwasser.Tagesbedarf.Info"></app-help-icon>
            </div>
            <div>
                <div class="inner-content radio-wrapper" style="display: flex;">
                    <ejs-radiobutton #hotWaterManual
                    label="{{'RadioManuell_T' | translate}}"
                    name="option"
                    (change)="onChangeErmittlung(WarmwasserExaktErmittlungstyp.Direkt)"
                    [checked]="editData.ErmittlungWarmwasserExakt.Ermittlungstyp == WarmwasserExaktErmittlungstyp.Direkt"
                    ></ejs-radiobutton>
                </div>
                    <div [hidden]="!hotWaterManual.checked">
                        <div class="radioButtonText" style=" margin-left: 1em;">
                            <app-slider-text-combo
                            class="slider-text-combo"
                        	        style="margin-left: 0em;"
                                [sliderMin]="0"
                                [sliderMax]="10000"
                                [sliderStep]="250"
                                [textboxMin]="0"
                                [textboxMax]="99999"
                                [textboxStep]="250"
                                [textboxClass]="'slider-input'"
                                [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                [textboxDecimals]="0"
                                [showLabel]='false'
                                [(value)]='editData.ErmittlungWarmwasserExakt.ErmittlungDirekt.WarmwasserDirekt'
                                (valueChange)="onChangeManualSlider($event)"
                            ></app-slider-text-combo>
                            <!--<ejs-slider
                                #option1
                                [min]=0 [max]=10000 [step]=250
                                [(value)]='editData.ErmittlungWarmwasserExakt.ErmittlungDirekt.WarmwasserDirekt'
                                (change)='onChangeManualSlider($event)'
                                ></ejs-slider>
                             <ejs-textbox class="slider-input" min=0 max=100000 type="number" (change)='onChangeManualSlider($event)' [(value)]='editData.ErmittlungWarmwasserExakt.ErmittlungDirekt.WarmwasserDirekt' floatLabelType="Never" style=" border-color: white !important; width: 9em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>-->
                             <span style="margin-left: 0.6em; width: 37em;">{{'RadioManuell.SliderDescription_T' | translate : SliderDescriptionLiterPerDay : SliderDescriptionDegreeCelsius}}</span>
                        </div>
                    </div>
                <div class="inner-content radio-wrapper text-tooltip-wrapper">
                    <ejs-radiobutton
                        #hotWaterCalculate
                        label="{{'RadioDinEn12831_T' | translate}}"
                        name="option"
                        (change)="onChangeErmittlung(WarmwasserExaktErmittlungstyp.DIN12831)"
                        [checked]="editData.ErmittlungWarmwasserExakt.Ermittlungstyp == WarmwasserExaktErmittlungstyp.DIN12831"
                        ></ejs-radiobutton>
                    <app-help-icon hinweisId="Warmwasser.TagesbedarfNachDin12831"></app-help-icon>
                </div>

                <div [hidden]="!hotWaterCalculate.checked">
                    <div class="inner-content" style="margin-left: 2em;">
                        <span class="titletext">{{'RadioDinEn12831.Headline_T' | translate}}</span>
                        <div style="margin-top: 1em; width: 45em ; display:flex; align-items: baseline; justify-content: flex-start;">
                            <span style="margin-bottom: 0.4em; margin-right: 1em;  width: 11em;">{{'RadioDinEn12831.DropdownNutzungsart_T' | translate}}</span>
                            <ejs-dropdownlist
                                class="dropdown"
                                #structureDataDDL
                                [index]=0
                                [dataSource]='nutzungsarten'
                                [fields]="{text: 'DisplayNameLoc', value: 'DailyNeedUsageTypeId'}"
                                [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsart"
                                (change)="onChangeNutzungsart($event)"
                                (select)="onSelectDropdownNutzungsart($event)"
                                popupWidth="auto"
                                ></ejs-dropdownlist>
                        </div>



                        <div style="margin-top: 1em; width: 45em ;display:flex; align-items: baseline; justify-content: flex-start;">
                            <span style="margin-bottom: 0.4em; margin-right: 1em; width: 11em;">{{'RadioDinEn12831.DropdownNutzung_T' | translate}}</span>
                            <div style="width: 24.5em;">
                                <ejs-dropdownlist
                                    class="dropdown"
                                    #structureDetailsDataDDL
                                    [index]=0
                                    [dataSource]='nutzungen'
                                    [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzung"
                                    [fields]="{text: 'DisplayNameLoc', value: 'DailyNeedUsageId'}"
                                    (change)="onChangeNutzung($event)"
                                    (select)="onSelectDropdownNutzung($event)"
                                    [popupWidth]="getPopupWidth()"
                                    ></ejs-dropdownlist>
                            </div>
                        </div>

                        <div [hidden]="!selectedNutzung.SpezifischerBedarfFlaecheLiterTag"> <!--Bedingung für die beiden radio Buttons-->
                            <div class="inner-content">
                                <ejs-radiobutton
                                    #usageDependent
                                    label="{{'RadioDinEn12831.RadioNutzungsabhängig_T' | translate}}"
                                    name="option2"
                                    (change)="onChangeBerechnung(WwBerechnungValuesBerechnungstyp.Nutzungsabhaengig)"
                                    [checked]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Berechnungstyp == WwBerechnungValuesBerechnungstyp.Nutzungsabhaengig"
                                    ></ejs-radiobutton>
                            </div>
                            <div [hidden]="!usageDependent.checked">
                                <div style="display: flex;">
                                    <div style=" margin-left: 2em;">
                                        <div class="radioButtonText">
                                            <app-slider-text-combo
                                            class="slider-text-combo"
                        	        style="margin-left: 0em;"
                                                [sliderMin]="selectedNutzung.SpezifischeNutzungMin"
                                                [sliderMax]="selectedNutzung.SpezifischeNutzungSliderMax"
                                                [sliderStep]="selectedNutzung.SpezifischeNutzungSliderStep"
                                                [textboxMin]="selectedNutzung.SpezifischeNutzungMin"
                                                [textboxMax]="selectedNutzung.SpezifischeNutzungMax"
                                                [textboxStep]="selectedNutzung.SpezifischeNutzungSliderStep"
                                                [textboxClass]="'slider-input'"
                                                [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                                [textboxDecimals]="0"
                                                [showLabel]='false'
                                                [(value)]='editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig'
                                                (valueChange)="calculateValues()"
                                            ></app-slider-text-combo>
                                            <!--<ejs-slider #option1 [min]=0
                                            [step]="selectedNutzung.SpezifischeNutzungSliderStep"
                                            [max]="selectedNutzung.SpezifischeNutzungSliderMax"
                                            [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig"
                                            (change)="calculateValues()"
                                            ></ejs-slider>
                                            <ejs-textbox (change)="calculateValues()" class="slider-input" min="selectedNutzung.SpezifischeNutzungMin" max="selectedNutzung.SpezifischeNutzungMax" type="number" [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig" floatLabelType="Never" style=" border-color: white !important; width: 6em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>-->
                                            <span class="titletext" style="margin-left: 2em;      width: fit-content;">{{selectedNutzung.SpezifischeNutzungEinheitLoc}}</span>
                                        </div>
                                        <!-- <span>{{'RadioDinEn12831.RadioNutzungsabhaengig.DescriptionEntspricht_D_Html' | translate : DescriptionNutzungsabhaengigLiterPerDay : DescriptionNutzungsabhaengigDegreeCelsius}} </span> -->
                                    </div>
                                </div>
                            </div>

                            <div class="inner-content">
                                <ejs-radiobutton
                                    #areaRelated
                                    label="{{'RadioDinEn12831.RadioFlaechenbezogen_T' | translate}}"
                                     name="option2"
                                    [checked]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Berechnungstyp == WwBerechnungValuesBerechnungstyp.Flaechenbezogen"
                                    (change)="onChangeBerechnung(WwBerechnungValuesBerechnungstyp.Flaechenbezogen)"
                                    ></ejs-radiobutton>
                            </div>
                            <div [hidden]="!areaRelated.checked">
                                <div style="display: flex;">
                                    <div style=" margin-left: 2em;">
                                        <div class="radioButtonText">
                                            <app-slider-text-combo
                                            class="slider-text-combo"
                        	        style="margin-left: 0em;"
                                                [sliderMin]="selectedNutzung.SpezifischeNutzungFlaecheMin"
                                                [sliderMax]="selectedNutzung.SpezifischeNutzungFlaecheSliderMax"
                                                [sliderStep]="selectedNutzung.SpezifischeNutzungFlaecheSliderStep"
                                                [textboxMin]="selectedNutzung.SpezifischeNutzungFlaecheMin"
                                                [textboxMax]="selectedNutzung.SpezifischeNutzungFlaecheMax"
                                                [textboxStep]="selectedNutzung.SpezifischeNutzungFlaecheSliderStep"
                                                [textboxDecimals]="2"
                                                [textboxClass]="'slider-input'"
                                                [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                                [showLabel]='false'
                                                [(value)]='editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter'
                                                (valueChange)="calculateValues()"
                                            ></app-slider-text-combo>
                                            <!--<ejs-slider
                                            #option1 [min]=0 [max]="selectedNutzung.SpezifischeNutzungFlaecheSliderMax"
                                            [step]="selectedNutzung.SpezifischeNutzungFlaecheSliderStep"
                                            [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter"
                                            (change)="calculateValues()"
                                            ></ejs-slider>
                                            <ejs-textbox (change)="calculateValues()" class="slider-input" min=selectedNutzung.SpezifischeNutzungFlaecheMin max=selectedNutzung.SpezifischeNutzungFlaecheMax type="number" [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter" floatLabelType="Never" style=" border-color: white !important; width: 6em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>-->
                                            <div class="unit-wrapper">
                                                <span class="titletext">{{selectedNutzung.SpezNutzungFlaecheEinheitLoc}}</span>
                                            </div>
                                        </div>
                                        <!-- <span>{{'RadioDinEn12831.RadioFlaechenbezogen.DescriptionEntspricht_D_Html' | translate : DescriptionFlaechenbezogenLiterPerDay : DescriptionFlaechenbezogenDegreeCelsius}}</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="selectedNutzung.SpezifischerBedarfFlaecheLiterTag"> <!--Bedingung für spezifischen Bedarf-->
                            <div style="display: flex;">
                                <div style=" margin-top: 2em;">
                                    <span>{{'SpezifischerBedarfHeadline_T' | translate}}</span>
                                    <div class="radioButtonText">
                                        <app-slider-text-combo
                                        class="slider-text-combo"
                        	        style="margin-left: 0em;"
                                                [sliderMin]="selectedNutzung.SpezifischeNutzungMin"
                                                [sliderMax]="selectedNutzung.SpezifischeNutzungSliderMax"
                                                [sliderStep]="selectedNutzung.SpezifischeNutzungSliderStep"
                                                [textboxMin]="selectedNutzung.SpezifischeNutzungMin"
                                                [textboxMax]="selectedNutzung.SpezifischeNutzungMax"
                                                [textboxStep]="selectedNutzung.SpezifischeNutzungSliderStep"
                                                [textboxClass]="'slider-input'"
                                                [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                                [textboxDecimals]="0"
                                                [showLabel]='false'
                                                [(value)]='editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig'
                                                (valueChange)="calculateValues()"
                                            ></app-slider-text-combo>
                                        <!--<ejs-slider
                                        #option1
                                        [step]="selectedNutzung.SpezifischeNutzungSliderStep"
                                        [min]=0 [max]="selectedNutzung.SpezifischeNutzungSliderMax"
                                        [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig"
                                        (change)="calculateValues()"
                                        ></ejs-slider>
                                        <ejs-textbox (change)="calculateValues()" class="slider-input" min=selectedNutzung.SpezifischeNutzungMin max=selectedNutzung.SpezifischeNutzungMax type="number" [(value)]="editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig" floatLabelType="Never" style=" border-color: white !important; width: 6em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>-->
                                        <span class="titletext" style="margin-left: 1.6em; width: fit-content;">{{selectedNutzung.SpezifischeNutzungEinheitLoc}}</span>
                                    </div>
                                    <!-- <span>{{'RadioDinEn12831.RadioNutzungsabhaengig.DescriptionEntspricht_D_Html' | translate : DescriptionNutzungsabhaengigLiterPerDay : DescriptionNutzungsabhaengigDegreeCelsius}} </span> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</viega-collapsible-panel>

