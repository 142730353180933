import { Deferred } from '@syncfusion/ej2-data';
import { Ajax, extend } from '@syncfusion/ej2-base';
import { environment } from 'src/environments/environment';
import { PimStatic } from './PimStatic';

export interface IRequestError
{
    action: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    requestUrl: string,
    request: XMLHttpRequest,
    responseStatus: number,
    responseText: string,
    odataErrorMsg: string
}

export function executeHttpRequest(options: any, authorization?: string): Promise<any>
{
    let defer: Deferred = new Deferred();

    options = extend(
        {},
        {
            contentType: 'application/json; charset=utf-8',
            processData: false,
        }, options);

    let ajax: Ajax = new Ajax(options);

    ajax.beforeSend = () =>
    {
        if (authorization)
            ajax.httpRequest.setRequestHeader('Authorization', authorization);
    };
    ajax.onSuccess = (responseRaw: string, request: Ajax) =>
    {
        const responseObject = JSON.parse(responseRaw || '{}');
        defer.resolve(responseObject);
    };
    ajax.onFailure = (request: XMLHttpRequest) =>
    {
        let odataError = null;
        if (request.status !== 404 && request.response)
            odataError = JSON.parse(request.responseText)?.error;

        defer.reject(
        {
            action: options.type,
            request,
            requestUrl: ajax.url,
            responseStatus: request.status,
            responseText: request.statusText,
            odataErrorMsg: odataError
        } as IRequestError);
    };

    // to prevent promise unhandled errors that occur even when
    // the erros are handled (due to the defer promise)
    ajax.send().catch();

    return defer.promise as Promise<Ajax>;
}
