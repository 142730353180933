import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DimensioningSchematicComponent } from '../dimensioning-schematic/dimensioning-schematic.component';

/**
 * A special schematic container which allows to update certain values immediately.
 */
@Component({
    selector: 'app-ufc-schematic',
    templateUrl: '../dimensioning-schematic/dimensioning-schematic.component.html',
    styleUrls: ['../dimensioning-schematic/dimensioning-schematic.component.scss'],
})
export class UfcSchematicComponent extends DimensioningSchematicComponent implements OnInit, OnChanges
{
    @Input()
    public dte: string;

    @Input()
    public ufc: string;

    @Input()
    public zirkulationsvolumenstrom: string;

    @Input()
    public filtervolumenstrom: string;

    public ngOnInit(): void
    {
        this.schematicType = 'Ufc';
    }

    public ngOnChanges(changes: SimpleChanges)
    {
        this.updateTextElements();
    }

    protected onImageLoaded(): void
    {
        super.onImageLoaded();
        this.updateTextElements();
    }

    private updateTextElements(): void
    {
        if (!this.isImageLoaded)
            return;

        this.setTextElementValue('Station_1', this.dte);
        this.setTextElementValue('Station_2', this.ufc);
        this.setTextElementValue('PWH_C_1_Liter_1', this.zirkulationsvolumenstrom);
        this.setTextElementValue('Filter_1_Liter_1', this.filtervolumenstrom);
    }
}
