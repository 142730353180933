import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef, Injector, Renderer2, ViewContainerRef } from '@angular/core';
import { setValue } from '@syncfusion/ej2-base';
import { ComponentBase, ComponentMixins, IComponentBase } from 'src/core-lib/angular/util';
import { PspLoadingSpinner } from 'src/core-lib/ej2/components/utility/psp-loading-spinner/PspLoadingSpinner';

// properties declared here can be changed by the user in the storyboard UI
export const propertyArgTypes =
{
    size: { control: { type: 'radio', options: ['small', 'medium', 'large'] } },
    displayStyle: { control: { type: 'radio', options: ['light', 'dark'] } },
};

// prevent storybook from updating these properties
const changeIgnoredProperties: string[] = [];

// declare some properties as bindable angular outputs
const outputBindings =
[
    'createdEvent',
    'destroyedEvent',
];

@Component({
    selector: 'psp-loading-spinner',
    template: '',
    inputs: [
        'cssClass',
        'size',
        'displayStyle'
    ],
    outputs: outputBindings,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@ComponentMixins([ComponentBase])
export class PspLoadingSpinnerComponent extends PspLoadingSpinner implements IComponentBase, OnInit, OnDestroy, AfterViewInit, AfterContentChecked
{
    /** @ignore */
    public tagObjects: any = [];

    /** @ignore */
    public context : any;

    /** @ignore */
    public tags: string[] = [];

    protected changeIgnoredPropertyMap: { [propertyName: string]: boolean };

    public constructor(
        private ngEle: ElementRef,
        private srenderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    )
    {
        super();

        this.element = this.ngEle.nativeElement;

        this.changeIgnoredPropertyMap = {};
        changeIgnoredProperties.forEach(prop => this.changeIgnoredPropertyMap[prop] = true);

        this.registerEvents(outputBindings);
        this.addTwoWay.call(this, []);
        setValue('currentInstance', this, viewContainerRef);
        this.context  = new ComponentBase();
    }

    /** @ignore */
    public ngOnInit(): void
    {
        this.context.ngOnInit(this);
    }

    /** @ignore */
    public ngAfterViewInit(): void
    {
        this.context.ngAfterViewInit(this);
    }

    /** @ignore */
    public ngOnDestroy(): void
    {
        this.context.ngOnDestroy(this);
    }

    /** @ignore */
    public ngAfterContentChecked(): void
    {
        this.context.ngAfterContentChecked(this);
    }

    /** @ignore */
    public registerEvents: (eventList: string[]) => void;

    /** @ignore */
    public addTwoWay: (propList: string[]) => void;
}
