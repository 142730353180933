import { INotifyPropertyChanged, Property, addClass, removeClass, select } from '@syncfusion/ej2-base';
import { IPspNotifyIconModel } from './IPspNotifyIconModel';
import { PspDropdownIcon } from '../../inputs/psp-dropdown-icon/PspDropdownIcon';

export class PspNotifyIcon extends PspDropdownIcon implements INotifyPropertyChanged
{
    constructor(options?: IPspNotifyIconModel, element?: string | HTMLButtonElement)
    {
        super(PspNotifyIcon.initOptions(options), element);
    }

    private static initOptions(options?: IPspNotifyIconModel): IPspNotifyIconModel
    {
        options = options || {};
        if (!options.popupWidth)
            options.popupWidth = '340px';
        if (!options.popupHeight)
            options.popupHeight = '500px';
        if (!options.popupPosition)
            options.popupPosition = { X: 'right', Y: 'bottom' };

        return options;
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-notify-icon';
    }

    protected render(): void
    {
        addClass([this.element], 'pspc-notify-icon');
        if (this.cssClass)
            addClass([this.element], this.cssClass.split(' '));

        super.render();
    }

    protected getDropDownCssClass(): string {
        return 'pspc-notify-icon-dropdown wrapped-dropdown ' + this.dropDownCssClass;
    }
}
