import { ChangeDetectionStrategy, Component, ElementRef, Injector, Renderer2, ViewContainerRef } from '@angular/core';
import { ComponentBase, ComponentMixins, IComponentBase, setValue } from 'src/core-lib/angular/util';
import { PspSiteHeader } from 'src/core-lib/ej2/components/navigation/psp-site-header/PspSiteHeader';

// properties declared here can be changed by the user in the storyboard UI
export const propertyArgTypes =
{
    cssClass: { control: 'text' },
    logoImageSrc: { control: 'text' },
};

// declare some properties as bindable angular inputs
const inputBindings =
[
    'cssClass',
    'logoImageSrc',
];

// prevent storybook from updating these properties
const changeIgnoredProperties: string[] = [];

// declare some properties as bindable angular outputs
const outputBindings =
[
    'createdEvent',
    'destroyedEvent',
    'logoClickEvent',
];

@Component({
    selector: 'psp-site-header',
    template: '<ng-content></ng-content>',
    inputs: inputBindings,
    outputs: outputBindings,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@ComponentMixins([ComponentBase])
export class PspSiteHeaderComponent extends PspSiteHeader implements IComponentBase
{
    /** @ignore */
    public tagObjects: any = [];

    /** @ignore */
    public context : any;

    /** @ignore */
    public tags: string[] = [];

    protected changeIgnoredPropertyMap: { [propertyName: string]: boolean };

    public constructor(
        private ngEle: ElementRef,
        private srenderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    )
    {
        super();

        this.element = this.ngEle.nativeElement;

        this.changeIgnoredPropertyMap = {};
        changeIgnoredProperties.forEach(prop => this.changeIgnoredPropertyMap[prop] = true);

        this.registerEvents(outputBindings);
        this.addTwoWay.call(this, []);
        setValue('currentInstance', this, viewContainerRef);
        this.context  = new ComponentBase();
    }

    /** @ignore */
    public ngOnInit(): void
    {
        this.context.ngOnInit(this);
    }

    /** @ignore */
    public ngAfterViewInit(): void
    {
        this.context.ngAfterViewInit(this);
    }

    /** @ignore */
    public ngOnDestroy(): void
    {
        this.context.ngOnDestroy(this);
    }

    /** @ignore */
    public ngAfterContentChecked(): void
    {
        this.context.ngAfterContentChecked(this);
    }

    /** @ignore */
    public registerEvents: (eventList: string[]) => void;

    /** @ignore */
    public addTwoWay: (propList: string[]) => void;
}
