import { environment } from 'src/environments/environment';
import { ExecutionEnvironment } from './ej2/services/IRuntimeInfo';
import { ODataHttpService } from './ej2/services/ODataHttpService';
import { executeHttpRequest } from './ej2/utils/httpUtils';

export type LogLevel = 'Verbose' | 'Debug' | 'Info' | 'Warn' | 'Error' | 'Fatal';

let currentLogLevel: number = 10;

const serverLogEndpoint = environment.backendRootUrl + 'writelog';

export function setLogLevel(newLogLevel: LogLevel)
{
    if (newLogLevel === 'Fatal')
        currentLogLevel = 60;
    else if (newLogLevel === 'Error')
        currentLogLevel = 50;
    else if (newLogLevel === 'Warn')
        currentLogLevel = 40;
    else if (newLogLevel === 'Info')
        currentLogLevel = 30;
    else if (newLogLevel === 'Debug')
        currentLogLevel = 20;
    else if (newLogLevel === 'Verbose')
        currentLogLevel = 10;
    else
        throw new Error(`Unrecognized log level "${newLogLevel}".`);
}

/**
 * Returns a log level commonly used in the given execution environment of the application.
 */
export function logLevelFromExecEnv(execEnvironment: ExecutionEnvironment): LogLevel
{
    if (execEnvironment === 'Release')
        return 'Warn';
    else
        return 'Debug';
}

export const log = {
    fatal: logWriteFatal,
    error: logWriteError,
    warning: logWriteWarning,
    information: logWriteInfo,
    debug: logWriteDebug,
    verbose: logWriteVerbose,
    audit: logWriteAudit,
};

/**
 * Logs a message with fatal severity.
 * @param contextOrMessage Either an object holding attributes with context information or the message to log.
 * @param message The message to log if a context object is given.
 */
function logWriteFatal(contextOrMessage?: {} | string, message?: string, logToBackend: boolean = false)
{
    logWrite(60, contextOrMessage, message, logToBackend);
}

/**
 * Logs a message with fatal severity.
 * @param contextOrMessage Either an object holding attributes with context information or the message to log.
 * @param message The message to log if a context object is given.
 */
function logWriteError(contextOrMessage?: {} | string, message?: string, logToBackend: boolean = false)
{
    logWrite(50, contextOrMessage, message, logToBackend);
}

/**
 * Logs a message with warning severity.
 * @param contextOrMessage Either an object holding attributes with context information or the message to log.
 * @param message The message to log if a context object is given.
 */
function logWriteWarning(contextOrMessage?: {} | string, message?: string, logToBackend: boolean = false)
{
    logWrite(40, contextOrMessage, message, logToBackend);
}

/**
 * Logs a message with information severity.
 * @param contextOrMessage Either an object holding attributes with context information or the message to log.
 * @param message The message to log if a context object is given.
 */
function logWriteInfo(contextOrMessage?: {} | string, message?: string, logToBackend: boolean = false)
{
    logWrite(30, contextOrMessage, message, logToBackend);
}

/**
 * Logs a message with debug severity.
 * @param contextOrMessage Either an object holding attributes with context information or the message to log.
 * @param message The message to log if a context object is given.
 */
function logWriteDebug(contextOrMessage?: {} | string, message?: string, logToBackend: boolean = false)
{
    logWrite(20, contextOrMessage, message, logToBackend);
}

/**
 * Logs a message with verbose severity.
 * @param contextOrMessage Either an object holding attributes with context information or the message to log.
 * @param message The message to log if a context object is given.
 */
function logWriteVerbose(contextOrMessage?: {} | string, message?: string, logToBackend: boolean = false)
{
    logWrite(10, contextOrMessage, message, logToBackend);
}

/**
 * Logs an auditable message with information severity.
 * @param context An object holding attributes with context information or the message to log.
 * @param auditKey A key helping with distinguishing this audit log entry from others.
 * @param message The message to log if a context object is given.
 */
function logWriteAudit(context: {} | string, auditKey: string, message: string, logToBackend: boolean = false)
{
    context['auditKey'] = auditKey;
    logWrite(30, context, message, logToBackend);
}

function logWrite(logLevel: number, contextOrMessage?: {} | string, message?: string, logToBackend: boolean = false)
{
    let context: {};
    if (typeof contextOrMessage === 'object')
        context = contextOrMessage;
    else
        message = String(contextOrMessage);

    const messageLogLevel = logLevel;
    const allowedLogLevel: number = currentLogLevel;
    if (messageLogLevel < allowedLogLevel)
        return;

    const messageString = message;
    let logMethod = 'log'; // Debug, Verbose
    if (messageLogLevel >= 60) // Fatal
        logMethod = 'error';
    else if (messageLogLevel >= 50) // Error
        logMethod = 'error';
    else if (messageLogLevel >= 40) // Warn
        logMethod = 'warn';
    else if (messageLogLevel >= 30) // Info
        logMethod = 'info';

    if (context)
        console[logMethod](messageString, context);
    else
        console[logMethod](messageString);

    if (logToBackend)
        logWriteToServer(context, messageString, messageLogLevel);
}


interface IWriteLogEntryDto
{
    Level: string;
    Message: string;
    Args: string[];
}

function logWriteToServer(context: {} | string, message: string, logLevel: number)
{
    const severLogLevel: string = numericLogLevelToServerLogLevel(logLevel);
    const postBody: IWriteLogEntryDto =
    {
        Level: severLogLevel,
        Message: message,
        Args: [],
    };

    if (context)
    {
        Object.keys(context).forEach(key =>
        {
            postBody.Message += ' ' + key;
            postBody.Args.push(context[key]);
        });
    }

    executeHttpRequest({ type: 'POST', url: serverLogEndpoint, data: JSON.stringify(postBody) })
        .catch(error => logWrite(40, { error }, 'Failed to log message to backend.', false));
}

/**
 * Returns a log level commonly used in the given execution environment of the application.
 */
function numericLogLevelToServerLogLevel(logLevel: number): string
{
    let logMethod = 'Verbose'; // Debug, Verbose
    if (logLevel >= 60) // Fatal
        logMethod = 'Fatal';
    else if (logLevel >= 50) // Error
        logMethod = 'Error';
    else if (logLevel >= 40) // Warn
        logMethod = 'Warning';
    else if (logLevel >= 30) // Info
        logMethod = 'Information';

    return logMethod;
}
