import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { ProjectEntity } from "./BackendApi/projectentity.entity";
import { ProjectDataService } from "./project-data.service";

@Injectable({providedIn: 'root'})
export class ProjectResolver implements Resolve<ProjectEntity> {
    // Constructors
    constructor(
        private readonly router: Router,
        private DataService: ProjectDataService
    ){ } 
    
    // Methods
    public resolve(ars: ActivatedRouteSnapshot): Promise<ProjectEntity> {
        return this.DataService.selectProject(ars.paramMap.get('id'))
        .then(project => {
            if(project == null){
                throw new Error(`No project found for given id: ${ars.paramMap.get('id')}`)
            }
            return project;
        }).catch(e => {
            console.error(e);
            this.router.navigate(['/home']);
            return null;
        });
    }
}