import { Ajax, Internationalization, L10n, loadCldr, NumberFormatOptions, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { parse } from 'jsonc-parser';
import { log } from 'src/core-lib/logging';
import { environment } from 'src/environments/environment';
import { PimStatic } from '../utils/PimStatic';
import { ServiceStatic } from '../utils/ServiceStatic';

import * as n1 from 'cldr-data/main/de/currencies.json'
import * as n2 from 'cldr-data/main/de/timeZoneNames.json';
import * as n3 from 'cldr-data/main/de/numbers.json';
import * as n4 from 'cldr-data/main/de/ca-gregorian.json';
import * as s from 'cldr-data/supplemental/currencyData.json';
import * as s2 from 'cldr-data/supplemental/numberingSystems.json';

import * as EJ2_LOCALE_DE from '@syncfusion/ej2-locale/src/de.json';
import * as EJ2_LOCALE_ENUS from '@syncfusion/ej2-locale/src/en-US.json';
import * as EJ2_LOCALE_ENGB from '@syncfusion/ej2-locale/src/en-GB.json';

import { localization as gridLocalization } from '../components/lists-tables/grid/localization';
import { localization as inputsLocalization } from '../components/inputs/localization';
import { localization as listboxLocalization } from '../components/lists-tables/listbox/localization';
import { localization as buttonsLocalization } from '../components/buttons/localization';
import { localization as otherLocalization } from '../components/other/localization';
import { Inject, Injectable } from '@angular/core';
import { COMPONENT_CONTEXT } from 'src/core-lib/angular/constants';

export interface ITranslationLabel {
    ContextDescription: string;
}

/**
 * This service supports context based components.
 * The context (key prefix) can be configured by injecting the COMPONENT_CONTEXT symbol with the respective key prefix
 * aswell as an instance of this service.
 *
 * Label keys passed to getString will be automatically prefixed with the current context unless the key is prefixed
 * with a sharp #.
 */
@Injectable()
export class LangService
{
    /**
     * identity regex => locale map.
     */
    private static readonly identityToLocaleMappings =
    {
        'VIEGA\\': 'de-DE',
        'AMERICAS\\': 'en-US',
    };

    /**
     * Browser locale => psp locale map.
     */
    private static readonly browserLocaleMappings =
    {
        'de': 'de-DE',
        'en': 'en-US',
        'de-DE': 'de-DE',
        'en-US': 'en-US',
        'en-GB': 'en-GB'
    };

    private static readonly pspLocaleValidationRegex = new RegExp('^[a-z]{2}\-([A-Z]{2}|X\-[A-Z]+)$');

    private static labelDataCache: { [locale: string]: {} } = {};
    private static labelDataReceivePromises: { [locale: string]: Promise<void> } = {};
    private static _currentLocale: string;

    /** The effective flattened label data for the current language. */
    private static currentLangLabelData: {};

    private static isSyncfusionInitialized: boolean = false;
    private static internationalization: Internationalization;

    public get currentLocale(): string | undefined
    {
        return LangService._currentLocale;
    }

    /**
     * Whether language data has been loaded and whether the language was set.
     */
    public get isLangDataLoaded(): boolean
    {
        return LangService.currentLangLabelData !== undefined &&
            LangService.labelDataCache[PimStatic.BaseLocale] !== undefined &&
            LangService.labelDataCache[LangService._currentLocale] !== undefined;
    }

    public constructor(
        @Inject(COMPONENT_CONTEXT)
        private context: string = ''
    ) {}

    /**
     * Changes the current language.
     * Receives language files from the backend if not available yet.
     */
    public setLanguage(locale: string): Promise<void>
    {
        const isBaseAvailable = LangService.labelDataCache[PimStatic.BaseLocale] !== undefined;
        const isLocaleAvailable = LangService.labelDataCache[locale] !== undefined;

        let retrievePromise: Promise<any> = Promise.resolve();
        if (!isBaseAvailable)
            retrievePromise = this.retrieveLabelFile(PimStatic.BaseLocale);
        if (!isLocaleAvailable)
            retrievePromise = Promise.all([retrievePromise, this.retrieveLabelFile(locale)]);

        return retrievePromise.then(() =>
            {
                const baseLabelData = LangService.labelDataCache[PimStatic.BaseLocale];
                const localeLabelData = LangService.labelDataCache[locale];
                LangService.currentLangLabelData = {};

                Object.assign(LangService.currentLangLabelData, baseLabelData, localeLabelData);
                this.setLanguageDirect(locale);
            })
            .catch();
    }

    public setLanguageDirect(locale: string): void
    {
        LangService._currentLocale = locale;
        LangService.currentLangLabelData = LangService.currentLangLabelData || {};
        this.applyHtmlLangAttribute(locale);

        this.setSyncfusionLocale(locale);

        log.debug({ locale }, `Successfully set language to "${locale}".`);
    }

    public setSyncfusionLocale(locale: string)
    {
        if (!LangService.isSyncfusionInitialized)
        {
            loadCldr(
                n1['default'],
                n2['default'],
                n3['default'],
                n4['default'],
                s['default'],
                s2['default']
            );

            L10n.load({
                de: EJ2_LOCALE_DE.de,
                'en-US': EJ2_LOCALE_ENUS['en-US'],
                'en-GB': EJ2_LOCALE_ENGB['en-GB']
            });
            L10n.load(gridLocalization);
            L10n.load(inputsLocalization);
            L10n.load(listboxLocalization);
            L10n.load(buttonsLocalization);
            L10n.load(otherLocalization);

            LangService.isSyncfusionInitialized = true;
        }

        const sfCulture = locale === 'de-DE' ? 'de' : locale;
        setCulture(sfCulture);
        setCurrencyCode(locale === 'en-US' ? 'USD' : 'EUR');
        LangService.internationalization = new Internationalization(sfCulture);
    }

    /**
     * Tries to get the locale that is most desired by the user.
     *
     * It is determined by the following attempts in order:
     *   - If a "locale" query parameter exists and is a valid locale, then its value is taken as the locale.
     *   - If the current identity contains a domain root, an attempt is made to map it into a valid locale.
     *   - If the browser provides locale information, then the browser locale is used.
     *   - PimStatic.FallbackLocale is used.
     */
    public getDesiredLocale(currentIdentity?: string): { result: string, mappedBy: 'query-param' | 'identity' | 'browser' | 'fallback' }
    {
        const queryParams = new URLSearchParams(window.location.search);
        const localeFromQuery = queryParams.get('locale');

        if (LangService.pspLocaleValidationRegex.test(localeFromQuery))
            return { result: localeFromQuery, mappedBy: 'query-param' };

        if (currentIdentity)
        {
            let localeFromIdentity = null;
            Object.keys(LangService.identityToLocaleMappings).some(regex =>
            {
                if (new RegExp(regex).test(currentIdentity))
                {
                    localeFromIdentity = LangService.identityToLocaleMappings[regex];
                    return true;
                }

                return false;
            });

            if (localeFromIdentity)
                return { result: localeFromIdentity, mappedBy: 'identity' };
        }

        const browserLocale: string = navigator.language || navigator['userLanguage'];
        if (browserLocale && LangService.browserLocaleMappings[browserLocale])
            return { result: LangService.browserLocaleMappings[browserLocale], mappedBy: 'browser' };

        return { result: PimStatic.FallbackLocale, mappedBy: 'fallback' };
    }

    private retrieveLabelFile(locale: string): Promise<void>
    {
        if (LangService.labelDataReceivePromises[locale])
            return LangService.labelDataReceivePromises[locale];

        const endpointRoute = environment.backendRootUrl + ServiceStatic.labelAssetEndpoint.replace('{0}', locale);

        const request = new Ajax(endpointRoute, 'GET');
        LangService.labelDataReceivePromises[locale] = request.send()
            .then((responseData: any) =>
            {
                const flattenedLabelData = {};
                const labelData: {} = this.parseLabelData(responseData, locale);
                this.flattenLabelData(labelData, flattenedLabelData);
                LangService.labelDataCache[locale] = flattenedLabelData;

                log.debug({ locale }, 'Successfully received label file from backend.');
                delete LangService.labelDataReceivePromises[locale];
            })
            .catch((error: Error) =>
            {
                log.fatal({ error: error.message }, 'Failed to receive label data from backend.');
                return Promise.reject(error);
            });

        return LangService.labelDataReceivePromises[locale];
    }

    /**
     * Directly puts label data into the cache.
     * This is useful when data have been bundled within the application already and must not be retrieved from the backend.
     */
    public setLabelData(hierarchyLabelData: any, forLocale: string, overwriteCurrent?: boolean, preferExisting?: boolean): void
    {
        const flattenedLabelData = {};
        this.flattenLabelData(hierarchyLabelData, flattenedLabelData);
        if (preferExisting)
            Object.assign(flattenedLabelData, LangService.labelDataCache[forLocale]);

        LangService.labelDataCache[forLocale] = flattenedLabelData;

        if (overwriteCurrent)
            Object.assign(LangService.currentLangLabelData, flattenedLabelData);
    }

    private flattenLabelData(hierarchyLabelData: {}, destLabelData: {}, keyPrefix: string = '')
    {
        Object.keys(hierarchyLabelData).forEach(itemKey =>
        {
            let itemValue = hierarchyLabelData[itemKey];
            if (typeof itemValue === 'object')
                this.flattenLabelData(itemValue, destLabelData, keyPrefix + itemKey + '.');
            else
                itemValue = String(itemValue);

            destLabelData[keyPrefix + itemKey] = itemValue;
        });
    }

    /**
     * Gets a translated string from the given label key.
     *
     * @param labelKey The context based label key. Prefix with # ignore context.
     * @param defaultValue The value returned if the label key doesn't exist.
     * @param replaceValues If the label values contains format strings in the format {0} they are replaced with values of this array.
     */
    public getString(labelKey: string, defaultValue?: string, ...replaceValues: string[]): string
    {
        if (!LangService.currentLangLabelData)
            throw new Error('No language has been set yet.');

        if (window['revealLabelIds'])
            return `[${labelKey}]`;

        if (labelKey.startsWith('#'))
            labelKey = labelKey.substring(1);
        else if (this.context)
            labelKey = `${this.context}.${labelKey}`;

        let result = LangService.currentLangLabelData[labelKey] as string;
        if (result === undefined) {
            log.warning({ labelKey }, 'No translation found for key.');

            if (defaultValue !== undefined)
                result = defaultValue;
            else
                result = `[${labelKey}]`;
        }

        if (replaceValues)
        {
            replaceValues.forEach((replaceValue: string, index: number) =>
                result = result.replace(new RegExp(`\\\{${index}\\\}`, 'g'), replaceValue));
        }

        return result;
    }

    public formatNumber(value: number, options?: NumberFormatOptions)
    {
        return LangService.internationalization.formatNumber(value, options);
    }

    /**
     * Applies a "lang" attribute to the html root element, so that the browser is locale aware when performing hyphenation.
     */
    private applyHtmlLangAttribute(locale: string) {
        const htmlElement = document.getElementsByTagName('html')[0];
        htmlElement.setAttribute('lang', locale);
    }

    private parseLabelData(rawData: string, locale: string): {}
    {
        try
        {
            return parse(rawData);
        }
        catch (error)
        {
            throw new Error(`Failed to parse json label data received from backend for locale "${locale}".`);
        }
    }
}
