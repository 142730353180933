import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { UserService } from 'src/app/core/UserService';
import { WebTrekkService } from 'src/app/core/WebTrekkService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [provideContext("Home")],
})
export class HomeComponent implements OnInit
{
    headlineText = "";
    subHeadlineText = "";
    backgroundUrl;
    description = this.langService.getString('Description_D')

    constructor(
        private readonly router: Router,
        public userService: UserService,
        private langService: LangService,
        private dataService: ProjectDataService,
        private aggregator: AggregatorDataService,
        private tracker: WebTrekkService
    ) { }

    public ngOnInit(): void {
        this.backgroundUrl = this.aggregator.aggregatorData.Tables.ImageAssetRows.find(r => r.ImageId == "Startseite.Hintergrund").ImageUrlLoc
        this.headlineText = this.langService.getString('Headline_T');
        this.dataService.resetTempProject();
        this.tracker.initTracker();
        this.tracker.trackPage("start", this.userService.userInfo.UserId, this.userService.isAuthenticated, null, null);
    }

    public startButton_clicked(): void
    {
        this.router.navigate(['/overview']);
    }

    public async loginButton_clicked(): Promise<void>
    {
        await this.userService.login();
        this.router.navigate(['/overview']);
    }
}
