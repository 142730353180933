import { Position, Tooltip } from '@syncfusion/ej2-angular-popups';
import { Event, EmitType, EventHandler, Component, INotifyPropertyChanged, Property, addClass, removeClass, select } from '@syncfusion/ej2-base';
import { events } from './events';
import { IPspContextualHelpModel } from './IPspContextualHelpModel';
import { L10n } from '@syncfusion/ej2-base';
import { localization } from './localization';

L10n.load(localization);

export class PspContextualHelp extends Component<HTMLElement> implements INotifyPropertyChanged
{
    private tooltip: Tooltip;
    private isTooltipOpen: boolean = false;

    /** All scroll event handlers registered in the parent tree of this component. */
    private scrollEventHandlers: { el: HTMLElement, handler: any }[];

    /** @ignore */
    public l10n: L10n;

    @Property()
    public tooltipTextHtml: string;

    @Property()
    public documentationHref?: string;

    @Property(null)
    public documentationLinkName?: string;

    @Property('TopRight')
    public tooltipPosition?: Position;

    @Property()
    public cssClass: string;

    constructor(options?: IPspContextualHelpModel, element?: string | HTMLElement)
    {
        super(options, element);
        this.l10n = new L10n('psp-contextual-help', localization, this.locale);
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-contextual-help';
    }

    protected preRender(): void
    {
        // NOP
    }

    protected render(): void
    {
        this.element.tabIndex = 0;
        addClass([this.element], ['pspc-contextual-help', 'icon-help-with-circle']);
        if (this.cssClass)
            addClass([this.element], this.cssClass.split(' '));

        this.tooltip = new Tooltip(
        {
            content: this.getTooltipContent(),
            position: this.tooltipPosition,
            openDelay: 0,
            opensOn: 'Custom',
            cssClass: 'pspc-contextual-help-tooltip',
            offsetX: this.tooltipPosition === 'TopRight' ? -8 : 0,
            afterClose: () => this.isTooltipOpen = false,
        }, this.element);

        this.wireEvents();
        this.trigger(events.created, {});
    }

    protected wireEvents(): void
    {
        EventHandler.add(this.element, 'click', this.element_click, this);
        EventHandler.add(this.element, 'blur', this.element_blur, this);

        this.scrollEventHandlers = [];
        let currentParent = this.element.parentElement;
        while (currentParent)
        {
            const handler = this.closeTooltip.bind(this);
            currentParent.addEventListener('scroll', handler);

            this.scrollEventHandlers.push({ el: currentParent, handler });

            currentParent = currentParent.parentElement;
        };
    }

    protected unwireEvents(): void
    {
        EventHandler.remove(this.element, 'click', this.element_click);
        EventHandler.remove(this.element, 'focus', this.element_focus);
        EventHandler.remove(this.element, 'blur', this.element_blur);

        this.scrollEventHandlers.forEach(eh =>
            eh.el.removeEventListener('scroll', eh.handler));
        delete this.scrollEventHandlers;
    }

    private element_click(): void
    {
        this.openTooltip();
    }

    private element_focus(): void
    {
        this.openTooltip();
    }

    private element_blur(): void
    {
        this.closeTooltip();
    }

    private openTooltip(): void
    {
        if (this.isTooltipOpen)
            return;

        addClass([this.element], 'pspc-contextual-help--focus');
        this.tooltip.open();
        this.isTooltipOpen = true;
    }

    private closeTooltip(): void
    {
        if (!this.isTooltipOpen)
            return;

        removeClass([this.element], 'pspc-contextual-help--focus');
        this.tooltip.close();
        this.isTooltipOpen = false;
    }

    /** @ignore */
    public onPropertyChanged(newProp: IPspContextualHelpModel, oldProp: IPspContextualHelpModel): void
    {
        for (const propertyName of Object.keys(newProp))
        {
            const oldValue = oldProp[propertyName];
            const newValue = newProp[propertyName];

            switch (propertyName)
            {
                case 'tooltipTextHtml':
                case 'documentationHref':
                case 'documentationLinkName':
                case 'tooltipPosition':
                   this.updateTooltip();
                   break;

                case 'cssClass':
                   if (oldValue)
                       removeClass([this.element], oldValue.split(' '));
                   if (newValue)
                       addClass([this.element], newValue.split(' '));

                   break;
            }
        }
    }

    private updateTooltip(): void
    {
        this.tooltip.content = this.getTooltipContent();
        this.tooltip.position = this.tooltipPosition;
        this.tooltip.offsetX = this.tooltipPosition === 'TopRight' ? -8 : 0;
    }

    private getTooltipContent(): string
    {
        let tooltipContent = this.tooltipTextHtml;

        if (this.documentationHref)
        {
            const linkName = this.documentationLinkName || this.l10n.getConstant('DocumentationLink');
            tooltipContent += `
                <p>
                    <a href="${this.documentationHref}" target="_blank">
                        <span class="icon-new-tab"></span>
                        <span>${linkName}</span>
                    </a>
                </p>`;
        }

        return tooltipContent;
    }

    protected getPersistData(): string
    {
        return '';
    }

    public destroy(): void
    {
        this.unwireEvents();
        this.tooltip?.destroy();

        this.trigger(events.destroyed, {});
        super.destroy();
    }
}
