import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GuidService } from 'src/core-lib/ej2/services/GuidService';
import { PspFormField } from '../PspFormField';

@Component({
    selector: 'psp-form-field',
    templateUrl: './psp-form-field.component.html',
    styleUrls: ['./psp-form-field.component.scss']
})
export class FormFieldComponent
{
    /**
     * The id of the html element referenced by the label element.
     * If unset defaults to formField.fieldName.
     */
    @Input()
    public forId?: string;

    @Input()
    public formField: PspFormField;

    @Input()
    public reserveLabelSpace: boolean = true;

    public isError(): boolean
    {
        return this.formField.control.invalid && (this.formField.control.dirty || this.formField.control.touched);
    }
}
