import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RuntimeService } from 'src/core-lib/ej2/services/RuntimeService';
import { log } from 'src/core-lib/logging';

export interface IUserInfo
{
    UserId: string;
    GivenName: string;
    FamilyName: string;
    EMail: string;
}

// KeyCloak JavaScript Adapter Reference:
// https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc
@Injectable()
export class UserService
{
    private static readonly accessTokenRefreshSeconds = 30;

    private keycloak: any;
    private _userInfo: IUserInfo;
    public isAuthenticated: boolean;

    public get subject(): string {
        return this.keycloak.subject;
    }

    public get userInfo(): IUserInfo {
        return this._userInfo;
    }

    public get isViegaUser(): boolean {
        if(!this.keycloak?.tokenParsed?.email) {
            return false;
        }
        return this.keycloak.tokenParsed.email_verified === true && (this.keycloak.tokenParsed.email.endsWith("@viega.de") || this.keycloak.tokenParsed.email.endsWith("@klaus-rauch.de"));
    }

    public constructor(
        private runtimeService: RuntimeService
    ) {}

    public login(): Promise<boolean>
    {
        if (this.runtimeService.getConfigValue("EnableAuthentication") === 'False')
        {
            this.isAuthenticated = true;
            this._userInfo = {
                UserId: '00000000-0000-0000-0000-000000000000',
                GivenName: 'Joe',
                FamilyName: 'User',
                EMail: 'joe.user@viega.de',
            };
            return Promise.resolve(true);
        }

        this.keycloak = new window['Keycloak'](
        {
            url: this.runtimeService.getConfigValue("UserService:KeyCloakAuthServerUrl"),
            realm: this.runtimeService.getConfigValue("UserService:Realm"),
            clientId: this.runtimeService.getConfigValue("UserService:ClientId"),
        });

        // keycloak will silently use an iframe to perform the authentication against keycloak
        const initOptions = {
            checkLoginIframe: false,
            onLoad: 'login-required'
        };
        return this.keycloak.init(initOptions).then(isAuthenticated =>
        {
            this.isAuthenticated = isAuthenticated;

            // if sso has failed (i.e. the user is not logged into the keycloak realm), then perform a redirect to the login page
            if (!isAuthenticated)
                this.keycloak.login();

            this._userInfo = {
                UserId: this.keycloak.tokenParsed.sub,
                GivenName: this.keycloak.tokenParsed.given_name,
                FamilyName: this.keycloak.tokenParsed.family_name,
                EMail: this.keycloak.tokenParsed.email,
            };
        }
        ).catch(e =>
        {
            console.log('failed to initialize', e);
        });
    }

    public async getToken(): Promise<string | null>
    {
        if (!this.isAuthenticated || this.runtimeService.getConfigValue("EnableAuthentication") === 'False')
            return Promise.resolve(null);

        try {
            await this.keycloak.updateToken(UserService.accessTokenRefreshSeconds)
        }
        catch (error) {
            log.warning({ error }, 'Refreshing the keycloak access token has failed.');
        }
        return this.keycloak.token;
    }

    public redirectToAccountManagement(): Promise<void>
    {
        if (!this.keycloak)
            throw new Error('User is not logged in.');

        return this.keycloak.accountManagement();
    }

    public async logout(): Promise<void>
    {
        if (this.runtimeService.getConfigValue("EnableAuthentication") === 'False')
        {
            this.isAuthenticated = false;
            delete this._userInfo;

            return Promise.resolve();
        }

        // redirect back to welcome page
        const redirectUri = window.location.origin + window.location.pathname + '#/';
        this.isAuthenticated = false;
        delete this._userInfo;
        await this.keycloak.logout({ redirectUri });
    }
}
