import { L10n } from '@syncfusion/ej2-base';

// labels to improve the default localization of the grid
export const localization = {
    'de': {
        grid: {
            EmptyRecord: 'Keine Datensätze verfügbar',
            Item: 'Datensatz',
            Items: 'Datensätze',
            Columnchooser: 'Spalten',
            ChooseColumns: 'Spalten auswählen',
            Search: 'Filtern nach',
            OKButton: 'OK',
            CancelButton: 'Abbrechen',
            SelectAll: 'Alle auswählen',
            True: 'Ja',
            False: 'Nein',
            SortAscending: 'Aufsteigend Sortieren',
            SortDescending: 'Absteigend Sortieren',
            CancelEdit: 'Möchten Sie die Änderungen wirklich verwerfen?',
            NotEqual: 'Ungleich',
            FilterTrue: 'Ja',
            FilterFalse: 'Nein',
            InvalidFilterMessage: 'Ungültiger Filter',
        },
        pager: {
            currentPageInfo: '{0} von {1} Seiten',
            totalItemsInfo: '({0} Datensätze)',
            firstPageTooltip: 'Zur ersten Seite',
            lastPageTooltip: 'Zur letzten Seite',
            nextPageTooltip: 'Zur nächsten Seite',
            previousPageTooltip: 'Zurück zur letzten Seite',
            nextPagerTooltip: 'Zum nächsten Pager',
            previousPagerTooltip: 'Zum vorherigen Pager',
        },
        dialog: {
            close: 'Schließen',
        }
    }
};
