import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'viega-button-image',
  templateUrl: './viega-button-image.component.html',
  styleUrls: ['./viega-button-image.component.scss']
})
export class ViegaButtonImageComponent implements OnInit {

  constructor() { }

  @Input()
  public imagePath: string;

  @Input()
  public imageText: string;

  @Input()
  public description: string;

  @Input()
  public title: string;

  @Input()
  public isSelected?: boolean;

  @Output() iconClicked = new EventEmitter<MouseEvent>();

  @Input()
  public unSelected?: boolean;

  ngOnInit(): void {
  }

  icon_clicked(event:MouseEvent){
      this.iconClicked.emit(event);
  }

}
