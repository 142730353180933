import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfigDteDialogComponent } from '../config-dte-dialog/config-dte-dialog.component';
import { ProjectConfigurationItemComponent } from '../project-configuration-item/project-configuration-item.component';
import { ProjectConfigurationItemModule } from '../project-configuration-item/project-configuration-item.module';
import { ConfigDetailsDialogComponent } from './config-details-dialog.component';


@NgModule({
    imports:
    [
       SharedModule,
       ProjectConfigurationItemModule
    ],
    declarations:
    [
        ConfigDetailsDialogComponent

    ],
    exports:
    [
        ConfigDetailsDialogComponent

    ]


})
export class ConfigDetailsDialogModule { }