import { Router } from "@angular/router";
import { PositionDataModel } from "@syncfusion/ej2-popups/src/popup/popup-model";
import { AggregatorDataService } from "./AggregatorDataService";
import { ProjectEntity } from "./BackendApi/projectentity.entity";
import { BedarfsvolumenstromErmittlungstyp, BedarfsvolumenstromErmittlungstypOther, ZweitBadTyp, SectionState } from "./Enums";
import { ProjectDataService } from "./project-data.service";
import { ValueFormatterService } from "./ValueFormatterService";

export abstract class DemandVolumetricFlowBaseComponent
{
    // Enum providers
    public ErmittlungsTyp = BedarfsvolumenstromErmittlungstyp;
    public ErmittlungsTypOther = BedarfsvolumenstromErmittlungstypOther;

    // Variables
    public dialogPosition: PositionDataModel = { X: 'center', Y: 'center' };
    public currentProject: ProjectEntity;

    // Constructors
    constructor(
        protected readonly router: Router,
        protected DataService: ProjectDataService,
        protected formatterService: ValueFormatterService,
        protected aggregatorDataService: AggregatorDataService
    ){

    }

    // Internal Methods
    protected clone(entity: any): any {
        return JSON.parse(JSON.stringify(entity));
    }
    protected zweitbadTypFromString(zweitbad: string): ZweitBadTyp {
        switch(zweitbad) {
            case 'ZweitesBad': return ZweitBadTyp.ZweitesBad;
            case 'GästezimmerMitBwUndDu': return ZweitBadTyp.GästezimmerMitBwUndDu;
            case 'GästezimmerOhneBwUndDu': return ZweitBadTyp.GästezimmerOhneBwUndDu;
            default: return ZweitBadTyp.None;
        }
    }
    protected zweitbadTypFromEnum(zweitbad: ZweitBadTyp): "None" | "ZweitesBad" | "GästezimmerMitBwUndDu" | "GästezimmerOhneBwUndDu" {
        switch(zweitbad) {
            case ZweitBadTyp.ZweitesBad: return 'ZweitesBad';
            case ZweitBadTyp.GästezimmerMitBwUndDu: return 'GästezimmerMitBwUndDu';
            case ZweitBadTyp.GästezimmerOhneBwUndDu: return 'GästezimmerOhneBwUndDu';
            default: return 'None';
        }
    }
    protected onClickSaveBase(){
        // Set result values
        if(this.currentProject.ProjectValues == null) {
            this.currentProject.ProjectValues = { };
        }
        this.currentProject.ProjectValues.BedarfsvolumenstromErgebnisLMin = (
            this.currentProject.ResidentialBuilding != null && this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom != null
                ? this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.BedarfsvolumenstromErgebnisLMin : 0
        ) + (
            this.currentProject.OtherBuildings != null ?
            this.currentProject.OtherBuildings
                .filter(b => b.Bedarfsvolumenstrom != null)
                .reduce((sum, b) => sum + b.Bedarfsvolumenstrom.BedarfsvolumenstromErgebnisLMin, 0) : 0
        )

        // Set building section state
        this.currentProject.SectionStates.BuildingTypeBedarfsvolumenstromStates
            .find(s => s.Key == this.getBuildingType()).Value = SectionState.Set;

        // Manage related section states
        // var buildingWwState = this.currentProject.SectionStates.BuildingTypeWarmwasserStates.find(b => b.Key == this.getBuildingType());
        // if(buildingWwState.Value == SectionState.Set)
        //     buildingWwState.Value = SectionState.Check;
        this.currentProject.SectionStates.BuildingTypeWarmwasserStates.forEach(b => {
            if(b.Value == SectionState.Set) {
                b.Value = SectionState.Check;
            }})
        if(this.currentProject.SectionStates.Dte == SectionState.Set)
            this.currentProject.SectionStates.Dte = SectionState.Check;
        if(this.currentProject.SectionStates.Zirkulationsvolumenstrom == SectionState.Set)
            this.currentProject.SectionStates.Zirkulationsvolumenstrom = SectionState.Check;
        if(this.currentProject.SectionStates.Energiespeicher == SectionState.Set)
            this.currentProject.SectionStates.Energiespeicher = SectionState.Check;
        if(this.currentProject.SectionStates.Rohrauslegung == SectionState.Set)
            this.currentProject.SectionStates.Rohrauslegung = SectionState.Check;
        if(this.currentProject.SectionStates.Ufc == SectionState.Set)
            this.currentProject.SectionStates.Ufc = SectionState.Check;

        // Save and close view
        this.DataService.saveCurrentProject()
            .then(_ => this.router.navigate([`/project-view/${this.currentProject.ProjectId}`]));
    }
    protected abstract getBuildingType(): string;

    // Button/Click Events
    public onClickGoBack(): void {
        this.router.navigate([`/project-view/${this.currentProject.ProjectId}`]);
    }
}