import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlurEventArgs, ChangeEventArgs } from '@syncfusion/ej2-inputs/src';

@Component({
    selector: 'app-slider-text-combo',
    templateUrl: './slider-text-combo.component.html',
    styleUrls: [ './slider-text-combo.component.scss' ]
})
export class SliderTextComboComponent implements OnInit, AfterViewInit {
    // Input bindings
    @Input() sliderMin: number;
    @Input() sliderMax: number;
    @Input() sliderStep: number;
    @Input() sliderClass: string;
    @Input() sliderStyle: string;

    @Input() textboxMin: number;
    @Input() textboxMax: number;
    @Input() textboxStep: number;
    @Input() textboxClass: string;
    @Input() textboxStyle: string;
    @Input() textboxDecimals: number;

    @Input() showLabel: boolean;
    @Input() labelText: string;
    @Input() labelStyle: string;
    @Input() labelClass: string;

    // 2-way bindings
    private _value: number;
    @Input() set value(newValue: number) {
        this._value = newValue;
        this.processValueChange();
    }
    @Output() valueChange = new EventEmitter<number>();

    // Variables
    public sliderValue: number;
    public textboxValue: number;
    private lastChangeWasSlider: boolean;

    // Constructors/Initializers
    constructor() {

    }
    ngAfterViewInit(): void {
         // Set value
         this.processValueChange();

         // Emit initial change
         this.onChanged();
    }
    ngOnInit(): void {
        // Validate inputs
        if(!this.sliderMin)
            this.sliderMin = 0;
        if(!this.sliderMax)
            this.sliderMax = 100;
        if(!this.sliderStep)
            this.sliderStep = 1;
        if(!this.textboxMin)
            this.textboxMin = 0;
        if(!this.textboxMax)
            this.textboxMax = 100;
        if(!this.textboxStep)
            this.textboxStep = 1;
        if(!this._value)
            this._value = 0;
        this.sliderValue = 0;


       
    }

    

    // Events
    public onSliderChanged(args: ChangeEventArgs) {
        if(this.sliderValue == null){
            this.sliderValue = this.sliderMin;
        }

        // Handle change
        if(args.isInteracted){
            this.textboxValue = this.sliderValue;
            this.lastChangeWasSlider = true;
            this.onChanged();
        }
    }
    public onTextboxChanged(args: ChangeEventArgs) {
        // Change empty textbox to min value
        if(this.textboxValue == null) {
            this.textboxValue = this.textboxMin;
        }

        // Handle change
        if(args.isInteracted){
            this.lastChangeWasSlider = false;
            this.sliderValue = this.textboxValue
            this.onChanged();
        }
    }
    public onTextboxBlur(args: BlurEventArgs) {
        this.onTextboxChanged({ isInteracted: false });
    }

    // Internal Methods
    private processValueChange() {
        if(this._value >= this.sliderMin && this._value <= this.sliderMax)
        {
            this.sliderValue = this._value;
            this.textboxValue = this._value;
            this.lastChangeWasSlider = true;
        } else if(this._value <= this.textboxMax) {
            this.sliderValue = this._value;
            this.textboxValue = this._value;
            this.lastChangeWasSlider = false;
        } else {
            this.sliderValue = this.sliderMin;
            this.textboxValue = this.sliderMin;
            this.lastChangeWasSlider = true;
        }
    }
    private onChanged() {
        this._value = this.lastChangeWasSlider ? this.sliderValue : this.textboxValue;
        this.valueChange.emit(this._value);
    }
}
