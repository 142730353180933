export const events = {
    startSearch: 'startSearchEvent',
    change: 'changeEvent',
    beforeOpen: 'beforeOpenEvent',
    open: 'openEvent',
    close: 'closeEvent',
    blur: 'blurEvent',
    focus: 'focusEvent',
    actionBegin: 'actionBeginEvent',
    actionComplete: 'actionCompletedEvent',
    actionFailure: 'actionFailureEvent',
    select: 'selectEvent',
    dataBound: 'dataBoundEvent',
    filtering: 'filteringEvent',
    created: 'createdEvent',
    destroyed: 'destroyedEvent',
};
