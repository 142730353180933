// labels to improve the default localization of dropdowns
export const localization = {
    'de': {
        'psp-assortment-toggle-button': {
            'addLabel': 'Zu Sortiment hinzufügen',
            'removeLabel': 'Aus Sortiment entfernen',

            'modelsAddedTooltip': '{0} Modelle hinzugefügt',
            'singleModelAddedTooltip': '1 Modell hinzugefügt',
            'modelsRemovedTooltip': '{0} Modelle entfernt',
            'singleModelRemovedTooltip': '1 Modell entfernt',
            'articlesAddedTooltip': '{0} Artikel hinzugefügt',
            'singleArticleAddedTooltip': '1 Artikel hinzugefügt',
            'articlesRemovedTooltip': '{0} Artikel entfernt',
            'singleArticleRemovedTooltip': '1 Artikel entfernt',
            'productGroupsAddedTooltip': '{0} Produktgruppen hinzugefügt',
            'singleProductGroupAddedTooltip': '1 Produktgruppe hinzugefügt',
            'productGroupsRemovedTooltip': '{0} Produktgruppen entfernt',
            'singleProductGroupRemovedTooltip': '1 Produktgruppe entfernt',
        },
        'psp-data-package-toggle-button': {
            'addLabel': 'Zu Datenpaket hinzufügen',
            'removeLabel': 'Aus Datenpaket entfernen',

            'filesAddedTooltip': '{0} Dateien hinzugefügt',
            'singleFileAddedTooltip': '1 Datei hinzugefügt',
            'filesRemovedTooltip': '{0} Dateien entfernt',
            'singleFileRemovedTooltip': '1 Datei entfernt',
        }
    },
    'en-US': {
        'psp-assortment-toggle-button': {
            'addLabel': 'Add to Assortment',
            'removeLabel': 'Remove from Assortment',

            'modelsAddedTooltip': '{0} Models Added',
            'singleModelAddedTooltip': '1 Model Added',
            'modelsRemovedTooltip': '{0} Models Removed',
            'singleModelRemovedTooltip': '1 Model Removed',
            'articlesAddedTooltip': '{0} Articles Added',
            'singleArticleAddedTooltip': '1 Article Added',
            'articlesRemovedTooltip': '{0} Articles Removed',
            'singleArticleRemovedTooltip': '1 Article Removed',
            'productGroupsAddedTooltip': '{0} Product Groups Added',
            'singleProductGroupAddedTooltip': '1 Product Group Added',
            'productGroupsRemovedTooltip': '{0} Product Groups Removed',
            'singleProductGroupRemovedTooltip': '1 Product Group Removed',
        },
        'psp-data-package-toggle-button': {
            'addLabel': 'Add to Data Package',
            'removeLabel': 'Remove from Data Package',

            'filesAddedTooltip': '{0} Files Added',
            'singleFileAddedTooltip': '1 File Added',
            'filesRemovedTooltip': '{0} Files Removed',
            'singleFileRemovedTooltip': '1 File Removed',
        }
    },
    'en-GB': {
        'psp-assortment-toggle-button': {
            'addLabel': 'Add to Assortment',
            'removeLabel': 'Remove from Assortment',

            'modelsAddedTooltip': '{0} Models Added',
            'singleModelAddedTooltip': '1 Model Added',
            'modelsRemovedTooltip': '{0} Models Removed',
            'singleModelRemovedTooltip': '1 Model Removed',
            'articlesAddedTooltip': '{0} Articles Added',
            'singleArticleAddedTooltip': '1 Article Added',
            'articlesRemovedTooltip': '{0} Articles Removed',
            'singleArticleRemovedTooltip': '1 Article Removed',
            'productGroupsAddedTooltip': '{0} Product Groups Added',
            'singleProductGroupAddedTooltip': '1 Product Group Added',
            'productGroupsRemovedTooltip': '{0} Product Groups Removed',
            'singleProductGroupRemovedTooltip': '1 Product Group Removed',
        },
        'psp-data-package-toggle-button': {
            'addLabel': 'Add to Data Package',
            'removeLabel': 'Remove from Data Package',

            'filesAddedTooltip': '{0} Files Added',
            'singleFileAddedTooltip': '1 File Added',
            'filesRemovedTooltip': '{0} Files Removed',
            'singleFileRemovedTooltip': '1 File Removed',
        }
    }
};
