import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectConfigurationItemComponent } from '../project-configuration-item/project-configuration-item.component';
import { ProjectConfigurationItemModule } from '../project-configuration-item/project-configuration-item.module';
import { SelectionItemRadioModule } from '../selection-item-radio/selection-item-radio.module';
import { ConfigDteDialogComponent } from './config-dte-dialog.component';


@NgModule({
    imports:
    [
       SharedModule,
       ProjectConfigurationItemModule,
       SelectionItemRadioModule
    ],
    declarations:
    [
        ConfigDteDialogComponent
    ],
    exports:
    [
        ConfigDteDialogComponent
    ]


})
export class ConfigDteDialogModule { }