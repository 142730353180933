import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegendSettingsModel } from '@syncfusion/ej2-charts';
import { JsonAdaptor } from '@syncfusion/ej2-data';
import { EventArgs } from '@syncfusion/ej2-navigations';
import * as _ from 'lodash';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { Dte } from 'src/app/core/BackendApi/dte.complex';
import { Messpunkt } from 'src/app/core/BackendApi/messpunkt.complex';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { DteRow } from 'src/app/core/DteufcAggregatorServiceApi/dterow.complex';
import { SectionState } from 'src/app/core/Enums';
import { CirculationFlowMath } from 'src/app/core/math/CirculationFlowMath';
import { DteMath, IDtePwhParams, IDteResult } from 'src/app/core/math/DteMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { IProjectData } from 'src/app/core/project-interfaces';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
    selector: 'app-dte-temperature',
    templateUrl: './dte-temperature.component.html',
    styleUrls: ['./dte-temperature.component.scss'],
    providers: [provideContext("DteTemperature")],
})
export class DteTemperatureComponent implements OnInit {

    stepText = this.langService.getString('PanelStepText_T');
    stepTitle = this.langService.getString('PanelHeadline_T');
    stepBack = this.langService.getString('PanelStepBackLabel_T');
    stepDescription = this.langService.getString('PanelDescription_D');

    // Graph Variables
    public primaryYAxis: object;
    public primaryXAxis: object;
    public chartDataBedarfsvolumenstrom: Messpunkt[];
    public chartDataPWH60: Messpunkt[];
    public chartDataPWH60Betriebspunkt: Messpunkt[];
    public chartDataPWHAbgesenkt: Messpunkt[];
    public chartDataPWHAbgesenktBetriebspunkt: Messpunkt[];
    public tooltipBetriebspunkt = { enable: true, header: 'Betriebspunkt' };
    public tooltipformatBetriebspunkt = this.langService.getString('TooltipformatBetriebspunkt_T_Html', undefined, this.langService.getString('#Generic.Unit.LiterPerMinute_T'), this.langService.getString('#Generic.Unit.DegreeCelsius_T'));
    public legendSettings: LegendSettingsModel;
    public animation: object;
    public palette: string[];

    // General Project/Table Variables
    private readonly pwh60: number = DteMath.pwh;
    private selectedProject: ProjectEntity;
    public bedarfsvolumenstrom : number;
    private dteRows: DteRow[];

    // Sidebar Values
    public druckverlustStrecke60 : number;
    public druckverlust60Gesamt : number;
    public druckverlustHpa60: number;

    public druckverlustStreckeAbgesenkt : number;
    public druckverlustAbgesenktGesamt : number;
    public druckverlustHpaAbgesenkt: number;

    // Bindings/Calculation Values
    public betriebspunkt60: Messpunkt;
    public betriebspunktAbgesenkt: Messpunkt;
    public maxAbgesenkteTemperatur : number = 45;
    public bedarfsvolumenstromAbgesenkt: number;
    public selectedDTE : "40" | "70" | "100";
    public vorlauftemperatur: number = 53;
    public vorlauftemperaturSliderMin: number = 45;
    public benoetigteVorlauftemperatur: number = 53;
    public benoetigteVorlauftemperaturAbgesenkt: number;
    private DTEAvailable: IDteResult[];
    public loadedDteIsInvalid: boolean;
    private selectedDteInfo: DteRow;
    private recommendedDTE: DteRow;
    public pwhAbgesenkt : number;
    public pwhName: string = "";
    public isValidFlowRate: boolean;
    private bedarfsvolumenstromEffective: number;

    private changeCounter: number = 0;

    // Constructors/Initializers
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private DataService: ProjectDataService,
        private formatter: ValueFormatterService,
        private aggregatorService: AggregatorDataService,
        private dteMath: DteMath,
        private circulationFlowMath: CirculationFlowMath,
        private langService: LangService
    ) {
    }
    ngOnInit(): void {
        this.isValidFlowRate = true;

        // Load current project
        this.selectedProject = this.activatedRoute.snapshot.data['project'];
        if(this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin || !(this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin > 0)) {
            this.bedarfsvolumenstrom = this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin;
        }
        else{
            this.bedarfsvolumenstrom = 55; // DEBUG value
        }

        if(this.selectedProject.ProjectValues.Dte){
            this.vorlauftemperatur = this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput;
            var inputAbgesenkt = this.selectedProject.ProjectValues.Dte.VorlauftemperaturAbgesenktInput;
            this.maxAbgesenkteTemperatur = inputAbgesenkt == 0 || !inputAbgesenkt ? 45 : inputAbgesenkt;
        }

        // Set initial values
        this.dteRows = this.aggregatorService.aggregatorData.Tables.DteRows;
        this.legendSettings = { visible: true, alignment: 'Center', shapeWidth: 40 };
        this.palette = ["#5181e8", "#9e0b0b", "#5181e8", "#9e0b0b", "#000000"];
        this.animation = { enable: false };

        // Override recommended dte value with loaded if available
        if(this.selectedProject.ProjectValues.Dte){
            this.DTEAvailable = this.dteMath.dtesFromBedarfsvolumenstromForAbgesenkt(this.bedarfsvolumenstrom, { dteRows: this.dteRows });
            this.loadedDteIsInvalid = !this.dteMath.dtesFromBedarfsvolumenstromForAbgesenkt(this.bedarfsvolumenstrom, { dteRows: this.dteRows })
                .some(d => d.dte == this.selectedProject.ProjectValues.Dte.Ergebnis.toString());

            if(!this.loadedDteIsInvalid) {
                this.selectDte(this.selectedProject.ProjectValues.Dte.Ergebnis.toString() as "40" | "70" | "100", false);
            } else {
                this.selectRecommendedDte();
                this.calculateValues()
            }
        } else {
            this.loadedDteIsInvalid = false;
            this.selectRecommendedDte();
            this.calculateValues();
        }
    };

    // Internal Methods
    private calculateValues() : void {
        // Get current dte info
        this.selectedDteInfo = this.dteRows.find(d => d.Dte == this.selectedDTE);
        if(this.selectedDteInfo) {
            // Get graph axis config
            this.primaryYAxis = {
                minimum: this.selectedDteInfo.DiagramStartVorlauftemperatur, maximum: this.selectedDteInfo.DiagramEndVorlauftemperatur, interval: this.selectedDteInfo.DiagramStepVorlauftemperaturLMin,
                    title: this.langService.getString('LeistungsdiagrammYAxis_T', undefined, this.langService.getString('#Generic.Unit.DegreeCelsius_T'))
            };
            this.primaryXAxis = {
                minimum: this.selectedDteInfo.DiagramStartBedarfsvolumenstromLMin, maximum: this.selectedDteInfo.DiagramEndBedarfsvolumenstromLMin, interval: this.selectedDteInfo.DiagramStepBedarfsvolumenstromLMin,
                    title: this.langService.getString('LeistungsdiagrammXAxis_T', undefined, this.langService.getString('#Generic.Unit.LiterPerMinute_T'))
            };

            // Get sidebar values
            this.pwhAbgesenkt = this.dteMath.pwhAbgesenktFromPwhC(Number(this.maxAbgesenkteTemperatur));
            this.druckverlustHpa60 = this.dteMath.dteDruckverlust(this.bedarfsvolumenstrom, this.selectedDteInfo.KvsSecondary);
            this.druckverlustStrecke60 = this.dteMath.dteDruckverlust(this.bedarfsvolumenstrom, this.selectedDteInfo.KvsCirculationFlow);
            this.druckverlust60Gesamt = this.druckverlustHpa60;

            this.bedarfsvolumenstromAbgesenkt = this.dteMath.bedarfsvolumenstromAbgesenktFromBedarfsvolumenstrom(this.bedarfsvolumenstrom, this.pwhAbgesenkt);
            this.druckverlustHpaAbgesenkt = this.dteMath.dteDruckverlust(this.bedarfsvolumenstromAbgesenkt, this.selectedDteInfo.KvsSecondary);
            this.druckverlustStreckeAbgesenkt = this.dteMath.dteDruckverlust(this.bedarfsvolumenstromAbgesenkt, this.selectedDteInfo.KvsCirculationFlow);
            this.druckverlustAbgesenktGesamt = this.druckverlustHpaAbgesenkt;

            // Get graph values
            var yValuesPwh60 = this.dteMath.volauftemperaturenMesspunkteFromDte(this.selectedDteInfo, this.pwh60);
            this.chartDataPWH60 = yValuesPwh60.map(v => ({ Vorlauftemperatur: v, Bedarfsvolumenstrom: this.dteMath.dteVsFromBedarfstemp(this.selectedDteInfo, v, this.pwh60) }));
            this.pwhName = "PWH " + this.langService.getString('#Generic.UnitFormats.DegreeCelsius', undefined, this.getPwhAbgesenktChart());

            var yValuesAbgesenkt = this.dteMath.volauftemperaturenMesspunkteFromDte(this.selectedDteInfo, this.pwhAbgesenkt);
            this.chartDataPWHAbgesenkt = yValuesAbgesenkt.map(v => ({ Vorlauftemperatur: v, Bedarfsvolumenstrom: this.dteMath.dteVsFromBedarfstemp(this.selectedDteInfo, v, this.pwhAbgesenkt) }))

            this.benoetigteVorlauftemperaturAbgesenkt = this.dteMath.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({
                bedarfsvolumenstromLMin: this.bedarfsvolumenstromAbgesenkt,
                bedarfstemperaturPwh: this.pwhAbgesenkt,
                dteRow: this.selectedDteInfo
            });

            this.createBetriebspunkte();
        } else {
            // Reset sidebar values
            this.druckverlust60Gesamt = 0;
            this.druckverlustStrecke60 = 0;
            this.druckverlustAbgesenktGesamt = 0;
            this.druckverlustStreckeAbgesenkt = 0;
            this.bedarfsvolumenstromAbgesenkt = 0;
            this.druckverlustHpa60 = 0;
            this.druckverlustHpaAbgesenkt = 0;
        }

        // Set dte button classes
        switch (this.selectedDTE) {
            case '40': this.selectDte40(); break;
            case '70': this.selectDte70(); break;
            case '100': this.selectDte100(); break;
            default: this.selectDteNone(); break;
        }
    }
    private createBetriebspunkte() : void {
        // note that some of these values MUST be rounded to two digits, or the graphical representation will be invalid
        var y60 = Math.round(this.vorlauftemperatur * 100) / 100;
        var x60 = Math.round(this.dteMath.dteVsFromBedarfstemp(this.selectedDteInfo, Number(this.vorlauftemperatur), this.pwh60) * 100) / 100;
        this.betriebspunkt60 = { Bedarfsvolumenstrom: x60, Vorlauftemperatur: y60 };
        this.chartDataPWH60Betriebspunkt = [
            { Bedarfsvolumenstrom: 0, Vorlauftemperatur: y60 },
            { Bedarfsvolumenstrom: x60, Vorlauftemperatur: y60 },
            { Bedarfsvolumenstrom: x60, Vorlauftemperatur: 0 }
        ];
        const dtePwhParams = this.dteMath.getDtePwhParams(this.selectedDteInfo, this.pwhAbgesenkt);
        var xAbgesenkt = Math.round(this.bedarfsvolumenstromAbgesenkt * 100) / 100;
        var xAbgesenktPinned = Math.round(Math.max(Math.min(xAbgesenkt, this.selectedDteInfo.MaxBedarfsvolumenstromLMin), dtePwhParams.minEffectiveBedarfsvolumenstrom) * 100) / 100;
        var yAbgesenkt = Math.round(this.benoetigteVorlauftemperaturAbgesenkt * 100) / 100;
        this.betriebspunktAbgesenkt = { Bedarfsvolumenstrom: xAbgesenktPinned, Vorlauftemperatur: yAbgesenkt };
        this.chartDataPWHAbgesenktBetriebspunkt = [
            {Bedarfsvolumenstrom: 0, Vorlauftemperatur: yAbgesenkt},
            {Bedarfsvolumenstrom: xAbgesenktPinned, Vorlauftemperatur: yAbgesenkt},
            {Bedarfsvolumenstrom: xAbgesenktPinned, Vorlauftemperatur: 0}
        ];
    }
    private deselectDTE() : void {
        this.resetGraph();
        this.selectedDTE = null;
        this.pwhName = "";
        this.benoetigteVorlauftemperatur = 0;
        this.benoetigteVorlauftemperaturAbgesenkt = 0;
    }
    private resetGraph() : void {
        this.primaryYAxis = {
            minimum: 50, maximum: 90, interval: 5,
            title: this.langService.getString('LeistungsdiagrammYAxis_T', undefined, this.langService.getString('#Generic.Unit.DegreeCelsius_T'))
        };
        this.primaryXAxis = {
            minimum: 40, maximum: 120, interval: 5,
            title: this.langService.getString('LeistungsdiagrammXAxis_T', undefined, this.langService.getString('#Generic.Unit.LiterPerMinute_T'))
        };
        this.chartDataPWHAbgesenktBetriebspunkt = [{ Bedarfsvolumenstrom: 0, Vorlauftemperatur: 0 }];
        this.chartDataPWH60Betriebspunkt = [{ Bedarfsvolumenstrom: 0, Vorlauftemperatur: 0 }];
        this.chartDataPWHAbgesenkt = [{ Vorlauftemperatur: 0, Bedarfsvolumenstrom: 0 }];
        this.chartDataPWH60 = [{ Vorlauftemperatur: 0, Bedarfsvolumenstrom: 0 }];
    }

    // Button/Click Events
    public selectDte(type: "40" | "70" | "100", autoOptimizeSettings: boolean): void {
        if(this.selectedDTE != type){
            this.loadedDteIsInvalid = false;
            this.selectedDTE = type;

            const dteRow: DteRow = this.dteRows.find(d => d.Dte === this.selectedDTE);

            this.benoetigteVorlauftemperatur = this.dteMath.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({
                dteRow,
                bedarfstemperaturPwh: 60,
                bedarfsvolumenstromLMin: this.bedarfsvolumenstrom,
            });

            if (autoOptimizeSettings)
                this.setOptimalDteValues(dteRow);

            this.pwhAbgesenkt = this.dteMath.pwhAbgesenktFromPwhC(Number(this.maxAbgesenkteTemperatur));
            this.vorlauftemperaturSliderMin = Math.ceil(this.benoetigteVorlauftemperatur);
            this.calculateValues();

            const dteParams: IDtePwhParams = this.dteMath.getDtePwhParams(dteRow, 60);
            this.bedarfsvolumenstromEffective = this.bedarfsvolumenstrom;
            this.chartDataBedarfsvolumenstrom = [
                { Vorlauftemperatur: 50, Bedarfsvolumenstrom: this.bedarfsvolumenstromEffective },
                { Vorlauftemperatur: 90, Bedarfsvolumenstrom: this.bedarfsvolumenstromEffective }
            ];
        }
    }
    public goBack() : void {
        window.history.back();
    }
    public navigateNext() : void {
        this.calculateValues();
        if(this.selectedProject.ProjectValues.Dte){
            if(this.selectedProject.SectionStates.Dte == SectionState.Set && this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput === this.vorlauftemperatur && this.selectedProject.ProjectValues.Dte.VorlauftemperaturAbgesenktInput === this.maxAbgesenkteTemperatur && this.selectedProject.ProjectValues.Dte.Ergebnis == Number(this.selectedDTE)){
                this.selectedProject.SectionStates.Dte = SectionState.Set;
                this.DataService.saveCurrentProject()
                    .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
                return;
            }
            this.selectedProject.ProjectValues.Dte.Vorlauftemperatur = this.dteMath.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({
                bedarfsvolumenstromLMin: this.bedarfsvolumenstrom,
                bedarfstemperaturPwh: this.pwh60,
                dteRow: this.dteRows.find(d => d.Dte == this.selectedDTE)
            });
            this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput = this.vorlauftemperatur;
            this.selectedProject.ProjectValues.Dte.VorlauftemperaturAbgesenktInput = this.maxAbgesenkteTemperatur;
            this.selectedProject.ProjectValues.Dte.VorlauftemperaturAbgesenkt = this.benoetigteVorlauftemperaturAbgesenkt;
            this.selectedProject.ProjectValues.Dte.PwhTemperatur = this.pwh60;
            this.selectedProject.ProjectValues.Dte.DruckverlustHpa = this.druckverlustHpaAbgesenkt;
            this.selectedProject.ProjectValues.Dte.DruckverlustZirkulationsstreckeHpa = this.druckverlustStreckeAbgesenkt;
            this.selectedProject.ProjectValues.Dte.DruckverlustGesamtHpa = this.druckverlustAbgesenktGesamt;
            this.selectedProject.ProjectValues.Dte.Ergebnis = Number(this.selectedDTE);
            this.selectedProject.ProjectValues.Dte.MesspunktePwh60 = this.chartDataPWH60;
            this.selectedProject.ProjectValues.Dte.TemperaturAbgesenktPwhc = this.maxAbgesenkteTemperatur;
            this.selectedProject.ProjectValues.Dte.DruckverlustNormalbetriebHpa = this.druckverlustHpa60;
            this.selectedProject.ProjectValues.Dte.DruckverlustZirkulationsstreckeNormalbetriebHpa = this.druckverlustStrecke60;
            this.selectedProject.ProjectValues.Dte.DruckverlustGesamtNormalbetriebHpa = this.druckverlust60Gesamt;
            this.selectedProject.ProjectValues.Dte.PwhTemperaturAbgesenkt = this.pwhAbgesenkt;
            this.selectedProject.ProjectValues.Dte.MesspunktePwhAbgesenkt = this.chartDataPWHAbgesenkt;
            this.selectedProject.ProjectValues.Dte.BetriebspunktPwh60 = this.betriebspunkt60;
            this.selectedProject.ProjectValues.Dte.BetriebspunktPwhAbgesenkt = this.betriebspunktAbgesenkt;
            this.selectedProject.ProjectValues.Dte.GraphBedarfsvolumenstromEffektiv = this.bedarfsvolumenstromEffective;
        } else {
            this.selectedProject.ProjectValues.Dte = {
                Vorlauftemperatur: this.dteMath.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({
                    bedarfsvolumenstromLMin: this.bedarfsvolumenstrom,
                    bedarfstemperaturPwh: this.pwh60,
                    dteRow: this.dteRows.find(d => d.Dte == this.selectedDTE)
                }),
                VorlauftemperaturInput: this.vorlauftemperatur,
                VorlauftemperaturAbgesenktInput: this.maxAbgesenkteTemperatur,
                VorlauftemperaturAbgesenkt: this.benoetigteVorlauftemperaturAbgesenkt,
                PwhTemperatur: this.pwh60,
                DruckverlustHpa: this.druckverlustHpaAbgesenkt,
                DruckverlustZirkulationsstreckeHpa: this.druckverlustStreckeAbgesenkt,
                DruckverlustGesamtHpa: this.druckverlustAbgesenktGesamt,
                Ergebnis: Number(this.selectedDTE),
                MesspunktePwh60: this.chartDataPWH60,
                TemperaturAbgesenktPwhc: this.maxAbgesenkteTemperatur,
                DruckverlustNormalbetriebHpa: this.druckverlustHpa60,
                DruckverlustZirkulationsstreckeNormalbetriebHpa: this.druckverlustStrecke60,
                DruckverlustGesamtNormalbetriebHpa: this.druckverlust60Gesamt,
                PwhTemperaturAbgesenkt: this.pwhAbgesenkt,
                MesspunktePwhAbgesenkt: this.chartDataPWHAbgesenkt,
                BetriebspunktPwh60: this.betriebspunkt60,
                BetriebspunktPwhAbgesenkt: this.betriebspunktAbgesenkt,
                GraphBedarfsvolumenstromEffektiv: this.bedarfsvolumenstromEffective
            };
        }

        this.selectedProject.ProjectValues.BedarfsvolumenstromEffektivLMin = this.bedarfsvolumenstromAbgesenkt;
        this.selectedProject.ProjectValues.VorlauftemperaturEffektiv = this.benoetigteVorlauftemperaturAbgesenkt;
        this.selectedProject.ProjectValues.BedarfstemperaturGrad = this.pwhAbgesenkt;
        this.selectedProject.ProjectValues.Dte.ZirkulationsverlustleistungBerechnetAbgesenktWatt = this.circulationFlowMath.verlustleistungAbgesenktFromVerlustleistung(
            60,
            this.maxAbgesenkteTemperatur,
            this.selectedProject.ProjectValues.Zirkulationsvolumenstrom.ZirkulationsverlustleistungBerechnetWatt);

        this.selectedProject.SectionStates.Dte = SectionState.Set;

        if (this.selectedProject.SectionStates.Rohrauslegung === SectionState.Set) {
            this.selectedProject.SectionStates.Rohrauslegung = SectionState.Check;
        }

        if(this.selectedProject.SectionStates.Ufc == SectionState.Set)
            this.selectedProject.SectionStates.Ufc = SectionState.Check;


        if(this.selectedProject.SectionStates.BuildingTypeWarmwasserStates){
            this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.forEach(b => {
                if(b.Value == SectionState.Set){
                    b.Value = SectionState.Check;
                }
            });
        }

        if (this.selectedProject.SectionStates.Energiespeicher === SectionState.Set) {
            this.selectedProject.SectionStates.Energiespeicher = SectionState.Check;
        }

        this.DataService.saveCurrentProject()
        .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
    }

    // Change Events
    public onChangeSlider(args: EventArgs): void {
        // if(args && !args.isInteracted){
        //     return;
        // }

        // Recalculate
        this.calculateValues();
    }

    private selectRecommendedDte(): void
    {
        // Get available DTEs for configuration
        this.DTEAvailable = this.dteMath.dtesFromBedarfsvolumenstromForAbgesenkt(this.bedarfsvolumenstrom, { dteRows: this.dteRows });
        if (this.DTEAvailable && this.DTEAvailable.length > 0) {
            // Get recommended DTE
            this.recommendedDTE = this.dteMath.recommendedDteForAbgesenkt({
                bedarfstemperaturPwh: 60,
                bedarfsvolumenStromLMin: this.bedarfsvolumenstrom,
                dteRows: this.dteRows
            });

            // Select recommended DTE if available
            if (this.recommendedDTE) {
                this.selectDte(this.recommendedDTE.Dte as "40" | "70" | "100", true);
            } else {
                this.deselectDTE();
            }
        } else {
            this.deselectDTE();
        }
    }

    private setOptimalDteValues(dteRow: DteRow): void
    {
        this.maxAbgesenkteTemperatur = this.dteMath.optimalPwhC(dteRow, this.bedarfsvolumenstrom);
        this.vorlauftemperatur = Math.ceil(this.benoetigteVorlauftemperatur);
    }

    // Validation Methods
    public isDteDisabled(dte: string) : boolean {
        if (this.DTEAvailable && this.DTEAvailable.length > 0) {
            return !this.DTEAvailable.some(x => x.dte == dte)
        }
        return true;
    }

    // Value Formatters
    public getDteName() : string {
        return this.formatter.formatDteName(this.selectedDTE);
    }
    public getVorlauftemperatur() : string {
        return this.formatter.formatNumber(this.vorlauftemperatur, 'DegreeCelsius')
    }
    public getPwh(): string {
        return this.formatter.formatNumber(this.pwh60, 'DegreeCelsius')
    }
    public getBedarfsvolumenstrom() : string {
        return this.formatter.formatNumber(Number(this.bedarfsvolumenstrom), 'LiterPerMinute', {maximumFractionDigits: 0})
    }
    public getMaxAbgesenkteTemperatur() : string {
        return this.formatter.formatNumber(Number(this.maxAbgesenkteTemperatur), 'DegreeCelsius')
    }
    public getPwhAbgesenkt() : string {
        return this.formatter.formatNumber(Number(this.pwhAbgesenkt), 'DegreeCelsius', { maximumFractionDigits: 0 });
    }
    public getPwhAbgesenktChart() : string{
        return this.formatter.formatNumber(Number(this.pwhAbgesenkt), null, {maximumFractionDigits: 0})
    }
    public getBedarfsvolumenstromAbgesenkt(){
        return this.formatter.formatNumber(Number(this.bedarfsvolumenstromAbgesenkt), 'LiterPerMinute', { maximumFractionDigits: 0 });
    }
    public isBedarfsvolumenstromValid(){
        return !this.selectedDteInfo || this.bedarfsvolumenstromAbgesenkt <= this.selectedDteInfo.MaxBedarfsvolumenstromLMin;
    }
    public getVorlaufErgebnis(){
        if(this.selectedDTE){
            return this.formatter.formatNumber(Math.ceil(Number(this.benoetigteVorlauftemperatur)), 'DegreeCelsius', { maximumFractionDigits: 0 });
        }

        return "-"
    }
    public getVorlaufAbgesenktErgebnis(){
        if (this.selectedDTE) {
            return this.formatter.formatNumber(Math.ceil(Number(this.benoetigteVorlauftemperaturAbgesenkt)), 'DegreeCelsius', { maximumFractionDigits: 0 });
        }

        return "-"
    }
    public getDruckverlustStrecke() : string {
        return this.formatter.formatNumber(this.druckverlustStrecke60, 'HektopascalInBar', { maximumFractionDigits: 0 });
    }
    public getDruckverlustGesamt() : string {
        return this.formatter.formatNumber(this.druckverlust60Gesamt, 'HektopascalInBar', { maximumFractionDigits: 0 });
    }
    public getDruckverlustStreckeAbgesenkt() : string {
        return this.formatter.formatNumber(this.druckverlustStreckeAbgesenkt, 'HektopascalInBar', { maximumFractionDigits: 0 });
    }
    public getDruckverlustGesamtAbgesenkt() : string {
        return this.formatter.formatNumber(this.druckverlustAbgesenktGesamt, 'HektopascalInBar', { maximumFractionDigits: 0 });
    }
    public getDte40Btn(): string{
        return this.formatter.formatDteName('40')
    }
    public getDte70Btn(): string{
        return this.formatter.formatDteName('70')
    }
    public getDte100Btn(): string{
        return this.formatter.formatDteName('100')
    }
    public getPwh60(): string{
        return this.formatter.formatNumber(60, 'DegreeCelsius')
    }


    // DTE Button Style Setter
    private selectDte40() : void {
        var dte40 = document.getElementById('dte40');
        var dte70 = document.getElementById('dte70');
        var dte100 = document.getElementById('dte100');
        dte40.classList.add('dteButtonFocused');
        dte70.classList.remove('dteButtonFocused');
        dte100.classList.remove('dteButtonFocused');
    }
    private selectDte70() : void {
        var dte70 = document.getElementById('dte70');
        var dte40 = document.getElementById('dte40');
        var dte100 = document.getElementById('dte100');
        dte70.classList.add('dteButtonFocused');
        dte40.classList.remove('dteButtonFocused');
        dte100.classList.remove('dteButtonFocused');
    }
    private selectDte100() : void {
        var dte100 = document.getElementById('dte100');
        var dte40 = document.getElementById('dte40');
        var dte70 = document.getElementById('dte70');
        dte100.classList.add('dteButtonFocused');
        dte70.classList.remove('dteButtonFocused');
        dte40.classList.remove('dteButtonFocused');
    }
    private selectDteNone() : void {
        var dte100 = document.getElementById('dte100');
        var dte40 = document.getElementById('dte40');
        var dte70 = document.getElementById('dte70');
        dte100.classList.remove('dteButtonFocused');
        dte70.classList.remove('dteButtonFocused');
        dte40.classList.remove('dteButtonFocused');
    }
}


