import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollapsiblePanelComponent } from './collapsible-panel.component';

@NgModule({
    imports: [
        CommonModule,

    ],
    declarations: [
        CollapsiblePanelComponent
    ],
    exports: [
        CollapsiblePanelComponent
    ]
})
export class CollapsiblePanelModule { }
