import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { log } from 'src/core-lib/logging';
import { IAppState } from './IAppState';
import { INavigateToExternalPayload } from './shell-messages/INavigateToExternalPayload';
import { INavigateToPayload } from './shell-messages/INavigateToPayload';
import { INotifyPermissionChangePayload } from './shell-messages/INotifyPermissionChangePayload';
import { IShellMessage } from './shell-messages/IShellMessage';
import { IUpdateAppStatePayload } from './shell-messages/IUpdateAppStatePayload';

/**
 * Sends and receives messages from the hosting application shell, if available.
 * If this application is not being hosted in a shell, the methods of this service will have no effect.
 */
export class AppShellClientService
{
    /**
     * Subscribe to this in order to receive messages from the app shell host.
     */
    public hostMessagesObservable: Observable<IShellMessage>;

    constructor()
    {
        this.hostMessagesObservable = fromEvent(window, 'message')
            .pipe(
                map((args: MessageEvent) =>
                {
                    if (!args.data.type || !args.data.type.startsWith('Psp'))
                        return null;

                    return args.data;
                }),
                filter(shellMsg => shellMsg !== null)
            );
    }

    /**
     * Updates the state of the running application tab in the current session.
     * Only defined values of appStateUpdates will be applied, undefined values are ignored.
     */
    public updateAppState(appStateUpdates: IAppState): void
    {
        this.sendMessageToHost(
        {
            type: 'PspUpdateAppState',
            payload:
            {
                appStateUpdates
            } as IUpdateAppStatePayload,
        });
    }

    /**
     * Requests the application to close itself.
     * False is returned if the app shell prevents the application from closing.
     */
    public closeSelf(): void
    {
        this.sendMessageToHost(
        {
            type: 'PspCloseSelf',
        });
    }

    /**
     * Requests the app shell to navigate to a certain route. The route has to be absolute and
     * can point to the frontends of other services.
     *
     * Note that the purpose of this function is to navigate to routes of other services (e.g. to open
     * the product model viewer from the product search app).
     * It is not recommended to navigate to routes inside of this running application instance - for
     * this regular angular routing services should be the preference.
     *
     * The app shell will attempt to spawn the frontend of a service if its not instanced yet, otherwise
     * the existing application instance is told to navigate to the route given.
     *
     * Its up to the app shell to decide whether it navigates to the url in the foreground (changing from
     * the current app to the destination app) or the background.
     * A hint may be given by using the openInBackground flag indicating that the app shell should prefer
     * to perform the navigation in the background.
     *
     * Note that opening external urls is prevented by the shell. The url must have a registered psp
     * service url as its destination.
     */
    public navigateTo(targetUrl: string, preferBackground?: boolean): void
    {
        this.sendMessageToHost(
        {
            type: 'PspNavigateTo',
            payload: {
                targetUrl,
                preferenceBackground: preferBackground,
            } as INavigateToPayload,
        });
    }

    /**
     * Requests the app shell to open the target url in a new window / browser tab.
     *
     * A route to another service may also be passed here, however the app shell will not open it in an
     * application tab in this case.
     */
    public navigateToExternal(targetUrl: string): void
    {
        this.sendMessageToHost(
        {
            type: 'PspNavigateToExternal',
            payload: {
                targetUrl,
            } as INavigateToExternalPayload,
        });
    }

    /**
     * Notifies the app shell that either the current user or their permissions have changed.
     * The app shell host may then broadcast this notification to all client apps so that they
     * can invalidate their local permission data.
     */
    public notifyPermissionChange(newUserIdentity?: string): void
    {
        this.sendMessageToHost(
        {
            type: 'PspNotifyPermissionChange',
            payload: {
                newUserIdentity,
            } as INotifyPermissionChangePayload,
        });
    }

    /**
     * Notifies the app shell that the active assortment of the current user has changed.
     * The app shell host may then broadcast this notification to all client apps so that they
     * can invalidate their local assortment data against the assortment service APIs.
     */
    public notifyActiveAssortmentChange(): void
    {
        this.sendMessageToHost(
        {
            type: 'PspNotifyActiveAssortmentChange',
        });
    }

    /**
     * Notifies the app shell that the named assortments of the current user have changed.
     * The app shell host may then broadcast this notification to all client apps so that they
     * can invalidate their local assortment data against the assortment service APIs.
     */
    public notifyNamedAssortmentsChange(): void
    {
        this.sendMessageToHost(
        {
            type: 'PspNotifyNamedAssortmentsChange',
        });
    }

    private sendMessageToHost(messageObj: IShellMessage): void
    {
        window.top.postMessage(messageObj, '*');
    }
}
