<ejs-dialog
    (overlayClick)="onEditDialogBackdropClick()"
    height='50em'
    width='40%'
    [header]='dialogHeader'
    [isModal]='true'
    [closeOnEscape]='false'
    [visible]='false'
    [showCloseIcon]='true'
    #editZapfstellenDialog
>
    <ng-template #content>
        <div class="base-data">
            <div style="display: flex;">
                <div class="inner-table">
                    <div [formGroup]="typeFormFields.group" class="form-fields">
                        <psp-form-field *localVar="typeFormFields.fieldMap['Auswahl'] as field" [formField]="field">
                            <ejs-dropdownlist
                                #normSelection
                                [formControl]="field.control"
                                [dataSource]='facilities'
                                [(value)]="currentType"
                                [fields]="typeFormFieldsConfig"
                                placeholder="{{'ExaktTypRadio.DialogDropDownPlaceholder_T' | translate}}"
                                (change)="onTypeChanged()"
                            ></ejs-dropdownlist>
                        </psp-form-field>
                    </div>
                    <div *ngIf="currentType"
                        class="inner-sub-content">
                        <div *ngIf="!currentlyFree" class="form-fields">
                            <div [formGroup]="comfortFormFields.group" class="form-fields">
                                <psp-form-field *localVar="comfortFormFields.fieldMap['Komfort'] as field" [formField]="field">
                                    <ejs-dropdownlist
                                        #tierSelection
                                        [formControl]="field.control"Ed
                                        [dataSource]='comfortValues'
                                        [(value)]="currentComfort"
                                        [fields]="comfortValuesConfig"
                                        placeholder="{{'ExaktTypRadio.DialogDropDownKomfortstufePlaceholder_T'| translate}}"
                                        (change)="onStufeChange()"
                                    ></ejs-dropdownlist>
                                </psp-form-field>
                            </div>
                        </div>
                        <div *ngIf="currentlyFree">
                            <div style="margin-top: 0.8em;" class="form-fields yellow-effect"
                                [formGroup]="freeFormFields.group">
                                <psp-form-field *localVar="freeFormFields.fieldMap['Bezeichnung'] as field" [formField]="field">
                                    <ejs-textbox
                                        type="text"
                                        [formControl]="field.control"
                                        [(value)]="currentName"
                                        style="padding-top: 0.5em"
                                    ></ejs-textbox>
                                </psp-form-field>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="currentType" class="inner-sub-content">
                        <div class="text-tooltip-wrapper">
                            <span class="e-label form-fields">{{'ExaktTypRadio.DialogHeadlineBerechnungsfluss_T' | translate}}</span>
                            <app-help-icon hinweisId="Bedarfsvolumenstrom.AndereGebäude.Berechnungsfluss">
                            </app-help-icon>
                        </div>
                        <div *ngIf="!currentlyFree"
                            style="flex-direction: row; display: flex; align-items: center;">
                            <span class="e-label">
                                {{formatBerechnungsdurchfluss(currentFlow)}}
                            </span>
                        </div>
                        <div *ngIf="currentlyFree"
                            style="flex-direction: row; display: flex; align-items: center;">
                            <div class="radioButtonText2" style="width: 20em;">
                                <app-slider-text-combo
                                    class="slider-text-combo"
                                    style="margin-left:0em;"
                                    [sliderMin]="0"
                                    [sliderMax]="1"
                                    [sliderStep]="0.01"
                                    [textboxMin]="0"
                                    [textboxMax]="9.99"
                                    [textboxStep]="0.01"
                                    [textboxDecimals]="2"
                                    [textboxClass]="'slider-input'"
                                    [textboxStyle]="{ 'width': '9em', 'color':'black !important', 'cursor': 'default', 'margin-left':'1em' }"
                                    [showLabel]='true'
                                    [labelStyle]="{'margin-left':'3em'}"
                                    labelText="l/s"
                                    [(value)]='currentFlow'
                                    (valueChange)="recalculate()"
                                ></app-slider-text-combo>
                            </div>
                        </div>
                        <div class="inner-sub-content" *ngIf="currentlyFree" style="display: flex; flex-direction: row;">
                            <div style="margin-right: 0.5em;">
                                <span class="e-label icon-warning1" ></span>
                            </div>
                            <div style="width: 20em;">
                                <span style="font-size: 13px;" class="e-label">
                                    {{'ExaktTypRadio.DialogWarningBerechnugsdurchfluss_SD' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div style="width: 20em;" class="inner-sub-content">
                        <span class="e-label form-fields">{{'ExaktTypRadio.DialogHeadlineAnzahl_T' | translate}}</span>
                        <div class="radioButtonText2">
                            <app-slider-text-combo
                                    class="slider-text-combo"
                                    style="margin-left:0em;"
                                    [sliderMin]="0"
                                    [sliderMax]="100"
                                    [sliderStep]="1"
                                    [textboxMin]="0"
                                    [textboxMax]="999"
                                    [textboxStep]="1"
                                    [textboxClass]="'slider-input'"
                                    [textboxDecimals]="0"
                                    [textboxStyle]="{ 'width': '9em', 'color':'black !important', 'cursor': 'default', 'margin-left':'1em' }"
                                    [showLabel]='false'
                                    [(value)]='currentCount'
                                    (valueChange)="recalculate()"
                                ></app-slider-text-combo>
                        </div>
                    </div>
                    <div *ngIf="currentType" class="inner-sub-content">

                        <span class="e-label form-fields">{{'ExaktTypRadio.DialogHeadkineBerechnungsflussGesamt_T' | translate}}</span>

                        <div style="flex-direction: row; display: flex; align-items: center;">
                            <span class="e-label">
                                {{formatBerechnungsdurchfluss(currentSum)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="e-footer-content">
        <button
            class="e-control e-btn e-lib e-primary e-flat"
            style="margin-top: 1em; margin-bottom: 1em;"
            (click)="onZapfstelleSave()"
        >
            {{'ExaktTypRadio.DialogButtonSpeichern_T' | translate}}
        </button>
    </div>
</ejs-dialog>

<!-- Invalid data dialog -->
<ejs-dialog
    #invalidFieldsDialog
    target='app-demand-volumetric-flow-other'
    [buttons]='invalidFieldsDialogButtons'
    header="{{'ExaktTypRadio.InvalidDialogHeader_T' | translate}}"
    [visible]='false'
    [isModal]='true'
    [closeOnEscape]='false'
    [showCloseIcon]='true'
    width='50em'
>
    <ng-template #content>
        <div class="row">
            <span>{{'ExaktTypRadio.InvalidDialogContent_SD' | translate}}</span>
        </div>
    </ng-template>
</ejs-dialog>