// This code was copied from the Syncfusion angular library and contains important utility
// code to build angular wrappers for Syncfusion components.

/* tslint:disable */
import { Component, EventEmitter, Renderer2 } from '@angular/core';
import { getValue, setTemplateEngine, getTemplateEngine, attributes, createElement, isNullOrUndefined, isUndefined } from '@syncfusion/ej2-base';
import { ViewContainerRef, EmbeddedViewRef, ElementRef, TemplateRef } from '@angular/core';

/**
 * Angular Utility Module (from syncfusion)
 */

export function applyMixins(derivedClass: any, baseClass: any[]): void {
    baseClass.forEach(baseClass => {
        Object.getOwnPropertyNames(baseClass.prototype).forEach(name => {
             if (!derivedClass.prototype.hasOwnProperty(name) || baseClass.isFormBase) {
                derivedClass.prototype[name] = baseClass.prototype[name];
              }
        });
    });
}

export function ComponentMixins(baseClass: Function[]): ClassDecorator {
    return function (derivedClass: Function) {
        applyMixins(derivedClass, baseClass);
    };
}

/**
 * @private
 */
export function registerEvents(eventList: string[], obj: any, direct?: boolean): void {
    let ngEventsEmitter: { [key: string]: Object } = {};
    if (eventList && eventList.length) {
        for (let event of eventList) {
            if (direct === true) {
                obj.propCollection[event] = new EventEmitter(false);
                obj[event] = obj.propCollection[event];
            } else {
                ngEventsEmitter[event] = new EventEmitter(false);
            }
        }
        if (direct !== true) {
            obj.setProperties(ngEventsEmitter, true);
        }
    }
}

/**
 * @private
 */
export function clearTemplate(_this: any, templateNames?: string[], index?: any): void {
    let regTemplates: string[] = Object.keys(_this.registeredTemplate);
    if (regTemplates.length) {
        /* istanbul ignore next */
        let regProperties: string[] = templateNames && templateNames.filter(
            (val: string) => {
                return (/\./g.test(val) ? false : true);
            });
        for (let registeredTemplate of (regProperties && regProperties || regTemplates)) {
            /* istanbul ignore next */
            if (index && index.length) {
                for (let e = 0; e < index.length; e++) {
                    for (let m = 0; m < _this.registeredTemplate.template.length; m++) {
                        let value = _this.registeredTemplate.template[m].rootNodes[0];
                        if (value === index[e]) {
                            let rt = _this.registeredTemplate[registeredTemplate];
                            rt[m].destroy();
                        }
                    }
                }
            } else {
                for (let rt of _this.registeredTemplate[registeredTemplate]) {
                    if (!rt.destroyed) {
                        if(rt._view){
                            let pNode: any = rt._view.renderer.parentNode(rt.rootNodes[0]);
                            if (!isNullOrUndefined(pNode)) {
                                for (let m: number = 0; m < rt.rootNodes.length; m++) {
                                    pNode.appendChild(rt.rootNodes[m]);
                                }
                            }
                        }
                        rt.destroy();
                    }
                }
            }
            delete _this.registeredTemplate[registeredTemplate];
        }
    }
    for (let tagObject of _this.tagObjects) {
        if (tagObject.instance) {
            /* istanbul ignore next */
            tagObject.instance.clearTemplate((templateNames && templateNames.filter(
                (val: string) => {
                    return (new RegExp(tagObject.name).test(val) ? true : false);
                })));
        }
    }
}

/**
 * To set value for the nameSpace in desired object.
 * @param {string} nameSpace - String value to the get the inner object
 * @param {any} value - Value that you need to set.
 * @param {any} obj - Object to get the inner object value.
 * @return {void}
 * @private
 */
export function setValue(nameSpace: string, value: any, object: any): any {
    let keys: string[] = nameSpace.replace(/\[/g, '.').replace(/\]/g, '').split('.');
    /* istanbul ignore next */
    let fromObj: any = object || {};
    for (let i: number = 0; i < keys.length; i++) {
        let key: string = keys[i];
        if (i + 1 === keys.length) {
            fromObj[key] = value === undefined ? {} : value;
        } else if (fromObj[key] === undefined) {
            fromObj[key] = {};
        }
        fromObj = fromObj[key];
    }
    return fromObj;
}

export interface PropertyCollectionInfo {
    props: PropertyDetails[];
    complexProps: PropertyDetails[];
    colProps: PropertyDetails[];
    events: PropertyDetails[];
    propNames: string[];
    complexPropNames: string[];
    colPropNames: string[];
    eventNames: string[];
}

export interface PropertyDetails {
    propertyName: string;
    type: FunctionConstructor | Object;
    defaultValue: Object;
}


const SVG_REG: RegExp = /^svg|^path|^g/;

export interface IComponentBase {
    registerEvents: (eventList: string[]) => void;
    addTwoWay: (propList: string[]) => void;
}

interface Tag {
    hasChanges: boolean;
    getProperties?: Function;
    isInitChanges: boolean;
    hasNewChildren: boolean;
    list: TagList[];
    clearTemplate?: (arg: string[]) => void;
}

interface TagList {
    getProperties: Function;
    hasChanges: boolean;
    isUpdated: boolean;
}

@Component({
    template: ''
})
export class ComponentBase<T> {
    /** @ignore */
    public element: HTMLElement;

    /** @ignore */
    public tags: string[];

    private ngAttr: string;
    private srenderer: Renderer2;
    protected isProtectedOnChange: boolean = true;
    private isAngular: boolean;
    private isFormInit: boolean = true;
    public preventChange: boolean;
    public isPreventChange: boolean;
    protected oldProperties: { [key: string]: Object };
    protected changedProperties: { [key: string]: Object };
    protected finalUpdate: Function;
    protected isUpdated: boolean;
    /** @ignore */
    public ngEle: ElementRef;

    private tagObjects: { name: string, instance: Tag }[];

    /** @ignore */
    public onPropertyChanged: (newProp: Object, oldProp: Object) => void;

    /** @ignore */
    public appendTo: (ele: string | HTMLElement) => void;

    /** @ignore */
    public setProperties: (obj: Object, muteOnChange: boolean) => void;

    /** @ignore */
    public properties: Object;

    /** @ignore */
    public dataBind: Function;

    private createElement: Function;

    protected changeIgnoredPropertyMap: { [propertyName: string]: boolean } = {};

    protected saveChanges(key: string, newValue: Object, oldValue: Object): void {
        if (this.isProtectedOnChange)
            return;
        if (this.changeIgnoredPropertyMap[key])
            return;

        this.oldProperties[key] = oldValue;
        this.changedProperties[key] = newValue;
        // this.changedProperties = {};
        this.finalUpdate();

        let changeTime = setTimeout(this.dataBind.bind(this));
        let clearUpdate = () => clearTimeout(changeTime);
        this.finalUpdate = clearUpdate;
    };

    /** @ignore */
    public destroy: Function;

    private registeredTemplate: { [key: string]: EmbeddedViewRef<Object>[] };
    private complexTemplate: string[];

    private ngBoundedEvents: { [key: string]: Map<object, object> };

    /** @ignore */
    public ngOnInit(isTempRef?: any): void {
        let tempOnThis: any = isTempRef || this;
        tempOnThis.registeredTemplate = {};
        tempOnThis.ngBoundedEvents = {};
        tempOnThis.isAngular = true;
        tempOnThis.isFormInit = true;

        if (isTempRef) {
            this.tags = isTempRef.tags;
        }
        tempOnThis.tags = this.tags || [];
        tempOnThis.complexTemplate = this.complexTemplate || [];
        tempOnThis.tagObjects = [];
        tempOnThis.ngAttr = this.getAngularAttr(tempOnThis.element);

        tempOnThis.createElement = (tagName: string, prop?:
            { id?: string, className?: string, innerHTML?: string, styles?: string, attrs?: { [key: string]: string } }) => {

            let ele: Element = tempOnThis.srenderer ? tempOnThis.srenderer.createElement(tagName) : createElement(tagName);
            if (typeof (prop) === 'undefined')
                return <HTMLElement>ele;

            ele.innerHTML = (prop.innerHTML ? prop.innerHTML : '');

            if (prop.className !== undefined)
                ele.className = prop.className;

            if (prop.id !== undefined)
                ele.id = prop.id;

            if (prop.styles !== undefined)
                ele.setAttribute('style', prop.styles);

            if (tempOnThis.ngAttr !== undefined)
                ele.setAttribute(tempOnThis.ngAttr, '');

            if (prop.attrs !== undefined)
                attributes(ele, prop.attrs);

            return <HTMLElement>ele;
        };
        for (let tag of tempOnThis.tags) {
            let tagObject: { name: string, instance: Tag } = {
                instance: getValue('child' + tag.substring(0, 1).toUpperCase() + tag.substring(1), tempOnThis),
                name: tag
            };
            tempOnThis.tagObjects.push(tagObject);
        }

        let complexTemplates: string[] = Object.keys(tempOnThis);
        complexTemplates = complexTemplates.filter((val: string): boolean =>
            /Ref$/i.test(val) && /\_/i.test(val));

        for (let tempName of complexTemplates) {
            let propName: string = tempName.replace('Ref', '');
            let val: Object = {};
            setValue(propName.replace('_', '.'), getValue(propName, tempOnThis), val);
            tempOnThis.setProperties(val, true);
        }
    }

    public getAngularAttr(ele: Element): string {
        let attributes: NamedNodeMap = ele.attributes;
        let length: number = attributes.length;
        let ngAr: string;
        for (let i: number = 0; i < length; i++) {
            /* istanbul ignore next */
            if (/_ngcontent/g.test(attributes[i].name))
                ngAr = attributes[i].name;
        }
        return ngAr;
    };

    public ngAfterViewInit(isTempRef?: any): void {
        let tempAfterViewThis: any = isTempRef || this;
        let regExp: RegExp = /ejs-tab|ejs-accordion|psp-pin-accordion/g;
        /* istanbul ignore next */
        if (regExp.test(tempAfterViewThis.ngEle.nativeElement.outerHTML))
            tempAfterViewThis.ngEle.nativeElement.style.visibility = 'hidden';

        /**
         * Root level template properties are not getting rendered,
         * Due to ngonchanges not get triggered.
         * so that we have set template value for root level template properties,
         * for example: refer below syntax
         * ```html
         * <ejs-grid>
         * <e-column></e-column>
         * <ng-template #editSettingsTemplate></ng-template>
         * </ejs-grid>
         * ```
         */
        let templateProperties: string[] = Object.keys(tempAfterViewThis);
        templateProperties = templateProperties.filter((val: string): boolean => /Ref$/i.test(val));
        for (let tempName of templateProperties) {
            let propName: string = tempName.replace('Ref', '');
            setValue(propName.replace('_', '.'), getValue(propName + 'Ref', tempAfterViewThis), tempAfterViewThis);
        }

        // WARNING: applying a timeout here leads to render problems of this component in multiselects and probably other ng-templates
        //          however, this issue seems to appear in storybook only
        const isNotStorybook = window['__STORYBOOK_ADDONS'] === undefined;
        if (isNotStorybook)
        {
            // Used setTimeout for template binding
            // Refer Link: https://github.com/angular/angular/issues/6005
            setTimeout(() => {
                /* istanbul ignore else  */
                if (typeof window !== 'undefined') {
                    tempAfterViewThis.appendTo(tempAfterViewThis.element);
                    tempAfterViewThis.ngEle.nativeElement.style.visibility = '';
                }
            });
        }
        else
        {
            /* istanbul ignore else  */
            if (typeof window !== 'undefined') {
                tempAfterViewThis.appendTo(tempAfterViewThis.element);
                tempAfterViewThis.ngEle.nativeElement.style.visibility = '';
            }
        }
    }

    public ngOnDestroy(isTempRef?: any): void {
        let tempOnDestroyThis: any = isTempRef || this;
        /* istanbul ignore else  */
        setTimeout(() => {
            if (typeof window !== 'undefined' && tempOnDestroyThis.element.classList.contains('e-control')) {
                tempOnDestroyThis.destroy();
                tempOnDestroyThis.clearTemplate(null);
                // removing bounded events and tagobjects from component after destroy
                tempOnDestroyThis.ngBoundedEvents = {};
                tempOnDestroyThis.tagObjects = {};
                tempOnDestroyThis.ngEle = null;
            }
        });
    }

    public clearTemplate(templateNames?: string[], index?: any): void {
        clearTemplate(this, templateNames, index);
    };

    public ngAfterContentChecked(isTempRef?: any): void
    {
        let tempAfterContentThis: any = isTempRef || this;
        for (let tagObject of tempAfterContentThis.tagObjects) {
            if (!isUndefined(tagObject.instance) &&
                (tagObject.instance.isInitChanges || tagObject.instance.hasChanges || tagObject.instance.hasNewChildren)) {
                if (tagObject.instance.isInitChanges) {
                    let propObj: { [key: string]: Object } = {};
                    // For angular 9 compatibility
                    // Not able to get complex directive properties reference ni Onint hook
                    // So we have constructed property here and used
                    let complexDirProps;
                    let list = getValue('instance.list', tagObject);

                    if (list && list.length) {
                        complexDirProps = list[0].directivePropList;
                    }
                    let skip: any = true;
                    if ((tempAfterContentThis as any).getModuleName && (tempAfterContentThis as any).getModuleName() === 'gantt') {
                        skip = false
                    }

                    if (complexDirProps && skip && complexDirProps.indexOf(tagObject.instance.propertyName) === -1) {
                        let compDirPropList = Object.keys(tagObject.instance.list[0].propCollection);
                        for (let h = 0; h < tagObject.instance.list.length; h++) {
                            tagObject.instance.list[h].propCollection[tagObject.instance.propertyName] = [];
                            let obj: any = {};
                            for (let k = 0; k < compDirPropList.length; k++) {
                                let complexPropName = compDirPropList[k];
                                obj[complexPropName] = tagObject.instance.list[h].propCollection[complexPropName];
                            }
                            for (let i = 0; i < tagObject.instance.list[h].tags.length; i++) {
                                let tag = tagObject.instance.list[h].tags[i];
                                let childObj = getValue('child' + tag.substring(0, 1).toUpperCase() + tag.substring(1), tagObject.instance.list[h]);
                                if (childObj) {
                                    let innerchildObj = tagObject.instance.list[h]['child' + tag.substring(0, 1).toUpperCase() + tag.substring(1)];
                                    if (innerchildObj) {
                                        for (let j = 0; j < innerchildObj.list.length; j++) {
                                            let innerTag = innerchildObj.list[0].tags[0];
                                            if (innerTag) {
                                                let innerchildTag = getValue('child' + innerTag.substring(0, 1).toUpperCase() + innerTag.substring(1), innerchildObj.list[j]);
                                                if (innerchildTag) {
                                                    innerchildObj.list[j].tagObjects.push({ instance: innerchildTag, name: innerTag });
                                                }
                                            }
                                        }
                                    }
                                    tagObject.instance.list[h].tagObjects.push({ instance: childObj, name: tag });
                                }
                            }
                            tagObject.instance.list[h].propCollection[tagObject.instance.propertyName].push(obj);
                        }
                    }
                    // End angular 9 compatibility
                    propObj[tagObject.name] = tagObject.instance.getProperties();
                    tempAfterContentThis.setProperties(propObj, tagObject.instance.isInitChanges);
                } else {
                    /* istanbul ignore next */
                    if ((tempAfterContentThis[tagObject.name].length !== tagObject.instance.list.length) || (tempAfterContentThis.getModuleName() === 'diagram')) {
                        tempAfterContentThis[tagObject.name] = tagObject.instance.list;
                    }
                    for (let list of tagObject.instance.list) {
                        let curIndex: number = tagObject.instance.list.indexOf(list);
                        let curChild: any = getValue(tagObject.name, tempAfterContentThis)[curIndex];
                        let complexTemplates: string[] = Object.keys(curChild);
                        complexTemplates = complexTemplates.filter((val: string): boolean => {
                            return /Ref$/i.test(val);
                        });
                        for (let complexPropName of complexTemplates) {
                            complexPropName = complexPropName.replace(/Ref/, '');
                            curChild.properties[complexPropName] = Object.keys(curChild.properties).length != 0 &&
                                !curChild.properties[complexPropName] ?
                                curChild.propCollection[complexPropName] : curChild.properties[complexPropName];
                        }
                        if (!isUndefined(curChild) && !isUndefined(curChild.setProperties)) {
                            if (/diagram|DashboardLayout/.test(tempAfterContentThis.getModuleName())) {
                                curChild.setProperties(list.getProperties(), true);
                            } else {
                                curChild.setProperties(list.getProperties());
                            }
                        }
                        list.isUpdated = true;
                    }
                }
            }
        }
    }

    protected registerEvents(eventList: string[]): void {
        registerEvents(eventList, this);
    }

    protected twoWaySetter(newVal: Object, prop: string): void {
        let oldVal: Object = getValue(prop, this.properties);
        if (oldVal === newVal) {
            return;
        }
        this.saveChanges(prop, newVal, oldVal);
        setValue(prop, (isNullOrUndefined(newVal) ? null : newVal), this.properties);
        getValue(prop + 'Change', this).emit(newVal);
    }

    protected addTwoWay(propList: string[]): void {
        for (let prop of propList) {
            getValue(prop, this);
            Object.defineProperty(this, prop, {
                get: () => getValue(prop, this.properties),
                set: (newVal: Object) => this.twoWaySetter(newVal, prop)
            });
            setValue(prop + 'Change', new EventEmitter(), this);
        }
    }

    public addEventListener(eventName: string, handler: Function): void {
        let eventObj: EventEmitter<Object> = getValue(eventName, this);
        if (!isUndefined(eventObj)) {
            if (!this.ngBoundedEvents[eventName])
                this.ngBoundedEvents[eventName] = new Map();

            this.ngBoundedEvents[eventName].set(handler, eventObj.subscribe(handler));
        }
    }

    public removeEventListener(eventName: string, handler: Function): void {
        let eventObj: EventEmitter<Object> = getValue(eventName, this);

        if (!isUndefined(eventObj))
            (<EventEmitter<object>>this.ngBoundedEvents[eventName].get(handler)).unsubscribe();
    }

    public trigger(eventName: string, eventArgs: object, success?: (eventArgs) => void): void {
        let eventObj: { next: Function } = getValue(eventName, this);

        let prevDetection: boolean = this.isProtectedOnChange;
        this.isProtectedOnChange = false;

        if (eventArgs)
            (<{ name: string }>eventArgs).name = eventName;

        if (!isUndefined(eventObj) && !isUndefined(eventObj.next))
            eventObj.next(eventArgs);

        let localEventObj: Function = getValue('local' + eventName.charAt(0).toUpperCase() + eventName.slice(1), this);
        if (!isUndefined(localEventObj))
            localEventObj.call(this, eventArgs);

        this.isProtectedOnChange = prevDetection;
        /* istanbul ignore else  */
        if (success) {
            this.preventChange = this.isPreventChange;
            success.call(this, eventArgs);
        }
        this.isPreventChange = false;
    }
}

let stringCompiler: (template: string, helper?: object) => (data: Object | JSON) => string = getTemplateEngine();

/**
 * Angular Template Compiler
 */
export function compile(templateEle: AngularElementType, helper?: Object):
    (data: Object | JSON, component?: any, propName?: any) => Object
{
    if (typeof templateEle === 'string') {
        return stringCompiler(templateEle, helper);
    } else {
        let contRef: ViewContainerRef = templateEle.elementRef.nativeElement._viewContainerRef;
        let pName: string = templateEle.elementRef.nativeElement.propName;
        return (data: Object, component?: any, propName?: any): Object => {
            let context: Object = { $implicit: data };
            /* istanbul ignore next */
            let conRef: ViewContainerRef = contRef ? contRef : component.viewContainerRef;
            let viewRef: EmbeddedViewRef<Object> = conRef.createEmbeddedView(templateEle as TemplateRef<Object>, context);
            viewRef.markForCheck();
            /* istanbul ignore next */
            let viewCollection: { [key: string]: EmbeddedViewRef<Object>[] } = (component && component.registeredTemplate) ?
                component.registeredTemplate : getValue('currentInstance.registeredTemplate', conRef);

            propName = (propName && component.registeredTemplate) ? propName : pName;
            if (typeof viewCollection[propName] === 'undefined')
                viewCollection[propName] = [];

            viewCollection[propName].push(viewRef);
            return viewRef.rootNodes;
        };
    }
}

/**
 * Property decorator for angular.
 */
export function Template<T>(defaultValue?: Object): PropertyDecorator {
    return (target: Object, key: string) => {
        let propertyDescriptor: Object = {
            set: setter(key),
            get: getter(key, defaultValue),
            enumerable: true,
            configurable: true
        };
        Object.defineProperty(target, key, propertyDescriptor);
    };
}

function setter(key: string): Function {
    return function (val: AngularElementType): void {
        if (val === undefined)
            return;

        setValue(key + 'Ref', val, this);
        if (typeof val !== 'string') {
            val.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
            val.elementRef.nativeElement.propName = key;
        } else {
            if (this.saveChanges) {
                this.saveChanges(key, val, undefined);
                this.dataBind();
            }
        }
    };
}

function getter(key: string, defaultValue: Object): Function {
    return function (): Object {
        /* istanbul ignore next */
        return getValue(key + 'Ref', this) || defaultValue;
    };
}

export interface AngularElementType {
    elementRef: ElementRef;
}

setTemplateEngine({ compile: (compile as any) });
/* tslint:enable */
