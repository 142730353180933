import { NgModule } from '@angular/core';
import { PspSearchBoxComponent } from './psp-search-box.component';

@NgModule({
    declarations:
    [
        PspSearchBoxComponent,
    ],
    exports:
    [
        PspSearchBoxComponent,
    ],
})
export class PspSearchBoxModule {}
