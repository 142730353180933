<span *ngIf="stepText" class="current-step" [innerHTML]="stepText"></span>
<a
    class="step-back"
    (click)="stepBack_clicked($event)"
>
    <span *ngIf="stepBackLabel" class="icon icon-angle-left"></span>
    <span>{{stepBackLabel}}</span>
</a>

<div *ngIf="titleHelpId" class="title-help-wrapper">
    <h1 [innerHTML]="stepTitle"></h1>
    <app-help-icon [hinweisId]="titleHelpId"></app-help-icon>
</div>
<h1 *ngIf="!titleHelpId" [innerHTML]="stepTitle"></h1>

<p class="description-text" *ngIf="stepDescription" >
    <span [innerHTML]="stepDescription"></span>&nbsp;
    <span class="description-help" *ngIf="stepDescriptionHelpId">
        <app-help-icon [hinweisId]="stepDescriptionHelpId"></app-help-icon>
    </span>
</p>