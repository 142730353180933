import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups/src/dialog/dialog.component';
import * as _ from 'lodash';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { BedarfsvolumenstromOther } from 'src/app/core/BackendApi/bedarfsvolumenstromother.complex';
import { FreieZapfstelle } from 'src/app/core/BackendApi/freiezapfstelle.complex';
import { KeyValueInt } from 'src/app/core/BackendApi/keyvalueint.complex';
import { VordefinierteZapfstelle } from 'src/app/core/BackendApi/vordefiniertezapfstelle.complex';
import { DemandVolumetricFlowBaseComponent } from 'src/app/core/DemandVolumetricFlowBaseComponent';
import { BuildingTypeRow } from 'src/app/core/DteufcAggregatorServiceApi/buildingtyperow.complex';
import { Din1988SimultaneityRow } from 'src/app/core/DteufcAggregatorServiceApi/din1988simultaneityrow.complex';
import { FacilityGroupRow } from 'src/app/core/DteufcAggregatorServiceApi/facilitygrouprow.complex';
import { Vdi6003FacilityRow } from 'src/app/core/DteufcAggregatorServiceApi/vdi6003facilityrow.complex';
import { Komfortstufe, BedarfsvolumenstromErmittlungstypOther, SectionState } from 'src/app/core/Enums';
import { ITappingPointParam, VolumetricFlowRateMath } from 'src/app/core/math/VolumetricFlowRateMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { ZapfstellenDialogData } from 'src/app/shared/zapfstellen-dialog-data/zapfstellen-dialog-data.component';

@Component({
    selector: 'app-demand-volumetric-flow-other',
    templateUrl: './demand-volumetric-flow-other.component.html',
    styleUrls: ['./demand-volumetric-flow-other.component.scss'],
    providers: [provideContext('DemandVolumetricFlowOther')]
})
export class DemandVolumetricFlowOtherComponent extends DemandVolumetricFlowBaseComponent implements OnInit, AfterViewInit {
    // Viewchilds
    @ViewChild('gridVordefiniert') public gridVordefinierteZapfstelle: GridComponent;
    @ViewChild('gridFree') public gridFreieZapfstelle: GridComponent;
    @ViewChild('editDialog') public dialogData: ZapfstellenDialogData;
    @ViewChild('invalidFieldsDialog') public invalidFieldsDialog: DialogComponent;
    @ViewChild('deleteDialog') public deleteDialog: DialogComponent;
    @ViewChild("exaktGleichzeitigkeitDropdown") public ddlObj: DropDownListComponent;

    // Button bindings
    editDialogButtons = [{
        'click': this.onZapfstelleAdd.bind(this),
        buttonModel: {
            content: this.langService.getString('EditDialogUebernehmenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
    }]

    deleteDialogButtons = [{
        'click': this.onZapfstelleDelete.bind(this),
        buttonModel: {
            content: this.langService.getString('DeleteDialogUebernehmenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
    }]

    stepBack = this.langService.getString('StepBack_T');
    stepDescription = this.langService.getString('StepDescription_D');

    // Public Variables
    public stepTitle:string;
    public gebaeudetyp : string;
    public gleichzeitigkeitDropdownFields = { text: "DisplayNameLoc", value: "SimultaneityId" };
    public gleichzeitigkeitRows: Din1988SimultaneityRow[];
    public editingData: BedarfsvolumenstromOther;
    public facilityGroups: FacilityGroupRow[];
    public currentBuildingType: string;
    public currentType: string;
    public deleteIsFree: boolean;
    public deleteIndex: number;
    public bedarfTemp = 0;
    public bedarfGrobTemp: KeyValueInt[];
    public bedarfExaktTemp = 0;
    public bedarfExaktTableTemp;

    // Internal Variables
    private buildingTypeInfo: BuildingTypeRow;
    private allFacilities: Vdi6003FacilityRow[];
    private editingItemIsFree: boolean;
    private editingItemIndex: number;

    // Constructors/Initializers
    constructor(
        private activatedRoute: ActivatedRoute,
        protected readonly router: Router,
        protected DataService: ProjectDataService,
        protected formatterService: ValueFormatterService,
        protected aggregatorDataService: AggregatorDataService,
        private langService: LangService,
        @Inject(VolumetricFlowRateMath) private bvsMath: VolumetricFlowRateMath
    ) {
        super(router, DataService, formatterService, aggregatorDataService);
    }
    ngAfterViewInit(): void {

        if(this.bedarfTemp && this.bedarfTemp != 0){

            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = this.bedarfTemp;
        }
        if(this.bedarfGrobTemp && this.bedarfGrobTemp  && this.editingData.ErmittlungOtherGrob){

            this.editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl = this.clone(this.bedarfGrobTemp);
        }
        console.log(this.editingData.ErmittlungsTyp)
        if( this.editingData.ErmittlungOtherExakt && this.editingData.ErmittlungsTyp == this.ErmittlungsTypOther.Exakt){
            this.editingData.ErmittlungOtherExakt =this.clone(this.bedarfExaktTableTemp);
            this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear = this.bedarfExaktTemp;
        }
        if(this.editingData.ErmittlungsTyp == this.ErmittlungsTypOther.Grob){
            this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear = 0;
            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = 0;
        }
        if(this.editingData.ErmittlungsTyp == this.ErmittlungsTypOther.Exakt){
            this.editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl.forEach(x => x.Value = 0);
            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = 0;
        }
        if(this.editingData.ErmittlungsTyp == this.ErmittlungsTypOther.BvsDirekt){  
            this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear = 0;
            this.editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl.forEach(x => x.Value = 0);
        }
        //this.dialogData = new ZapfstellenDialogData(this.langService, this.formatterService, this.allFacilities);
        let helpTextComfort = this.aggregatorDataService.aggregatorData.Tables.TooltipRows
        .find(x => x.TooltipId == "Bedarfsvolumenstrom.AndereGebäude.Komfortstufe").TooltipContentHtmlLoc
        this.dialogData.helpTextComfort = helpTextComfort;
        // Calculate
        this.calculateBvs();
    }
    ngOnInit(): void {
        // Init project
        this.currentProject = this.activatedRoute.snapshot.data['project'];
        this.currentBuildingType = this.activatedRoute.snapshot.data['buildingType'];
        if(!this.currentBuildingType || (this.currentProject.ResidentialBuilding?.BuildingType != this.currentBuildingType
            && !this.currentProject.OtherBuildings?.some(b => b.BuildingType == this.currentBuildingType))
        ){
            this.router.navigate([`/project-view/${this.currentProject.ProjectId}`])
        }

        // Init table data
        this.facilityGroups = this.aggregatorDataService.aggregatorData.Tables.FacilityGroupRows.filter(r => r.BuildingTypeId == this.currentBuildingType);
        this.buildingTypeInfo = this.aggregatorDataService.aggregatorData.Tables.BuildingTypeRows.find(b => b.BuildingId == this.currentBuildingType);
        this.allFacilities = this.aggregatorDataService.aggregatorData.Tables.Vdi6003FacilityRows;
        this.gleichzeitigkeitRows = this.aggregatorDataService.aggregatorData.Tables.Din1988SimultaneityRows;
        if (!this.gleichzeitigkeitRows.some(x => x.SimultaneityId == "Manuell")) {
            this.gleichzeitigkeitRows.push({ DisplayNameLoc: this.langService.getString('ExaktTypRadio.DialogDropDownManuelleEingabe_T'), SimultaneityId: "Manuell", Din1988KonstanteA: 0, Din1988KonstanteB: 0, Din1988KonstanteC: 0 })
        }

        // Init dialog data
        this,this.gebaeudetyp = `${this.buildingTypeInfo.DisplayNameLoc}`
        this.stepTitle = this.langService.getString('StepTitle', undefined, this.gebaeudetyp);

        // Load/Init data
        var building = this.currentProject.OtherBuildings.find(b => b.BuildingType == this.currentBuildingType);
        if (building.Bedarfsvolumenstrom) {
            this.editingData = this.clone(building.Bedarfsvolumenstrom);
        }
        else {
            this.editingData = { 
                ErmittlungsTyp: BedarfsvolumenstromErmittlungstypOther.None,
                ErmittlungBvsDirekt: {}
            };
        }

        if(this.editingData.ErmittlungBvsDirekt){

            this.bedarfTemp = this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom;
        }
        if(this.editingData.ErmittlungOtherGrob){

            this.bedarfGrobTemp = this.clone(this.editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl);
        }
        if(this.editingData.ErmittlungOtherExakt){

            this.bedarfExaktTemp = this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear;
            this.bedarfExaktTableTemp = this.clone(this.editingData.ErmittlungOtherExakt);
        }

        this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = 0;
        this.editingData.ErmittlungOtherGrob = {
            WohneinheitenAbnehmerAnzahl: this.facilityGroups.map(g => { return { Key: g.FacilityGroupId, Value: 0 }})
        }
        this.editingData.ErmittlungOtherExakt = {
            ZapfstellenFrei: [],
            ZapfstellenVordefiniert: [],
            gleichzeitigkeitLinear: 0
        }

    }

    // Shared Methods
    public calculateBvs(): void {
        switch(this.editingData.ErmittlungsTyp){
            case BedarfsvolumenstromErmittlungstypOther.BvsDirekt:
                this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = Number(this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom);
                this.editingData.BedarfsvolumenstromErgebnisLMin = this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom;
                break;
            case BedarfsvolumenstromErmittlungstypOther.Grob:
                this.calculateBvsGrob();
                break;
            case BedarfsvolumenstromErmittlungstypOther.Exakt:
                this.calculateBvsExact();
                break;
            default: this.resetResult(); break;
        }
    }
    public getUnitRow(unitId: string): FacilityGroupRow {
        return this.facilityGroups.find(p => p.FacilityGroupId == unitId);
    }

    // Internal Methods
    protected getBuildingType(): string {
        return this.currentBuildingType;
    }

    private resetResult(): void {
        this.editingData.BedarfsvolumenstromErgebnisLMin = 0;
    }

    private calculateBvsGrob(): void {
        if(!this.editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl.some(w => w.Value > 0))
        {
            this.resetResult();
            return;
        }

        // Determine gleichzeitigkeit to use
        var gleichzeitigkeitType: Din1988SimultaneityRow = null;
        var gleichzeitigkeitLinear = this.buildingTypeInfo.LineareGleichzeitigkeitFaktor;
        if(this.buildingTypeInfo.VorauswahlSimultaneityId){
            gleichzeitigkeitType = this.gleichzeitigkeitRows.find(g => g.SimultaneityId == this.buildingTypeInfo.VorauswahlSimultaneityId)
        }

        // Get facilities from groups
        this.editingData.ErmittlungOtherGrob.Zapfstellen = this.editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl.map(wa => {
            return this.aggregatorDataService.aggregatorData.Tables.FacilityGroupItemRows.filter(gi => gi.FacilityGroupId == wa.Key).map(gi => {
                let berechnungsdurchflussLSek: number;
                let count = wa.Value * gi.Count;
                let stufe: Komfortstufe;
                switch (gi.Stufe) {
                    case "S1":
                        berechnungsdurchflussLSek = this.allFacilities.find(d => d.VdiFacilityId == gi.VdiFacilityId).BerechnungsdurchflussKS1
                        stufe = Komfortstufe.Stufe1;
                        break;
                    case "S2":
                        berechnungsdurchflussLSek = this.allFacilities.find(d => d.VdiFacilityId == gi.VdiFacilityId).BerechnungsdurchflussKS2
                        stufe = Komfortstufe.Stufe2;
                        break;
                    case "S3":
                        berechnungsdurchflussLSek = this.allFacilities.find(d => d.VdiFacilityId == gi.VdiFacilityId).BerechnungsdurchflussKS3
                        stufe = Komfortstufe.Stufe3;
                        break;
                }
                return {
                    Typ: gi.VdiFacilityId,
                    Komfortstufe: stufe,
                    Anzahl: count,
                    BerechnungsflussLiterSek: berechnungsdurchflussLSek,
                    BerechnungsflussSummeLiterSek: count * berechnungsdurchflussLSek
                } as VordefinierteZapfstelle
            });
        }).reduce((a,b) => a.concat(b));

        // Calculate result
        this.editingData.BedarfsvolumenstromErgebnisLMin = this.bvsMath.bedarfsvolumenstromFromEntnahmestellen({
            gleichzeitigkeitLinearFactor: gleichzeitigkeitLinear,
            gleichzeitigkeitType: gleichzeitigkeitType,
            entnahmestellen: this.editingData.ErmittlungOtherGrob.Zapfstellen.map(z => { return {
                berechnungsdurchflussLSek: z.BerechnungsflussLiterSek,
                anzahl: z.Anzahl
            }})
        });
    }

    private calculateBvsExact(): void {
        // make sure that the linear factor is input as a number
        this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear = Number(this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear);

        // Reset results if no zapfstellen are configured
        if(this.isEntnahmestelleErrorShown())
        {
            this.resetResult();
            return;
        }

        // Determine gleichzeitigkeit to use
        let gleichzeitigkeitTypeRow = this.gleichzeitigkeitRows.find(g => g.SimultaneityId == this.editingData.ErmittlungOtherExakt.Gleichzeitigkeit)

        // Get zapfstellen
        let zapfstellen: ITappingPointParam[] = [];
        if(this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert){
            zapfstellen = zapfstellen.concat(this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert.map(z => { return{
                anzahl: z.Anzahl,
                berechnungsdurchflussLSek: z.BerechnungsflussLiterSek
            }}));
        }
        if(this.editingData.ErmittlungOtherExakt.ZapfstellenFrei){
            zapfstellen = zapfstellen.concat(this.editingData.ErmittlungOtherExakt.ZapfstellenFrei.map(z => { return{
                anzahl: z.Anzahl,
                berechnungsdurchflussLSek: z.BerechnungsflussLiterSek
            }}));
        }

        // Calculate result
        this.editingData.BedarfsvolumenstromErgebnisLMin = this.bvsMath.bedarfsvolumenstromFromEntnahmestellen({
            gleichzeitigkeitLinearFactor: this.editingData.ErmittlungOtherExakt.Gleichzeitigkeit == 'Manuell' ? this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear : null,
            gleichzeitigkeitType: gleichzeitigkeitTypeRow,
            entnahmestellen: zapfstellen
        });
    }

    public sort_stringify(obj){
        var sortedKeys = Object.keys(obj).sort();
        var arr = [];
        for(var i=0;i<sortedKeys.length;i++){
            var key = sortedKeys[i];
            var value = obj[key];
            key = JSON.stringify(key);
            value = JSON.stringify(value);
            arr.push(key + ':' + value);
        }
        return "{" + arr.join(",\n\r") + "}";
    }

    // Button/Click Events
    public onClickSave(): void {
        let projectBedarf = this.currentProject.OtherBuildings.find(b => b.BuildingType == this.currentBuildingType).Bedarfsvolumenstrom;
        //set non-selected values to null and round necessary results
        if(projectBedarf && this.editingData.ErmittlungsTyp === projectBedarf.ErmittlungsTyp){
           let changed = true;
            switch(this.editingData.ErmittlungsTyp){
                case(this.ErmittlungsTypOther.BvsDirekt):
                    if(projectBedarf.ErmittlungBvsDirekt.Bedarfsvolumenstrom === this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom)
                        changed = false;
                break;
                case(this.ErmittlungsTypOther.Exakt):
                    if(this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear === projectBedarf.ErmittlungOtherExakt.gleichzeitigkeitLinear &&
                        this.editingData.ErmittlungOtherExakt.Gleichzeitigkeit === projectBedarf.ErmittlungOtherExakt.Gleichzeitigkeit)
                        {
                            if(JSON.stringify(this.editingData.ErmittlungOtherExakt.ZapfstellenFrei) === JSON.stringify(projectBedarf.ErmittlungOtherExakt.ZapfstellenFrei) &&
                               JSON.stringify(this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert) === JSON.stringify(projectBedarf.ErmittlungOtherExakt.ZapfstellenVordefiniert))
                                changed = false;
                        }
                break;
                case(this.ErmittlungsTypOther.Grob):
                    if(JSON.stringify(this.editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl) === JSON.stringify(projectBedarf.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl))
                        changed = false;
                break;
            }
            if(!changed){
                this.currentProject.SectionStates.BuildingTypeBedarfsvolumenstromStates
                .find(s => s.Key == this.getBuildingType()).Value = SectionState.Set;
                
                this.DataService.saveCurrentProject()
                    .then(_ => this.router.navigate([`/project-view/${this.currentProject.ProjectId}`]));
                    return;
            }
        }
        
        // Overwrite BVS object in project entity with the new version
        this.currentProject.OtherBuildings.find(b => b.BuildingType == this.currentBuildingType).Bedarfsvolumenstrom = this.clone(this.editingData);

        // Call base implementation
        this.onClickSaveBase();
    }
    public onClickEditZapfstelleVordefiniert(zapfstelle: VordefinierteZapfstelle, index: number): void {
        this.editingItemIsFree = false;
        this.editingItemIndex = index;
        this.currentType = 'Vordefiniert';
        this.dialogData.reset();
        this.dialogData.selectVordefiniertItem(zapfstelle, Number(index));
        this.dialogData.open();
    }
    public onClickDeleteZapfstelleVordefiniert(index: number): void {
        this.deleteIsFree = false;
        this.deleteIndex = index;
        this.deleteDialog.show();
    }
    public onClickEditZapfstelleFrei(zapfstelle: FreieZapfstelle, index: number): void {
        this.editingItemIsFree = true;
        this.editingItemIndex = index;
        this.currentType = 'Manual';
        this.dialogData.reset();
        this.dialogData.selectFreeItem(zapfstelle, Number(index));
        this.dialogData.open();
    }
    public onClickDeleteZapfstelleFrei(index: number): void {
        this.deleteIsFree = true;
        this.deleteIndex = index;
        this.deleteDialog.show();
    }
    public onClickAddNewZapfstelle(): void {
        this.dialogData.reset();
        this.dialogData.open();
    }

    public zapfstellenTypeHasChanged(): boolean {
        return this.editingItemIsFree == null
            || (this.editingItemIsFree == false && this.currentType == 'Manual')
            || (this.editingItemIsFree == true && this.currentType != 'Manual');
    }

    // Change Events
    public onSelect(args) {
        // var multiObject = document.getElementById(id).parentElement;
        var width = this.getTextWidth(args.itemData.DisplayNameLoc, "bold 22px viega-thin");

        // 20px added for dropdownIcon width
        this.ddlObj.element.style.width = width + 20 + "px";
    }
    getTextWidth(text, font) {
        // re-use canvas object for better performance
        var canvas =
          (this.getTextWidth as any).canvas ||
          ((this.getTextWidth as any).canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }
    public onChangeErmittlungstyp(typ: BedarfsvolumenstromErmittlungstypOther): void {
        // Set type
        this.editingData.ErmittlungsTyp = typ;

        // Init type field object
        switch(typ){
            case BedarfsvolumenstromErmittlungstypOther.BvsDirekt:
                if(this.editingData.ErmittlungBvsDirekt == null){
                    this.editingData.ErmittlungBvsDirekt = {
                        Bedarfsvolumenstrom: 0
                    }
                }
                break;
            case BedarfsvolumenstromErmittlungstypOther.Grob:
                if(this.editingData.ErmittlungOtherGrob == null){
                    this.editingData.ErmittlungOtherGrob = {
                        WohneinheitenAbnehmerAnzahl: this.facilityGroups.map(g => { return { Key: g.FacilityGroupId, Value: 0 }})
                    }
                }
                break;
            case BedarfsvolumenstromErmittlungstypOther.Exakt:
                if(this.editingData.ErmittlungOtherExakt == null){
                    this.editingData.ErmittlungOtherExakt = {
                        ZapfstellenFrei: [],
                        ZapfstellenVordefiniert: [],
                        gleichzeitigkeitLinear: 0
                    }
                }
                if(this.buildingTypeInfo.LineareGleichzeitigkeitFaktor){

                    this.editingData.ErmittlungOtherExakt.Gleichzeitigkeit = 'Manuell';
                    this.editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear = this.buildingTypeInfo.LineareGleichzeitigkeitFaktor;
                }
                break;
        }

        // Recalculate
        this.calculateBvs();
    }

    // Dialog events
    public onZapfstelleAdd($event): void {

        // Decide wether to save a new or edit an existing item
        if(this.dialogData.currentlyFree){
            this.editingData.ErmittlungOtherExakt.ZapfstellenFrei.push($event);
            this.dialogData.close();
        } else {
            this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert.push($event);
            this.dialogData.close();
        }

        // Update grid
        this.gridFreieZapfstelle.refresh();
        this.gridVordefinierteZapfstelle.refresh();

        // Recalculate
        this.calculateBvs();
    }
    public onCloseInvalidFieldsDialog(): void {
        this.invalidFieldsDialog.hide();
    }
    public onZapfstelleEdit($event): void {
        if(this.zapfstellenTypeHasChanged()){
            if(this.editingItemIsFree){
                this.editingData.ErmittlungOtherExakt.ZapfstellenFrei.splice(this.editingItemIndex, 1);
                this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert.push($event);
            } else {
                this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert.splice(this.editingItemIndex, 1);
                this.editingData.ErmittlungOtherExakt.ZapfstellenFrei.push($event);
            }
        } else {
            if(this.editingItemIsFree){
                    this.editingData.ErmittlungOtherExakt.ZapfstellenFrei.splice(
                    this.editingItemIndex, 1, $event
                );
            } else {
                    this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert.splice(
                    this.editingItemIndex, 1, $event
                );
            }
        }

        //Update Grid
        this.gridFreieZapfstelle.refresh();
        this.gridVordefinierteZapfstelle.refresh();

        // Recalculate
        this.calculateBvs();
    }

    public onZapfstelleDelete(): void {
        // Delete item
        if(this.deleteIsFree){
            this.editingData.ErmittlungOtherExakt.ZapfstellenFrei.splice(this.deleteIndex, 1);
        } else {
            this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert.splice(this.deleteIndex, 1);
        }

        // Reset values
        this.deleteIsFree = null;
        this.deleteIndex = null;

        // Update grid
        this.gridFreieZapfstelle.refresh();
        this.gridVordefinierteZapfstelle.refresh();
        this.deleteDialog.hide();

        // Recalculate
        this.calculateBvs();
    }

    // Formatters
    public formatErgebnisBvs(): string {
        return this.formatterService.formatNumber(this.editingData.BedarfsvolumenstromErgebnisLMin, 'LiterPerMinute', { maximumFractionDigits: 0 })
    }
    public getVordefinierteZapfstelleTypeName(zapfstelle: VordefinierteZapfstelle): string {
        return this.allFacilities.find(f => f.VdiFacilityId == zapfstelle.Typ).DisplayNameLoc;
    }
    public formatBerechnungsdurchfluss(fluss: number): string {
        if(fluss == null)
            return "";
        else
            return this.formatterService.formatNumber(fluss, 'LiterPerSecond', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }

    // Condition Validators
    public isSaveValid(): boolean {
        return this.editingData.BedarfsvolumenstromErgebnisLMin > 0
            && this.editingData.BedarfsvolumenstromErgebnisLMin <= 140
    }
    public isEntnahmestelleErrorShown(): boolean {
        return this.editingData.ErmittlungsTyp == BedarfsvolumenstromErmittlungstypOther.Exakt &&
            (this.editingData.ErmittlungOtherExakt.ZapfstellenFrei == null || this.editingData.ErmittlungOtherExakt.ZapfstellenFrei.length <= 0)
            && (this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert == null || this.editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert.length <= 0);
    }
    public isToHighVolumeShown(): boolean{
        return this.editingData.BedarfsvolumenstromErgebnisLMin > 140;
    }
    public isToLowVolumeShown(): boolean{
        return this.editingData.BedarfsvolumenstromErgebnisLMin < 4;
    }

}
