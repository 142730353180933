import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { ApartmentGroupFacilityRow } from '../DteufcAggregatorServiceApi/apartmentgroupfacilityrow.complex';
import { Din1988SimultaneityRow } from '../DteufcAggregatorServiceApi/din1988simultaneityrow.complex';
import { MathLogger } from './MathLogger';

export interface IApartmentGroupParam
{
    /** ApartmentCount */
    wohnungsanzahl: number;

    /** Occupancy (amount of people) */
    belegungszahl: number;

    /** Sum of ZapfstellenbedarfWv of all Ausstattungen */
    ausstattungen: ApartmentGroupFacilityRow[];

    /** Type of the second bathroom. */
    zweitesBadTyp: 'None' | 'ZweitesBad' | 'GästezimmerMitBwUndDu' | 'GästezimmerOhneBwUndDu';

    /** Sum of ZapfstellenbedarfWv of all Ausstattungen in the second bathroom. */
    facilitiesZweitesBad?: ApartmentGroupFacilityRow[];
}

export interface IVfrFromTappingPointsParams
{
    /**
     * If set, gleichzeitigkeitLinearFactor is ignored.
     */
    gleichzeitigkeitType?: Din1988SimultaneityRow;

    /**
     * Lineare Gleichzeitigkeit in % (0-100).
     * If set, gleichzeitigkeitType is ignored.
     */
    gleichzeitigkeitLinearFactor?: number;

    entnahmestellen: ITappingPointParam[];
}

export interface ITappingPointParam
{
    anzahl: number;

    /**
     * Wenn nach VDI6003, dann Abhängig von Komfortstufe.
     * Wenn nicht nach VDI6003 (manuelle Eingabe), dann eingegebener Wert.
     */
    berechnungsdurchflussLSek: number;
}

/**
 * Volumetric flow rate (Bedarfsvolumenstrom) related mathematic functions.
 *
 * Sources of mathematical formulas:
 * - 210716-V1.0 Erfassungsbogen_DTE-UFC-Speicher.xlsm
 * - 201126-KR Schema Auswahl DTE V1.1.xlsx
 *
 * rauch@klaus-rauch.de
 */
@Injectable()
export class VolumetricFlowRateMath
{
    /**
     * Nur für die Auslegung von Wohngebäuden verwenden.
     * Ergebnis in l/min
     */
    public barfsvolumenstromFromBedarfskennzahl(
        bedarfskennzahl: number,
        gleichzeitigkeit: 'TUDresden' | 'DIN4708'
    ): number
    {
        const logger = new MathLogger('BarfsvolumenstromFromBedarfskennzahl');
        logger.logInputs({ bedarfskennzahl, gleichzeitigkeit });

        if (gleichzeitigkeit === 'TUDresden')
        {
            const result = Math.pow(bedarfskennzahl, -0.57) * bedarfskennzahl * 10;
            logger.logResult({ gleichzeitigkeit: 'TUD', bedarfsvolumenstrom: result });

            return result;
        }
        else if (gleichzeitigkeit === 'DIN4708')
        {
            const u3 = (1 / 6) * 0.244 * ((1 + Math.sqrt(bedarfskennzahl)) / Math.sqrt(bedarfskennzahl));
            const u4 = (1 / 6) * 3.599 * ((1 + Math.sqrt(bedarfskennzahl)) / Math.sqrt(bedarfskennzahl));

            const ku3 = this.gleichzeitigkeitZu(u3);
            const ku4 = this.gleichzeitigkeitZu(u4);

            const wzB = 5820*(bedarfskennzahl * ku3 + Math.sqrt(bedarfskennzahl) * ku4);
            const r3 = wzB / (50 * 10 * 1.163);

            logger.logIntermediateResult({ u3, u4, ku3, ku4, wzB });

            logger.logResult({ gleichzeitigkeit: 'DIN4708', bedarfsvolumenstrom: r3 });

            return r3;
        }
        else
            throw new Error('Unknown gleichzeitigkeit given.');
    }

    private gleichzeitigkeitZu(u: number): number
    {
        let result = 1;
        if (u > 1.81)
        {
            return 1;
        }
        else
        {
            let faculty = 1;
            let resultZu3 = 0;
            for (let i = 1; i <= 10; i++)
            {
                faculty *= i;
                const x = 2 * i + 1;
                const a = Math.pow(-1, i) * Math.pow(u, x);
                const b = faculty * x;

                resultZu3 += a / b;
            }

            return (2 / Math.sqrt(Math.PI)) * (u + resultZu3);
        }
    }

    /**
     * Nur für die Auslegung von Wohngebäuden verwenden.
     */
    public bedarfskennzahlFromWohnungsgruppen(wohnungsgruppen: IApartmentGroupParam[]): number
    {
        const logger = new MathLogger('BedarfskennzahlFromWohnungsgruppen');
        logger.logInputs({ wohnungsgruppen });

        const bedarfGesamt = wohnungsgruppen.map(wg =>
        {
            const zapfstellenbedarfAusstattungen = wg.ausstattungen
                .map(a => a.ZapfstellenbedarfWVErstbad)
                .reduce((a, b) => a + b);

            let zapfstellenbedarfZweitbadAusstattungen = 0;
            if (wg.facilitiesZweitesBad && wg.facilitiesZweitesBad.length > 0)
            {
                zapfstellenbedarfZweitbadAusstattungen = wg.facilitiesZweitesBad
                    .map(a =>
                    {
                        if (wg.zweitesBadTyp == 'ZweitesBad')
                            return a.ZapfstellenbedarfWVZweitbad;
                        else if (wg.zweitesBadTyp == 'GästezimmerMitBwUndDu')
                            return a.ZapfstellenbedarfWVGaesteBwDu;
                        else if (wg.zweitesBadTyp == 'GästezimmerOhneBwUndDu')
                            return a.ZapfstellenbedarfWVGaesteOhneBwDu;
                        else
                            return 0;
                    })
                    .reduce((a, b) => a + b);
            }

            const zapfstellenbedarfProWohnung = zapfstellenbedarfAusstattungen + zapfstellenbedarfZweitbadAusstattungen;
            const zapfstellenbedarfGesamt: number = wg.belegungszahl * wg.wohnungsanzahl * zapfstellenbedarfProWohnung;

            logger.logIntermediateResult(
            {
                wohnungsgruppe: wg,
                zapfstellenbedarfAusstattungen,
                zapfstellenbedarfZweitbadAusstattungen,
                zapfstellenbedarfGesamt,
            });

            return zapfstellenbedarfGesamt;

        }).reduce((a, b) => a + b);

        const bedarskennzahl = bedarfGesamt / (3.5 * 5820);
        logger.logResult({ bedarfGesamt: bedarfGesamt, bedarskennzahl: bedarskennzahl });
        return bedarskennzahl;
    }

    /**
     * Nur für die Auslegung von Nicht-Wohhngebäuden verwenden.
     * Ergebnis in l/min
     */
    public bedarfsvolumenstromFromEntnahmestellen(params: IVfrFromTappingPointsParams): number
    {
        const temperaturGrad = 60;

        const logger = new MathLogger('BedarfsvolumenstromFromEntnahmestellen');
        logger.logInputs(params);

        let sumBerechnungsdurchfluss = params.entnahmestellen
            .map(es => es.anzahl * es.berechnungsdurchflussLSek)
            .reduce((a, b) => a + b);

        logger.logIntermediateResult({ sumBerechnungsdurchfluss });

        if (sumBerechnungsdurchfluss === 0)
        {
            logger.logResult({ bedarfsvolumenstrom: 0 });
            return 0;
        }

        let resultBerechnungsdurchflussLMin: number;
        if (!isNullOrUndefined(params.gleichzeitigkeitLinearFactor))
        {
            resultBerechnungsdurchflussLMin = sumBerechnungsdurchfluss * (params.gleichzeitigkeitLinearFactor / 100) * temperaturGrad;
        }
        else if (!isNullOrUndefined(params.gleichzeitigkeitType))
        {
            const a: number = params.gleichzeitigkeitType.Din1988KonstanteA;
            const b: number = params.gleichzeitigkeitType.Din1988KonstanteB;
            const c: number = params.gleichzeitigkeitType.Din1988KonstanteC;
            resultBerechnungsdurchflussLMin = (a * Math.pow(sumBerechnungsdurchfluss, b) - c) * temperaturGrad;
        }
        else
            throw new Error('Expected at least one simultaneity parameter to be given.');

        logger.logResult({ ergebnisBedarfsvolumenstromLMin: resultBerechnungsdurchflussLMin });

        return resultBerechnungsdurchflussLMin;
    }
}
