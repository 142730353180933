import { executeHttpRequest, IRequestError } from '../utils/httpUtils';
import { IODataQueryResponse } from '../utils/IODataQueryResponse';
import { Injectable } from '@angular/core';
import { log } from 'src/core-lib/logging';
import buildQuery, { QueryOptions } from 'odata-query'
import { UrlResolverService } from './UrlResolverService';
import { ServiceLocator } from './ServiceLocator';

@Injectable()
export class ODataHttpService
{
    private urlResolverService: UrlResolverService;

    public constructor()
    {
        this.urlResolverService = ServiceLocator.get(UrlResolverService);
    }

    /**
     * Performs a get request against an OData queryable endpoint which returns json data.
     * Use this method to retrieve entity sets.
     *
     * @param queryableEndpoint The endpoint e.g. "http://localhost:8488/api/v1/Products"
     * @param query The query parameters. See https://github.com/techniq/odata-query
     */
    public async query<T>(queryableEndpoint: string, query?: Partial<QueryOptions<string>>, authorization?: string): Promise<IODataQueryResponse<T>>
    {
        queryableEndpoint = this.urlResolverService.resolveServiceUrl(queryableEndpoint);
        const queryString = buildQuery(query);
        if (queryableEndpoint.endsWith('/'))
            queryableEndpoint = queryableEndpoint.substring(0, queryableEndpoint.length - 1);

        const url = queryableEndpoint + queryString;
        try
        {
            return await executeHttpRequest({ type: 'GET', url }, authorization);
        }
        catch (error)
        {
            const errorObj = error as IRequestError;
            if (errorObj.request.status === 0)
                log.warning({ endpoint: queryableEndpoint }, 'Failed to query data. Destination was uneachable.');
            else
                log.warning({ endpoint: queryableEndpoint, status: errorObj.request.status, responseText: errorObj.odataErrorMsg }, `Failed to query data. Received ${errorObj.request.status}.`);

            throw error;
        }
    }

    /**
     * Performs a regular get request against an endpoint which returns json data.
     * Use this method to query singletons or to invoke OData functions.
     *
     * @returns The raw json data.
     */
    public async getJson(endpoint: string, authorization?: string): Promise<Object>
    {
        return await this.simpleRequest(endpoint, 'GET', undefined, authorization);
    }

    /**
     * Performs a regular post request against an endpoint which returns no data or json data.
     * Use this method to post to collections or to invoke OData actions.
     */
    public async putJson(endpoint: string, requestBody: Object, authorization?: string): Promise<Object | null>
    {
        return await this.simpleRequest(endpoint, 'PUT', requestBody, authorization);
    }

    /**
     * Performs a regular post request against an endpoint which returns no data or json data.
     * Use this method to post to collections or to invoke OData actions.
     */
    public async postJson(endpoint: string, requestBody?: Object, authorization?: string): Promise<Object | null>
    {
        return await this.simpleRequest(endpoint, 'POST', requestBody, authorization);
    }

    /**
     * Performs a regular post request against an endpoint which returns no data or json data.
     * Use this method to post to collections or to invoke OData actions.
     */
    public async deleteJson(endpoint: string, requestBody?: Object, authorization?: string): Promise<Object | null>
    {
        return await this.simpleRequest(endpoint, 'DELETE', requestBody, authorization);
    }

    private async simpleRequest(endpoint: string, method: string, requestBody?: Object, authorization?: string): Promise<Object | null>
    {
        endpoint = this.urlResolverService.resolveServiceUrl(endpoint);
        try
        {
            return await executeHttpRequest({ type: method, url: endpoint, data: requestBody }, authorization);
        }
        catch (error)
        {
            const errorObj = error as IRequestError;
            if (errorObj.request.status === 0)
                log.warning({ endpoint: endpoint }, 'Failed to execute request. Destination was unreachable.');
            else
                log.warning({ endpoint: endpoint, status: errorObj.request.status, responseText: errorObj.odataErrorMsg }, `Failed to execute request. Received ${errorObj.request.status}.`);

            throw error;
        }
    }
}
