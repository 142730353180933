
<ejs-dialog #dialog (close)="onCloseDialog()" (beforeClose)="onBeforeClose($event)" (open)="isClosedWithoutSave = true" (overlayClick)="onOverlayClick()" [target]='target' [buttons]='buttons' [header]='headerText' [isModal]="true" [closeOnEscape]='false' [visible]='false' [minHeight]="dialogHeight" [height]='dialogHeight' [showCloseIcon]='true' [width]='dialogWidth' [position]='dialogPosition'>
    <ng-template #header>
        <div class="text-tooltip-wrapper text-tooltip-wrapper-header">
            <span>{{headerText}}</span>
            <app-help-icon hinweisId="Projektkonfiguration.DTE"></app-help-icon>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="subtitle">
            <span>{{'Content_SD' | translate: projectName}}</span>
        </div>
        <div class="options">
            <app-project-configuration-item
                #Dte
                [itemHintId]='"Projektkonfiguration.DTEStandardbetrieb"'
                [itemChecked]="!_dteUfcChecked"
                (stateChanged)="onClickConfigDte(0)"
                itemTitle="{{'#ConfigDteDialog.ItemTitleDte_T' | translate}}"
                [itemImage]="imageDte"
                itemDescription="{{'#ConfigDteDialog.ContentDte_D' | translate}}"
                [isDisabled]="!userService.isViegaUser && _dteUfcChecked"
            ></app-project-configuration-item>
            <app-project-configuration-item
                #DteUfc
                [itemHintId]='"Projektkonfiguration.DTEAbgesenkt"'
                [itemChecked]="_dteUfcChecked"
                (stateChanged)="onClickConfigDte(1)"
                itemTitle="{{'#ConfigDteDialog.ItemTitleDteUfc_T' | translate}}"
                [itemImage]="imageDteUfc"
                itemDescription="{{'#ConfigDteDialog.ContentDteUfc_D' | translate}}"
                [isDisabled]="!userService.isViegaUser"
            ></app-project-configuration-item>
        </div>
        <div>
            <p>
                <span class="icon-warning"></span>
                {{changeWarning}}
            </p>
        </div>
    </ng-template>
</ejs-dialog>