import { Pipe, PipeTransform, InjectionToken } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LangService } from 'src/core-lib/ej2/services/LangService';

/**
 * Takes a translation label key and resolves it to the respective localized value using LangService.
 *
 * This pipe is also aware of some commonly used suffixes:
 *   - "_LT" and "_D" - new lines will be replaced by <br>
 *   - "_UnsafeHtml" - will bypass html sanitization for the value.
 *
 * Pass further parameters to this pipe to replace argument {0}, {1}... occurences with the given value.
 */
@Pipe({
    name: 'translate',
    pure: true,
})
export class TranslatePipe implements PipeTransform
{
    constructor(
        private lang: LangService,
        private sanitizer: DomSanitizer
    ) {}

    public transform(key: string, ...replaceValues: string[]): string | SafeHtml
    {
        let translation: string | SafeHtml = this.lang.getString(key, undefined, ...replaceValues);

        if (key.indexOf('_LT') !== -1 || key.indexOf('_D') !== -1)
            translation = (translation as string).replace(/\n/g, '<br>');

        if (key.indexOf('_UnsafeHtml') !== -1)
            translation = this.sanitizer.bypassSecurityTrustHtml(translation as string);

        return translation;
    }
}
