import { Event, EmitType, EventHandler, Component, INotifyPropertyChanged, Property, addClass, removeClass, select, remove, detach, Complex } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DropDownList, DropDownListModel, PopupEventArgs, SelectEventArgs } from '@syncfusion/ej2-dropdowns';
import { HintType, IPspDropdownIconModel } from './IPspDropdownIconModel';
import { events } from './events';
import { Tooltip } from '@syncfusion/ej2-popups';
import { PimStatic } from 'src/core-lib/ej2/utils/PimStatic';
import { LabelPosition } from '@syncfusion/ej2-buttons';
import { ChangeEventArgs, FieldSettings, FieldSettingsModel, FilteringEventArgs, FilterType, SortOrder } from '@syncfusion/ej2-angular-dropdowns';
import { Position } from '@syncfusion/ej2-angular-popups';

export class PspDropdownIcon extends Component<HTMLElement> implements INotifyPropertyChanged
{
    public static readonly TooltipDelay = PimStatic.TooltipDelayMs * 3;

    /**
     * Used to display the popup menu when clicked.
     */
    protected wrappedDropDownList: DropDownList;
    private iconWrapperEl: HTMLSpanElement;
    private iconSpanEl: HTMLSpanElement;
    private labelEl: HTMLSpanElement;

    protected tooltip: Tooltip;
    protected actionTooltip: Tooltip;

    @Property(null)
    public filterBarPlaceholder?: string;

    @Property(null)
    public query?: Query;

    @Property(false)
    public allowFiltering?: boolean;

    @Property(null)
    public text?: string;

    @Property(null)
    public value?: number | string | boolean;

    @Property(null)
    public index?: number;

    @Complex<FieldSettingsModel>({ text: null, value: null, iconCss: null, groupBy: null }, FieldSettings)
    public fields: FieldSettingsModel;

    @Property(null)
    public groupTemplate?: string;

    @Property('Request failed')
    public actionFailureTemplate?: string;

    @Property('No records found')
    public noRecordsTemplate?: string;

    @Property<SortOrder>('None')
    public sortOrder?: SortOrder;

    @Property('Contains')
    public filterType?: FilterType;

    @Property(true)
    public ignoreCase?: boolean;

    @Property(1000)
    public zIndex?: number;

    @Property(false)
    public ignoreAccent?: boolean;

    /**
     * The data source to retrieve dropdown items from.
     */
    @Property()
    public itemDataSource?: { [key: string]: Object }[] | DataManager | string[] | number[] | boolean[];

    /**
     * A tooltip to display when the user hovers over the notify icon.
     */
    @Property()
    public iconTooltip?: string;

    /**
     * The placement of the icon tooltip.
     * @default 'BottomCenter'
     */
    @Property('BottomCenter')
    public iconTooltipPosition?: Position;

    @Property('icon-envelope')
    public iconCssClass?: string;

    @Property()
    public cssClass?: string;

    @Property()
    public dropDownCssClass?: string;

    /**
     * The type of hint to visualize. This is the dot indicator on the top right of the notify icon.
     * Unread usually adds a yellow dot hint.
     * Urgent usually adds a red dot hint.
     * None means no dot hint.
     */
    @Property()
    public hintType?: HintType;

    @Property()
    public headerTemplate?: string;

    @Property()
    public itemTemplate?: string;

    @Property()
    public footerTemplate?: string;

    @Property(null)
    public popupWidth?: string | number;

    @Property(null)
    public popupHeight?: string | number;

    @Property(null)
    public popupPosition?: { X: string | number, Y: string | number };

    @Property('')
    public label?: string;

    @Property('After')
    public labelPosition?: LabelPosition;

    @Property(false)
    public isDisabled?: boolean;

    @Property(null)
    public width?: string;

    @Event()
    public beforeOpenEvent?: EmitType<object>;

    @Event()
    public openEvent?: EmitType<PopupEventArgs>;

    @Event()
    public closeEvent?: EmitType<PopupEventArgs>;

    @Event()
    public clickEvent?: EmitType<MouseEvent>;

    @Event()
    public changeEvent?: EmitType<ChangeEventArgs>;

    @Event()
    public filteringEvent?: EmitType<FilteringEventArgs>;

    @Event()
    public actionBeginEvent?: EmitType<Object>;

    @Event()
    public actionCompleteEvent?: EmitType<Object>;

    @Event()
    public actionFailureEvent?: EmitType<Object>;

    @Event()
    public dataBoundEvent?: EmitType<Object>;

    @Event()
    public selectEvent?: EmitType<SelectEventArgs>;

    @Event()
    public createdEvent?: EmitType<object>;

    @Event()
    public destroyedEvent?: EmitType<object>;

    constructor(options?: IPspDropdownIconModel, element?: string | HTMLElement)
    {
        super(options, element);
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-dropdown-icon';
    }

    protected preRender(): void
    {
        // NOP
    }

    protected render(): void
    {
        this.element.tabIndex = 0;
        addClass([this.element], 'pspc-dropdown-icon');
        if (this.cssClass)
            addClass([this.element], this.cssClass.split(' '));

        const hintDotEl = document.createElement('SPAN');
        this.iconWrapperEl = document.createElement('SPAN');
        this.iconWrapperEl.className = 'ddicon-wrapper';
        this.iconSpanEl = document.createElement('SPAN');
        this.iconSpanEl.className = 'ddicon-element';
        hintDotEl.className = 'ddicon-hint-dot';

        this.setTooltipText(this.iconTooltip);

        if (this.hintType)
            this.setHintType(this.hintType);

        if (this.iconCssClass)
            this.setIconCss(this.iconCssClass);

        this.iconWrapperEl.appendChild(this.iconSpanEl);
        this.iconWrapperEl.appendChild(hintDotEl);
        this.element.appendChild(this.iconWrapperEl);

        this.refreshLabel();
        this.refreshStyles();
        this.updateWidth();

        const dropdownEl = document.createElement('INPUT');
        this.element.appendChild(dropdownEl);

        const dropDownListSetup: DropDownListModel = {
            filterBarPlaceholder: this.filterBarPlaceholder,
            query: this.query,
            allowFiltering: this.allowFiltering,
            text: this.text,
            value: this.value,
            index: this.index,
            fields: this.fields,
            enablePersistence: this.enablePersistence,
            groupTemplate: this.groupTemplate,
            actionFailureTemplate: this.actionFailureTemplate,
            noRecordsTemplate: this.noRecordsTemplate,
            sortOrder: this.sortOrder,
            filterType: this.filterType,
            ignoreCase: this.ignoreCase,
            zIndex: this.zIndex,
            ignoreAccent: this.ignoreAccent,
            cssClass: this.getDropDownCssClass(),
            dataSource: this.itemDataSource,
            headerTemplate: this.headerTemplate,
            itemTemplate: this.itemTemplate,
            footerTemplate: this.footerTemplate,
            popupWidth: this.popupWidth,
            popupHeight: this.popupHeight,
            beforeOpen: (args) => this.dropDown_beforeOpen(args),
            open: (args) => this.dropDown_open(args),
            close: (args) => this.dropDown_close(args),
            select: (args) => this.dropDown_select(args),
            change: (args) => this.dropDown_change(args),
            filtering: (args) => this.dropDown_filtering(args),
            actionBegin: (args) => this.dropDown_actionBegin(args),
            actionComplete: (args) => this.dropDown_actionComplete(args),
            actionFailure: (args) => this.dropDown_actionFailure(args),
            dataBound: (args) => this.dropDown_dataBound(args),
        };

        this.wrappedDropDownList = new DropDownList(dropDownListSetup, dropdownEl);

        this.actionTooltip = new Tooltip({
            opensOn: 'Custom',
            position: this.iconTooltipPosition,
            closeDelay: 2000,
            cssClass: 'psp-action-tooltip psp-dropdown-icon-tt',
            target: this.element as any,
            windowCollision: true
        }, this.element);

        this.wireEvents();

        this.trigger(events.created, {});
    }

    private refreshStyles(): void
    {
        if (this.isDisabled)
            addClass([this.element], 'is-disabled');
        else
            removeClass([this.element], 'is-disabled');
    }

    private refreshLabel(newPosition?: LabelPosition): void
    {
        if (this.label && !this.labelEl)
        {
            this.labelEl = document.createElement('span');
            this.labelEl.className = 'ddicon-label';
            this.labelEl.innerText = this.label;

            if ((newPosition || this.labelPosition) === 'After')
                this.iconWrapperEl.insertAdjacentElement('afterend', this.labelEl);
            else
                this.iconWrapperEl.insertAdjacentElement('beforebegin', this.labelEl);
        }
        else if (!this.label && this.labelEl)
        {
            remove(this.labelEl);
            delete this.labelEl;
        }
        else if (this.label)
        {
            if (newPosition)
            {
                detach(this.labelEl);
                if (newPosition === 'After')
                    this.iconWrapperEl.insertAdjacentElement('afterend', this.labelEl);
                else
                    this.iconWrapperEl.insertAdjacentElement('beforebegin', this.labelEl);
            }
            this.labelEl.innerText = this.label;
        }
    }

    private updateWidth(): void
    {
        this.element.style.width = this.width || '';
    }

    private setTooltipText(tooltipText: string): void
    {
        if (tooltipText)
        {
            if (!this.tooltip)
            {
                this.tooltip = new Tooltip({
                    content: tooltipText,
                    openDelay: PspDropdownIcon.TooltipDelay,
                    position: this.iconTooltipPosition,
                    showTipPointer: false,
                });
                this.tooltip.appendTo(this.element);
            }
            else
            {
                this.tooltip.content = tooltipText;
            }
        }
        else if (this.tooltip)
        {
            this.tooltip.destroy();
            this.tooltip.element.parentElement.removeChild(this.tooltip.element);
            delete this.tooltip;
        }
    }

    protected getDropDownCssClass(): string
    {
        return 'wrapped-dropdown ' + this.dropDownCssClass;
    }

    protected dropDown_select(args: SelectEventArgs): void
    {
        this.value = this.wrappedDropDownList.value;

        this.trigger(events.select, args);
        this.element.blur();
    }

    protected dropDown_beforeOpen(args: object): void
    {
        if (!this.itemDataSource)
        {
            args['cancel'] = true;
            return;
        }

        this.trigger(events.beforeOpen, args);
    }

    protected dropDown_open(args: PopupEventArgs): void
    {
        this.disableTooltip();

        this.element.focus();
        this.wrappedDropDownList['popupObj'].position = this.popupPosition || { X: 'left', Y: 'bottom' };

        this.trigger(events.open, args)
    }

    protected dropDown_close(args: PopupEventArgs): void
    {
        this.enableTooltip();
        this.trigger(events.close, args);
    }

    protected dropDown_change(args: any): void
    {
        this.value = this.wrappedDropDownList.value;
        this.trigger(events.change, args);
    }

    protected dropDown_filtering(args: any): void
    {
        this.trigger(events.filtering, args);
    }

    protected dropDown_actionBegin(args: any): void
    {
        this.trigger(events.actionBegin, args);
    }

    protected dropDown_actionComplete(args: any): void
    {
        this.trigger(events.actionComplete, args);
    }

    protected dropDown_actionFailure(args: any): void
    {
        this.trigger(events.actionFailure, args);
    }

    protected dropDown_dataBound(args: any): void
    {
        this.trigger(events.dataBound, args);
    }

    private disableTooltip(): void
    {
        if (this.tooltip) {
            this.tooltip.openDelay = 9999999;
            this.tooltip.close();
        }
    }

    private enableTooltip(): void
    {
        if (this.tooltip)
            this.tooltip.openDelay = PspDropdownIcon.TooltipDelay;
    }

    private setIconCss(newIconCss: string, oldIconCss: string = ''): void
    {
        if (oldIconCss)
            removeClass([this.iconSpanEl], oldIconCss.split(' '));

        if (newIconCss)
            addClass([this.iconSpanEl], newIconCss.split(' '));
    }

    private setHintType(hintType: HintType): void
    {
        removeClass([this.element], ['pspc-dropdown-icon--unread', 'pspc-dropdown-icon--urgent', 'pspc-dropdown-icon--active']);

        if (hintType === 'Unread')
            addClass([this.element], 'pspc-dropdown-icon--unread');
        else if (hintType === 'Urgent')
            addClass([this.element], 'pspc-dropdown-icon--urgent');
        else if (hintType === 'Active')
            addClass([this.element], 'pspc-dropdown-icon--active');
    }

    protected wireEvents(): void
    {
        EventHandler.add(this.element, 'click', this.element_click, this);

    }
    protected unwireEvents(): void
    {
        EventHandler.remove(this.element, 'click', this.element_click);
    }

    protected element_click(args: MouseEvent): void
    {
        if (this.isDisabled)
            return;

        if (!this.wrappedDropDownList['isPopupOpen'])
        {
            this.wrappedDropDownList['removeSelection']();
            this.wrappedDropDownList.showPopup();
            this.wrappedDropDownList['removeFocus']();
        }

        this.trigger(events.click, args);
    }

    /** @ignore */
    public onPropertyChanged(newProp: IPspDropdownIconModel, oldProp: IPspDropdownIconModel): void
    {
        for (const propertyName of Object.keys(newProp))
        {
            const oldValue = oldProp[propertyName];
            const newValue = newProp[propertyName];

            switch (propertyName)
            {
                // properties to be passed through to the wrapper
                case 'filterBarPlaceholder':
                case 'query':
                case 'allowFiltering':
                case 'text':
                case 'value':
                case 'index':
                case 'fields':
                case 'enablePersistence':
                case 'groupTemplate':
                case 'actionFailureTemplate':
                case 'noRecordsTemplate':
                case 'sortOrder':
                case 'filterType':
                case 'ignoreCase':
                case 'zIndex':
                case 'ignoreAccent':
                case 'headerTemplate':
                case 'itemTemplate':
                case 'footerTemplate':
                case 'popupWidth':
                case 'popupHeight':
                    this.wrappedDropDownList[propertyName as string] = newValue;
                    break;
                case 'itemDataSource':
                    this.wrappedDropDownList.dataSource = newValue;
                    break;
                case 'iconTooltip':
                    this.setTooltipText(newValue);
                    break;
                case 'iconTooltipPosition':
                    if (this.tooltip)
                        this.tooltip.position = newValue;

                    break;
                case 'iconCssClass':
                    this.setIconCss(newValue, oldValue);
                    break;
                case 'label':
                    this.refreshLabel();
                    break;
                case 'labelPosition':
                    this.refreshLabel(newValue);
                    break;
                case 'cssClass':
                    if (oldValue)
                        removeClass([this.element], oldValue.split(' '));
                    if (newValue)
                        addClass([this.element], newValue.split(' '));

                    break;
                case 'dropDownCssClass':
                    this.wrappedDropDownList.cssClass = this.getDropDownCssClass();
                    break;
                case 'hintType':
                    this.setHintType(newValue);
                    break;
                case 'isDisabled':
                    this.refreshStyles();
                    break;
                case 'width':
                    this.updateWidth();
                    break;
            }
        }
    }

    public closePopup(): void
    {
        this.wrappedDropDownList.hidePopup();
    }

    public showActionTooltip(content: string): void
    {
        this.tooltip?.close();

        this.actionTooltip.content = content;
        this.actionTooltip.position = this.iconTooltipPosition;
        this.actionTooltip.open();
        // will apply close delay before actually closing
        this.actionTooltip.close();
    }

    protected getPersistData(): string
    {
        return '';
    }

    public destroy(): void
    {
        this.unwireEvents();

        this.wrappedDropDownList.destroy();

        this.trigger(events.destroyed, {});
        super.destroy();
    }
}
