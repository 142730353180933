import { NgModule } from '@angular/core';
import { PspButtonComponent } from './psp-button.component';

@NgModule(
{
    declarations:
    [
        PspButtonComponent,
    ],
    exports:
    [
        PspButtonComponent,
    ],
})
export class PspButtonModule {}
