import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectViewComponent } from '../project-view/project-view.component';
import { SelectionItemRadioComponent } from './selection-item-radio.component';



@NgModule({
  declarations: [
    // SelectionItemRadioComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    //   SelectionItemRadioComponent
  ]
})
export class SelectionItemRadioModule { }
