import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ImageAssetRow } from 'src/app/core/DteufcAggregatorServiceApi/imageassetrow.complex';
import { provideContext } from 'src/core-lib/angular/context-util';
import { ProjectConfigurationItemComponent } from '../project-configuration-item/project-configuration-item.component';
import { Button } from '@syncfusion/ej2-buttons';

@Component({
  selector: 'app-config-details-dialog',
  templateUrl: './config-details-dialog.component.html',
  styleUrls: ['./config-details-dialog.component.scss'],
  providers: [provideContext("ConfigDetailsDialog")],
})
export class ConfigDetailsDialogComponent implements OnInit {
    @ViewChild('dialog') public dialog: DialogComponent;

    @Input() target: string;
    @Input() buttons: Button[];
    @Input() headerText: string;

    dialogWidth: string = "55%";
    dialogHeight: string = "90vh";
    dialogPosition: PositionDataModel = {X: 'center', Y: 'center'}

    @Input() projectName: string;

    @Input() isPipeChecked: boolean;
    @Output() isPipeCheckedChange = new EventEmitter<boolean>();
    _isPipeChecked: boolean;

    @Input() isEnergyChecked: boolean ;
    @Output() isEnergyCheckedChange = new EventEmitter<boolean>();
    _isEnergyChecked: boolean;

    @Output() closedCompletely = new EventEmitter<any>()
    isClosedWithoutSave: boolean = true;

    canBeClosed: boolean = false;

    @Input() isConfigDteDialogPristine: boolean;

    @Output() dialogPristine = new EventEmitter<any>()

    @Input() changeWarning: string;

    imageRohrleitung: string;
    imageSpeicher: string;

  constructor(private aggregatorDataService: AggregatorDataService) { }

  ngOnInit(): void {
    var images = this.aggregatorDataService.aggregatorData.Tables.ImageAssetRows;

    this.imageRohrleitung = images.find(i => i.ImageId == 'Projektkonfiguration.AuslegungRohrleitung').ImageUrlLoc;
    this.imageSpeicher = images.find(i => i.ImageId == 'Projektkonfiguration.AuslegungSpeicher').ImageUrlLoc;

    this.resetDialog();
}

  emitChecked(){
    this.isPipeChecked = this._isPipeChecked;
    this.isPipeCheckedChange.emit(this.isPipeChecked);
    this.isEnergyChecked = this._isEnergyChecked;
    this.isEnergyCheckedChange.emit(this.isEnergyChecked);
  }

  onOverlayClick(){
    this.dialog.hide();
    }

    onCloseDialog(){
        if(this.isClosedWithoutSave){
            this.closedCompletely.emit();
            this.resetDialog();
        }
    }

    onBeforeClose(event){
        if(this.target == 'app-overview'){
            if(this.isClosedWithoutSave){
                if(!this.canBeClosed){
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
        else{
            if(this.isClosedWithoutSave){
                if(!this.canBeClosed && (!this.checkIfPristine() || !this.isConfigDteDialogPristine)){
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
    }

    resetDialog(){
        if (this.target == 'app-overview') {
            this._isPipeChecked = true;
            this._isEnergyChecked = true;
        }
        else{
            this._isEnergyChecked = this.isEnergyChecked;
            this._isPipeChecked = this.isPipeChecked;
        }
    }

    onOpenDialog(){
        this.isClosedWithoutSave = true;
    }

    checkIfPristine(): boolean{
        if (this._isEnergyChecked != this.isEnergyChecked ||
            this._isPipeChecked != this.isPipeChecked) {
            return false
        }
        else{
            return true;
        }
    }

}
