import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { Energielabel, EnergiespeicherErmittlungstyp } from 'src/app/core/Enums';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
  selector: 'app-select-calculation-reservoir',
  templateUrl: './select-calculation-reservoir.component.html',
  styleUrls: ['./select-calculation-reservoir.component.scss'],
  providers: [provideContext('SelectCalculationReservoir')]
})
//Hier fehlt noch das Daten speichern beim navigateNext
export class SelectCalculationReservoirComponent implements OnInit {

  stepText = this.langService.getString('StepText_T');
  stepBack = this.langService.getString('StepBack_T');

    checkedOption: number;

    ermittlungsTyp: string; //TODO; Enum???

    selectedProject: ProjectEntity;

    grobImage;
    exaktImage;

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private dataService: ProjectDataService,
    private aggregatorService: AggregatorDataService,
    private langService: LangService
  ) { }

  ngOnInit(): void {
      this.selectedProject = this.activatedRoute.snapshot.data['project'];
      this.dataService.setTempProject(this.selectedProject)
      this.selectedProject = this.dataService.TempProject;

      this.grobImage = this.aggregatorService.aggregatorData.Tables.ImageAssetRows.find(x => x.ImageId == "Speicher.Grobermittlung").ImageUrlLoc;
      this.exaktImage = this.aggregatorService.aggregatorData.Tables.ImageAssetRows.find(x => x.ImageId =="Speicher.Detailermittlung").ImageUrlLoc;

      if(this.selectedProject.ProjectValues.Energiespeicher){
        if (this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp == EnergiespeicherErmittlungstyp.Grob) {
            this.checkedOption = 1;
        }
        else if (this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp = EnergiespeicherErmittlungstyp.Exakt) {
            this.checkedOption = 2;
        }
      }
      else{
          this.selectedProject.ProjectValues.Energiespeicher = {
              Ermittlungstyp: EnergiespeicherErmittlungstyp.None,
              Energielabel: null,
              MinutenwerteEnergiebedarf: null,
              MinutenwerteEnergiebedarfAbgesenkt: null,
              MinutenwerteQStoOn: null,
              MinutenwerteQStoOnAbgesenkt: null,
              MinutenwerteVersorgung: null,
              MinutenwerteVersorgungAbgesenkt: null
          }
          this.checkedOption = 1;
      }

  }

  goBack() {
    window.history.back();
  }

  onChangeOption(option: number){
    this.checkedOption = option;
  }

  navigateNext(){
    if(this.checkedOption === 1){
      this.router.navigate([`/heat-generator-power/${this.selectedProject.ProjectId}`]);
    }
    else{
      this.router.navigate([`/heat-generating-types/${this.selectedProject.ProjectId}`]);
      }
    }
}
