<div class="item-wrapper">
    <div class="item-title">
        <div class="text-tooltip-wrapper">
            <ejs-checkbox
                #titleCheckBox
                [label]='itemTitle'
                (change)="onChange($event)"
                [(checked)]="itemChecked"
                [disabled]="isDisabled"
            ></ejs-checkbox>
            <div *ngIf="itemHintId">
                <app-help-icon [hinweisId]='itemHintId'></app-help-icon>
            </div>
        </div>
    </div>

    <div class="item-content-wrapper">
        <div class="image-wrapper">
            <img [src]='itemImage' alt="">
        </div>

        <div class="description-wrapper">
            <span [innerHTML]="itemDescription"></span>
        </div>
    </div>

    <div class="item-options-wrapper">
        <ng-content select="[item-options]"></ng-content>
    </div>
</div>

