<viega-collapsible-panel>
    <div class="panel-content" panel-content>
        <div class="top-panel-content">
            <viega-panel-header
                [stepText]="stepText"
                [stepBackLabel]="stepBack"
                stepTitle="&nbsp;"
                stepDescription="&nbsp;"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>
        </div>
        <div class="bot-panel-content">
            <div class="result-box">
                <button psp-button class="e-btn e-small e-icon-right" style="width: 100%; flex-flow: row; justify-content: center;" (click)="navigateNext()">{{'WeiterBtn_T' | translate}}</button>
            </div>
        </div>
    </div>

    <div class="content" content>
            <span class="content-title">{{'Headline_T' | translate}}</span>

        <div class="options">
            <div class="option-wrapper">
                <app-selection-item-radio
                    [imgSrc]="grobImage"
                    [itemChecked]="checkedOption === 1"
                    (stateChanged)="onChangeOption(1)"
                    optionGroup="reservoirOptions"
                    [itemIndex]="0"
                    itemTitle="{{'#SelectCalculationReservoir.GeschaetzerWert_T' | translate}}"
                    description="{{'#SelectCalculationReservoir.DescriptionGeschaetzt_D' | translate}}"
                    ></app-selection-item-radio>
            </div>
            <div class="option-wrapper">
                <app-selection-item-radio
                    [imgSrc]="exaktImage"
                    [itemChecked]="checkedOption === 2"
                    (stateChanged)="onChangeOption(2)"
                    optionGroup="reservoirOptions"
                    [itemIndex]="1"
                    itemTitle="{{'#SelectCalculationReservoir.Detailermittlung_T' | translate}}"
                    description="{{'#SelectCalculationReservoir.DescriptionDetail_D' | translate}}"
                    ></app-selection-item-radio>
            </div>
        </div>



    </div>
</viega-collapsible-panel>

