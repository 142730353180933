<div class="content-wrapper">
    <ng-content select="[top-content]"></ng-content>
</div>

<div class="image-wrapper" (click)="emitCardClicked($event)">
    <a
        [attr.href]="linkUrl"
        [attr.routerLink]="routerLink"
        [class.touched]="touchActivated"
        [class.hovering]="isHovering"
        [style.height]="fixedImgHeight"
        (mousedown)="linkMouseDown($event)"
        (mouseup)="linkMouseUp($event)"
        (mouseover)="linkMouseOver($event)"
        (mouseout)="linkMouseOut($event)"
        (touchstart)="linkTouchStart($event)"
        (touchend)="linkTouchEnd($event)"
    >
        <div [style.background-image]="'url(' + imgSrc + ')'"></div>
        <span class="overlay" *ngIf="imgOverlayText !== undefined">{{ imgOverlayText }}</span>
    </a>

    <button
        *ngIf="advancedTouchMode"
        class="triangle-button select-button icon icon-download-01"
        [class.touched]="touchActivated"
        (click)="emitSelectButtonClicked($event)"
    >
        <svg viewBox="0 0 1 1" preserveAspectRatio="none">
            <polygon fill="#333" points="0,1 0,0 1,0"></polygon>
        </svg>
    </button>

    <button
        *ngIf="displayInfoButton || advancedTouchMode"
        class="triangle-button info-button icon icon-info-01"
        [class.touched]="touchActivated || enlargeInfoButton"
        (click)="emitInfoButtonClicked($event)"
    >
        <svg viewBox="0 0 1 1" preserveAspectRatio="none">
            <polygon fill="#333" points="0,1 1,1 1,0"></polygon>
        </svg>
    </button>
</div>

<div class="content-wrapper">
    <ng-content select="[bottom-content]"></ng-content>
</div>
