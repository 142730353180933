import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ChangeEventArgs, SliderComponent } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { Wärmeerzeuger } from 'src/app/core/BackendApi/wärmeerzeuger.complex';
import { HeatGeneratorRow } from 'src/app/core/DteufcAggregatorServiceApi/heatgeneratorrow.complex';
import { Energielabel, EnergiespeicherErmittlungstyp } from 'src/app/core/Enums';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
  selector: 'app-heat-generating-types',
  templateUrl: './heat-generating-types.component.html',
  styleUrls: ['./heat-generating-types.component.scss'],
  providers: [provideContext("HeatGeneratingTypes")],
})
export class HeatGeneratingTypesComponent implements OnInit {

    @ViewChild('addDataDialog')
    public AddDataDialog: DialogComponent;


    @ViewChild("delete") public DeleteDialog: DialogComponent;

    @ViewChild('editDataDialog')
    public EditDataDialog: DialogComponent;

    @ViewChild('errorRequiredDialog')
    public ErrorRequiredDialog: DialogComponent;

    @ViewChild('errorMaxTypesDialog')
    public ErrorMaxTypesDialog: DialogComponent;

    @ViewChild('typeDropdownAdd')
    public TypeDropdownAdd: DropDownListComponent;

    @ViewChild('typeDropdownEdit')
    public TypeDropdownEdit: DropDownListComponent;

    @ViewChild('grid')
    public Grid: GridComponent;

    @ViewChild('slider')
    public Slider: SliderComponent;

    stepText = this.langService.getString('PanelStepText_T');
    stepBack = this.langService.getString('PanelStepBackLabel_T');
    stepTitle = this.langService.getString('PanelHeadline_T');
    stepDescription = this.langService.getString('PanelDescription_D');
    energyLabels = this.aggregatorDataService.aggregatorData.Tables.StorageEnergyEfficiencyCategoryRows;

    addOrEditDataDialogHeader = this.langService.getString('AddDataDialog.Headline_T');
    errorDialogHeader = this.langService.getString('ErrorRequiredDialog.Headline_T');

    addOrEditDataDialogWidth = "40rem"
    errorRequiredDialogWidth = "30%"
    errorMaxTypesDialogWidth = "40em";
    deleteDialogWidth = "40%";
    errorDialogHeight = "65em"

    dialogPosition: PositionDataModel = {X: 'center', Y: 'center'}
    dialogTarget = "app-heat-generating-types";
    dialogWidth = "50%";
    dialogHeight = "65em";

    public gridData: any[] = [];

    public heatGeneratorData: any[];
    fields = { text:"DisplayNameLoc", value:"HeatGeneratorId" }

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private aggregatorDataService: AggregatorDataService,
        private langService: LangService,
        private dataService: ProjectDataService,
        private formatter: ValueFormatterService
    ) { }

    currentGenerator: Wärmeerzeuger;
    selectedProject: ProjectEntity;
    _power: number = 0;
    _delay: number = 0;

    _editPower: number = 0;
    _editDelay: number = 0;

    _index: number;



    ngOnInit(): void {

        let urlProject = this.activatedRoute.snapshot.data['project'];
        if(!this.dataService.TempProject){
            this.dataService.setTempProject(urlProject);
        }

        this.selectedProject = this.dataService.TempProject;

        if (!this.selectedProject) {
            if (urlProject) {
                this.router.navigate([`/project-view/${urlProject.ProjectId}`]);
            } else {
                this.router.navigate([`/home`]);
            }
            return;
        }

        this.currentGenerator = {};

        if(this.selectedProject.ProjectValues.Energiespeicher){
             //DEBUG, falls alte Projekte mit Grob bearbeitet werden
            if(this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp != EnergiespeicherErmittlungstyp.Exakt){
                this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp = EnergiespeicherErmittlungstyp.Exakt;
            }

            if(this.selectedProject.ProjectValues.Energiespeicher.Wärmeerzeuger && this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp == EnergiespeicherErmittlungstyp.Exakt){
                this.gridData = this.selectedProject.ProjectValues.Energiespeicher.Wärmeerzeuger;
            }
            else{
                this.selectedProject.ProjectValues.Energiespeicher.Wärmeerzeuger = [];
            }
        }
        else{
            this.selectedProject.ProjectValues.Energiespeicher = {
                Ermittlungstyp: EnergiespeicherErmittlungstyp.Exakt,
                Energielabel: null,
                MinutenwerteEnergiebedarf: null,
                MinutenwerteEnergiebedarfAbgesenkt: null,
                MinutenwerteQStoOn: null,
                MinutenwerteQStoOnAbgesenkt: null,
                MinutenwerteVersorgung: null,
                MinutenwerteVersorgungAbgesenkt: null
            }
        }



        this.heatGeneratorData = this.aggregatorDataService.aggregatorData.Tables.HeatGeneratorRows
            .filter(g => g.HeatGeneratorId !== 'Grobauslegung');


    }

    onOverlayClick(){
        this.AddDataDialog.hide();
        this.EditDataDialog.hide();
        this.resetTempVars();
    }

    public getErzeugerDisplayName(id: string): string {
        return this.heatGeneratorData.find(g => g.HeatGeneratorId == id).DisplayNameLoc;
    }

    getZirkuStrom(): string{
        return this.formatter.formatNumber(this.selectedProject.ProjectValues.Zirkulationsvolumenstrom.ZirkulationsvolumenstromBerechnetLMin, 'LiterPerMinute', {maximumFractionDigits: 1, minimumFractionDigits: 1})
    }

    getZirkuVerlust(): string{
        return this.formatter.formatNumber(Number(this.selectedProject.ProjectValues.Zirkulationsvolumenstrom.ZirkulationsverlustleistungBerechnetWatt), 'Watts', {maximumFractionDigits: 1, minimumFractionDigits: 1})
    }

    openAddDataDialog = (): void => {
          this.AddDataDialog.show();
    }

    openEditDataDialog = (): void => {
        this.EditDataDialog.show();
    }

    closeEditDataDialog = (): void => {
        this.EditDataDialog.hide();

    }


    onChangeDropdown(args){

        if(args.itemData && args.isInteracted){

            this.currentGenerator = {
                ErzeugerArt: args.value,
                LeistungWarmwasserBereitungKw: args.itemData.LeistungWWBereitungKw,
                VerzoegerungNachheizungMin: args.itemData.VerzoegerungNachheizungMin
            }

            this._power = args.itemData.LeistungWWBereitungKw;
            this._delay = args.itemData.VerzoegerungNachheizungMin;
            this._editPower = args.itemData.LeistungWWBereitungKw;
            this._editDelay = args.itemData.VerzoegerungNachheizungMin;
        }

    }



    goBack() {
        this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
    }

    navigateNext(){

        this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp = EnergiespeicherErmittlungstyp.Exakt;
        if(!this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt){
            this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt = { };
        }

        if(this.gridData.length > 0){
            this.selectedProject.ProjectValues.Energiespeicher.Wärmeerzeuger = this.gridData;
        }

        this.router.navigate([`/result/${this.selectedProject.ProjectId}`]);
    }

    showIndex(index: number): number{
        return Number(index) + 1;
    }

    addHeatType(): void{
    if(this.TypeDropdownAdd.value){

        var newEntry = {
            ErzeugerArt: this.currentGenerator.ErzeugerArt,
            LeistungWarmwasserBereitungKw: this._power,
            VerzoegerungNachheizungMin: this._delay
        }

        if(this.gridData.length <= 3){
            this.gridData.push(newEntry);
            this.Grid.refresh();
            this.AddDataDialog.hide();
        }
        else{
            this.ErrorMaxTypesDialog.show(); // ??? Wird erst beim Übernehmen gezeigt? Testen!
            this.AddDataDialog.hide();
        }
        this.resetTempVars();

    }
    else{
        this.ErrorRequiredDialog.show();
    }


  }

  saveHeattype(): void{

    var editedEntry = {
        ErzeugerArt: this.currentGenerator.ErzeugerArt,
        LeistungWarmwasserBereitungKw: this._editPower,
        VerzoegerungNachheizungMin: this._editDelay
    }

    this.gridData[this._index] = editedEntry;

    this.Grid.refresh();
    this.closeEditDataDialog();

    this.resetTempVars();
  }


  deleteHeatType(data: any): void{
      this._index = data.index;
      this.openDeleteDialog();
    }

    openDeleteDialog = (): void => {
        this.DeleteDialog.show();
    }

    closeDeleteDialog = (): void => {
        this.DeleteDialog.hide();
  }

  confirmDeleteObject = (): void => {
      this.gridData.splice(this._index, 1);
      this.Grid.refresh();
      this.closeDeleteDialog();
    }

    copyHeatType(data: any): void{
        var newEntry = {
            ErzeugerArt: data.ErzeugerArt,
            LeistungWarmwasserBereitungKw: data.LeistungWarmwasserBereitungKw,
            VerzoegerungNachheizungMin: data.VerzoegerungNachheizungMin
        }

        if(this.gridData.length <= 3){
            this.gridData.push(newEntry);
            this.Grid.refresh();
        }
        else{
            this.ErrorMaxTypesDialog.show(); // ??? Wird erst beim Übernehmen gezeigt? Testen!
        }

      this._index = data.index;
    }

  editHeatType(data: any): void{
    this._index = data.index;


    this.currentGenerator = {
        ErzeugerArt: data.ErzeugerArt,
        LeistungWarmwasserBereitungKw: data.LeistungWarmwasserBereitungKw,
        VerzoegerungNachheizungMin: data.VerzoegerungNachheizungMin
    }


    this.TypeDropdownEdit.value = data.ErzeugerArt;
    this._editPower = data.LeistungWarmwasserBereitungKw;
    this._editDelay = data.VerzoegerungNachheizungMin;

    this.openEditDataDialog();
  }

  resetTempVars(){

    this._power = 0;
    this._delay = 0;
    this._editPower = 0;
    this._editDelay = 0;

    this.TypeDropdownAdd.value = null;
    this.TypeDropdownEdit.value = null;
  }



checkContinue(){
    return this.gridData.length > 0 && this.selectedProject.ProjectValues.Energiespeicher.Energielabel != null;
}

changeOption(id: string){
    var label = 0;
    switch(id){
        case("APlus"):
            label = 0;
        break;
        case("A"):
            label = 1;
        break;
        case("B"):
            label = 2;
        break;
        case("C"):
            label = 3;
        break;
    }
    this.selectedProject.ProjectValues.Energiespeicher.Energielabel = label;
}

checkOptions(label: string){
    switch(label) {
        case("APlus"):
            return this.selectedProject.ProjectValues.Energiespeicher?.Energielabel === Energielabel.APlus;
        case("A"):
            return this.selectedProject.ProjectValues.Energiespeicher?.Energielabel === Energielabel.A;
        case("B"):
            return this.selectedProject.ProjectValues.Energiespeicher?.Energielabel === Energielabel.B;
        case("C"):
            return this.selectedProject.ProjectValues.Energiespeicher?.Energielabel === Energielabel.C;
        default: return false;
    }
}

cancelAddDataDialog(){
    this.AddDataDialog.hide();
}

cancelEditDataDialog(){
    this.EditDataDialog.hide();
}

addDataButtons = [
    {
        'click': this.cancelAddDataDialog.bind(this),
        buttonModel: {
            content: this.langService.getString('AddDataDialog.AbbrechenBtn_T'),
        }
    },
    {
    'click': this.addHeatType.bind(this),
    buttonModel:{
        content: this.langService.getString('AddDataDialog.UebernehmenBtn_T'),
        isPrimary: false,
        cssClass: 'e-primary'
    }
} ];

editDataButtons = [
    {
        'click': this.cancelEditDataDialog.bind(this),
        buttonModel: {
            content: this.langService.getString('EditDataDialog.AbbrechenBtn_T'),
        }
    },
    {
  'click': this.saveHeattype.bind(this),
  buttonModel:{
      content: this.langService.getString('EditDataDialog.UebernehmenBtn_T'),
      isPrimary: false,
      cssClass: 'e-primary'
  }
} ];

deleteButtons = [ {
    'click': this.closeDeleteDialog.bind(this),
    buttonModel:{
        content: this.langService.getString('DeleteDialog.AbbrechenBtn_T'),
        isPrimary: false
    }
  },
  {
    'click': this.confirmDeleteObject.bind(this),
    buttonModel:{
      content: this.langService.getString('DeleteDialog.LoeschenBtn_T'),
      isPrimary: false,
      cssClass: 'e-primary'
    }
  }];

}
