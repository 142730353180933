<psp-roof-site-header
    [titleLinkText]="'HeaderTitle_T' | translate"
    [titleLinkHref]="'HeaderTitleHref_URL' | translate"
    [links]="roofHeaderLinks"
    [hideOnMobile]="true"
></psp-roof-site-header>

<psp-site-header
    logoImageSrc="assets/img/viega-logo.svg"
    (logoClickEvent)="header_logoClick()"

>
    <div class="icons">
        <psp-help-notify-icon
            iconCssClass="icon-help-with-circle"
            headerText= "{{'HelpIconHeaderText_T' | translate}}"
            [itemDataSource]="[]"
            noRecordsTemplate=""
            subheaderText="{{'HelpIconSubHeader_T' | translate}}"
            buttonText="{{'HelpIconHilfeBtn_T' | translate}}"
            buttonIconCssClass="icon-cheveron-right e-icon-right e-margin-left"
            buttonCssClass='button-right e-btn e-primary'
            (buttonClickEvent)="sendToHelp()"        
        ></psp-help-notify-icon>

        <psp-user-notify-icon
            iconCssClass="icon-user-circle"
            [headerText]="userNotifyIconHeaderText"
            [subheaderText]="userService.isAuthenticated ? angemeldet : ''"
            [buttonText]="userService.isAuthenticated ? abmelden : anmelden"
            [buttonCssClass]="userService.isAuthenticated ? '' : 'e-primary'"
            [buttonIconCssClass]="userService.isAuthenticated ? 'icon-log-out' : 'icon-login'"
            [itemDataSource]="userService.isAuthenticated ? [bearbeiten] : []"
            noRecordsTemplate=""
            [headerTemplate]="userNotifyIconHeaderTemplate"
            (buttonClickEvent)="userNotifyIcon_buttonClick()"
            (selectEvent)="userNotifyIcon_select($event)"
        ></psp-user-notify-icon>
    </div>
</psp-site-header>
<router-outlet></router-outlet>


