import { NgModule } from '@angular/core';
import { PspLoadingSpinnerComponent } from './psp-loading-spinner.component';

@NgModule({
    declarations:
    [
        PspLoadingSpinnerComponent,
    ],
    exports:
    [
        PspLoadingSpinnerComponent,
    ],
})
export class PspLoadingSpinnerModule {}
