import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DemandVolumetricFlowResidentialComponent } from './demand-volumetric-flow-residential.component';



@NgModule({
  declarations: [
      DemandVolumetricFlowResidentialComponent
    ],
  imports: [
    SharedModule
  ]
})
export class DemandVolumetricFlowResidentialModule { }
