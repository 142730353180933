
<viega-collapsible-panel>
    <div class="panel-content" panel-content>

            <viega-panel-header
                stepText="&nbsp;"
                [stepTitle]="stepTitle"
                [stepBackLabel]="stepBackLabel"
                (stepBackClicked)="stepBack()"
            >
            </viega-panel-header>
            <div class="content-wrapper">
                <div class="top-content-wrapper">
                    <div class="top-panel-content">
                        <div class="project-text">
                            <span>{{selectedProject.ProjectName}}</span>
                        </div>
                       <div class="project-info">

                            <p style="margin-top: 1em;">{{selectedProject.ProjectBuildSection}}</p>

                            <p >{{selectedProject.ProjectNumber}}</p>

                            <p>{{selectedProject.ProjectDescription}}</p>
                            <span>{{selectedProject.Contacts[0].Name}}</span><br>
                            <span>{{selectedProject.Contacts[0].Address}}</span><br>
                            <span>{{selectedProject.Contacts[0].Country}}</span><br>
                            <span>{{selectedProject.Contacts[0].Zip}} {{selectedProject.Contacts[0].City}}</span><br>
                        </div>

                    </div>
                </div>
                <div class="bot-panel-content">
                    <button psp-button class="e-btn btn-panel" iconPosition="none" style="width: 100%;" (click)="openBaseDataDialog()">{{'StammdatenBtn_T'| translate}}</button>
                    <button psp-button class="e-btn btn-panel" iconPosition="none" style="width: 100%;" (click)="openStructureDialog()">{{'GebaeudeBtn_T' | translate}}</button>
                    <button psp-button class="e-btn btn-panel" iconPosition="none" style="width: 100%; margin-top: 1em;" (click)="openConfigDteDialog()">{{'ProjektkonfigurationBtn_T' | translate}}</button>
                    <button psp-button class="e-btn btn-panel e-primary" iconPosition="none" style="width: 100%;" (click)="openReportDialog()" >
                        {{'BerichtBtn_T' | translate}}
                    </button>
                </div>
            </div>

    </div>

    <div class="content" content>
        <div class="overview-top-content">
        <span *ngIf="selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung" class="top-content-title">{{'HeadlineMitTemperaturabsenkung_T' | translate}}</span>
        <span *ngIf="!selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung" class="top-content-title">{{'Headline_T' | translate}}</span>
        </div>
        <div style="margin-top: 3em;">
            <div class="control-section">
                <ejs-grid #grid [dataSource]='data' [allowSorting]='false' [allowSelection]='false'>
                    <e-columns>
                        <e-column headerText='' width='20' textAlign='right'>
                        </e-column>
                        <e-column headerText="{{'TableHeaderErmittlung_T' | translate}}" width='120' textAlign='Left'>
                        </e-column>
                        <e-column headerText="{{'TableHeaderErgebnis_T' | translate}}" width='130' textAlign='Left'>
                        </e-column>
                        <e-column headerText='' width='4em' textAlign='right'>
                        </e-column>
                    </e-columns>

                    <ng-template #rowTemplate let-data>
                        <tr [style.background]="getAltRow(data.index) ? 'white' : '#E1E7E7'" style="margin-top: 1em; margin-bottom: 2em;">
                            <td [ngClass]="{'bedarfsvolumen-align': data.OrientationFrom == 'Bedarfsvolumenstrom'}" class="row-custom" style="text-align: left;"><i [ngClass]="getIconClass(data.OrientationFrom)"></i></td>
                            <!-- <td class="row-custom" style="text-align: left;" *ngIf="checkIconState(data.OrientationFrom, SectionState.Empty)"><i class="icon-edit-pencil"></i></td>
                            <td class="row-custom" style="text-align: left;" *ngIf="checkIconState(data.OrientationFrom, SectionState.Set)"><i class="icon-check2"></i></td>
                            <td class="row-custom" style="text-align: left;" *ngIf="checkIconState(data.OrientationFrom, SectionState.Check)"><i class="icon-priority_high"></i></td> -->

                            <td [ngClass]="{'bedarfsvolumen-align': data.OrientationFrom == 'Bedarfsvolumenstrom'}" class="row-custom" style="text-align: left;">
                               <div class="text-tooltip-wrapper">
                                   {{data.Label}}
                                   <app-help-icon [hinweisId]="getHinweisId(data.OrientationFrom)"></app-help-icon>
                               </div>
                                <span style="color: #cc3d5b;" *ngIf="data.OrientationFrom == 'Bedarfsvolumenstrom' && selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin > 140">
                                   <br>
                                    {{'ErrorBedarfsvolumenstrom_SD' | translate}}
                                </span>
                            </td>

                            <td class="row-custom" style="text-align: left;" *ngIf="data.OrientationFrom != 'Rohrleitung' "
                            [ngClass]="{'bedarfsvolumen-align': data.OrientationFrom == 'Bedarfsvolumenstrom'}">
                                {{getDisplayValue(data.OrientationFrom)}}
                                <div style="color: #cc3d5b;" *ngIf="data.OrientationFrom == 'Bedarfsvolumenstrom' && selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin > 140">
                                    <span [innerHTML]="'ErrorDuplizieren_D_Html' | translate"></span>
                                </div>
                            </td>

                            <td class="row-custom" style="text-align: left;" *ngIf="data.OrientationFrom === 'Rohrleitung' &&  selectedProject.SectionStates.Rohrauslegung == SectionState.Check">{{'ErrorPruefungPipeline_SD' | translate}}</td>
                            <td class="row-custom" style="text-align: left;" *ngIf="data.OrientationFrom === 'Rohrleitung' && selectedProject.SectionStates.Rohrauslegung == SectionState.Set">{{'PipelineNennweiteVorlauf_T' | translate: getSelectedProjectProjectValuesRohrauslegungErgebnisVorlauf()}} <br>{{'PipelineNennweiteRuecklaufMitte_T' | translate: getSelectedProjectProjectValuesRohrauslegungErgebnisRuecklaufMitte()}} <br>{{'PipelineNennweiteRuecklaufUnten_T' | translate: getSelectedProjectProjectValuesRohrauslegungErgebnisRuecklaufUnten()}}</td>
                            <td class="row-custom" style="text-align: left;" *ngIf="data.OrientationFrom === 'Rohrleitung' && selectedProject.SectionStates.Rohrauslegung == SectionState.Empty">{{'PipelineNennweiteVorlauf_T' | translate: "Nicht ermittelt"}} <br>{{'PipelineNennweiteRuecklaufMitte_T' | translate: "Nicht ermittelt"}} <br>{{'PipelineNennweiteRuecklaufUnten_T' | translate: "Nicht ermittelt"}}</td>

                            <td *ngIf="data.OrientationFrom != 'Bedarfsvolumenstrom' && data.OrientationFrom != 'WarmwasserTagesbedarf'" class="row-custom" style="text-align: center;">
                                <button *ngIf="getDisplayValue(data.OrientationFrom) === 'Nicht ermittelt' && checkButtonState(data.OrientationFrom)" psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="calculate(data.OrientationFrom)">{{'ErmittelnBtn_T' | translate}}</button>
                                <button *ngIf="getDisplayValue(data.OrientationFrom) != 'Nicht ermittelt' && checkButtonState(data.OrientationFrom)" psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="calculate(data.OrientationFrom)">{{'BearbeitenBtn_T' | translate}}</button>
                            </td>
                            <td *ngIf="data.OrientationFrom === 'Bedarfsvolumenstrom' && isDemandOpen" style="text-align: right;"><i class="icon-chevron-thin-down" style="margin-right: 1em;" (click)="changeOpenState(data.OrientationFrom)"></i></td>
                            <td *ngIf="data.OrientationFrom === 'Bedarfsvolumenstrom' && !isDemandOpen" style="text-align: right;"><i class="icon-chevron-thin-up" style="margin-right: 1em;" (click)="changeOpenState(data.OrientationFrom)"></i></td>
                            <td *ngIf="data.OrientationFrom === 'WarmwasserTagesbedarf' && selectedProject.ConfigurationData.Konfiguration_Energiespeicher && isWaterOpen" style="text-align: right;"><i class="icon-chevron-thin-down" style="margin-right: 1em;" (click)="changeOpenState(data.OrientationFrom)"></i></td>
                            <td *ngIf="data.OrientationFrom === 'WarmwasserTagesbedarf' && selectedProject.ConfigurationData.Konfiguration_Energiespeicher && !isWaterOpen" style="text-align: right;"><i class="icon-chevron-thin-up" style="margin-right: 1em;" (click)="changeOpenState(data.OrientationFrom)"></i></td>
                        </tr>

                        <ng-template [ngIf]="data.OrientationFrom === 'Bedarfsvolumenstrom' && isDemandOpen">
                            <tr *ngIf="selectedProject.ResidentialBuilding" [style.background]="getAltRow(data.index) ? 'white' : '#E1E7E7'">
                                <td class="row-custom" style="text-align: left;"></td>
                                <td class="row-custom" style="text-align: left;"><i [ngClass]="getBuildingBvsIconClass(selectedProject.ResidentialBuilding.BuildingType)"></i> {{getDisplayName(selectedProject.ResidentialBuilding.BuildingType)}}</td>
                                <td *ngIf="selectedProject.ResidentialBuilding.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">{{formatBuildingBvsResult(selectedProject.ResidentialBuilding.Bedarfsvolumenstrom.BedarfsvolumenstromErgebnisLMin)}}</td>
                                <td *ngIf="selectedProject.ResidentialBuilding.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">
                                    <button psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openDemandSiteResidential(selectedProject.ResidentialBuilding.BuildingType)">{{'BearbeitenBtn_T' | translate}}</button>
                                </td>
                                <td *ngIf="!selectedProject.ResidentialBuilding.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">{{'NichtErmittelt_T' | translate}}</td>
                                <td *ngIf="!selectedProject.ResidentialBuilding.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">
                                    <button psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openDemandSiteResidential(selectedProject.ResidentialBuilding.BuildingType)">{{'ErmittelnBtn_T' | translate}}</button>
                                </td>
                            </tr>
                            <tr *ngFor="let building of selectedProject.OtherBuildings as OtherBuilding" [style.background]="getAltRow(data.index) ? 'white' : '#E1E7E7'">
                                <td class="row-custom" style="text-align: left;"></td>
                                <td class="row-custom" style="text-align: left;"><i [ngClass]="getBuildingBvsIconClass(building.BuildingType)"></i> {{getDisplayName(building.BuildingType)}}</td>
                                <td *ngIf="building.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">{{formatBuildingBvsResult(building.Bedarfsvolumenstrom.BedarfsvolumenstromErgebnisLMin)}}</td>
                                <td *ngIf="building.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">
                                    <button psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openDemandSiteOther(building.BuildingType)">{{'BearbeitenBtn_T' | translate}}</button>
                                </td>
                                <td *ngIf="!building.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">{{'NichtErmittelt_T' | translate}}</td>
                                <td *ngIf="!building.Bedarfsvolumenstrom" class="row-custom" style="text-align: left;">
                                    <button psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openDemandSiteOther(building.BuildingType)">{{'ErmittelnBtn_T' | translate}}</button>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template [ngIf]="data.OrientationFrom === 'WarmwasserTagesbedarf' && selectedProject.ConfigurationData.Konfiguration_Energiespeicher && isWaterOpen">
                            <tr *ngIf="selectedProject.ResidentialBuilding" [style.background]="getAltRow(data.index) ? 'white' : '#E1E7E7'">
                                <td class="row-custom" style="text-align: left;"></td>
                                <td class="row-custom" style="text-align: left;"><i [ngClass]="getBuildingWwIconClass(selectedProject.ResidentialBuilding.BuildingType)"></i> {{getDisplayName(selectedProject.ResidentialBuilding.BuildingType)}}</td>
                                <td *ngIf="selectedProject.ResidentialBuilding.Warmwasser" class="row-custom" style="text-align: left;">{{formatBuildingWwResult(selectedProject.ResidentialBuilding.Warmwasser.WarmwasserErgebnisLTag, selectedProject.ResidentialBuilding.BuildingType)}}</td>
                                <td *ngIf="selectedProject.ResidentialBuilding.Warmwasser" class="row-custom" style="text-align: left;">
                                    <button *ngIf="checkButtonState(data.OrientationFrom)" psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openWarmWaterSite(selectedProject.ResidentialBuilding.BuildingType)">{{'BearbeitenBtn_T' | translate}}</button>
                                </td>
                                <td *ngIf="!selectedProject.ResidentialBuilding.Warmwasser" class="row-custom" style="text-align: left;">{{'NichtErmittelt_T' | translate}}</td>
                                <td *ngIf="!selectedProject.ResidentialBuilding.Warmwasser" class="row-custom" style="text-align: left;">
                                    <button *ngIf="checkButtonState(data.OrientationFrom)" psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openWarmWaterSite(selectedProject.ResidentialBuilding.BuildingType)">{{'ErmittelnBtn_T' | translate}}</button>
                                </td>
                            </tr>
                            <tr *ngFor="let building of selectedProject.OtherBuildings as OtherBuilding" [style.background]="getAltRow(data.index) ? 'white' : '#E1E7E7'">
                                <td class="row-custom" style="text-align: left;"></td>
                                <td class="row-custom" style="text-align: left;"><i [ngClass]="getBuildingWwIconClass(building.BuildingType)"></i> {{getDisplayName(building.BuildingType)}}</td>
                                <td *ngIf="building.Warmwasser" class="row-custom" style="text-align: left;">{{formatBuildingWwResult(building.Warmwasser.WarmwasserErgebnisLTag, building.BuildingType)}}</td>
                                <td *ngIf="building.Warmwasser" class="row-custom" style="text-align: left;">
                                    <button *ngIf="checkButtonState(data.OrientationFrom)" psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openWarmWaterSite(building.BuildingType)">{{'BearbeitenBtn_T' | translate}}</button>
                                </td>
                                <td *ngIf="!building.Warmwasser" class="row-custom" style="text-align: left;">{{'NichtErmittelt_T' | translate}}</td>
                                <td *ngIf="!building.Warmwasser" class="row-custom" style="text-align: left;">
                                    <button *ngIf="checkButtonState(data.OrientationFrom)" psp-button class="e-btn e-row-btn" style="width: 100%;" (click)="openWarmWaterSite(building.BuildingType)">{{'ErmittelnBtn_T' | translate}}</button>
                                </td>
                            </tr>
                        </ng-template>

                    </ng-template>
                </ejs-grid>
                <app-dimensioning-schematic #DisplaySVG style="margin-top: 4em; height: 240px; margin-left: auto; margin-right: auto; display: block;"></app-dimensioning-schematic>
            </div>
        </div>
    </div>
</viega-collapsible-panel>

<app-base-data-dialog
    #baseDataDialog
    (dialogPristine)="checkBaseDataPristine()"
    target="app-project-view"
    [buttons]="baseDataButtons"
    [headerText]="baseDataHeader"
    [editingProject]="selectedProject"
    (projectEdited)="setSelectedProjectValues($event)"
></app-base-data-dialog>

<app-structure-dialog
    #structureDialog
    (dialogPristine)="checkStructurePristine()"
    [changeWarning]="structureDialogChangeWarning"
    target='app-project-view'
    [buttons]='structureButtons'
    [(selectedStructuresOther)]="selectedStructuresOther"
    [(selectedStructureResidential)]="selectedStructureResidential"
    [headerText]='structureHeader'
    [projectName]="selectedProject.ProjectName"
></app-structure-dialog>

<app-config-dte-dialog
    #configDteDialog
    (dialogPristine)="checkConfigPristine()"
    (closedCompletely)="resetConfigDialog()"
    [isConfigDetailsDialogPristine]="isConfigDetailsDialogPristine"
    [changeWarning]="configDteDialogChangeWarning"
    [(dteUfcChecked)]="isDteUfcChecked"
    target="app-project-view"
    [buttons]='configDteButtons'
    [headerText]='configDteHeader'
    [projectName]="selectedProject.ProjectName"
></app-config-dte-dialog>

<app-config-details-dialog
    #configDetailsDialog
    (dialogPristine)="checkConfigPristine()"
    (closedCompletely)="resetConfigDialog()"
    [isConfigDteDialogPristine]="isConfigDteDialogPristine"
    [changeWarning]="configDetailsDialogChangeWarning"
    [(isPipeChecked)]="isPipeChecked"
    [(isEnergyChecked)]="isEnergyChecked"
    target="app-project-view"
    [buttons]='configDetailsButtons'
    [headerText]='configDetailsHeader'
    [projectName]="selectedProject.ProjectName"
></app-config-details-dialog>

<ejs-dialog #closeDialog target='app-project-view' header="{{'CloseDialogHeader_T' | translate}}" [buttons]='closeDialogButtons' [visible]='false' [isModal]=true [closeOnEscape]='false' [showCloseIcon]='false' width='40em' [position]='dialogPosition'>
    <span>{{'CloseDialogDescription_SD' | translate}}</span>
</ejs-dialog>


<ejs-dialog class="report-dialog" #reportDialog (overlayClick)="onOverlayClickReport()" (close)="onCloseReportDialog()" target="app-project-view"  header="" isModal="true" closeOnEscape="false" [visible]="false" minHeight="75%" height="85%" width="35%"  showCloseIcon="true" [position]="dialogPosition" >
    <ng-template #header>
        <div class="text-tooltip-wrapper text-tooltip-wrapper-dialog-header">
            {{'ReportDialogHeader_T' | translate}}
            <app-help-icon hinweisId="Bericht.Erzeugen"></app-help-icon>
        </div>
    </ng-template>
    <ng-template #content>
        <span>
            {{'ReportDialogDescription_D' | translate}}
        </span>
        <div class="report-content-wrapper">

            <!-- Checkboxes -->
            <div *ngIf="!isGenerating && !isGenerated" class="report-checkboxes-wrapper">
                <div>
                    <ejs-checkbox label="{{'ReportDialogCheckboxAlles_T' | translate}}" (change)="onChangeAllOptions($event)" [(checked)]="allOptionsChecked"></ejs-checkbox>
                </div>
                <div class="report-options-wrapper">
                    <div class="report-option">
                        <ejs-checkbox [disabled]="true" [checked]="true" label="{{'ReportDialogCheckboxErgebnisbericht_T' | translate}}" ></ejs-checkbox>
                    </div>
                    <div class="report-option" *ngFor="let option of reportOptions; let i = index">
                        <ejs-checkbox  [label]="option.Row.DisplayNameLoc" (change)="onChangeOption($event)" [(checked)]="option.Checked"></ejs-checkbox>
                    </div>
                    <div *ngIf="selectedProject.ProjectValues.Rohrauslegung && selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung" class="report-pipe-option report-option">
                        <ejs-checkbox (change)="onChangeOption($event)"  [(checked)]="reportOptionPipe" label="{{'ReportDialogCheckboxPipelineErgebnisbericht_T' | translate}}" label="{{'ReportDialogCheckboxPipelineAusschreibungstexte_T' | translate: selectedProject.ProjectValues.Rohrauslegung.Rohrleitungssystem}}" ></ejs-checkbox>
                    </div>
                </div>
            </div>

            <!-- Loading-Spinner -->
            <div *ngIf="isGenerating && !isGenerated">
                <psp-loading-spinner size="large" class="e-control e-psp-loading-spinner e-lib pspc-loading-spinner pspc-loading-spinner--dark">
                    <span class="e-spinner">
                        <div class="e-spinner-pane e-spin-show">
                            <div class="e-spinner-inner">
                                <svg class="e-spin-material" id="x3eJl" viewBox="0 0 64 64" style="height: 64px; width: 64px; transform-origin: 32px 32px 32px;">
                                    <path class="e-path-circle" d="M32,3.2A28.8,28.8 0 1 1 3.2,32" stroke-width="6.4" stroke-dasharray="255.71680263507906" stroke-dashoffset="1005.3017108817691" transform="rotate(0 32 32)"></path>
                                </svg>
                            </div>
                        </div>
                    </span>
                </psp-loading-spinner>
                <div>
                    <span>{{'LoadingSpinner_SD' | translate}}</span>
                </div>
            </div>

            <!-- Download -->
            <div class="report-download-view" *ngIf="!isGenerating && isGenerated">
                <span  class="e-icons icon-document1"></span>
                <p class="file-name">{{downloadFileName}}</p>
                <button psp-button iconPosition="None" cssClass="e-primary" (click)="downloadFile()">{{'DownloadBtn_T' | translate}} ({{this.fileSize}}MB)</button>
            </div>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div *ngIf="!isGenerating && !isGenerated">
            <button psp-button iconPosition="None" (click)="closeReportDialog()">{{'ReportDialogAbbrechenBtn_T' | translate}}</button>
            <button psp-button iconPosition="None" cssClass="e-primary" (click)="startGeneratingReport()">{{'ReportDialogStartBtn_T' | translate}}</button>
        </div>

        <div *ngIf="isGenerating && !isGenerated">
            <button psp-button iconPosition="None" (click)="cancelGenerating()">{{'ReportDialogAbbrechenBtn_T' | translate}}</button>

        </div>

        <div *ngIf="!isGenerating && isGenerated">
            <button psp-button iconPosition="None" (click)="closeReportDialog()">{{'ReportDialogSchliessenBtn_T' | translate}}</button>

        </div>
    </ng-template>
</ejs-dialog>

<ejs-dialog
    class="report-no-data-dialog"
    #reportNoDataDialog
    [buttons]="reportNoDataButtons"
    target="app-project-view"
    header="{{'ErrorNichtGenugDatenHeader_T' | translate}}"
    isModal="true" closeOnEscape="false"
    [visible]="false"
    height="30%" width="40%"
    showCloseIcon="true"
    [position]="dialogPosition"
    >
        <p>{{'ErrorNichtGenugDaten_SD' | translate}}</p>
        <p>{{'ErrorBedarfsvolumenstromVollstaendig_SD' | translate}}</p>
</ejs-dialog>

<ejs-dialog
    class="report-error-dialog"
    #reportErrorDialog
    [buttons]="reportErrorButtons"
    target="app-project-view"
    header="Fehler bei der Generierung"
    isModal="true"
    closeOnEscape="false"
    [visible]="false"
    height="30%" width="40%"
    showCloseIcon="true"
    [position]="dialogPosition" >
    <p>{{'ErrorGenerierung_SD' | translate}}</p>
</ejs-dialog>