import { Component, OnInit, EventEmitter,Input, Output } from '@angular/core';

@Component({
  selector: 'viega-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss']
})
export class SelectImageComponent implements OnInit {

  @Input()
  public imagePath: string;

  @Input()
  public imageText: string;

  @Input()
  public description: string;

  @Input()
  public buttonText?: string;

  @Input()
  public isSelected?: boolean;

  @Output()
  public buttonClicked = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  button_clicked(event: MouseEvent){
      this.buttonClicked.emit(event);
  }

}
