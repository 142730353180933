import { NgModule } from '@angular/core';
import { PspNotifyIconComponent } from './psp-notify-icon.component';

@NgModule(
{
    declarations:
    [
        PspNotifyIconComponent,
    ],
    exports:
    [
        PspNotifyIconComponent,
    ],
})
export class PspNotifyIconModule {}
