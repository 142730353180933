import { ServiceLocator } from './ServiceLocator';
import { LangService } from './LangService';
import { RuntimeService } from './RuntimeService';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorProtocolService
{
    private langService: LangService;
    private runtimeService: RuntimeService;

    public constructor()
    {
        this.langService = ServiceLocator.get(LangService);
        this.runtimeService = ServiceLocator.get(RuntimeService);
    }

    /**
     * Generates a json object containing debug information meant to help developers in
     * discovering issues.
     */
    public generateProtocol(errorDetails: Error | string | object, contextInfo?: Object): string
    {
        if (errorDetails instanceof Error)
            errorDetails = errorDetails.stack.replace(/\n/g, '<br>');

        // remove some info from runtime config
        const runtimeInfoPart = JSON.parse(JSON.stringify(this.runtimeService.runtimeInfo));
        delete runtimeInfoPart.DeclaredPermissions;
        delete runtimeInfoPart.Config;

        const protocolObj = {
            Location: location.href,
            ErrorDetails: errorDetails,
            Context: contextInfo,
            Browser: {
                Agent: navigator.userAgent,
                Platform: navigator.platform,
                NavigatorLanguage: navigator.language,
                DeviceMemory: navigator['deviceMemory'],
                DevicePixelRatio: window.devicePixelRatio,
                Orientation: window.orientation,
                InnerWidth: window.innerWidth,
                InnerHeight: window.innerHeight,
            },
            PspLocale: this.langService.currentLocale,
            RuntimeInfo: runtimeInfoPart,
        };

        return JSON.stringify(protocolObj, null, 2);
    }
}
