import { ThisReceiver } from '@angular/compiler';
import { Complex, Component, INotifyPropertyChanged, Property, Event, EmitType, addClass, removeClass, L10n, defaultCulture, EventHandler } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { AutoComplete, AutoCompleteModel, ChangeEventArgs, FieldSettings, FieldSettingsModel, FilteringEventArgs, FilterType, PopupEventArgs, SelectEventArgs } from '@syncfusion/ej2-dropdowns';
import { SortOrder } from '@syncfusion/ej2-navigations';
import { events } from './events';
import { IPspSearchBoxModel, SearchBoxTheme } from './IPspSearchBoxModel';
import { IStartSearchEventArgs } from './IStartSearchEventArgs';

export class PspSearchBox extends Component<HTMLElement> implements INotifyPropertyChanged
{
    private static readonly cssBaseClass = 'pspc-search-box-ac';

    private inputAutoComplete: AutoComplete;
    private startSearchIconEl: HTMLButtonElement;

    /**
     * Data source which is queried as the user types a string in the input.
     */
    @Property()
    public quickSearchDataSource?: { [key: string]: object }[] | DataManager;

    /**
     * Data that is shown when the user focuses the input without typing anything.
     */
    @Property()
    public presetDataSource?: { [key: string]: object }[] | DataManager;

    /**
     * Whether the popup should open as the input is clicked. This is only applicable if a data source for
     * recommended items has been set.
     */
    @Property(true)
    public openOnFocus?: boolean;

    /**
     * Css classes to apply for the search icon.
     */
    @Property('icon-search1')
    public startSearchIconCss: string;

    /**
     * Whether autocomplete functionality should be enabled or not.
     */
    @Property(true)
    public enableAutocomplete?: boolean;

    /**
     * Configures the appearance of the search box.
     */
    @Property('light')
    public theme: SearchBoxTheme;

    /**
     * Specifies a short hint that describes the expected value of the DropDownList component.
     * @default null
     */
    @Property(null)
    public placeholder: string;

    /**
     * Specifies the width of the popup list. By default, the popup width sets based on the width of
     * the component.
     * > For more details about the popup configuration refer to
     * [`Popup Configuration`](../../drop-down-list/getting-started#configure-the-popup-list) documentation.
     * @default '100%'
     * @aspType string
     * @blazorType string
     */
    @Property('100%')
    public popupWidth: string | number;

    /**
     * Specifies the height of the popup list.
     * > For more details about the popup configuration refer to
     * [`Popup Configuration`](../../drop-down-list/getting-started#configure-the-popup-list) documentation.
     * @default '300px'
     * @aspType string
     * @blazorType string
     */
    @Property('300px')
    public popupHeight: string | number;

    /**
     * When set to true, the user interactions on the component are disabled.
     * @default false
     */
    @Property(false)
    public readonly: boolean;

    /**
     * Specifies the width of the component. By default, the component width sets based on the width of
     * its parent container. You can also set the width in pixel values.
     * @default '100%'
     * @aspType string
     * @blazorType string
     */
    @Property('100%')
    public width: string | number;

    @Property()
    public showPopupButton?: boolean;

    /**
     * Specifies whether to show or hide the clear button.
     * When the clear button is clicked, `value`, `text`, and `index` properties are reset to null.
     * @default true
     * @blazorOverrideType override
     */
    @Property(true)
    public showClearButton: boolean;

    /**
     * Allows you to set [`the minimum search character length']
     * (../../auto-complete/filtering#limit-the-minimum-filter-character),
     * the search action will perform after typed minimum characters.
     * @default 1
     * @blazorType int
     */
    @Property(1)
    public minLength: number;

    /**
     * When set to ‘true’, highlight the searched characters on suggested list items.
     * > For more details about the highlight refer to [`Custom highlight search`](../../auto-complete/how-to/custom-search) documentation.
     * @default false
     */
    @Property(false)
    public highlight: boolean;

    /**
     * The amount of items which should be shown as the user types.
     * @default 20
     * @blazorType int
     */
    @Property(20)
    public suggestionCount: number;

    /**
     * Configures how fields of data are bound to the component.
     * * text - The field to use for display values.
     * * value - The field to use for values.
     * * iconCss - The css classes to use for item icons.
     * * groupBy - The field to use for grouping.
     *
     * @default {text: null, value: null, iconCss: null, groupBy: null}
     */
    @Complex<FieldSettingsModel>({ text: null, value: null, iconCss: null, groupBy: null }, FieldSettings)
    public fields: FieldSettingsModel;

    /**
     * The template to apply to the header of the popup.
     * @default null
     */
    @Property(null)
    public headerTemplate: string;

    /**
     * Accepts the template design and assigns it to each list item present in the popup.
     * We have built-in `template engine`
     *
     * which provides options to compile template string into a executable function.
     * For EX: We have expression evolution as like ES6 expression string literals.
     * @default null
     */
    @Property(null)
    public itemTemplate: string;

    /**
     * The template to apply to the footer of the popup.
     * @default null
     */
    @Property(null)
    public footerTemplate: string;

    /**
     * Accepts the template design and assigns it to the group headers present in the popup list.
     * @default null
     */
    @Property(null)
    public groupTemplate: string;

    /**
     * Accepts the template design and assigns it to popup list of component
     * when no data is available on the component.
     * @default 'No records found'
     */
    @Property('No records found')
    public noRecordsTemplate: string;

    /**
     * Accepts the template and assigns it to the popup list content of the component
     * when the data fetch request from the remote server fails.
     * @default 'Request failed'
     */
    @Property('Request failed')
    public actionFailureTemplate: string;

    /**
     * Specifies the `sortOrder` to sort the data source. The available type of sort orders are
     * * `None` - The data source is not sorting.
     * * `Ascending` - The data source is sorting with ascending order.
     * * `Descending` - The data source is sorting with descending order.
     *
     * This setting does not apply to the search preset items.
     * @default null
     * @asptype object
     * @aspjsonconverterignore
     */
    @Property<SortOrder>('None')
    public sortOrder: SortOrder;

    /**
     * Specifies a value that indicates whether the component is enabled or not.
     * @default true
     */
    @Property(true)
    public enabled: boolean;

    /**
     * Accepts the external `Query`
     * which will execute along with the data processing.
     * @default null
     */
    @Property(null)
    public query: Query;

    /**
     * Determines on which filter type, the component needs to be considered on search action.
     * The `FilterType` and its supported data types are
     *
     * <table>
     * <tr>
     * <td colSpan=1 rowSpan=1>
     * FilterType<br/></td><td colSpan=1 rowSpan=1>
     * Description<br/></td><td colSpan=1 rowSpan=1>
     * Supported Types<br/></td></tr>
     * <tr>
     * <td colSpan=1 rowSpan=1>
     * StartsWith<br/></td><td colSpan=1 rowSpan=1>
     * Checks whether a value begins with the specified value.<br/></td><td colSpan=1 rowSpan=1>
     * String<br/></td></tr>
     * <tr>
     * <td colSpan=1 rowSpan=1>
     * EndsWith<br/></td><td colSpan=1 rowSpan=1>
     * Checks whether a value ends with specified value.<br/><br/></td><td colSpan=1 rowSpan=1>
     * <br/>String<br/></td></tr>
     * <tr>
     * <td colSpan=1 rowSpan=1>
     * Contains<br/></td><td colSpan=1 rowSpan=1>
     * Checks whether a value contains with specified value.<br/><br/></td><td colSpan=1 rowSpan=1>
     * <br/>String<br/></td></tr>
     * </table>
     *
     * The default value set to `StartsWith`, all the suggestion items which contain typed characters to listed in the suggestion popup.
     * @default 'StartsWith'
     */
    @Property('StartsWith')
    public filterType: FilterType;

    /**
     * When set to ‘false’, consider the `case-sensitive` on performing the search to find suggestions.
     * By default consider the casing.
     * @default true
     */
    @Property(true)
    public ignoreCase: boolean;

    /**
     * specifies the z-index value of the component popup element.
     * @default 1000
     */
    @Property(1000)
    public zIndex: number;

    /**
     * ignoreAccent set to true, then ignores the diacritic characters or accents when filtering.
     */
    @Property(false)
    public ignoreAccent: boolean;

    /**
     * Gets or sets the display text of the selected item in the component.
     * @default null
     */
    @Property(null)
    public text: string;

    /**
     * Gets or sets the value of the selected item in the component.
     * @default null
     * @isGenericType true
     */

    @Property(null)
    public value: number | string | boolean;

    /**
     * Sets CSS classes to the root element of the component that allows customization of appearance.
     * @default null
     */
    @Property(null)
    public cssClass: string;

    /**
     * Triggered when the user presses enter, selects an item from suggestions or presets or when the search icon is clicked.
     */
    @Event()
    public startSearchEvent: EmitType<IStartSearchEventArgs>;

    /**
     * Triggers when an item in a popup is selected or when the model value is changed by user.
     * @event
     * @blazorProperty 'ValueChange'
     */
    @Event()
    public changeEvent: EmitType<ChangeEventArgs>;

    /**
     * Triggers when the popup before opens.
     * @event
     * @blazorProperty 'OnOpen'
     * @blazorType BeforeOpenEventArgs
     */
    @Event()
    public beforeOpenEvent: EmitType<object>;

    /**
     * Triggers when the popup opens.
     * @event
     * @blazorProperty 'Opened'
     */
    @Event()
    public openEvent: EmitType<PopupEventArgs>;

    /**
     * Triggers when the popup is closed.
     * @event
     * @blazorProperty 'OnClose'
     */
    @Event()
    public closeEvent: EmitType<PopupEventArgs>;

    /**
     * Triggers when focus moves out from the component.
     * @event
     */
    @Event()
    public blurEvent: EmitType<object>;

    /**
     * Triggers when the component is focused.
     * @event
     */
    @Event()
    public focusEvent: EmitType<object>;

    /**
     * Triggers before fetching data from the remote server.
     * @event
     * @blazorProperty 'OnActionBegin'
     * @blazorType ActionBeginEventArgs
     */
    @Event()
    public actionBeginEvent: EmitType<object>;

    /**
     * Triggers after data is fetched successfully from the remote server.
     * @event
     * @blazorProperty 'OnActionComplete'
     * @blazorType ActionCompleteEventArgs
     */
    @Event()
    public actionCompleteEvent: EmitType<object>;

    /**
     * Triggers when the data fetch request from the remote server fails.
     * @event
     * @blazorProperty 'OnActionFailure'
     */
    @Event()
    public actionFailureEvent: EmitType<object>;

    /**
     * Triggers when an item in the popup is selected by the user either with mouse/tap or with keyboard navigation.
     * @event
     * @blazorProperty 'OnValueSelect'
     */
    @Event()
    public selectEvent: EmitType<SelectEventArgs>;

    /**
     * Triggers when data source is populated in the popup list.
     * @event
     * @blazorProperty 'DataBound'
     * @blazorType DataBoundEventArgs
     */
    @Event()
    public dataBoundEvent: EmitType<object>;

    /**
     * Triggers when filtering of data happens e.g. when the input text changes.
     * @event
     * @blazorProperty 'Filtering'
     * @blazorType DataBoundEventArgs
     */
    @Event()
    public filteringEvent: EmitType<FilteringEventArgs>;

    /**
     * Triggers when the component is created.
     * @event
     * @blazorProperty 'Created'
     */
    @Event()
    public createdEvent: EmitType<object>;

    /**
     * Triggers when the component is destroyed.
     * @event
     * @blazorProperty 'Destroyed'
     */
    @Event()
    public destroyedEvent: EmitType<object>;

    constructor(options?: IPspSearchBoxModel, element?: string | HTMLElement)
    {
        super(options, element);
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-search-box';
    }

    protected preRender(): void
    {
        // NOP
    }

    protected render(): void
    {
        const locale = L10n['locale'];
        const l10n = new L10n('auto-complete', locale, defaultCulture);;

        addClass([this.element], 'pspc-search-box');

        if (this.cssClass)
            addClass([this.element], this.cssClass.split(' '));

        this.setEnabled(this.enabled);
        this.setTheme(this.theme);

        const autoCompleteEl = document.createElement('SPAN');
        this.element.appendChild(autoCompleteEl);

        const autoCompleteSetup: AutoCompleteModel = {
            dataSource: (this.presetDataSource || this.quickSearchDataSource) as any,
            fields: this.fields,
            filterType: this.filterType,
            filtering: this.autoComplete_filtering.bind(this),
            allowFiltering: true,
            showPopupButton: this.showPopupButton,
            showClearButton: this.showClearButton,
            highlight: this.highlight,
            width: this.width,
            suggestionCount: 50,
            minLength: this.minLength,
            placeholder: this.placeholder,
            popupHeight: this.popupHeight,
            popupWidth: this.popupWidth,
            readonly: this.readonly,
            sortOrder: this.sortOrder,
            enabled: this.enabled,
            query: this.query as any,
            headerTemplate: this.headerTemplate,
            itemTemplate: this.itemTemplate,
            footerTemplate: this.footerTemplate,
            groupTemplate: this.groupTemplate,
            noRecordsTemplate: this.noRecordsTemplate === 'No records found' ? l10n.getConstant('noRecordsTemplate') : this.noRecordsTemplate,
            actionFailureTemplate: this.noRecordsTemplate === 'Request failed' ? l10n.getConstant('actionFailureTemplate') : this.actionFailureTemplate,
            text: this.text,
            value: this.value,
            zIndex: this.zIndex,
            cssClass: PspSearchBox.cssBaseClass,
            change: this.autoComplete_change.bind(this),
            select: this.autoComplete_select.bind(this),
            open: this.autoComplete_open.bind(this),
            beforeOpen: this.autoComplete_beforeOpen.bind(this),
            close: this.autoComplete_close.bind(this),
            blur: this.autoComplete_blur.bind(this),
            focus: this.autoComplete_focus.bind(this),
            actionBegin: (args) => this.trigger(events.actionBegin, args),
            actionComplete: (args) => this.trigger(events.actionComplete, args),
            actionFailure: (args) => this.trigger(events.actionFailure, args),
            dataBound: (args) => this.trigger(events.dataBound, args),
        };

        this.inputAutoComplete = new AutoComplete(autoCompleteSetup, autoCompleteEl);

        // TODO: @syncfusion: request a new property to prevent the popup from hiding when emptystring is entered or clear button is clicked. also ensure that unfiltered data is then loaded into popup
        // hack: override this private method to prevent the popup from hiding
        this.inputAutoComplete['filterAction'] = this.autoComplete_filterActionOverride.bind(this);

        // hack: override this private method to prevent the popup from hiding when the clear button is clicked
        this.inputAutoComplete['clearAll'] = this.autoComplete_clearAllOverride.bind(this);

        this.startSearchIconEl = document.createElement('BUTTON') as HTMLButtonElement;
        this.startSearchIconEl.className = 'pspc-search-box-submit';
        addClass([this.startSearchIconEl], this.startSearchIconCss);
        this.element.appendChild(this.startSearchIconEl);

        this.wireEvents();
        this.trigger(events.created, {});
    }

    private element_keyup(args: KeyboardEvent): void
    {
        const inputString: string = this.inputAutoComplete['typedString'];
        if (!this.enableAutocomplete && inputString === '')
        {
            this.text = '';
            this.value = '';
            const emitArgs: FilteringEventArgs =
            {
                cancel: false,
                baseEventArgs: args,
                preventDefaultAction: false,
                text: '',
                updateData: null,
            };
            this.trigger(events.filtering, emitArgs);
        }
    }

    private startSearchIcon_click(args: MouseEvent): void
    {
        const emitArgs: IStartSearchEventArgs =
        {
            text: this.text,
            value: this.value,
        };
        this.trigger(events.startSearch, emitArgs);
    }

    private autoComplete_beforeOpen(args: { cancel: boolean }): void
    {
        args.cancel = !this.enableAutocomplete;
        this.trigger(events.beforeOpen, args);
    }

    private autoComplete_blur(): void
    {
        removeClass([this.element], 'pspc-search-box--focused');
        this.trigger(events.blur, {});
    }

    private autoComplete_focus(): void
    {
        if (this.openOnFocus)
            this.inputAutoComplete.showPopup();

        addClass([this.element], 'pspc-search-box--focused');
        this.trigger(events.focus, {});
    }

    private autoComplete_filterActionOverride(
        dataSource: { [key: string]: object }[] | DataManager | string[] | number[] | boolean[],
        query?: Query, fields?: FieldSettingsModel
    ): void
    {
        const autoComplete: any = this.inputAutoComplete;
        if (
            (autoComplete.queryString === '' && this.presetDataSource) ||
            (autoComplete.queryString.length >= autoComplete.minLength)
        )
        {
            autoComplete.beforePopupOpen = true;
            autoComplete.resetList.call(autoComplete, dataSource, fields, query);
        }
        else
        {
            this.inputAutoComplete.hidePopup();
        }

        autoComplete.renderReactTemplates.call(autoComplete);
    }

    private autoComplete_clearAllOverride(
        e?: MouseEvent,
        property?: AutoCompleteModel
    ): void
    {
        // super call
        this.inputAutoComplete['__proto__']['__proto__'].clearAll.call(this.inputAutoComplete, e, property);
        this.autoComplete_filterActionOverride(this.presetDataSource);

        this.text = '';
        this.value = '';
        const emitArgs: FilteringEventArgs =
        {
            cancel: false,
            baseEventArgs: e,
            preventDefaultAction: false,
            text: '',
            updateData: null,
        };
        this.trigger(events.filtering, emitArgs);
    }

    private autoComplete_filtering(args: FilteringEventArgs)
    {
        if (args.text === '')
        {
            if (this.presetDataSource)
                args.updateData(this.presetDataSource as any);
            else
                this.inputAutoComplete.hidePopup();
        }
        else
        {
            const query = new Query()
                .where('text', this.filterType.toLowerCase(), args.text, this.ignoreCase, this.ignoreAccent)
                .take(this.suggestionCount);

            // remove grouping in suggestions
            const fieldsWithoutGroup = {
                text: this.fields.text,
                value: this.fields.value,
                iconCss: this.fields.iconCss
            };
            args.updateData(this.quickSearchDataSource as any, query as any, fieldsWithoutGroup);
        }

        this.isProtectedOnChange = true;
        this.text = args.text;
        this.value = args.text;
        this.isProtectedOnChange = false;

        this.trigger(events.filtering, args);
    }

    private autoComplete_change(args: ChangeEventArgs)
    {
        this.isProtectedOnChange = true;
        if (args.itemData)
        {
            const textFieldName = this.fields ? this.fields.text : 'text';
            const itemText = args.itemData[textFieldName];

            this.text = itemText !== undefined ? itemText : (args.value?.toString() || '');
        }
        else
        {
            this.text = args.value?.toString() || '';
        }
        this.value = args.value;
        this.isProtectedOnChange = false;

        this.trigger(events.change, args);
    }

    private autoComplete_select(args: SelectEventArgs)
    {
        const textFieldName = this.fields ? this.fields.text : 'text';
        const valueFieldName = this.fields ? this.fields.text : 'value';
        const itemText = args.itemData[textFieldName];
        const itemValue = args.itemData[valueFieldName];

        this.isProtectedOnChange = true;
        this.text = itemText !== undefined ? itemText : itemValue;
        this.value = itemValue !== undefined ? itemValue : itemText;
        this.isProtectedOnChange = false;

        this.trigger(events.select, args);
    }

    private autoComplete_open(args: PopupEventArgs): void
    {
        args.popup.offsetY -= 1;
        addClass([this.element], 'pspc-search-box--popup-open');

        if (!this.theme)
            args.popup.element.classList.add('pspc-search-box--light');
        else
            args.popup.element.classList.add('pspc-search-box--' + this.theme);

        this.trigger(events.open, args);
    }

    private autoComplete_close(args: PopupEventArgs): void
    {
        this.trigger(events.close, args);
        setTimeout(() => {
            removeClass([this.element], 'pspc-search-box--popup-open');
        }, 100);
    }

    /** @ignore */
    public onPropertyChanged(newProp: IPspSearchBoxModel, oldProp: IPspSearchBoxModel): void
    {
        for (const propertyName of Object.keys(newProp))
        {
            const oldValue = oldProp[propertyName];
            const newValue = newProp[propertyName];

            switch (propertyName)
            {
                // properties to be passed through to the wrapper
                case 'width':
                case 'fields':
                case 'filterType':
                case 'allowFiltering':
                case 'showPopupButton':
                case 'highlight':
                case 'minLength':
                case 'placeholder':
                case 'popupHeight':
                case 'popupWidth':
                case 'readonly':
                case 'sortOrder':
                case 'query':
                case 'showClearButton':
                case 'headerTemplate':
                case 'itemTemplate':
                case 'footerTemplate':
                case 'groupTemplate':
                case 'noRecordsTemplate':
                case 'actionFailureTemplate':
                case 'text':
                case 'value':
                case 'zIndex':
                    this.inputAutoComplete[propertyName as string] = newValue;
                    break;

                case 'cssClass':
                    if (oldValue)
                        removeClass([this.element], oldValue.split(' '));
                    if (newValue)
                        addClass([this.element], newValue.split(' '));

                    break;

                case 'presetDataSource':
                case 'quickSearchDataSource':
                    this.inputAutoComplete.dataSource = (this.presetDataSource || this.quickSearchDataSource) as any;
                    break;

                case 'enabled':
                    this.inputAutoComplete.enabled = newValue;
                    this.setEnabled(newValue);
                    break;

                case 'theme':
                    this.setTheme(newValue);
                    break;

                case 'startSearchIconCss':
                    removeClass([this.startSearchIconEl], oldValue.split(' '));
                    addClass([this.startSearchIconEl], newValue.split(' '));

                    break;
            }
        }
    }

    private setEnabled(isEnabled: boolean)
    {
        if (isEnabled)
            removeClass([this.element], 'pspc-search-box--disabled');
        else
            addClass([this.element], 'pspc-search-box--disabled');
    }

    private setTheme(theme?: SearchBoxTheme)
    {
        removeClass([this.element], ['pspc-search-box--light', 'pspc-search-box--dark']);

        if (!theme)
            addClass([this.element], 'pspc-search-box--light');
        else
            addClass([this.element], 'pspc-search-box--' + theme);
    }

    protected getPersistData(): string
    {
        return '';
    }

    private wireEvents(): void
    {
        EventHandler.add(this.startSearchIconEl, 'click', this.startSearchIcon_click, this);
        EventHandler.add(this.element, 'keyup', this.element_keyup, this);
    }

    private unwireEvents(): void
    {
        EventHandler.remove(this.startSearchIconEl, 'click', this.startSearchIcon_click);
        EventHandler.remove(this.element, 'keyup', this.element_keyup);
    }

    public destroy(): void
    {
        this.unwireEvents();
        this.trigger(events.destroyed, {});
        super.destroy();
    }
}
