import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipelineComponent } from './pipeline.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    PipelineComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PipelineModule { }
