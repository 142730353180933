import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RadioButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-inputs';
import { columnSelectionComplete } from '@syncfusion/ej2-grids';
import { PdfLayoutBreakType } from '@syncfusion/ej2-pdf-export';
import * as _ from 'lodash';
import { json } from 'odata-query';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ErmittlungZVSVolumenstromDirekt } from 'src/app/core/BackendApi/ermittlungzvsvolumenstromdirekt.complex';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { Zirkulationsvolumenstrom } from 'src/app/core/BackendApi/zirkulationsvolumenstrom.complex';
import { DteRow } from 'src/app/core/DteufcAggregatorServiceApi/dterow.complex';
import { SectionState, ZirkulationsVSBerechnungstyp, ZirkulationsVSErmittlungstyp, ZVSDirektEinheit } from 'src/app/core/Enums';
import { CirculationFlowMath, ICirculationFlowFromPipesParams } from 'src/app/core/math/CirculationFlowMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { CirculationVolumetricFlowMethods, ICirculationVolumetricFlow, IProjectData } from 'src/app/core/project-interfaces';
import { UnitTypes, ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { RadioComponent } from 'src/app/shared/radio';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

// Classes/Interfaces
export class PipeTableRow {
  description: string;
  resultWatts: number;
  lengthValue: number;
  wattsPerMeter: number;
  sliderMin: number;
  sliderMax: number;
  sliderStep: number;
}

// Component
@Component({
  selector: 'app-circulation-volumetric-flow',
  templateUrl: './circulation-volumetric-flow.component.html',
  styleUrls: ['./circulation-volumetric-flow.component.scss'],
  providers: [provideContext("CirculationVolumetricFlow")],
})
export class CirculationVolumetricFlowComponent implements OnInit, AfterViewInit {

  // Constants
  public readonly DIRECT_SELECTABLE_UNITS: { label: string, value: ZVSDirektEinheit }[];
  public ZirkulationsVSBerechnungstyp = ZirkulationsVSBerechnungstyp;

  // Labels
  public label_description = this.langService.getString('PanelDescription_D');
  public label_stepBack = this.langService.getString('PanelStepBackLabel_T')
//   public label_stepText = this.langService.getString('PanelStepText_T')
  public label_title = this.langService.getString('PanelHeadline_T');

  // Variables
  private currentProject: ProjectEntity;
  private dteInfoRows: DteRow[];
  public currentBerechnungstyp: ZirkulationsVSBerechnungstyp;
  public selectedDirectFlowUnit: ZVSDirektEinheit = ZVSDirektEinheit.LiterSekunde;
  public enteredDirectFlow: number = 0;
  public stepDirectFlow: number = 1;
  public maxDirectFlowSlider: number = 30;
  public minDirectFlowSlider: number = 0;
  public maxDirectFlowText: number = 300;
  public minDirectFlowText: number = 0;
  public decimalsDirectFlowText: number = 1;

  public enteredDirectWattage: number = 0;
  public decimalsDirectWattageText: number = 0;

  public calculatedResultFlow: number = 0;
  public calculatedResultWattage: number = 0;
  public calculateFromPipesRows: PipeTableRow[]
  public availableDtesForConfiguredValues: string[];

  // Constructors/Initializers
  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private DataService: ProjectDataService,
    private langService: LangService,
    private valueFormatterService: ValueFormatterService,
    private aggregatorService : AggregatorDataService,
    private circulationFlowMath : CirculationFlowMath
  ){
    this.DIRECT_SELECTABLE_UNITS = [
      { label: 'l/min', value: ZVSDirektEinheit.LiterMinute },
      { label: 'l/s', value: ZVSDirektEinheit.LiterSekunde },
      { label: 'kg/min', value: ZVSDirektEinheit.KgMinute },
      { label: 'kg/h', value: ZVSDirektEinheit.KgStunde }
    ]
  }
  ngAfterViewInit(): void {
    if(this.currentProject.ProjectValues.Zirkulationsvolumenstrom?.ZirkulationsvolumenstromBerechnetLMin) {
      this.loadValuesFromProject(this.currentProject.ProjectValues.Zirkulationsvolumenstrom);
    }

    this.calculateResult();
  }
  ngOnInit(): void {
    // Configure  pipe rows
    this.calculateFromPipesRows = [
      {
        description: this.langService.getString('RadioDVGW553.Table.DescriptionKeller_T', undefined, this.valueFormatterService.formatNumber(11, 'WattsPerMinute')),
        wattsPerMeter: 11,
        lengthValue: 0,
        resultWatts: 0,
        sliderMin: 0,
        sliderMax: 10000,
        sliderStep: 2.5
      },
      {
        description: this.langService.getString('RadioDVGW553.Table.DescriptionSchacht_T', undefined, this.valueFormatterService.formatNumber(7, 'WattsPerMinute')),
        wattsPerMeter: 7,
        lengthValue: 0,
        resultWatts: 0 ,
        sliderMin: 0,
        sliderMax: 10000,
        sliderStep: 2.5
      },
      {
        description: this.langService.getString('RadioDVGW553.Table.DescriptionNutzbereich_T', undefined, this.valueFormatterService.formatNumber(7, 'WattsPerMinute')),
        wattsPerMeter: 7,
        lengthValue: 0,
        resultWatts: 0 ,
        sliderMin: 0,
        sliderMax: 10000,
        sliderStep: 5
      }
    ]

    // Set default unit
    this.selectedDirectFlowUnit = ZVSDirektEinheit.LiterMinute;

    // Get dte info
    this.dteInfoRows = this.aggregatorService.aggregatorData.Tables.DteRows;

    // Load data from project
    this.currentProject = this.activatedRoute.snapshot.data['project'];


    // Calculate result values
    this.calculateResult();
  }

  // Shared Methods
  public setBerechnungstyp(art: ZirkulationsVSBerechnungstyp) : void {
    this.currentBerechnungstyp = art;
    this.calculateResult();
  }
  public setDirectEntryUnit(newUnit: ZVSDirektEinheit) : void {
    // Set new unit
    let oldUnit = this.selectedDirectFlowUnit;
    this.selectedDirectFlowUnit = newUnit;

    // No need to recalculate if the units did not change
    if(oldUnit == newUnit)
      return;

    // Convert entered value and slider range between unit intervals
    switch (newUnit) {
        case ZVSDirektEinheit.LiterSekunde:
            this.maxDirectFlowSlider = 0.5;
            this.maxDirectFlowText = 5;
            this.stepDirectFlow = 0.02;
            this.decimalsDirectFlowText = 2;
            break;
        case ZVSDirektEinheit.LiterMinute:
            this.maxDirectFlowSlider = 30;
            this.maxDirectFlowText = 300;
            this.stepDirectFlow = 1;
            this.decimalsDirectFlowText = 1;
            break;
        case ZVSDirektEinheit.KgMinute:
            this.maxDirectFlowSlider = 30;
            this.maxDirectFlowText = 300;
            this.stepDirectFlow = 1;
            this.decimalsDirectFlowText = 1;
            break;
        case ZVSDirektEinheit.KgStunde:
            this.maxDirectFlowSlider = 1800;
            this.maxDirectFlowText = 18000;
            this.stepDirectFlow = 5;
            this.decimalsDirectFlowText = 0;
            break;
    }

    this.enteredDirectFlow = Math.round(this.convertBetweenUnits(this.enteredDirectFlow, oldUnit, newUnit) * 100) / 100; //TODO Runden entfernen wegen Rundungsfehlern? Eingabe wird unschön -> 123,4123...

    // Calculate result values
    this.calculateResult();
  }

  public calculateResult() : void {

    // Calculate result values
    switch(this.currentBerechnungstyp) {
      case ZirkulationsVSBerechnungstyp.VolumenstromDirekt:
        this.enteredDirectFlow = Number(this.enteredDirectFlow);
        this.calculatedResultFlow = this.convertBetweenUnits(this.enteredDirectFlow, this.selectedDirectFlowUnit, ZVSDirektEinheit.LiterMinute);
        var calc = this.convertBetweenUnits(this.enteredDirectFlow, this.selectedDirectFlowUnit, ZVSDirektEinheit.KgStunde);
        this.calculatedResultWattage = this.circulationFlowMath.verlustleistungFromZirkulationsvolumenstrom(calc) * 1000;

        break;

      case ZirkulationsVSBerechnungstyp.VerlustleistungDirekt:
        this.enteredDirectWattage = Number(this.enteredDirectWattage);
        this.calculatedResultWattage = this.enteredDirectWattage;
        this.calculatedResultFlow = this.circulationFlowMath.zirkulationsvolumenstromFromVerlustleistung(this.enteredDirectWattage / 1000) / 60;
        break;

      case ZirkulationsVSBerechnungstyp.DVGW553:
        let calcResult = this.circulationFlowMath.zirkulationsvolumenstromFromLeitungenDVGW553({
          leitungKellerMeter: Number(this.calculateFromPipesRows[0].lengthValue),
          leitungSchachtMeter: Number(this.calculateFromPipesRows[1].lengthValue),
          leitungNutzbereichMeter: Number(this.calculateFromPipesRows[2].lengthValue)
        });
        this.calculateFromPipesRows[0].resultWatts = calcResult.leitungKellerWatt;
        this.calculateFromPipesRows[1].resultWatts = calcResult.leitungSchachtWatt;
        this.calculateFromPipesRows[2].resultWatts = calcResult.leitungNutzbereichWatt;
        this.calculatedResultWattage = calcResult.leitungKellerWatt + calcResult.leitungSchachtWatt + calcResult.leitungNutzbereichWatt;
        this.calculatedResultFlow = calcResult.zirukulationsvolumenstromLiterStunde / 60;
        break;

      default:
        this.calculatedResultFlow = 0;
        this.calculatedResultWattage = 0;
        break;
    }

    // Determine possible dtes
    this.availableDtesForConfiguredValues = this.circulationFlowMath.compatibleDtes(this.calculatedResultFlow, this.dteInfoRows).map(r => r.Dte);
  }

  // Internal Methods
  private loadValuesFromProject(from: Zirkulationsvolumenstrom) : void {
    // Load result values
    this.calculatedResultFlow = from.ZirkulationsvolumenstromBerechnetLMin;
    this.calculatedResultWattage = from.ZirkulationsverlustleistungBerechnetWatt;

    // Load berechnungstyp specific values
    this.setBerechnungstyp(from.Berechnungstyp);

    switch(from.Berechnungstyp) {
      case ZirkulationsVSBerechnungstyp.VolumenstromDirekt:
        this.setDirectEntryUnit(from.ErmittlungZVSVolumenstromDirekt.Einheit);
        this.enteredDirectFlow = from.ErmittlungZVSVolumenstromDirekt.VolumenstromDirekt;
        break;
      case ZirkulationsVSBerechnungstyp.VerlustleistungDirekt:
        this.enteredDirectWattage = from.ErmittlungZVSVerlustleistungDirekt.VerlustleistungDirekt;
        break;
      case ZirkulationsVSBerechnungstyp.DVGW553:
        this.calculateFromPipesRows[0].lengthValue = from.ErmittlungZVSDVGW553.LeitungKellerMeter;
        this.calculateFromPipesRows[1].lengthValue = from.ErmittlungZVSDVGW553.LeitungSchachtMeter;
        this.calculateFromPipesRows[2].lengthValue = from.ErmittlungZVSDVGW553.LeitungNutzbereichMeter;
        break;
    }
  }

  private convertBetweenUnits(value: number, fromUnit: ZVSDirektEinheit, toUnit: ZVSDirektEinheit) : number {
    // Handle trivial case
    if(fromUnit == toUnit){
      return value;
    }

    // Convert
    switch(fromUnit) {
      case ZVSDirektEinheit.LiterSekunde:
        switch(toUnit) {
          case ZVSDirektEinheit.LiterMinute: return value * 60;
          case ZVSDirektEinheit.KgStunde: return value * CirculationFlowMath.Dichte * 3600;
          case ZVSDirektEinheit.KgMinute: return value * CirculationFlowMath.Dichte * 60;
        }
        break;
      case ZVSDirektEinheit.LiterMinute:
        switch(toUnit) {
          case ZVSDirektEinheit.LiterSekunde: return value / 60;
          case ZVSDirektEinheit.KgStunde: return value * CirculationFlowMath.Dichte * 60;
          case ZVSDirektEinheit.KgMinute: return value * CirculationFlowMath.Dichte;
        }
        break;
      case ZVSDirektEinheit.KgStunde:
        switch(toUnit) {
          case ZVSDirektEinheit.LiterSekunde: return value / CirculationFlowMath.Dichte / 3600;
          case ZVSDirektEinheit.LiterMinute:return value / CirculationFlowMath.Dichte / 60;
          case ZVSDirektEinheit.KgMinute: return value / 60;
        }
        break;
      case ZVSDirektEinheit.KgMinute:
        switch(toUnit) {
          case ZVSDirektEinheit.LiterSekunde: return value / CirculationFlowMath.Dichte / 60;
          case ZVSDirektEinheit.LiterMinute:return value / CirculationFlowMath.Dichte;
          case ZVSDirektEinheit.KgStunde: return value * 60;
        }
        break;
    }

    // Handle error case
    console.error(`Unhandled conversion: ${fromUnit} -> ${toUnit}`);
    return value;
  }

  protected clone(entity: any): any {
    return JSON.parse(JSON.stringify(entity));
  }
  // Button/Click Events
  public navigateNext() : void {
    // Prepare save data object
    let saveData: Zirkulationsvolumenstrom = {
        Berechnungstyp: this.currentBerechnungstyp,
        Ermittlungstyp: ZirkulationsVSErmittlungstyp.None //TODO Kay fragen ???
    };

    // Set berechnungstyp values
    switch(this.currentBerechnungstyp) {
      case ZirkulationsVSBerechnungstyp.VolumenstromDirekt:
        saveData.ErmittlungZVSVolumenstromDirekt = {
            Einheit: this.selectedDirectFlowUnit,
            VolumenstromDirekt: this.enteredDirectFlow
        };
        break;
      case ZirkulationsVSBerechnungstyp.VerlustleistungDirekt:
        saveData.ErmittlungZVSVerlustleistungDirekt = {
            VerlustleistungDirekt: this.enteredDirectWattage
        }
        break;
      case ZirkulationsVSBerechnungstyp.DVGW553:
        saveData.ErmittlungZVSDVGW553 = {
            LeitungKellerMeter: this.calculateFromPipesRows[0].lengthValue,
            LeitungKellerWatt: this.calculateFromPipesRows[0].resultWatts,
            LeitungSchachtMeter: this.calculateFromPipesRows[1].lengthValue,
            LeitungSchachtWatt: this.calculateFromPipesRows[1].resultWatts,
            LeitungNutzbereichMeter: this.calculateFromPipesRows[2].lengthValue,
            LeitungNutzbereichWatt: this.calculateFromPipesRows[2].resultWatts
        }
        break;
    }

    // Set result
    saveData.ZirkulationsvolumenstromBerechnetLMin = this.calculatedResultFlow;
    saveData.ZirkulationsverlustleistungBerechnetWatt = this.calculatedResultWattage;

    if (!this.currentProject.ProjectValues) {
        this.currentProject.ProjectValues = { }
    }

    let changed = true;
    this.calculateResult();
    if(this.currentProject.ProjectValues.Zirkulationsvolumenstrom && this.currentProject.ProjectValues.Zirkulationsvolumenstrom.Berechnungstyp === saveData.Berechnungstyp){
      let projectData = this.currentProject.ProjectValues.Zirkulationsvolumenstrom;
      switch(saveData.Berechnungstyp){
        case(ZirkulationsVSBerechnungstyp.VolumenstromDirekt):
            if(projectData.ErmittlungZVSVolumenstromDirekt.VolumenstromDirekt === saveData.ErmittlungZVSVolumenstromDirekt.VolumenstromDirekt &&
              projectData.ErmittlungZVSVolumenstromDirekt.Einheit === saveData.ErmittlungZVSVolumenstromDirekt.Einheit)
                changed = false;
        break;
        case(ZirkulationsVSBerechnungstyp.VerlustleistungDirekt):
            if(projectData.ErmittlungZVSVerlustleistungDirekt.VerlustleistungDirekt === saveData.ErmittlungZVSVerlustleistungDirekt.VerlustleistungDirekt)
              changed = false;
        break;
        case(ZirkulationsVSBerechnungstyp.DVGW553):
              if(projectData.ErmittlungZVSDVGW553.LeitungKellerMeter === saveData.ErmittlungZVSDVGW553.LeitungKellerMeter &&
                projectData.ErmittlungZVSDVGW553.LeitungNutzbereichMeter === saveData.ErmittlungZVSDVGW553.LeitungNutzbereichMeter &&
                projectData.ErmittlungZVSDVGW553.LeitungSchachtMeter === saveData.ErmittlungZVSDVGW553.LeitungSchachtMeter)
              changed = false;
        break;
    }
    if(!changed){
      this.currentProject.SectionStates.Zirkulationsvolumenstrom = SectionState.Set;
      this.DataService.saveCurrentProject()
                    .then(_ => this.router.navigate([`/project-view/${this.currentProject.ProjectId}`]));
                return;
    }
    }

    this.currentProject.ProjectValues.Zirkulationsvolumenstrom = this.clone(saveData);
    this.currentProject.SectionStates.Zirkulationsvolumenstrom = SectionState.Set;

    if(this.currentProject.SectionStates.Dte == SectionState.Set)
        this.currentProject.SectionStates.Dte = SectionState.Check;

    if (this.currentProject.SectionStates.Rohrauslegung === SectionState.Set) {
        this.currentProject.SectionStates.Rohrauslegung = SectionState.Check;
    }

    if(this.currentProject.SectionStates.Ufc == SectionState.Set)
        this.currentProject.SectionStates.Ufc = SectionState.Check;


    if(this.currentProject.SectionStates.BuildingTypeWarmwasserStates){
        this.currentProject.SectionStates.BuildingTypeWarmwasserStates.forEach(b => {
            if(b.Value == SectionState.Set){
                b.Value = SectionState.Check;
            }
        });
    }

    if (this.currentProject.SectionStates.Energiespeicher === SectionState.Set) {
        this.currentProject.SectionStates.Energiespeicher = SectionState.Check;
    }

    this.DataService.saveCurrentProject()
    .then(_ => this.router.navigate([`/project-view/${this.currentProject.ProjectId}`]));
  }
  public goBack() : void {
    this.router.navigate([`/project-view/${this.currentProject.ProjectId}`]);
  }

  // Display Value Formatters
  public getDisplayResultWattage() : string {
    return this.valueFormatterService.formatNumber(this.calculatedResultWattage, 'Watts', { maximumFractionDigits: 0 });
  }
  public getDisplayResultFlow() : string {
    return this.valueFormatterService.formatNumber(this.calculatedResultFlow, 'LiterPerMinute', { maximumFractionDigits: 1, minimumFractionDigits: 1 });
  }

  // Condition Validators
  public isCurrentBerechnungstyp(art: ZirkulationsVSBerechnungstyp) : boolean {
    return this.currentBerechnungstyp && this.currentBerechnungstyp == art;
  }
  public isSaveValid() : boolean {
    if(!this.currentBerechnungstyp){
      return false;
    }
    return this.isAnyDteAvailable();
  }
  public isAnyDteAvailable() : boolean {
    return this.availableDtesForConfiguredValues?.length > 0;
  }
}
