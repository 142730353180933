import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    HostListener,
    HostBinding,
} from '@angular/core';

@Component({
    selector: 'viega-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
    @Input()
    public linkUrl: string;

    @Input()
    public routerLink: string;

    @Input()
    public imgSrc: string;

    @Input()
    public imgAlt: string;

    @Input()
    public imgOverlayText: string;

    /** 'top' or 'bottom' */
    @Input()
    public cardContentPosition: string;

    @Input()
    public fixedImgHeight: any;

    /**
     * Whether to enable extra buttons and animations for a more advanced touch mode.
     */
    @Input()
    public advancedTouchMode: boolean = false;

    /** Always enabled if advancedTouchMode is true. */
    @Input()
    public displayInfoButton: boolean = false;

    private _touchActivated: boolean = false;

    @Input()
    public get touchActivated(): boolean {
        return this._touchActivated;
    }

    public set touchActivated(newValue: boolean) {
        this._touchActivated = newValue;
        this.isHovering = false;
    }

    @Input()
    public enlargeInfoButton = false;

    @Output()
    public cardClicked = new EventEmitter<MouseEvent>();

    @Output()
    public demandTouchActivation = new EventEmitter<TouchEvent>();

    @Output()
    public selectButtonClicked = new EventEmitter<MouseEvent>();

    @Output()
    public infoButtonClicked = new EventEmitter<MouseEvent>();

    @HostBinding('class.scaled')
    public isScaled: boolean = false;

    public isVerticalImage: boolean = false;

    public isHovering: boolean = false;

    private preventClick: boolean = false;

    constructor() {}

    public ngOnInit(): void {}

    public emitCardClicked(event: MouseEvent) {
        if (this.preventClick) {
            this.preventClick = false;
            return;
        }

        if (this.advancedTouchMode && this.touchActivated)
            this.cardClicked.emit(event);
        else
            this.cardClicked.emit(event);
    }

    public emitSelectButtonClicked(event: MouseEvent) {
        this.selectButtonClicked.emit(event);
        event.stopImmediatePropagation();
    }

    public emitInfoButtonClicked(event: MouseEvent) {
        this.infoButtonClicked.emit(event);
        event.stopImmediatePropagation();
    }

    @HostListener('touchend', ['$event'])
    public onTouchEnd(event: any) {
        this.preventClick = false;

        if (this.advancedTouchMode && !this._touchActivated) {
            const touchEvent = event as TouchEvent; // do not use TouchEvent for the 'event' parameter. it wouldn't work in firefox
            this.demandTouchActivation.emit(event);
            this.preventClick = true; // two touches are required to actually trigger a "click" when in advanced touch mode
            this.isScaled = false;
        }
    }

    public linkMouseDown(event: MouseEvent) {
        this.isScaled = true;
    }

    public linkMouseUp(event: MouseEvent) {
        this.isScaled = false;
    }

    // NOTE: do NOT use :hover to replace isHovering - it will cause issues with the touch handling.
    public linkMouseOver(event: MouseEvent) {
        this.isHovering = true;
    }

    public linkMouseOut(event: MouseEvent) {
        this.isScaled = false;
        this.isHovering = false;
    }

    public linkTouchStart(event: TouchEvent) {
        this.isScaled = true;
    }

    public linkTouchEnd(event: TouchEvent) {
        this.isScaled = false;
    }
}
