<viega-collapsible-panel>
    <div class="panel-content" panel-content>
            <viega-panel-header
                [stepText]="stepText"
                [stepBackLabel]="stepBack"
                [stepTitle]="stepTitle"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>
            <div class="content-wrapper">
                <!-- Top-Wrapper -->
                <div class="top-content-wrapper">
                    <!-- Top -->
                    <div class="top-panel-content">
                            <p class="description-text" *ngIf="stepDescription" >
                                <span [innerHTML]="stepDescription"></span>&nbsp;
                            </p>
                    </div>
                    <!-- Middle -->
                    <div class="mid-panel-content">

                    </div>
                </div>
                <!-- Bot -->
                <div class="bot-panel-content">
                    <div class="result-box">
                        <!-- <span class="result-title">{{'PanelResultHeadline_T' | translate}}</span> -->
                        <!-- <span class="result-content">{{'PanelResultZirkulationsverlustleistung_D' | translate : getZirkuVerlust()}}</span>
                        <span class="result-content">{{'PanelResultZirkulationsvolumenstrom_D' | translate : getZirkuStrom()}}</span> -->
                    </div>

                    <button psp-button
                        (click)="navigateNext()"
                        [disabled]="!checkContinue()"
                        class="e-btn e-small"
                        style="margin-top: 1em; width: 100%; flex-flow: row; justify-content: center;">
                        {{'WeiterBtn_T'| translate}}
                    </button>
                </div>
            </div>
    </div>

    <div class="content" content>
        <div class="text-tooltip-wrapper">
            <span class="container-title">{{'Headline_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.AngabenZumEnergiespeichervolumen"></app-help-icon>
        </div>
            <div>
                <div>
                    <div class="inner-content">
                        <div class="text-tooltip-wrapper">
                            <span style=" font-family: 'viega-bold';">{{'HeadlineWärmeerzeugertypen_T' | translate}}</span>
                            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger"></app-help-icon>
                        </div>
                        <div class="data-table">
                            <ejs-grid #grid [dataSource]="gridData" [allowSorting]="false" [allowTextWrap]="true">
                                <e-columns>
                                    <e-column headerText="{{'Table.HeadlineErzeuger_T' | translate}}" textAlign="left">
                                        <ng-template #template let-data>
                                            <div class="name-cell">
                                                <span>Typ {{showIndex(data.index)}}</span>
                                                <span style="white-space: break-spaces;">{{getErzeugerDisplayName(data.ErzeugerArt)}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column headerText="{{'Table.HeadlineLeistung_T' | translate}}" textAlign="left">
                                        <ng-template #template let-data>
                                            <span>{{data.LeistungWarmwasserBereitungKw}} kW</span>
                                        </ng-template>
                                    </e-column>
                                    <e-column headerText="{{'Table.HeadlineVerzögerung_T' | translate}}" textAlign="left">
                                        <ng-template #template let-data>
                                            <span>{{data.VerzoegerungNachheizungMin}} min</span>
                                        </ng-template>
                                    </e-column>
                                    <e-column >
                                        <ng-template #template let-data>
                                            <div class="command-cell">
                                                <span class="icon-copy" (click)="copyHeatType(data)"></span>
                                                <span class="icon-edit-pencil" (click)="editHeatType(data)"></span>
                                                <span class="icon-x-mark" (click)="deleteHeatType(data)"></span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                </e-columns>
                            </ejs-grid>
                            <button psp-button class="e-btn e-small" style="margin-top: 1.5em;" (click)="openAddDataDialog()" [disabled]="gridData.length > 3">{{'HinzufuegenBtn_T' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="panel-values">
                <div class="text-tooltip-wrapper">
                    <span class="titletext">{{'HeadlineEnergielabel_T' | translate}}</span>
                    <app-help-icon hinweisId="Speicher.Energielabel"></app-help-icon>
                </div>
                <div class="panel-object" *ngFor="let item of energyLabels">
                    <ejs-radiobutton class="radio-button" #radio5 label="" name="option2" style="white-space: nowrap;"
                        [checked]="checkOptions(item.EfficiencyCategoryId)"
                        (change)='changeOption(item.EfficiencyCategoryId)'
                        ></ejs-radiobutton>
                    <img [src]="item.DisplayImageUrl" style="margin-left: 2em; margin-top: 0.3em; max-height: 2em;" >
                </div>

            </div>
    </div>
</viega-collapsible-panel>




<ejs-dialog #addDataDialog (overlayClick)="onOverlayClick()" (close)="resetTempVars()" [buttons]='addDataButtons' [header]='addOrEditDataDialogHeader' [isModal]=true [closeOnEscape]='false' [visible]='false' [showCloseIcon]='true' [width]='addOrEditDataDialogWidth' [position]='dialogPosition'>
    <ng-template #header>
        <div class="text-tooltip-wrapper text-tooltip-wrapper-header">
            <span>{{addOrEditDataDialogHeader}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Typ"></app-help-icon>
        </div>
    </ng-template>
    <div class="dialog-content-wrapper">
        <div class="text-tooltip-wrapper">
            <span class="form-fields">{{'AddDataDialog.HeadlineDropdown_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Art"></app-help-icon>
        </div>
        <div class="type-wrapper">
            <ejs-dropdownlist class="dropdown" #typeDropdownAdd placeholder="Bitte auswählen..."
            [dataSource]="heatGeneratorData" [fields]="fields" (change)="onChangeDropdown($event)" ></ejs-dropdownlist>
        </div>
        <div class="text-tooltip-wrapper">
            <span class="form-fields">{{'AddDataDialog.HeadlineSliderLeistung_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Leistung"></app-help-icon>
        </div>
        <div class="power-wrapper">
            <app-slider-text-combo
            class="slider-text-combo"
            style="margin-left: 0em;"
                                            [sliderMin]="0"
                                            [sliderMax]="500"
                                            [sliderStep]="1"
                                            [textboxMin]="0"
                                            [textboxMax]="500"
                                            [textboxStep]="1"
                                            [textboxDecimals]="2"
                                            [textboxClass]="'textbox-wrapper'"
                                            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                            [showLabel]='false'
                                            [(value)]='_power'
                                        ></app-slider-text-combo>
            <!--<div class="slider-wrapper">
                <ejs-slider  min=0 max=500  [(value)]="_power">

                </ejs-slider>
            </div>
            <div class="textbox-wrapper">
                <ejs-textbox class="slider-input" type="number" min=0 max=500 [(value)]="_power">

                </ejs-textbox>
            </div>-->
            <span style="margin-left: 2em;">{{'#Generic.Unit.KiloWatts_T' | translate}}</span>
        </div>
        <div class="text-tooltip-wrapper">
            <span class="form-fields">{{'AddDataDialog.HeadlineSliderVerzoegerung_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Verzögerung"></app-help-icon>
        </div>
        <div class="delay-wrapper">
            <app-slider-text-combo
            class="slider-text-combo"
                    style="margin-left: 0em;"
            [sliderMin]="0"
            [sliderMax]="500"
            [sliderStep]="1"
            [textboxMin]="0"
            [textboxMax]="500"
            [textboxStep]="1"
            [textboxDecimals]="2"
            [textboxClass]="'textbox-wrapper'"
            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
            [showLabel]='false'
            [(value)]='_delay'
        ></app-slider-text-combo>
            <!--<div class="slider-wrapper">
                <ejs-slider min=0 max=200 [(value)]="_delay">

                </ejs-slider>
            </div>
            <div class="textbox-wrapper">
                <ejs-textbox class="slider-input" type="number" min=0 max=200 [(value)]="_delay">

                </ejs-textbox>
            </div>-->
            <span style="margin-left: 2em;">{{'#Generic.Unit.Minutes_T' | translate}}</span>
        </div>
    </div>
</ejs-dialog>


<ejs-dialog #editDataDialog (overlayClick)="onOverlayClick()" (close)="resetTempVars()" [buttons]='editDataButtons' [header]='addOrEditDataDialogHeader' [isModal]=true [closeOnEscape]='false' [visible]='false' [showCloseIcon]='true' [width]='addOrEditDataDialogWidth' [position]='dialogPosition'>
    <ng-template #header>
        <div class="text-tooltip-wrapper text-tooltip-wrapper-header">
            <span>{{addOrEditDataDialogHeader}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Typ"></app-help-icon>
        </div>
    </ng-template>
    <div class="dialog-content-wrapper">
        <div class="text-tooltip-wrapper">
            <span>{{'EditDataDialog.HeadlineDropdown_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Art"></app-help-icon>
        </div>
        <div class="type-wrapper">
            <ejs-dropdownlist
                #typeDropdownEdit
                class="dropdown"
                placeholder="Bitte auswählen..."
                [dataSource]="heatGeneratorData"
                [fields]="fields"
                (change)="onChangeDropdown($event)"
            ></ejs-dropdownlist>
        </div>
        <div class="text-tooltip-wrapper">
            <span>{{'EditDataDialog.HeadlineSliderLeistung_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Leistung"></app-help-icon>
        </div>
        <div class="power-wrapper">
            <app-slider-text-combo
            class="slider-text-combo"
                    style="margin-left: 0em;"
                                            [sliderMin]="0"
                                            [sliderMax]="500"
                                            [sliderStep]="1"
                                            [textboxMin]="0"
                                            [textboxMax]="500"
                                            [textboxStep]="1"
                                            [textboxDecimals]="2"
                                            [textboxClass]="'slider-input'"
                                            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                            [showLabel]='false'
                                            [(value)]='_editPower'
                                        ></app-slider-text-combo>
            <!--<div class="slider-wrapper">
                <ejs-slider min=0 max=500 [(value)]="_editPower">

                </ejs-slider>
            </div>
            <div class="textbox-wrapper">
                <ejs-textbox class="slider-input" type="number" min=0 max=500 [(value)]="_editPower">

                </ejs-textbox>
            </div>-->
            <span style="margin-left: 2em;">{{'#Generic.Unit.KiloWatts_T' | translate}}</span>
        </div>
        <div class="text-tooltip-wrapper">
            <span>{{'EditDataDialog.HeadlineSliderVerzoegerung_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.Detail.Wärmeerzeuger.Verzögerung"></app-help-icon>
        </div>
        <div class="delay-wrapper">
            <app-slider-text-combo
            class="slider-text-combo"
                    style="margin-left: 0em;"
            [sliderMin]="0"
            [sliderMax]="500"
            [sliderStep]="1"
            [textboxMin]="0"
            [textboxMax]="500"
            [textboxStep]="1"
            [textboxDecimals]="0"
            [textboxClass]="'slider-input'"
            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
            [showLabel]='false'
            [(value)]='_editDelay'
        ></app-slider-text-combo>
            <!--<div class="slider-wrapper">
                <ejs-slider min=0 max=200 [(value)]="_editDelay">

                </ejs-slider>
            </div>
            <div class="textbox-wrapper">
                <ejs-textbox class="slider-input" type="number" min=0 max=200 [(value)]="_editDelay">

                </ejs-textbox>
            </div>-->
            <span style="margin-left: 2em;">{{'#Generic.Unit.Minutes_T' | translate}}</span>
        </div>
    </div>
</ejs-dialog>


<ejs-dialog #delete target='app-heat-generating-types' header="{{'DeleteDialog.Headline_T' | translate}}" [buttons]='deleteButtons' [visible]='false' [isModal]=true [closeOnEscape]='false' [showCloseIcon]='true' [width]='deleteDialogWidth' [position]='dialogPosition'>
    <ng-template #content>
        <div class="error-content">
            <p>{{'DeleteDialog.Description_SD' | translate}}</p>
        </div>
    </ng-template>
</ejs-dialog>

<ejs-dialog #errorRequiredDialog [target]='dialogTarget' [header]='errorDialogHeader' [isModal]=true [closeOnEscape]='false' [visible]='false' [showCloseIcon]='true' [width]='errorRequiredDialogWidth' [position]='dialogPosition' >
    <ng-template #content>
        <div class="error-content">
            <p>{{'ErrorRequiredDialog.Description_SD' | translate}}</p>
        </div>
    </ng-template>
</ejs-dialog>

<ejs-dialog #errorMaxTypesDialog [target]='dialogTarget' [header]='errorDialogHeader' [isModal]=true [closeOnEscape]='false' [visible]='false' [showCloseIcon]='true' [width]='errorMaxTypesDialogWidth' [position]='dialogPosition' >
    <ng-template #content>
        <div class="error-content">
            <p>{{'ErrorMaxTypesDialog.DescriptionNichtMehr_SD' | translate}}</p>

            <p>{{'ErrorMaxTypesDialog.DescriptionBitteLoeschen_D' | translate}}</p>
        </div>
    </ng-template>
</ejs-dialog>