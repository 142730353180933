<div class="wrapper">
    <div class="panel">
        <header>
            <h1>
                {{headlineText}}
            </h1>
            <p class="mobile-hidden">
                {{subHeadlineText}}
            </p>
        </header>

        <div class="content">
            <div class="panel-content mobile-hidden">
                <ng-content select="[panel]"></ng-content>
            </div>

            <div class="panel-content desktop-hidden">
                <ng-content select="[panel-mobile]"></ng-content>
            </div>
        </div>
    </div>

    <div class="background-image" [style.background-image]="'url(' + backgroundUrl + ')'"></div>
</div>
