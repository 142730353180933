import { Injectable } from '@angular/core';
import { Guid } from '@syncfusion/ej2-pdf-export/src/implementation/graphics/pdf-resources';
import { ProjectEntity } from './BackendApi/projectentity.entity';
import { ProjectMetadataEntity } from './BackendApi/projectmetadataentity.entity';
import { IProjectData } from './project-interfaces';
import { ProjectService } from './ProjectService';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {
  // Variables
  private _tempProject: ProjectEntity;
  public get TempProject(): ProjectEntity { return this._tempProject; }

  // Constructors/Initializers
  constructor(private ProjectService: ProjectService){}

  // Methods
  public async listProjects() : Promise<ProjectMetadataEntity[]> {
    return await this.ProjectService.listProjectMetadata();
  }
  public async selectProject(projectId: string): Promise<ProjectEntity> {
    if(this.ProjectService.currentProject && this.ProjectService.currentProject.ProjectId == projectId)  {
      return this.ProjectService.currentProject;
    } else {
      return await this.ProjectService.openProject(projectId);
    }
  }
  public getCurrentProject(): ProjectEntity {
    return this.ProjectService.currentProject;
  }
  public async addAndSelectNewProject(data: ProjectEntity): Promise<ProjectEntity> {
    return await this.ProjectService.addNewProject(data);
  }
  public async saveCurrentProject() : Promise<ProjectEntity> {
    this.ProjectService.currentProject.LastUpdatedUtc = new Date();
    return await this.ProjectService.saveProject();
  }
  public async deleteProject(projectId: string): Promise<void> {
    return await this.ProjectService.deleteProject(projectId);
  }
  public async cloneProject(projectId: string): Promise<ProjectEntity> {
    let toClone = await this.ProjectService.openProject(projectId);
    var copy = JSON.parse(JSON.stringify(toClone)) as ProjectEntity;
    copy.ProjectName = 'Kopie von ' + toClone.ProjectName;
    copy.CreatedUtc = new Date();
    copy.ProjectId = this.generateProjectId();
    return await this.ProjectService.addNewProject(copy);
  }
  public generateProjectId(): string {
    return Guid.getNewGuidString();
  }

  public setTempProject(project: ProjectEntity): void {
    this._tempProject = JSON.parse(JSON.stringify(project));
  }
  public saveTempProject(): Promise<ProjectEntity> {
    if(this.TempProject != null){
      return this.ProjectService.saveTempProject(this.TempProject);
    }
  }
  public resetTempProject(): void {
    this._tempProject = null;
  }
}
