<ejs-dialog
    #editDialog
    (overlayClick)="onDialogBackdropClick()"
    width='50%'
    [header]="getEditDialogHeader()"
    [isModal]='true'
    [closeOnEscape]='false'
    [visible]='false'
    [showCloseIcon]='true'
>
    <ng-template #content>
        <div class="base-data" [formGroup]="formFields.group">
            <div style="display: flex;">
                <div class="left-side form-fields" style="margin-top: 0.5em;">
                    <div style="margin-top: 0.8em;">
                        <psp-form-field *localVar="formFields.fieldMap['Name'] as field" [formField]="field">
                            <ejs-textbox
                                type="text"
                                [formControl]="field.control"
                                [(value)]="currentElement.Bezeichnung"
                                style="padding-top: 0.5em"
                            ></ejs-textbox>
                        </psp-form-field>
                    </div>
                </div>
                <div class="right-side">
                    <div class="right-side-slider form-fields" style="align-items: flex-start;">
                        <div style="width: 100%; margin-top: 1em;">
                            <psp-form-field *localVar="formFields.fieldMap['groupAmountLabel'] as field" [formField]="field" [reserveLabelSpace]="false">
                                <ejs-slider
                                    #option2
                                    [min]=0
                                    [max]=100
                                    [step]=1
                                    [(value)]='sliderValue'
                                    (change)="onSliderChanged($event)"
                                ></ejs-slider>
                            </psp-form-field>
                        </div>
                        <psp-form-field *localVar="formFields.fieldMap['groupAmount'] as field" [formField]="field" style="margin-top: 3em;">
                            <ejs-numerictextbox
                                class="slider-text-combo slider-input"
                                style="width:9em; color:black;margin-left:1em;"
                                [strictMode]="true"
                                [decimals]="0"
                                [validateDecimalOnType]="true"
                                floatLabelType="Never"
                                [validateDecimalOnType]="true"
                                [formControl]="field.control"
                                format="#,###.##"
                                [min]=0
                                [max]=999
                                [step]=1
                                floatLabelType="Never"
                                [(value)]='textboxValue'
                                (blur)="onTextboxBlur($event)"
                                (change)="onTextboxChanged($event)"
                            ></ejs-numerictextbox>
                        </psp-form-field>
                    </div>
                </div>
            </div>
            <div class="mid-row form-fields yellow-effect">
                <div class="text-tooltip-wrapper kleinwohnung">
                    <psp-form-field *localVar="formFields.fieldMap['groupChecked'] as field" [formField]="field">
                        <ejs-checkbox
                            #livingGroupCheck^
                            [formControl]="field.control"
                            label="{{'FormFieldCheckBox1und2Zimmer_T' | translate}}"
                            [(checked)]="currentElement.IsKleinwohnungen"
                            (change)="calculateMinBelegungszahl()"
                        ></ejs-checkbox>
                    </psp-form-field>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude.Wohnungsgruppen.Überwiegend1u2Zimmerwohnungen"></app-help-icon>
                </div>
            </div>
            <div style="display: flex;" class="form-fields">
                <div class="left-side">
                    <div class="left-side-slider">
                        <psp-form-field *localVar="formFields.fieldMap['roomAmount'] as field" [formField]="field">
                        <app-slider-text-combo
                        class="slider-text"
                                    [sliderMin]="0"
                                    [sliderMax]="maxDefinedRaumZahl"
                                    [sliderStep]="0.5"
                                    [textboxMin]="0"
                                    [textboxMax]="100"
                                    [textboxStep]="0.5"
                                    [textboxDecimals]="1"
                                    [textboxClass]="'slider-input dialog-textbox'"
                                    [showLabel]='false'
                                    [(value)]='currentElement.Raumzahl'
                                    (valueChange)="raumzahlChanged()"
                                ></app-slider-text-combo>
                            </psp-form-field>
                    </div>
                </div>
                <div class="right-side">
                    <div class="right-side-slider" style="max-height: 4em;
                    margin-top: 1.3em;">
                    <psp-form-field *localVar="formFields.fieldMap['belegAmount'] as field" [formField]="field">
                        <app-slider-text-combo
                        class="slider-text"
                        [sliderMin]="calculatedMin"
                        [sliderMax]="10"
                        [sliderStep]="0.1"
                        [textboxMin]="calculatedMin"
                        [textboxMax]="100"
                        [textboxStep]="0.1"
                        [textboxDecimals]="1"
                        [textboxClass]="'slider-input dialog-textbox'"
                        [showLabel]='false'
                        [(value)]='currentElement.Belegungszahl'
                    ></app-slider-text-combo>
                </psp-form-field>
                    </div>
                    <div class="right-side-slider"><span class="e-label">{{'ExaktTypRadio.DialogBelegungszahlDin_T' | translate: getCalculatedMin()}}</span></div>
                </div>
            </div>
            <div class="mid-row form-fields">
                <div>
                    <psp-form-field *localVar="formFields.fieldMap['groupEquipment'] as field" [formField]="field">
                        <ejs-multiselect
                            #livingGroupEquipmentDropdown
                            maximumSelectionLength="15"
                            [formControl]="field.control"
                            [(value)]="currentElementAusstattungsIDs"
                            [fields]="austattungFields"
                            [dataSource]='availableErstbadFacilities'
                        ></ejs-multiselect>
                    </psp-form-field>
                </div>
            </div>
            <div class="mid-row form-fields">
                <div>
                    <psp-form-field *localVar="formFields.fieldMap['groupSecondBath'] as field" [formField]="field">
                        <ejs-dropdownlist
                            class="dropdown"
                            #livingGroupSecond
                            [formControl]="field.control"
                            [(value)]="currentElement.ZweitBadTyp"
                            [fields]="zweitbadTypesFields"
                            [dataSource]='zweitbadTypes'
                            (select)="onZweitbadTypChanged()"
                        ></ejs-dropdownlist>
                    </psp-form-field>
                </div>
                <div [hidden]="!isZweitbadConfigured()">
                        <ejs-multiselect
                            #livingGroupEquipmentSecondBathDropDown
                            maximumSelectionLength="15"
                            [(value)]="currentElementZweitbadAusstattungsIDs"
                            [fields]="austattungFields"
                            [dataSource]='availableZweitbadFacilities'
                        ></ejs-multiselect>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="e-footer-content">
        <button
            class="e-control e-btn e-lib e-primary e-flat"
            style="margin-top: 1em; margin-bottom: 1em;"
            (click)="onEditDialogSave()"
        >
            {{'ExaktTypRadio.DialogButtonSpeichern_T' | translate}}
        </button>
    </div>
</ejs-dialog>

<!-- Invalid data dialog -->
<ejs-dialog
    #invalidFieldsDialog
    target='app-demand-volumetric-flow-residential'
    [buttons]='invalidFieldsDialogButtons'
    header="{{'ExaktTypRadio.InvalidDialogHeader_T' | translate}}"
    [visible]='false'
    [isModal]='true'
    [closeOnEscape]='false'
    [showCloseIcon]='true'
    width='50em'
>
    <ng-template #content>
        <div class="row">
            <span>{{'ExaktTypRadio.InvalidDialogContent_SD' | translate}}</span>
        </div>
    </ng-template>
</ejs-dialog>