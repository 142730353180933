import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-structure-item',
  templateUrl: './structure-item.component.html',
  styleUrls: ['./structure-item.component.scss']
})
export class StructureItemComponent implements OnInit {

    @Input() itemTitle: string;
    @Input() imgSrc: string;
    @Input() itemChecked: boolean;

    @Output() stateChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }


  onChange(){
      this.stateChanged.emit();
  }

  onClick(){
    this.itemChecked = !this.itemChecked;
    this.onChange();
  }

}
