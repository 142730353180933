<nav class="desktop">
    <ul>
        <li
            *ngFor="let segment of segments; let i = index"
            [class.active]="i == activeIndex"
            [class.current]="i <= currentIndex"
            [class.hover]="
                i > currentIndex && i <= hoverIndex && i < skipAllowedIndex
            "
            [class.allow-click]="i <= skipAllowedIndex"
        >
            <div class="line" [class.allow-click]="i <= skipAllowedIndex"></div>

            <a
                (click)="segment_click(segment, i)"
                (mouseover)="segment_mouseover(i)"
                (mouseout)="segment_mouseout(i)"
                tabindex="1"
            >
                <div class="label">
                    <span>{{segment.label}}</span>
                </div>
                <span class="dot"></span>
            </a>
        </li>
    </ul>
</nav>

<div class="mobile">
    <div class="mobile-step-bar" *ngIf="isNumber(activeIndex)">
        <!-- <viega-icon-button
            icon="left_thin-01"
            [disabled]="activeIndex === 0"
            (click)="step_back()"
        ></viega-icon-button>
        <h4>{{stepLabelMobile | uppercase}} {{activeIndex + 1}}</h4>
        <viega-icon-button
            icon="right_thin-01"
            [disabled]="currentIndex <= activeIndex"
            (click)="step_forward()"
        ></viega-icon-button> -->
    </div>
</div>
