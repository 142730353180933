import { log } from 'src/core-lib/logging';

/**
 * To enable the logger type this in the dev console of the browser:
 * mathLogging = true
 */
export class MathLogger
{
    private static instanceCounter: number = 1;

    private readonly instanceNo: number;

    public static get isLoggingEnabled(): boolean
    {
        return window['mathLogging'] === true;
    }

    public constructor(private calculationName: string)
    {
        this.instanceNo = MathLogger.instanceCounter++;
    }

    public logInputs(value: any)
    {
        if (MathLogger.isLoggingEnabled)
            log.verbose(value, `[CALC ${this.calculationName} ${this.instanceNo}] Inputs`);
    }

    public logIntermediateResult(value: any)
    {
        if (MathLogger.isLoggingEnabled)
            log.verbose(value, `[CALC ${this.calculationName} ${this.instanceNo}] Intermediate Results`);
    }

    public logResult(value: any)
    {
        if (MathLogger.isLoggingEnabled)
            log.verbose(value, `[CALC ${this.calculationName} ${this.instanceNo}] Results`);
    }

    public logWarning(message: string)
    {
        if (MathLogger.isLoggingEnabled)
            log.warning(`[CALC ${this.calculationName} ${message}]`);
    }
}
