import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { ComponentBase, ComponentMixins, IComponentBase, setValue, Template } from 'src/core-lib/angular/util';
import { events } from 'src/core-lib/ej2/components/search/psp-search-box/events';
import { PspSearchBox } from 'src/core-lib/ej2/components/search/psp-search-box/PspSearchBox';

// properties declared here can be changed by the user in the storyboard UI
export const propertyArgTypes =
{
    theme: { control: { type: 'radio', options: ['light', 'dark'] } },
    placeholder: { control: 'text' },
    minLength: { control: 'number' },
    showPopupButton: { control: 'boolean' },
    showClearButton: { control: 'boolean' },
    openOnFocus: { control: 'boolean' },
    startSearchIconCss: { control: 'text' },
    enableAutocomplete: { control: 'boolean' },
    width: { control: 'text' },

    popupHeight: { control: 'text' },
    popupWidth: { control: 'text' },
    zIndex: { control: 'number' },

    readonly: { control: 'boolean' },
    enabled: { control: 'boolean' },
    text: { control: 'text' },
    value: { control: 'text' },

    allowFiltering: { control: 'boolean' },
    filterType: { control: { type: 'radio', options: ['StartsWith', 'EndsWith', 'Contains'] } },
    autofill: { control: 'boolean' },
    highlight: { control: 'boolean' },
    suggestionCount: { control: 'number' },
    ignoreCase: { control: 'boolean' },
    sortOrder: { control: { type: 'radio', options: ['None', 'Ascending', 'Descending'] } },
    ignoreAccent: { control: 'boolean' },
    enableRtl: { control: 'boolean' },
};

// prevent storybook from updating these properties
const changeIgnoredProperties: string[] = [];

// declare some properties as bindable angular outputs
const outputBindings =
[
    'startSearchEvent',
    'changeEvent',
    'beforeOpenEvent',
    'openEvent',
    'closeEvent',
    'blurEvent',
    'focusEvent',
    'actionBeginEvent',
    'actionCompletedEvent',
    'actionFailureEvent',
    'selectEvent',
    'dataBoundEvent',
    'filteringEvent',
    'createdEvent',
    'destroyedEvent',
];

@Component({
    selector: 'psp-search-box',
    template: '',
    inputs:
    [
        'quickSearchDataSource',
        'startSearchIconCss',
        'enableAutocomplete',
        'fields',
        'headerTemplate',
        'itemTemplate',
        'footerTemplate',
        'groupTemplate',
        'noRecordsTemplate',
        'actionFailureTemplate',
        'query',
        'width',
        'cssClass',
        'theme',
        'placeholder',
        'minLength',
        'showPopupButton',
        'showClearButton',
        'openOnFocus',
        'width',
        'popupHeight',
        'popupWidth',
        'zIndex',
        'readonly',
        'enabled',
        'text',
        'value',
        'allowFiltering',
        'filterType',
        'autofill',
        'highlight',
        'suggestionCount',
        'ignoreCase',
        'sortOrder',
        'ignoreAccent',
        'enableRtl',
    ],
    outputs: outputBindings,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@ComponentMixins([ComponentBase])
export class PspSearchBoxComponent extends PspSearchBox implements IComponentBase, OnInit, OnDestroy, AfterViewInit, AfterContentChecked
{
    /** @ignore */
    public tagObjects: any = [];

    /** @ignore */
    public context : any;

    /** @ignore */
    public tags: string[] = [];

    /**
     * Accepts the template design and assigns it to the footer container of the popup list.
     * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
     * @default null
     * @deprecated
     */
    @ContentChild('footerTemplate')
    @Template()
    public footerTemplate: any;

    /**
     * Accepts the template design and assigns it to the header container of the popup list.
     * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
     * @default null
     */
    @ContentChild('headerTemplate')
    @Template()
    public headerTemplate: any;

    /**
     * Accepts the template design and assigns it to the group headers present in the popup list.
     * @default null
     */
    @ContentChild('groupTemplate')
    @Template()
    public groupTemplate: any;

    /**
     * Accepts the template design and assigns it to each list item present in the popup.
     * We have built-in `template engine`
     *
     * which provides options to compile template string into a executable function.
     * For EX: We have expression evolution as like ES6 expression string literals.
     * @default null
     */
    @ContentChild('itemTemplate')
    @Template()
    public itemTemplate: any;

    @ContentChild('noRecordsTemplate')
    @Template('No records found')
    public noRecordsTemplate: any;

    @ContentChild('actionFailureTemplate')
    @Template('Request failed')
    public actionFailureTemplate: any;

    protected changeIgnoredPropertyMap: { [propertyName: string]: boolean };

    public constructor(
        private ngEle: ElementRef,
        private srenderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    )
    {
        super();

        this.element = this.ngEle.nativeElement;

        this.changeIgnoredPropertyMap = {};
        changeIgnoredProperties.forEach(prop => this.changeIgnoredPropertyMap[prop] = true);

        this.registerEvents(outputBindings);
        this.addTwoWay.call(this, ['value', 'text']);
        setValue('currentInstance', this, viewContainerRef);
        this.context  = new ComponentBase();
    }

    /** @ignore */
    public ngOnInit(): void
    {
        this.context.ngOnInit(this);
    }

    /** @ignore */
    public ngAfterViewInit(): void
    {
        this.context.ngAfterViewInit(this);
    }

    /** @ignore */
    public ngOnDestroy(): void
    {
        this.context.ngOnDestroy(this);
    }

    /** @ignore */
    public ngAfterContentChecked(): void
    {
        this.context.ngAfterContentChecked(this);
    }

    /** @ignore */
    public registerEvents: (eventList: string[]) => void;

    /** @ignore */
    public addTwoWay: (propList: string[]) => void;
}
