import { NgModule } from '@angular/core';
import { VarDirective } from './directives/local-var/loval-var.directive';
import { StringReplacePipe } from './pipes/string-replace.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';

@NgModule({
    declarations:
    [
        TrustHtmlPipe,
        StringReplacePipe,
        TranslatePipe,
        VarDirective,
    ],
    imports: [],
    providers: [],
    exports:
    [
        StringReplacePipe,
        TrustHtmlPipe,
        TranslatePipe,
        VarDirective,
    ],
})
export class CoreLibModule {}
