import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RuntimeService } from 'src/core-lib/ej2/services/RuntimeService';
import { AppShellClientService } from 'src/core-lib/ej2/services/shell-services/AppShellClientService';
import { AppActivity, IAppState } from 'src/core-lib/ej2/services/shell-services/IAppState';
import { IChangeRoutePayload } from 'src/core-lib/ej2/services/shell-services/shell-messages/IChangeRoutePayload';
import { IPermissionsChangedMessage } from 'src/core-lib/ej2/services/shell-services/shell-messages/IPermissionsChangedPayload';
import { IRequestClosePayload } from 'src/core-lib/ej2/services/shell-services/shell-messages/IRequestClosePayload';
import { IShellMessage } from 'src/core-lib/ej2/services/shell-services/shell-messages/IShellMessage';
import { log } from 'src/core-lib/logging';
import { IChangeRouteArgs } from './IChangeRouteArgs';
import { IRequestCloseArgs } from './IRequestCloseArgs';

@Injectable()
export class AppStateService
{
    private _currentState: IAppState;

    public get currentState(): IAppState
    {
        return JSON.parse(JSON.stringify(this._currentState));
    }

    public get isBusy(): boolean
    {
        return this._currentState.activity === 'busy';
    }

    public get isUnsaved(): boolean
    {
        return this._currentState.containsUnsavedChanges;
    }

    /**
     * Produces an event if the app shell is attempting to close the application.
     */
    public appClosingObservable: Subject<IRequestCloseArgs>;

    /**
     * Produces an event if the app shell is gracefully shutting the application down.
     * Perform cleanup / final storage operations here.
     */
    public appShutdownObservable: Subject<void>;

    public requestChangeRouteObservable: Subject<IChangeRouteArgs>;

    constructor(
        private shellService: AppShellClientService,
        private router: Router,
        private runtimeService: RuntimeService
    )
    {
        this.appClosingObservable = new Subject();
        this.appShutdownObservable = new Subject();

        this.shellService.hostMessagesObservable
            .subscribe((shellMsg: IShellMessage) =>
            {
                if (shellMsg.type === 'PspRequestClose')
                    this.handleRequestClose(shellMsg.payload);
                else if (shellMsg.type === 'PspChangeRoute')
                    this.handleChangeRoute(shellMsg.payload);
                else if (shellMsg.type === 'PspPermissionsChanged')
                    this.handlePermissionChange(shellMsg.payload);
            });
    }

    private handleChangeRoute(args: IChangeRoutePayload)
    {
        log.verbose('Navigating to a different route as requested by application shell.');
        const subjectArgs: IChangeRouteArgs =
        {
            targetRoute: args.targetRoute,
            cancel: false,
        };
        this.requestChangeRouteObservable.next(subjectArgs);

        if (!subjectArgs.cancel)
            this.router.navigate([args.targetRoute], { queryParamsHandling: 'merge' });
    }

    private handleRequestClose(args: IRequestClosePayload)
    {
        const subjectArgs: IRequestCloseArgs =
        {
            cancel: false,
            isForce: args.isForce,
        };
        this.appClosingObservable.next(subjectArgs);
        if (!subjectArgs.cancel || subjectArgs.isForce)
            this.closeSelf();
    }

    private handlePermissionChange(args: IPermissionsChangedMessage)
    {
        log.verbose('Refreshing runtime info as request by application shell.');
        this.runtimeService.refreshRuntimeInfo();
    }

    /**
     * Sets whether the app should visually be represented in a busy state by the application shell its hosted in, if available.
     */
    public setBusyState(isBusy: boolean): void
    {
        const newActivity: AppActivity = isBusy ? 'busy' : 'default';
        log.verbose(`Requesting the application shell to set activity status to ${newActivity}.`);
        this.shellService.updateAppState({ activity: newActivity });
        this.currentState.activity = newActivity;
    }

    /**
     * Sets whether the app should visually be represented in an unsaved state by the application shell its hosted in, if available.
     */
    public setUnsavedState(isUnsaved: boolean): void
    {
        log.information(`Requesting the application shell to set the containsUnsavedChanges flag to ${isUnsaved}.`);
        this.shellService.updateAppState({ containsUnsavedChanges: isUnsaved });
        this.currentState.containsUnsavedChanges = isUnsaved;
    }

    /**
     * Requests the app shell to close this application.
     * When this method is called, the isUnsaved state will be ignored and the appClosingObservable will not emit.
     */
    public closeSelf(): void
    {
        log.verbose('Requesting the application shell to close this app.');

        // allow the app to perform a graceful shutdown first
        this.appShutdownObservable.next();
        this.shellService.closeSelf();
    }

    /**
     * Sets the current name of the application tab in the application shell.
     */
    public setTabName(newTabName: string): void
    {
        log.verbose(`Requesting the application shell to set the tab name of this app to "${newTabName}".`);

        this.shellService.updateAppState({ tabName: newTabName });
        this.currentState.tabName = newTabName;
    }
}
