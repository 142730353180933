<viega-collapsible-panel>
    <div class="panel-content" panel-content>

            <viega-panel-header
                stepText="&nbsp;"
                [stepTitle]="stepTitle"
                [stepBackLabel]="stepBack"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>
            <div class="content-wrapper">
                <!-- Top-Wrapper -->
                <div class="top-content-wrapper">
                    <!-- Top -->
                    <div class="top-panel-content">
                        <p class="description-text" *ngIf="stepDescription" >
                            <span [innerHTML]="stepDescription"></span>&nbsp;
                        </p>
                    </div>
                    <!-- Middle -->
                    <div class="mid-panel-content">
                        <div class="panel-content" style="display: flex; flex-direction: column">
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'Temperatur_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getMinPwhTemperatur()}}</span>
                                </div>
                            </div>
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'Bedarfsvolumenstrom_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getBedarfsvolumenstrom()}}</span>
                                </div>
                            </div>

                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>
                                        {{'BenoetigteVorlauftemperatur_T' | translate}}
                                    </span>
                                </div>
                                <div class="value-wrapper">
                                    <span>
                                        {{getVorlaufErgebnis()}}
                                    </span>
                                </div>
                            </div>

                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'DruckverlustStrecke_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span *ngIf="selectedDTE">{{getDruckverlustStrecke()}}</span>
                                    <span *ngIf="!selectedDTE">-</span>
                                </div>
                            </div>
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'DruckverlustGesamt_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span *ngIf="selectedDTE">{{getDruckverlustGesamt()}}</span>
                                    <span *ngIf="!selectedDTE">-</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Bot -->
                <div class="bot-panel-content">
                    <div class="error-message" *ngIf='!selectedDTE'>
                        <i class="icon-warning1"></i><p class="error-text">
                            {{'ErrorGeringeVorlauftemperatur_D' | translate}}</p>
                    </div>
                    <div class="result-box">
                        <span class="result-title">{{'Result_T' | translate}}</span>
                        <span class="result-content" *ngIf="selectedDTE">{{getDteName()}}</span>
                        <span class="resutl-content" *ngIf="!selectedDTE">-</span>
                        <button
                            (click)="navigateNext()"
                            [disabled]="loadedDteIsInvalid || !selectedDTE"
                            style="justify-content: center; flex-flow: row;"
                            psp-button class="e-btn e-small"
                            >{{'UebernehmenBtn_T' | translate}}</button>
                    </div>
                </div>
            </div>
    </div>
    <div class="content" content>
        <div style="margin-left: 1em;">
            <div class="text-tooltip-wrapper" style="margin-bottom: 1em;">
                <h2>{{'Headline_T' | translate}}</h2>
                <app-help-icon hinweisId="Dte.ErmittlungStandardbetrieb"></app-help-icon>
            </div>
            <div class="image-container">
                <app-dte-standardbetrieb-schematic
                    [dte]="getDteName()"
                    [energiespeicherVorlauftemperatur]="getVorlaufErgebnis()"
                    [bedarfsvolumenstrom]="getBedarfsvolumenstrom()"
                ></app-dte-standardbetrieb-schematic>
            </div>
            <div style="margin-top: 2.5em;">
                <!-- <span class="titletext">Angaben für PWH 60°C</span> -->
                <div class="radioButtonText" >
                    <div style="width: 15;">
                        <span style="margin-right: 1.5em;">{{'VorlauftemperaturSlider_T' | translate}}</span>
                    </div>
                    <app-slider-text-combo
                        class="slider-text-combo"
                        style="margin-left: 0em;"
                        [sliderMin]="vorlauftemperaturSliderMin"
                        [sliderMax]="90"
                        [sliderStep]="1"
                        [textboxMin]="vorlauftemperaturSliderMin"
                        [textboxMax]="90"
                        [textboxStep]="1"
                        [textboxDecimals]="0"
                        [textboxClass]="'slider-input'"
                        [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                        [showLabel]='false'
                        [(value)]='vorlauftemperatur'
                        (valueChange)="onChangeVorlauftemperatur()"
                    ></app-slider-text-combo>
                    <span style="margin-left: 1.5em;">{{'#Generic.Unit.DegreeCelsius_T' | translate}}</span>
                    <span *ngIf="benoetigteVorlauftemperatur && benoetigteVorlauftemperatur > 0" class="radioButtonText" style="margin-left: 1em; white-space:nowrap;">{{'VorlaufTemperatur_T' | translate: getVorlaufErgebnis()}}</span>
                    <span *ngIf="!benoetigteVorlauftemperatur || benoetigteVorlauftemperatur == 0" class="radioButtonText" style="margin-left: 1em; white-space:nowrap;">{{'BenVorlaufTemperaturNull_T' | translate}}</span>
                </div>
            </div>

        </div>
        <div style="margin-top: 2.5em;">
            <div class="text-tooltip-wrapper">
                <span class="titletext">{{'AuswahlDteHeadline_T' | translate}}</span>
                <app-help-icon hinweisId="Dte.AuswahlDte"></app-help-icon>
            </div>
            <div class="row">
                <button id=dte40 psp-button class="dteButton " iconPosition="none" [disabled]="isDteDisabled('40')" (click)="selectDte('40', true)" >{{getDte40Btn()}}</button>
                <button id=dte70 psp-button class="dteButton " iconPosition="none" [disabled]="isDteDisabled('70')" (click)="selectDte('70', true)">{{getDte70Btn()}}</button>
                <button id=dte100 psp-button class="dteButton" iconPosition="none" [disabled]="isDteDisabled('100')" (click)="selectDte('100', true)">{{getDte100Btn()}}</button>
            </div>
        </div>
        <div style="margin-top: 1em;">
            <div class="text-tooltip-wrapper">
                <span class="titletext">{{'LeistungsdiagrammHeadline_T' | translate}}</span>
                <app-help-icon hinweisId="Dte.Leistungsdiagramm"></app-help-icon>
            </div>

            <div style="height: 30em; max-width: 85em;">
                <ejs-chart height="100%" width="85%" [tooltip]="tooltipBetriebspunkt" [primaryYAxis]='primaryYAxis' [primaryXAxis]='primaryXAxis'  [palettes]='palette'  [legendSettings]='legendSettings'>
                    <e-series-collection>
                        <e-series [enableTooltip]="false" [dataSource]='chartDataPWH60' dashArray="2" type='Line' xName='Bedarfsvolumenstrom' yName='Vorlauftemperatur' width=2 [name]="'PWH ' + getPwh60()" [animation]='animation'></e-series>
                        <e-series [tooltipFormat]="tooltipformatBetriebspunkt" [dataSource]='chartDataBetriebspunkt' type='Line' xName='Bedarfsvolumenstrom' yName='Vorlauftemperatur' width=2  [animation]='animation'></e-series>
                        <e-series [dataSource]="chartDataBedarfsvolumenstrom" [enableTooltip]="false" dashArray="7" type="Line" xName="Bedarfsvolumenstrom" yName='Vorlauftemperatur' width="2" name="{{'BedarfsvolumenstromPwh60_T' | translate: getBedarfsvolumenstromPwh60()}}" [animation]="animation"></e-series>
                    </e-series-collection>
                </ejs-chart>
            </div>

        </div>

    </div>
</viega-collapsible-panel>
