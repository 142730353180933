import { IHeatGeneratorParam, IStorageValuesResult, IStorageValuesParams, IMinuteValuesEnergyDemandParams, IMinuteValuesEnergyDemandResults, StorageMath } from 'src/app/core/math/StorageMath';
import { Energielabel, EnergiespeicherErmittlungstyp, SectionState } from 'src/app/core/Enums';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { HotWaterMath } from 'src/app/core/math/HotWaterMath';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HeatGeneratorRow } from 'src/app/core/DteufcAggregatorServiceApi/heatgeneratorrow.complex';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

// Classes
export class SumLineChartValue {
    public Minute: number;
    public Energy: number;
}

export class GaphData {
    // Config
    legendSettings: object;
    primaryYAxis: object;
    primaryXAxis: object;
    animation: object;
    palette: string[];

    // Data
    public chartDataDemand: SumLineChartValue[];
    public chartDataQsto: SumLineChartValue[];
    public chartDataSupply: SumLineChartValue[];
    public chartDataDemandAbgesenkt: SumLineChartValue[];
    public chartDataQstoAbgesenkt: SumLineChartValue[];
    public chartDataSupplyAbgesenkt: SumLineChartValue[];

    // Constructor
    constructor(private langService: LangService) {
        // Set config
        this.primaryYAxis = {
            minimum: 0,
            maximum: 250,
            interval: 50,
            title: this.langService.getString('YAxisName_T', undefined, this.langService.getString('#Generic.Unit.KiloWattsPerHour_T'))
        };
        this.primaryXAxis = {
            minimum: 0,
            maximum: 24,
            interval: 1,
            title: this.langService.getString('XAxisName_T')
        };
        this.palette = ["#199D3B", "#ed8332", "#5181e8", "#5e5e5e", "#5e5e5e", "#5e5e5e"];
        this.legendSettings = { visible: true, alignment: 'Center', shapeWidth: 40 };

        // Set default data
        this.chartDataDemand = [];
        this.chartDataQsto = [];
        this.chartDataSupply = [];

        this.chartDataDemandAbgesenkt = [];
        this.chartDataQstoAbgesenkt = [];
        this.chartDataSupplyAbgesenkt = [];
    }

    // Methods
    public setDemandData(result: IMinuteValuesEnergyDemandResults, abgesenkt: boolean): void {
        let demand = result.minutenwerteEnergiebedarf.map((v,i) => { return { Energy: v, Minute: i/60 }});
        if(abgesenkt){
            this.chartDataDemandAbgesenkt = demand;
        } else {
            this.chartDataDemand = demand;
        }
    }
    public setVersorgungsData(result: IStorageValuesResult, abgesenkt: boolean): void {
        let supply = result.minutenwerteVersorgungskennlinie.map((v,i) => { return { Energy: v, Minute:  i/60 }});
        let qsto = result.minutenwerteQstoOn.map((v,i) => { return { Energy: v, Minute: i/60 }});
        if(abgesenkt){
            this.chartDataSupplyAbgesenkt = supply;
            this.chartDataQstoAbgesenkt = qsto;
        } else {
            this.chartDataSupply = supply;
            this.chartDataQsto = qsto;
        }

        // Adjust graph scale
        let maxValue = Math.round(Math.max.apply(Math, supply.map(s => s.Energy)) + 25);
        maxValue = Math.round(maxValue/5) * 5; // Round to next multiple of 5
        this.primaryYAxis = {
            minimum: 0,
            maximum: maxValue,
            interval: maxValue / 5,
            title: this.langService.getString('YAxisName_T', undefined, this.langService.getString('#Generic.Unit.KiloWattsPerHour_T'))
        };
    }
}

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss'],
    providers: [provideContext('Result')]
})
export class ResultComponent implements OnInit,AfterViewInit {

    stepText = this.langService.getString('StepText_T');
    stepTitle = this.langService.getString('StepTitle_T');
    stepBack = this.langService.getString('StepBack_T');
    stepDescription = this.langService.getString('StepDescription_D');

    // Enum provider
    public EnergiespeicherErmittlungstyp = EnergiespeicherErmittlungstyp;

    // Variables
    private defaultHeater: HeatGeneratorRow;
    private heaterRows: HeatGeneratorRow[];
    public selectedProject: ProjectEntity;
    public graphData: GaphData;

    /** false when the blue line is below the green line */
    public isEnoughVersogung: boolean;

    private valuesChanged: boolean;
    private lastDemandResultsPwh: IMinuteValuesEnergyDemandResults;
    private lastDemandResultsPwhAbgesenkt: IMinuteValuesEnergyDemandResults;
    private lastStorageResultsPwh: IStorageValuesResult;
    private lastStorageResultsAbgesenkt: IStorageValuesResult;

    tempDirekteingabe: number;
    tempAnteil: number;

    public get isAbsenkung(): boolean
    {
        return this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung;
    }

    // Constructors/Initializers
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private DataService: ProjectDataService,
        private StorageMath: StorageMath,
        private aggregatorService: AggregatorDataService,
        private formatterServer: ValueFormatterService,
        private HotWaterMath: HotWaterMath,
        private langService: LangService
    ) {
        this.valuesChanged = false;
    }
    ngAfterViewInit(): void {
        // Set initial Energiespeichervolumen slider

        this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter = this.tempDirekteingabe ?? this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831LiterAbgesenkt ?? this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831Liter;


        // Prepare values

        this.selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent = this.tempAnteil ?? this.defaultHeater.AnteilBereitschaftsvolumenProzent;


        // Perform initial calculation
        this.valuesChanged = true;
        this.calculateValues();

        // Register update method
        setInterval(()=> { this.updateGraph() }, 1000 / 4); // update graph {4} times per second
    }
    ngOnInit(): void {
        // Get & Validate Project
        let urlProject = this.activatedRoute.snapshot.data['project'];
        this.selectedProject = this.DataService.TempProject;
        if (!this.selectedProject) {
            if (urlProject) {
                this.router.navigate([`/project-view/${urlProject.ProjectId}`]);
            } else {
                this.router.navigate([`/home`]);
            }
            return;
        }

        // Init graph data
        this.graphData = new GaphData(this.langService);

        // Get default heater values
        this.heaterRows = this.aggregatorService.aggregatorData.Tables.HeatGeneratorRows;
        this.defaultHeater = this.heaterRows.find(h => h.HeatGeneratorId == 'Grobauslegung');

        // Prepare values
        this.tempAnteil = this.selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent;
        this.selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent = 0;


        // Set initial Energiespeichervolumen slider
        this.tempDirekteingabe = this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter;
        this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter = 0;

    };

    // Button click events
    public onClickSave(): void {
        if(this.valuesChanged){
            return;
        }

        this.selectedProject.SectionStates.Energiespeicher = SectionState.Set;
        this.DataService.saveTempProject()
            .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
    }
    public goBack(): void {
        this.router.navigate([`/heat-generating-types/${this.selectedProject.ProjectId}`]);
    }
    public calculateValues(): void {
        // Fix value types
        if(this.selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent) {
            this.selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent = Number(this.selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent);
        }
        if(this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt?.VolumenDirekteingabeLiter){
            this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter = Number(this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter);
        }

        // Energiebedarf Normalbetrieb
        this.lastDemandResultsPwh = this.calculateEnergiebedarf(this.selectedProject.ProjectValues.Dte.PwhTemperatur);
        this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteEnergiebedarf = this.lastDemandResultsPwh.minutenwerteEnergiebedarf;

        // Versorgungswerte Normalbetrieb
        this.lastStorageResultsPwh = this.calculateStorageValues(this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput, this.lastDemandResultsPwh, this.selectedProject.ProjectValues.Zirkulationsvolumenstrom.ZirkulationsverlustleistungBerechnetWatt);
        this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteVersorgung = this.lastStorageResultsPwh.minutenwerteVersorgungskennlinie;
        this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteQStoOn = this.lastStorageResultsPwh.minutenwerteQstoOn;
        this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831Liter = this.lastStorageResultsPwh.energiespeicherVolumenLiterDin;

        if(this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung){
            // Energiebedarf Abgesenkt
            this.lastDemandResultsPwhAbgesenkt = this.lastDemandResultsPwh;
            this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteEnergiebedarfAbgesenkt = this.lastDemandResultsPwh.minutenwerteEnergiebedarf;

            // Versorgungswerte Abgesenkt
            this.lastStorageResultsAbgesenkt = this.calculateStorageValues(this.selectedProject.ProjectValues.VorlauftemperaturEffektiv, this.lastDemandResultsPwhAbgesenkt, this.selectedProject.ProjectValues.Dte.ZirkulationsverlustleistungBerechnetAbgesenktWatt);
            this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteVersorgungAbgesenkt = this.lastStorageResultsAbgesenkt.minutenwerteVersorgungskennlinie;
            this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteQStoOnAbgesenkt = this.lastStorageResultsAbgesenkt.minutenwerteQstoOn;

            // Set result values Abgesenkt
            this.selectedProject.ProjectValues.Energiespeicher.LeistungWärmeerzeugerKw = this.lastStorageResultsAbgesenkt.gesamtleistungWaermeerzeugerKw;
            this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831LiterAbgesenkt = this.lastStorageResultsAbgesenkt.energiespeicherVolumenLiterDin;
            this.selectedProject.ProjectValues.Energiespeicher.VolumenErgebnisLiter = this.lastStorageResultsAbgesenkt.energiespeicherVolumenLiter;

            const minutenwerteBedarf = this.lastDemandResultsPwh.minutenwerteEnergiebedarf;
            const minutenwerteBedarfAbgesenkt = this.lastDemandResultsPwh.minutenwerteEnergiebedarf;
            const minutenwerteVersorgung = this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteVersorgung;
            const minutenwerteVersorgungAbgesenkt = this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteVersorgungAbgesenkt;
            this.isEnoughVersogung =
                this.checkEnoughVersorgung(minutenwerteBedarf, minutenwerteVersorgung) &&
                this.checkEnoughVersorgung(minutenwerteBedarfAbgesenkt, minutenwerteVersorgungAbgesenkt);
        } else {
            // Set result values Normalbetrieb
            this.selectedProject.ProjectValues.Energiespeicher.LeistungWärmeerzeugerKw = this.lastStorageResultsPwh.gesamtleistungWaermeerzeugerKw;
            this.selectedProject.ProjectValues.Energiespeicher.VolumenErgebnisLiter = this.lastStorageResultsPwh.energiespeicherVolumenLiter;

            // Set result values Abgesenkt to default
            this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteEnergiebedarfAbgesenkt = [];
            this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteVersorgungAbgesenkt = [];
            this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteQStoOnAbgesenkt = [];

            const minutenwerteBedarf = this.lastDemandResultsPwh.minutenwerteEnergiebedarf;
            const minutenwerteVersorgung = this.selectedProject.ProjectValues.Energiespeicher.MinutenwerteVersorgung;
            this.isEnoughVersogung = this.checkEnoughVersorgung(minutenwerteBedarf, minutenwerteVersorgung);
        }

        // Mark values as changed
        this.valuesChanged = true;
    }

    private checkEnoughVersorgung(minutenwerteBedarf: number[], minutenwerteVersorgung: number[]): boolean
    {
        if (minutenwerteBedarf.length !== minutenwerteVersorgung.length)
            throw new Error('Array lengths must be equal.');

        // "the blue line must always be above the green line"
        if (minutenwerteBedarf.some((bedarf, i) => minutenwerteVersorgung[i] < bedarf))
            return false;

        return true;
    }

    // Value formatters
    public getDisplayValue(variable: string): string {
        switch (variable) {
            case ("Pwh"): return this.formatterServer.formatNumber(this.selectedProject.ProjectValues.Dte.PwhTemperatur, "DegreeCelsius", { maximumFractionDigits: 0 });
            case ("Vorlauf"): return this.formatterServer.formatNumber(Math.ceil(this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput), "DegreeCelsius", { maximumFractionDigits: 0 });
            case ("VorlaufAbgesenkt"): return this.formatterServer.formatNumber(Math.ceil(this.selectedProject.ProjectValues.Dte.VorlauftemperaturAbgesenkt), "DegreeCelsius", { maximumFractionDigits: 0 });
            case ("Zirkulationsverlust"): return this.formatterServer.formatNumber(this.selectedProject.ProjectValues.Zirkulationsvolumenstrom.ZirkulationsverlustleistungBerechnetWatt, "Watts", { maximumFractionDigits: 0 });
            case ("ZirkulationsverlustAbgesenkt"): return this.formatterServer.formatNumber(this.selectedProject.ProjectValues.Dte.ZirkulationsverlustleistungBerechnetAbgesenktWatt, "Watts", { maximumFractionDigits: 0 });
            case ("Warmwasser"): return this.formatterServer.formatNumber(this.selectedProject.ProjectValues.WarmwasserErgebnisLtag, "Liter", { maximumFractionDigits: 0 });
            case ("Temperatur"): return this.formatterServer.formatNumber(this.selectedProject.ProjectValues.Dte.PwhTemperatur, "DegreeCelsius", { maximumFractionDigits: 0 });
            case ("TemperaturAbgesenkt"): return this.formatterServer.formatNumber(this.selectedProject.ProjectValues.Dte.PwhTemperaturAbgesenkt, "DegreeCelsius", { maximumFractionDigits: 0 });
            case ("PwhEffektiv"):
                if (this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung)
                    return this.getDisplayValue('TemperaturAbgesenkt');
                else
                    return this.getDisplayValue('Temperatur');
            case ("VorlaufEffektiv"): return this.formatterServer.formatNumber(Math.ceil(this.selectedProject.ProjectValues.VorlauftemperaturEffektiv), "DegreeCelsius", { maximumFractionDigits: 0 });
            case ("LeistungFuerWW"):
                if(this.selectedProject.ProjectValues.Energiespeicher.LeistungWärmeerzeugerKw)
                    return this.formatterServer.formatNumber(Number(this.selectedProject.ProjectValues.Energiespeicher.LeistungWärmeerzeugerKw), 'KiloWatts', { maximumFractionDigits: 0 });
                else
                    return "-";
            case ("ValueDin12831"):
                if(this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831Liter)
                    return this.formatterServer.formatNumber(Number(this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831Liter), 'Liter', { maximumFractionDigits: 0 });
                else
                    return "-";
            case ("ValueDin12831Abgesenkt"):
                if(this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831LiterAbgesenkt)
                    return this.formatterServer.formatNumber(Number(this.selectedProject.ProjectValues.Energiespeicher.ErgebnisDin12831LiterAbgesenkt), 'Liter', { maximumFractionDigits: 0 });
                else
                    return "-";
            case ("ErgebnisLiter"):
                if(this.selectedProject.ProjectValues.Energiespeicher.VolumenErgebnisLiter)
                    return this.formatterServer.formatNumber(Number(this.selectedProject.ProjectValues.Energiespeicher.VolumenErgebnisLiter), 'Liter', { maximumFractionDigits: 0 });
                else
                    return "-";
            default: return "";
        }
    }

    // Validation methods
    public isSaveInvalid(): boolean {
        return this.valuesChanged === true || (this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung &&
            (this.selectedProject.ProjectValues.Energiespeicher.VolumenErgebnisLiter == null
            || this.selectedProject.ProjectValues.Energiespeicher.VolumenErgebnisLiter <= 0));
    }

    // Internal helper methods
    private updateGraph(): void {
        if(this.valuesChanged == true){
            // Update Abgesenkt
            if(this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung) {
                this.graphData.setDemandData(this.lastDemandResultsPwhAbgesenkt, true);
                this.graphData.setVersorgungsData(this.lastStorageResultsAbgesenkt, true);
            }

            // Update PWH
            this.graphData.setDemandData(this.lastDemandResultsPwh, false);
            this.graphData.setVersorgungsData(this.lastStorageResultsPwh, false);

            // Reset change state
            this.valuesChanged = false;
        }
    }
    private calculateEnergiebedarf(temperature: number): IMinuteValuesEnergyDemandResults {
        // Prepare calculation parameters
        var energiebedarfParams: IMinuteValuesEnergyDemandParams = { demands: [] };

        // Get other building values
        if(this.selectedProject.OtherBuildings) {
            energiebedarfParams.demands = this.selectedProject.OtherBuildings
                .filter(b => b.Warmwasser != null)
                .map(b => { return {
                    summeEnergiebedarfKwProTag: this.HotWaterMath.energiebedarfProTag(b.Warmwasser.WarmwasserErgebnisLTag, temperature),
                    tageslastgangAnteile: b.Warmwasser.Tageslastgang.Anteile
                }});
        }

        // Get residential values
        if(this.selectedProject.ResidentialBuilding?.Warmwasser) {
            energiebedarfParams.demands.push({
                tageslastgangAnteile: this.selectedProject.ResidentialBuilding.Warmwasser.Tageslastgang.Anteile,
                summeEnergiebedarfKwProTag: this.HotWaterMath.energiebedarfProTag(this.selectedProject.ResidentialBuilding?.Warmwasser.WarmwasserErgebnisLTag, temperature)
            });
        }

        // Calculate
        return this.StorageMath.minutenwerteEnergiebedarf(energiebedarfParams);
    }
    private calculateStorageValues(
        temperature: number,
        energyResult: IMinuteValuesEnergyDemandResults,
        zirkulationsverlustleistungWatt: number
    ): IStorageValuesResult {
        // Get heat generators
        var generators: IHeatGeneratorParam[] = this.selectedProject.ProjectValues.Energiespeicher.Wärmeerzeuger.map(x => {
            return { leistungWWBereitungKiloWatt: x.LeistungWarmwasserBereitungKw, verzoegerungNachheizungMinuten: x.VerzoegerungNachheizungMin }
        });

        // Prepare parameters
        let params: IStorageValuesParams = {
            vorlauftemperatur: temperature,
            storageEnergyEfficiency: this.aggregatorService.aggregatorData.Tables.StorageEnergyEfficiencyCategoryRows
                .find(x => x.EfficiencyCategoryId == this.getEnergieLabelString(this.selectedProject.ProjectValues.Energiespeicher.Energielabel)),
            energiebedarf: energyResult,
            waermeerzeuger: generators,
            anteilBereitschaftsvolumenPerecentage: this.selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent,
            zirkulationsverlustleistungWatt: zirkulationsverlustleistungWatt,
            energiespeicherVolumenLiterDirekteingabe: this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp == EnergiespeicherErmittlungstyp.Exakt
                ? this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter : null
        };

        // Calculate
        return this.StorageMath.speicherwerteFromEnergiebedarf(params);
    }
    private getEnergieLabelString(value: Energielabel): string {
        switch (value) {
            case Energielabel.APlus: return "APlus";
            case Energielabel.A: return "A";
            case Energielabel.B: return "B";
            case Energielabel.C: return "C";
            default: throw new Error(`Unknown energy label type encountered: ${value}`);
        }
    }
}
