<viega-collapsible-panel>
    <div class="panel-content" panel-content>
        <div class="top-panel-content">
            <viega-panel-header
                stepText="Schritt 4/6"
                stepTitle="Berechnung nach DIN 12831"
                stepBackLabel="Schritt zurück"
                stepDescription="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>
        </div>
        <div class="bot-panel-content">
            <button psp-button class="e-btn " (click)="navigateNext()" [disabled]='buttonDisabled'>Weiter <i class="icon-chevron-thin-right e-icon-right e-btn-icon"></i> </button>

        </div>
    </div>
    <div class="content" content>
        <span class="content-title">Weitere Angaben zum Energiespeichervolumen</span>
        <div class="panel-values">
            <span class="titletext">Energiespeichertemperatur (abgesenkter Betrieb)</span>

        </div>
        <div *ngIf="dteSelected">
            <div style="display: flex; width: 30%">
                <div class="panel-values">
                    <span>PWH-Temperatur:</span>
                    <span>Energiespeichertemperatur: </span>
                </div>
                <div class="panel-values">
                    <span>50 °C</span>
                    <span>50 °C</span>
                </div>
            </div>
        </div>
        <div *ngIf="!dteSelected">
            <div style="display: flex;">
                <div class="radioButtonText" style="    width: 46em;">
                    <span style="margin-right: 1.5em;     white-space: nowrap; width: 92em;">PWH-Temperatur manuell eingeben:</span>
                    
                    <ejs-slider #option1 [min]=65 [max]=90 [value]=0></ejs-slider>
                     <ejs-textbox type="number" [(value)]="option1.value" floatLabelType="Never" style=" border-color: white !important; width: 9em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>
                     <span style="margin-left: 0.6em">°C</span>
                </div>
            </div>
            <div style="display: flex;">
                <div class="radioButtonText" style="    width: 46em;">
                    <span style="margin-right: 1.5em;     white-space: nowrap; width: 92em;">Energiespeichertemperatur manuell eingeben:</span>
                    <ejs-slider #option2 [min]=65 [max]=90 [value]=0></ejs-slider>
                     <ejs-textbox type="number" [(value)]="option2.value" floatLabelType="Never" style=" border-color: white !important; width: 9em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>
                     <span style="margin-left: 0.6em">°C</span>
                </div>
            </div>
        </div>
        <div class="panel-values" style="margin-bottom: 1em;">
            <span class="titletext">Zirkulation</span>

        </div>
        <div *ngIf="ufcSelected">
            <div class="inner-content" style="margin-bottom: 0.5em;">
                <ejs-radiobutton #radio1 label="Automatisch ermittelten Zirkulationsverlust aus der Auslegung UFC nehmen: {{ufc}} W" name="option"></ejs-radiobutton>
            </div>
        </div>
        <div class="radioButtonText" style="    width: 50em;">
            <ejs-radiobutton #radio2 label="Zirkulationsvolumenstroms direkt eingeben:" name="option" style="margin-right: 1.5em;     white-space: nowrap; width: 100em;"></ejs-radiobutton>
            <ejs-slider #option3 [min]=65 [max]=90 [value]=0 (change)='changeOption2($event)' ></ejs-slider>
             <ejs-textbox type="number" [(value)]="option3.value" floatLabelType="Never" style=" border-color: white !important; width: 9em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>
             <span style="margin-left: 0.6em; width: 6em;"><ejs-dropdownlist value="l/s" [dataSource]="dropdownData"></ejs-dropdownlist></span>
        </div>
        <div class="radioButtonText" style="    width: 46em;">
            <ejs-radiobutton #radio3 label="Anhand der Zirkulationsverlustleistung ermitteln:" name="option" style="margin-right: 1.5em;     white-space: nowrap; width: 92em;"></ejs-radiobutton>
            <ejs-slider #option4 [min]=65 [max]=90 [value]=0 (change)='changeOption3($event)'></ejs-slider>
             <ejs-textbox type="number" [(value)]="option4.value" floatLabelType="Never" style=" border-color: white !important; width: 9em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>
             <span style="margin-left: 0.6em">W</span>
        </div>
        <div class="radioButtonText" style="    width: 46em; margin-top: 0.5em;">
            <ejs-radiobutton #radio4 label="Leistungen anhand DVGW 553 berechnen" name="option" style="margin-right: 1.5em;     white-space: nowrap; width: 92em;"></ejs-radiobutton>
        </div>
        <div style="margin-top: 2.5em;">
            <div class="datas-table" style="width: 44%;">
                <div class="control-section">
                    <ejs-grid [dataSource]='data' [allowSorting]='false'>
                        <e-columns>
                            <e-column field='pwh' headerText='Leitung PWH und PWH-C' width="30" textAlign='Left'></e-column>
                            <e-column headerText='Leistungslänge (m)' width='40' textAlign='Left'>
                                <ng-template #template let-data>
                                    <div class="radioButtonText">
                                        <ejs-slider #option1 [min]=0 [max]=50 [value]="data.lineLength" (change)='changeOption4($event)'></ejs-slider> <ejs-textbox type="number" [(value)]="data.lineLength" floatLabelType="Never" style=" border-color: white !important; width: 9em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox><span style="margin-left: 0.4em">m</span>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='result' headerText='Ergebnis' width='20' textAlign='Center'>
                                <ng-template #template let-data>
                                    <span style="margin-left: 0.4em">{{data.result}}W</span>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>
        <div class="under-table-wrapper">
            <div>

                <span>Zirkulationsverlustleistung: 205 W </span>
            </div>
            <div>

                <span>Zirkulationsvolumenstrom: XY l/h</span>
            </div>
        </div>

        <div class="panel-values">
            <span class="titletext">Energielabel des Energiespeichers</span>
            <div class="panel-object">
                <ejs-radiobutton #radio5 label="" name="option2" style="white-space: nowrap;" (change)='changeSecondOption($event)'></ejs-radiobutton>
                <img src="assets/img/A+.png" style="margin-left: 2em; margin-top: 0.5em;">
            </div>
            <div class="panel-object">
                <ejs-radiobutton #radio6 label="" name="option2" style="white-space: nowrap;" (change)='changeSecondOption($event)'></ejs-radiobutton>
                <img src="assets/img/A.png" style="margin-left: 2em; margin-top: 0.5em;">
            </div>
            <div class="panel-object">
                <ejs-radiobutton #radio7 label="" name="option2" style="white-space: nowrap;" (change)='changeSecondOption($event)'></ejs-radiobutton>
                <img src="assets/img/B.png" style="margin-left: 2em; margin-top: 0.5em;">
            </div>
            <div class="panel-object">
                <ejs-radiobutton #radio8 label="" name="option2" style="white-space: nowrap;" (change)='changeSecondOption($event)'></ejs-radiobutton>
                <img src="assets/img/C.png" style="margin-left: 2em; margin-top: 0.5em;">
            </div>

        </div>
    </div>
</viega-collapsible-panel>