import { NgModule } from '@angular/core';
import { PspSiteHeaderComponent } from './psp-site-header.component';

@NgModule(
{
    declarations:
    [
        PspSiteHeaderComponent,
    ],
    exports:
    [
        PspSiteHeaderComponent,
    ],
})
export class PspSiteHeaderModule {}
