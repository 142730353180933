import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PspContextualHelpModule } from '../other/psp-contextual-help/psp-contextual-help.module';
import { FormFieldComponent } from './psp-form-field/psp-form-field.component';

@NgModule({
    imports:
    [
        CommonModule,
        PspContextualHelpModule,
    ],
    declarations:
    [
        FormFieldComponent,
    ],
    exports:
    [
        FormFieldComponent,
    ],
})
export class PspFormsModule {}
