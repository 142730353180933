import { NgModule } from '@angular/core';
import { PspUserNotifyIconComponent } from './psp-user-notify-icon.component';

@NgModule(
{
    declarations:
    [
        PspUserNotifyIconComponent,
    ],
    exports:
    [
        PspUserNotifyIconComponent,
    ],
})
export class PspUserNotifyIconModule {}
