<div class="component-container" (click)="button_clicked($event)" style="cursor: pointer;">
    <div class="container" >
        <div class="thumbnail">
            <div class="images">
                <img [src]="imagePath" width="258" Height="157">
            </div>

            <div class="caption">
                <span class="centered">{{imageText}}</span>
            </div>
            <div *ngIf="isSelected; then selected"></div>
        </div>

        <span class="mainText">{{description}}</span>

    </div>
    <div *ngIf="buttonText;then buttonTemp"></div>
</div>
<ng-template #selected>
    <div class="selected">
        <i class="icon-check1" style="color: #FFED00; vertical-align: middle;"></i>
    </div>
</ng-template>
<ng-template #buttonTemp>
    <div class="noBackground">
        <button   psp-button class="e-btn e-primary" style="width: 100%; justify-content: center;">{{buttonText}}<span class="e-btn-icon icon-chevron-thin-right e-icon-right"></span></button>
    </div>
</ng-template>