import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
  selector: 'app-selection-item-radio',
  templateUrl: './selection-item-radio.component.html',
  styleUrls: ['./selection-item-radio.component.scss'],
  providers: [provideContext('SelectionItemRadio')]
})
export class SelectionItemRadioComponent implements OnInit {

    @Input() itemIndex: number;
    @Input() itemTitle: string;
    @Input() imgSrc: string;
    @Input() itemChecked: boolean;
    @Input() description: string;
    @Input() optionGroup: string;

    @Output() stateChanged = new EventEmitter<any>();


  constructor(private langService: LangService) { }

  ngOnInit(): void {

    }


  onChange(){
    this.stateChanged.emit();
}
}
