import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepBarComponent } from './step-bar.component';
// import { IconButtonModule } from '../icon-button/icon-button.module';

@NgModule({
    imports: [
        CommonModule,
        // IconButtonModule
    ],
    declarations: [
        StepBarComponent
    ],
    exports: [
        StepBarComponent
    ]
})
export class StepBarModule { }
