
<viega-start-page
    [headlineText]="headlineText"
    [backgroundUrl]="backgroundUrl">

    <div panel>

        <div class="description-wrapper">
            <p [innerHtml]="description">
                <!-- {{'Description_D' | translate}} -->
            </p>
            <a href="https://viega.de/av-dteufc-planer-datenschutzrichtlinie">Datenschutzrichtlinie</a>
        </div>

        <ng-container *ngIf="userService.isAuthenticated">
            <div class="text-tooltip-wrapper text-tooltip-wrapper-start-button">
                <button
                    psp-button
                    cssClass="start-btn"
                    textPosition="center"
                    (click)="startButton_clicked()"
                >
                    {{ 'StartButton_T' | translate}}
                </button>
                <app-help-icon hinweisId="Projekt.Start"></app-help-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="!userService.isAuthenticated">
            <p class="login-hint">{{ 'LoginHint_SD' | translate}}</p>

            <button
                psp-button
                cssClass="login-btn"
                textPosition="center"
                (click)="loginButton_clicked()"
            >
                {{ 'LoginButton_T' | translate}}
            </button>
        </ng-container>
    </div>
    <div panel-mobile>
        <p>
            {{'PanelMobilDescription_D' | translate}}
        </p>
        <p>{{'PanelMobilVollstaendigeVersion_D' | translate}}</p>
    </div>
</viega-start-page>


