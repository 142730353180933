import { ButtonModelPropsModel, Toast, ToastClickEventArgs, ToastModel } from '@syncfusion/ej2-angular-notifications';
import { Injectable } from '@angular/core';
import { LangService } from './LangService';
import { ServiceLocator } from './ServiceLocator';
import { GenericDialogService } from './GenericDialogService';

export type ToastType = 'success' | 'info' | 'warning' | 'error' | 'neutral';

@Injectable()
export class ToastService
{
    private static readonly toastDefaultCloseDelay = 5000;

    private toast: Toast;
    private dialogService: GenericDialogService;
    private langService: LangService;

    public static readonly typeIconMap = {
        'success': 'icon-success',
        'warning': 'icon-warning',
        'error': 'icon-close-outline',
        'info': 'icon-info',
    };

    public static readonly typeCssClassMap = {
        'success': 'e-toast-success',
        'warning': 'e-toast-warning',
        'error': 'e-toast-danger',
        'info': 'e-toast-info',
    };

    public constructor()
    {
        const toastContainer = document.createElement('div');
        document.body.insertBefore(toastContainer, document.body.firstChild);

        this.toast = new Toast({
            position:
            {
                X: 'Right',
                Y :'Bottom',
            },
            animation:
            {
                show: { duration: 1000 },
                hide: { duration: 500 },
            },
            click: (args: ToastClickEventArgs) =>
            {
                args.clickToClose = true;
            },
            newestOnTop: true,
        });
        this.toast.appendTo(toastContainer);

        this.dialogService = ServiceLocator.get(GenericDialogService);
        this.langService = ServiceLocator.get(LangService);
    }

    public showInfoToast(title: string, content: string, overrides?: ToastModel)
    {
        this.showToast(title, content, 'info', ToastService.toastDefaultCloseDelay, undefined, overrides);
    }

    public showWarningToast(title: string, content: string, overrides?: ToastModel)
    {
        this.showToast(title, content, 'warning', ToastService.toastDefaultCloseDelay, undefined, overrides);
    }

    public showErrorToast(title: string, content: string, overrides?: ToastModel, errorDetails?: string)
    {
        let buttons: ButtonModelPropsModel[];
        if (errorDetails)
        {
            buttons =
            [
                {
                    model:
                    {
                        content: this.langService.getString('#Generic.Toasts.ErrorDetailsBtn_T'),
                        isPrimary: false,
                        cssClass: 'psp-toast-error-details',
                    },
                    click: (event: MouseEvent) => {
                        this.dialogService.showErrorDetailsDialog(errorDetails);
                        event.stopImmediatePropagation();
                        event.stopPropagation();
                    }
                }
            ];
        }

        this.showToast(title, content, 'error', undefined, buttons, overrides);
    }

    public showNeutralToast(title: string, content: string, overrides?: ToastModel)
    {
        this.showToast(title, content, 'neutral', undefined, undefined, overrides);
    }

    private showToast(
        title: string,
        content: string,
        toastType: ToastType,
        timeOut?: number,
        buttons?: ButtonModelPropsModel[],
        overrides?: ToastModel,
    )
    {
        let width = '400px';
        if (content.length > 200 && window.innerWidth > 600)
            width = '550px';

        const toastObject = {
            title: title,
            content: content,
            icon: ToastService.typeIconMap[toastType],
            timeOut,
            cssClass: ToastService.typeCssClassMap[toastType],
            buttons,
            width
        };
        Object.assign(toastObject, overrides || {});

        this.toast.show(toastObject);
    }
}
