<viega-collapsible-panel>
    <div class="panel-content" panel-content>
        <viega-panel-header
            stepText="&nbsp;"
            [stepTitle]="stepTitle"
            [stepBackLabel]="stepBack"
            (stepBackClicked)="goBack()"
            >
        </viega-panel-header>
        <div class="content-wrapper">
            <!-- Top-Wrapper -->
            <div class="top-content-wrapper">
                <!-- Top -->
                <div class="top-panel-content">
                    <p class="description-text" *ngIf="stepDescription" >
                        <span [innerHTML]="stepDescription"></span>&nbsp;
                    </p>
                </div>
                <!-- Middle -->
                <div class="mid-panel-content panel-values">
                    <table>
                        <col style="width: 72%">
                        <col style="width: 28%">
                        <tbody>
                            <tr>
                                <td>
                                    <span class="panel-values-titles">{{'AusgelegteDte_T' | translate}}</span>
                                </td>
                                <td>
                                    <span>{{getDisplayDte()}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="panel-values-titles">{{'Volumenstrom_T' | translate}}</span>
                                </td>
                                <td>
                                    <span>{{getDisplayMaxVolumenstrom()}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="text-tooltip-wrapper">
                                        <span class="panel-values-titles">{{'MaxDruckverlust_T' | translate}}</span>
                                        <app-help-icon  hinweisId="Rohrauslegung.MaxDruckverlust"></app-help-icon>
                                    </div>
                                </td>
                                <td>
                                    <span>{{getMaxDruckverlust()}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Bot -->
            <div class="bot-panel-content">
                    <div class="error-message" *ngIf="!this.resultsVorlauf.nennweite|| !this.resultsMitte.nennweite|| !this.resultsUnten.nennweite">
                        <i class="icon-warning1"></i>
                        <span class="error-text">{{'KeineKonfiguration_SD' | translate}}</span>
                    </div>
                    <div class="error-message" *ngIf="druckverlustGesamt > maxDruckverlust">
                        <i class="icon-warning1"></i>
                        <span class="error-text">{{'ErrorMaxDruckverlustNichtUeberschritten_D' | translate}}</span>
                    </div>
                <div class="result-box">
                    <span class="result-title">{{'ResultHeadline_T' | translate}}</span>
                    <div class="result-content">
                        <div class="result-value">
                            <div class="druckverlust">
                                <div class="text-tooltip-wrapper">
                                    <span>{{'Druckverlust_T' | translate}}</span>
                                    <app-help-icon hinweisId="Rohrauslegung.Druckverlust"></app-help-icon>
                                    <span style="margin-right: 0.5em;">:</span>
                                </div>
                                <span *ngIf="druckverlustGesamt">{{druckverlustGesamtFormatted}}</span>
                                <span *ngIf="!druckverlustGesamt">-</span>
                            </div>
                        </div>
                        <div class="result-value">
                            <span>{{'ResultVorlauf_T' | translate}}</span>
                            <span *ngIf="resultsVorlauf.nennweite">{{'ResultVorlaufWert_T' | translate: getResultsVorlaufNennweite()}}</span>
                            <span *ngIf="!resultsVorlauf.nennweite" class="icon-warning1"></span>
                        </div>
                        <div class="result-value">
                            <span>{{'ResultRuecklaufMitte_T' | translate}}</span>
                            <span *ngIf="resultsMitte.nennweite">{{'ResultRuecklaufMitteWert_T' | translate: getResultsMitteNennweite()}}</span>
                            <span *ngIf="!resultsMitte.nennweite" class="icon-warning1"></span>
                        </div>
                        <div class="result-value">
                            <span>{{'ResultRuecklaufUnten_T' | translate}}</span>
                            <span *ngIf="resultsUnten.nennweite">{{'ResultRuecklaufUntenWert_T' | translate: getResultsUntenNennweite()}}</span>
                            <span *ngIf="!resultsUnten.nennweite" class="icon-warning1"></span>
                        </div>
                    </div>

                    <button psp-button (click)="navigateNext()" [disabled]="isContinueDisabled()" class="e-btn e-small">{{'UebernehmenBtn_T' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="content" content>
        <span class="content-title">{{'Headline_T' | translate}}</span>
        <div style="margin-top: 1em; width:15%">
            <div class="text-tooltip-wrapper text-tooltip-wrapper-titletext">
                <span class="titletext" style="margin-bottom: 1em; margin-top: 2em">{{'DropDownHeadline_T' | translate}}</span>
                <app-help-icon hinweisId="Rohrauslegung.Rohrleitungssystem"></app-help-icon>
            </div>
            <ejs-dropdownlist class="dropdown" style="margin-top: 1em;" #systemDropdown [value]="systemId" placeholder="Bitte auswählen..." [dataSource]='pipeSystems' [fields]="fields" (change)="onChangeDropdown($event)"></ejs-dropdownlist>
        </div>
        <div *ngIf="imgData" style="margin-top: 2em; display: flex; width:66em;">
            <div class="image-wrapper">
                <img style="width: 100%;" [src]='imgData'>
            </div>
            <div class="description-wrapper">
                <span>{{description}}</span>
            </div>
        </div>

        <div *ngIf="selectedPipeSystemRow" class="pipe-tab" style="margin-top: 4em;">
            <table>
                <tbody>
                    <div class="table-header">
                        <tr>
                            <td class="pipe-position"></td>
                             <td>
                                <div class="pipe-table-content">{{'HeadlineRohrlaengen_T' | translate}}
                                    <div class="text-tooltip-wrapper">
                                        <app-help-icon hinweisId="Rohrauslegung.Rohrlängen"></app-help-icon>
                                    </div>
                                </div>
                            </td>
                            <td><div class="pipe-table-content">{{'HeadlineAnzahlBoegen_T' | translate}}</div></td>
                            <td>
                                <div class="pipe-table-content">{{'HeadlineZusaetzlicherDruckverlust_T' | translate}}
                                    <div class="text-tooltip-wrapper">
                                        <app-help-icon hinweisId="Rohrauslegung.ZusätzlicherDruckverlust"></app-help-icon>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </div>

                    <div *ngFor = "let row of rows; let i = index">
                        <tr>
                            <td class="pipe-position">{{row.nameLoc}}</td>
                            <td >
                                <div class="pipe-table-content" >
                                    <app-slider-text-combo
                                    class="radioButtonText2"
                        	        style="margin-left: 0em;"
                                                [sliderMin]="0"
                                                [sliderMax]="20"
                                                [sliderStep]="1"
                                                [textboxMin]="0"
                                                [textboxMax]="50"
                                                [textboxStep]="1"
                                                [textboxDecimals]="1"
                                                [textboxClass]="'slider-input'"
                                                [textboxStyle]="{ 'width': '6em', 'color': 'black !important', 'margin-left': '1em' }"
                                                [showLabel]='true'
                                                labelText="{{'#Generic.Unit.Meter_T' | translate}}"
                                                [labelStyle]="{'margin-left':'6em'}"
                                                [(value)]='row.rohrlaenge'
                                                (valueChange)="calculateValues(i)"
                                            ></app-slider-text-combo>
                                    <!--<ejs-slider #vLaenge [min]='0' [max]='50' [(value)]="row.rohrlaenge" (change)="calculateValues(i)"></ejs-slider>
                                    <ejs-textbox class="slider-input" min=0  type="number" [(value)]="row.rohrlaenge" (change)="calculateValues(i)"></ejs-textbox>-->
                                        
                                </div>
                            </td>
                            <td >
                                <div class="pipe-table-content">
                                    <app-slider-text-combo
                                    class="radioButtonText2"
                        	        style="margin-left: 0em;"
                                                [sliderMin]="0"
                                                [sliderMax]="20"
                                                [sliderStep]="1"
                                                [textboxMin]="0"
                                                [textboxMax]="99"
                                                [textboxStep]="1"
                                                [textboxDecimals]="1"
                                                [textboxClass]="'slider-input'"
                                                [textboxStyle]="{ 'width': '6em', 'color': 'black !important', 'margin-left': '1em' }"
                                                [showLabel]='true'
                                                labelText="{{'#Generic.Unit.Piece_T' | translate}}"
                                                [labelStyle]="{'margin-left':'6em'}"
                                                [(value)]='row.anzahlBoegen'
                                                (valueChange)="calculateValues(i)"
                                            ></app-slider-text-combo>
                                    <!--<ejs-slider #vBoegen [min]='0' [max]='5' [(value)]='row.anzahlBoegen' (change)="calculateValues(i)"></ejs-slider>
                                    <ejs-textbox class="slider-input" min=0 max=5  type="number" [(value)]="row.anzahlBoegen" (change)="calculateValues(i)"></ejs-textbox>-->
                                    
                                </div>
                            </td>
                            <td>
                                <div class="pipe-table-content">
                                    <app-slider-text-combo
                                    class="radioButtonText2"
                        	        style="margin-left: 0em;"
                                                [sliderMin]="0"
                                                [sliderMax]="50"
                                                [sliderStep]="1"
                                                [textboxMin]="0"
                                                [textboxMax]="50"
                                                [textboxStep]="1"
                                                [textboxDecimals]="0"
                                                [textboxClass]="'slider-input'"
                                                [textboxStyle]="{ 'width': '6em', 'color': 'black !important', 'margin-left': '1em' }"
                                                [showLabel]='true'
                                                labelText="{{'#Generic.Unit.Hektopascal_T' | translate}}"
                                                [labelStyle]="{'margin-left':'6em'}"
                                                [(value)]='row.zusaetzlicherDruckverlust'
                                                (valueChange)="calculateValues(i)"
                                            ></app-slider-text-combo>
                                    <!--<ejs-slider #vDruck [min]='0' [max]='50' [(value)]='row.zusaetzlicherDruckverlust' (change)="calculateValues(i)"></ejs-slider>
                                    <ejs-textbox class="slider-input" min=0  type="number" [(value)]="row.zusaetzlicherDruckverlust" (change)="calculateValues(i)"></ejs-textbox>-->
                                    
                                </div>
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
    </div>
</viega-collapsible-panel>
