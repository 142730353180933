<div
    class="e-control-wrapper"
    [ngClass]="{'e-error': isError()}"
>
    <label [for]="forId || formField.fieldName">
        <span *ngIf="formField.displayIconCss" class="label-icon" [ngClass]="formField.displayIconCss"></span>
        <span>{{formField.displayName}}</span>
        <span *ngIf="formField.isRequired()" class="is-required">*</span>
    </label>
    <psp-contextual-help
        *ngIf="formField.helpMessageHtml"
        [tooltipTextHtml]="formField.helpMessageHtml"
        [documentationHref]="formField.helpDocumentationHref"
        cssClass="icon-help-with-circle"
    ></psp-contextual-help>
    <ng-content></ng-content>
</div>

<div
    [style.visibility]="isError() ? 'visible' : 'hidden'"
    [style.display]="(reserveLabelSpace || isError()) ? 'inline' : 'none'"
>
    <label class="e-error" [for]="forId || formField.fieldName">
        {{formField.currentErrorMsg || '&nbsp;'}}
    </label>
</div>
