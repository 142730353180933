import { Injectable, Inject } from '@angular/core';
import { COMPONENT_CONTEXT } from 'src/core-lib/angular/constants';

@Injectable()
export class UserStorageService
{
    public constructor(
        @Inject(COMPONENT_CONTEXT)
        private context: string = ''
    ) {}

    /**
     * Stores the given value using the given key using either local storage or
     * session storage.
     *
     * @param valueKey The context based value key. Prefix with # to ignore context.
     * @param value The value to store.
     * @param isSessionStorage Whether the value is stored for the current session only.
     */
    public getValue(valueKey: string, isSessionStorage: boolean = false): string | null
    {
        valueKey = this.processKey(valueKey);

        if (isSessionStorage)
            return sessionStorage.getItem(valueKey);
        else
            return localStorage.getItem(valueKey);
    }

    /**
     * Stores the given value using the given key using either local storage or
     * session storage.
     *
     * @param valueKey The context based value key. Prefix with # to ignore context.
     * @param value The value to store.
     * @param isSessionStorage Whether the value is stored for the current session only.
     */
    public setValue(valueKey: string, value: string, isSessionStorage: boolean = false)
    {
        valueKey = this.processKey(valueKey);

        if (isSessionStorage)
            sessionStorage.setItem(valueKey, value);
        else
            localStorage.setItem(valueKey, value);
    }

    public removeValue(valueKey: string, isSessionStorage: boolean = false)
    {
        valueKey = this.processKey(valueKey);

        if (isSessionStorage)
            sessionStorage.removeItem(valueKey);
        else
            localStorage.removeItem(valueKey);
    }

    public clearStorage(isSessionStorage: boolean = false)
    {
        if (isSessionStorage)
            sessionStorage.clear();
        else
            localStorage.clear();
    }

    private processKey(valueKey: string): string
    {
        if (valueKey.startsWith('#'))
            valueKey = valueKey.substring(1);
        else if (this.context)
            valueKey = `${this.context}.${valueKey}`;

        return valueKey;
    }
}
