import { Injectable } from '@angular/core';
export type TrackEvent = 'start' | 'appliance' | 'result' | 'pdf' | 'mobile';
declare var wtSmart: any;
@Injectable({
    providedIn: 'root'
})
export class WebTrekkService {
    private static readonly ConfiguratorId = 'dte';
    /** The tracking id for test / development domains. */
    private static readonly TrackIdTest = '799424540672764';
    private static readonly TestDomainMatchers = [
        'localhost',
        '-staging'
    ];
    private isFirstTrackEvent: boolean = true;
    private isInitialized: boolean = false;

    constructor() {}
    initTracker() {
        if (!window["wtSmart"]) {
            console.error(
                'expected wtSmart to be defined when initializing it.'
            );
            return;
        }
        // TODO: replace literal with language dependent tracking id
        const trackingId = WebTrekkService.isTestDomain()
            ? WebTrekkService.TrackIdTest
            : '413482775699004';
        wtSmart.init.set({
            trackId: trackingId,
            // TODO: replace literal with language dependent tracking domain
            trackDomain: 'viegade01.wt-eu02.net'
        });
        wtSmart.extension.action.activate();
        this.isInitialized = true;
    }
    trackAction(pageName: string, eventName: string, userid: string, intern: boolean, projectid: string, projectInfo: {Zip?: string, City?: string, Country?: string, Address?: string}) {
        if (!window["wtSmart"]) {
            console.error(
                'expected wtSmart to be defined when trying to sent a track event.'
            );
            return;
        }
        // console.log(pageName + "(" + eventName + ")" + " -> " + url);
        
        if(!this.isInitialized) {
            this.initTracker();
        }

        const defaultLangId = this.getDefaultLanguage();
        wtSmart.action.data.set({
            name: 
            `${defaultLangId}.web.configurator.${WebTrekkService.ConfiguratorId}.${pageName}.${eventName}`,
            parameter: {
              userId: userid,
              intern: intern,
              projectId: projectid,
              projectZip: projectInfo.Zip,
              projectCity: projectInfo.City,
              projectCountry: projectInfo.Country,
              projectAddress: projectInfo.Address
            },
            category: {
                1: defaultLangId,
                2: 'web',
                3: 'configurator',
                4: WebTrekkService.ConfiguratorId,
                5: pageName,
                6: eventName
            }
        });
        wtSmart.track();
    }

    trackActionCustom(pageName: string, eventName: string, parameters: any) {
        if (!window["wtSmart"]) {
            console.error(
                'expected wtSmart to be defined when trying to sent a track event.'
            );
            return;
        }
        // console.log(pageName + "(" + eventName + ")" + " -> " + url);
        
        if(!this.isInitialized) {
            this.initTracker();
        }

        const defaultLangId = this.getDefaultLanguage();
        wtSmart.action.data.set({
            name: 
            `${defaultLangId}.web.configurator.${WebTrekkService.ConfiguratorId}.${pageName}.${eventName}`,
            parameter: parameters,
            category: {
                1: defaultLangId,
                2: 'web',
                3: 'configurator',
                4: WebTrekkService.ConfiguratorId,
                5: pageName,
                6: eventName
            }
        });
        wtSmart.track();
    }
    trackPage(pageName: string, userid: string, intern: boolean, projectid: string, projectInfo: {Zip?: string, City?: string, Country?: string, Address?: string}) {
        if (!window["wtSmart"]) {
            console.error(
                'expected wtSmart to be defined when trying to sent a track event.'
            );
            return;
        }

        if(!this.isInitialized) {
            this.initTracker();
        }

        // console.log("(" + pageName + ")");
        let trackingParameter = {};
        if (this.isFirstTrackEvent) {
            trackingParameter = {
                15: document.referrer,
                16: this.getDefaultLanguageString()
            };
        }
        const defaultLangId = this.getDefaultLanguage();
        wtSmart.page.data.set(
            `${defaultLangId}.web.configurator.${WebTrekkService.ConfiguratorId}.${pageName}`,
            {
                parameter: {
                    userId: userid,
                    intern: intern,
                    projectId: projectid,
                    projectZip: projectInfo.Zip,
                    projectCity: projectInfo.City,
                    projectCountry: projectInfo.Country,
                    projectAddress: projectInfo.Address
                },
                category: {
                    1: defaultLangId,
                    2: 'web',
                    3: 'configurator',
                    4: WebTrekkService.ConfiguratorId,
                    5: pageName
                }
            }
        );
        if (!this.isFirstTrackEvent) wtSmart.extension.action.reload();
        wtSmart.track();
        this.isFirstTrackEvent = false;
    }

    trackPageCustom(pageName: string, parameters: any) {
        if (!window["wtSmart"]) {
            console.error(
                'expected wtSmart to be defined when trying to sent a track event.'
            );
            return;
        }

        if(!this.isInitialized) {
            this.initTracker();
        }

        // console.log("(" + pageName + ")");
        let trackingParameter = {};
        if (this.isFirstTrackEvent) {
            trackingParameter = {
                15: document.referrer,
                16: this.getDefaultLanguageString()
            };
        }
        const defaultLangId = this.getDefaultLanguage();
        wtSmart.page.data.set(
            `${defaultLangId}.web.configurator.${WebTrekkService.ConfiguratorId}.${pageName}`,
            {
                parameter: parameters,
                category: {
                    1: defaultLangId,
                    2: 'web',
                    3: 'configurator',
                    4: WebTrekkService.ConfiguratorId,
                    5: pageName
                }
            }
        );
        if (!this.isFirstTrackEvent) wtSmart.extension.action.reload();
        wtSmart.track();
        this.isFirstTrackEvent = false;
    }
    /**
     * returns the default language string for the tracking pixel
     * example "de_DE"
     */
    getDefaultLanguageString(): string {
        //TODO implemented after prevista configuratior, add missing languages if needed
        switch (this.getDefaultLanguage()) {
            case "de":
                return "de_DE";
            case "at":
                return "de_AT";
            case "com":
                return "en_INT";
            default:
                return this.getDefaultLanguage();
        }
    }
    /**
     * returns the defaultLanguage
     * in case of a developement system 'test' gets returned
     */
    getDefaultLanguage(): string {
        /* implementation consists of parts copied out of the prevista configurators appStateService,
           since no similar service seems to be existent in this application */
        // TODO proper solution
        const DomainLangUrlMappings = {
            "localhost": 'de',
            "pim-ww-i": 'de',
            "\\.viega\\.at": 'at',
            "-at\\.(dev|int|qa)\\.web\\.emea\\.dir": 'at',
            "\\.viega\\.com": 'com',
            "-com\\.(dev|int|qa)\\.web\\.emea\\.dir": 'com',
            "\\.viega\\.de": 'de',
            "-de\\.(dev|int|qa)\\.web\\.emea\\.dir": 'de',
            "\\.viega\\.cz": 'cz',
            "-cz\\.(dev|int|qa)\\.web\\.emea\\.dir": 'cz',
            "\\.viega\\.be": 'be',
            "-be\\.(dev|int|qa)\\.web\\.emea\\.dir": 'nlbe',
            "\\.viega\\.nl": 'nl',
            "-nl\\.(dev|int|qa)\\.web\\.emea\\.dir": 'nl',
            "\\.viega\\.fr": 'fr',
            "-fr\\.(dev|int|qa)\\.web\\.emea\\.dir": 'fr',
            "\\.viega\\.dk": 'dk',
            "-dk\\.(dev|int|qa)\\.web\\.emea\\.dir": 'dk',
            "\\.viega\\.pl": 'pl',
            "-pl\\.(dev|int|qa)\\.web\\.emea\\.dir": 'pl',
            "\\.viega\\.ru": 'ru',
            "-ru\\.(dev|int|qa)\\.web\\.emea\\.dir": 'ru',
            "\\.viega\\.co\\.uk": 'com',
            "-uk\\.(dev|int|qa)\\.web\\.emea\\.dir": 'com',
            "\\.viega\\.it": 'it',
            "-it\\.(dev|int|qa)\\.web\\.emea\\.dir": 'it'
        };
        const FallbackLanguage = 'com';
        const hostname = window.location.hostname;
        const matchedPattern = Object.keys(DomainLangUrlMappings).find((pattern: string) => {
            const regex = new RegExp(pattern, 'i');
            return regex.test(hostname);
        });
        const matchedLang = DomainLangUrlMappings[matchedPattern];
        return WebTrekkService.isTestDomain() ? 'test' : matchedLang || FallbackLanguage;
    }
    /**
     * returns a boolean stating if the server runs in a test development environment
     */
    static isTestDomain(): boolean {
        return WebTrekkService.TestDomainMatchers.some(
            m => document.URL.indexOf(m) !== -1
        );
    }
}