<viega-collapsible-panel>
    <div class="panel-content" panel-content>
            <viega-panel-header
                [stepTitle]="stepTitle"
                stepText="&nbsp;"
                [stepBackLabel]="stepBack"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>

            <div class="content-wrapper">
                <!-- Top-Wrapper -->
                <div class="top-content-wrapper">
                    <!-- Top -->
                    <div class="top-panel-content">
                            <p class="description-text" *ngIf="stepDescription" >
                                <span [innerHTML]="stepDescription"></span>&nbsp;
                            </p>
                    </div>
                    <!-- Middle -->
                    <div class="mid-panel-content">
                        <div class="panel-content">
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'Dte_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getDteDisplay()}}</span>
                                </div>
                            </div>
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper"> <!-- width-wrapper-tooltip -->
                                    <span>{{'Zirkulationsvolumenstrom_T' | translate}}</span>
                                    <!-- <app-help-icon hinweisId="UFC.Ermittlung.Zirkulationsvolumenstrom"></app-help-icon> -->
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getZirkuDisplay()}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Bot -->
                <div class="bot-panel-content">
                    <div class="result-box">
                        <span class="result-title">{{'Result_T' | translate}}</span>
                        <span class="result-content" *ngIf="ufc">{{getUfcDisplay()}}</span>
                        <span class="result-content"*ngIf="!ufc">-</span>
                        <button psp-button class="e-btn " [disabled]="!ufc" (click)="navigateNext()"> <span>{{'UebernehmenBtn_T' | translate}}</span> </button>
                    </div>
                </div>
            </div>
    </div>

    <div class="content" content>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</viega-collapsible-panel>

<ng-template #content>
    <div class="content">
        <div class="text-tooltip-wrapper text-tooltip-wrapper-titletext">
            <span class="content-title">{{'Headline_T' | translate}}</span>
            <app-help-icon hinweisId="UFC.Ermittlung.Info"></app-help-icon>
        </div>
        <div class="image-container" style="position: relative;">
            <app-ufc-schematic
                [dte]="getDteDisplay()"
                [ufc]="getUfcDisplay()"
                [zirkulationsvolumenstrom]="getZirkuDisplay()"
                [filtervolumenstrom]="getFilterDisplay()"
            ></app-ufc-schematic>

            <app-help-icon
                class="zirkulationsvolumenstrom"
                hinweisId="UFC.Ermittlung.Zirkulationsvolumenstrom"
            ></app-help-icon>

            <app-help-icon
                class="filtervolumenstrom"
                hinweisId="UFC.Ermittlung.Filtervolumenstrom"
            ></app-help-icon>
        </div>
    </div>
</ng-template>