import { Event, EmitType, EventHandler, Component, INotifyPropertyChanged, Property, addClass, removeClass, select } from '@syncfusion/ej2-base';
import { PopupEventArgs, SelectEventArgs } from '@syncfusion/ej2-dropdowns';
import { events } from './events';
import { DataManager } from '@syncfusion/ej2-data';
import { PspButton } from '../../buttons/psp-button/PspButton';
import { PspNotifyIcon } from '../psp-notify-icon/PspNotifyIcon';
import { IPspHelpNotifyIconModel } from './IPspHelpNotifyIconModel';

export class PspHelpNotifyIcon extends PspNotifyIcon implements INotifyPropertyChanged
{
    private static readonly defaultHeaderTemplate = '<h1></h1> <span class="subheader"></span> <hr>';
    private static readonly defaultFooterTemplate = '<hr> <button type="button" />';

    private dropDownButtonUnwireFunc;

    @Property('Viega Hotline')
    public headerText?: string;

    @Property('12345 123456789')
    public subheaderText?: string;

    @Property('Send Feedback')
    public buttonText?: string;

    @Property('e-primary')
    public buttonCssClass?: string;

    @Property('icon-emoji-happy')
    public buttonIconCssClass?: string;

    @Event()
    public buttonClickEvent?: EmitType<MouseEvent>;

    /**
     * Raised when the component has been created and rendered.
     */
    @Event()
    public createdEvent?: EmitType<{}>;

    /**
     * Raised when the component has been destroyed.
     */
    @Event()
    public destroyedEvent?: EmitType<{}>;

    constructor(options?: IPspHelpNotifyIconModel, element?: string | HTMLButtonElement)
    {
        super(options, element);

        if (this.headerTemplate === undefined)
            this.headerTemplate = PspHelpNotifyIcon.defaultHeaderTemplate;

        if (this.footerTemplate === undefined)
            this.footerTemplate = PspHelpNotifyIcon.defaultFooterTemplate;

        if (!this.dropDownCssClass)
            this.dropDownCssClass = 'pspc-notify-icon-dropdown-help';
        else
            this.dropDownCssClass += ' pspc-notify-icon-dropdown-help';
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-help-notify-icon';
    }

    protected render(): void
    {
        super.render();

        this.wireEvents();
    }

    protected dropDown_select(args: SelectEventArgs)
    {
        this.trigger(events.select, args)
    }

    protected dropDown_open(args: PopupEventArgs)
    {
        super.dropDown_open(args);

        const popupEl = args['popup'].element;

        const headerEl = select('h1', popupEl) as HTMLHeadingElement;
        headerEl.innerText = this.headerText;

        const subheaderEl = select('.subheader', popupEl) as HTMLSpanElement;
        if (subheaderEl && this.subheaderText)
            subheaderEl.innerText = this.subheaderText;

        const feedbackButtonEl = select('button', popupEl) as HTMLButtonElement;
        const feedbackButton = new PspButton({
            content: this.buttonText,
            cssClass: this.buttonCssClass,
            iconPosition: 'left',
            customIconCss: this.buttonIconCssClass
        }, feedbackButtonEl);

        const dropDownButton_clickHandler = this.dropDownButton_click.bind(this);
        EventHandler.add(feedbackButton.element, 'click', dropDownButton_clickHandler);
        this.dropDownButtonUnwireFunc = () =>
            EventHandler.remove(feedbackButton.element, 'click', dropDownButton_clickHandler);

        this.trigger(events.open, args);
    }

    protected dropDown_close(args: PopupEventArgs)
    {
        super.dropDown_close(args);
        this.dropDownButtonUnwireFunc();
    }

    protected dropDownButton_click(args: MouseEvent)
    {
        this.trigger(events.buttonClick, args);
    }
}
