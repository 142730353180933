import { NgModule } from '@angular/core';
import { PspRoofSiteHeaderComponent } from './psp-roof-site-header.component';

@NgModule(
{
    declarations:
    [
        PspRoofSiteHeaderComponent,
    ],
    exports:
    [
        PspRoofSiteHeaderComponent,
    ],
})
export class PspRoofSiteHeaderModule {}
