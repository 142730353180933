<viega-collapsible-panel>
    <div class="panel-content" panel-content>

            <viega-panel-header
                [stepText]="stepText"
                [stepTitle]="stepTitle"
                titleHelpId="Speicher.Ergebnis"
                [stepBackLabel]="stepBack"
                (stepBackClicked)="goBack()">
            </viega-panel-header>
            <div class="content-wrapper">
                <!-- Top-Wrapper -->
                <div class="top-content-wrapper">
                    <!-- Top -->
                    <div class="top-panel-content">
                        <p class="description-text" *ngIf="stepDescription" >
                            <span [innerHTML]="stepDescription"></span>&nbsp;
                        </p>
                    </div>
                    <!-- Middle -->
                    <div class="mid-panel-content">
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'Warmwasser_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getDisplayValue("Warmwasser")}}</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'WwBereitung_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getDisplayValue("LeistungFuerWW")}}</span>
                            </div>
                        </div>

                        <div *ngIf="isAbsenkung" class="panel-text-wrapper">
                            <div class="value-title text-tooltip-wrapper">
                                <span>{{'PanelHeadline60Pwh_T' | translate}}</span>
                                <app-help-icon hinweisId="Speicher.Sidebar.Standardbetrieb"></app-help-icon>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'Energiespeicher_T' | translate}} </span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getDisplayValue("Vorlauf")}}</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'Pwh_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getDisplayValue("Pwh")}}</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'Zirkulationsverlust_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getDisplayValue("Zirkulationsverlust")}}</span>
                            </div>
                        </div>
                        <div class="panel-text-wrapper">
                            <div class="width-wrapper">
                                <span>{{'BerechneterWert_T' | translate}}</span>
                            </div>
                            <div class="value-wrapper">
                                <span>{{getDisplayValue("ValueDin12831")}}</span>
                            </div>
                        </div>

                        <ng-container *ngIf="isAbsenkung">
                            <div class="panel-text-wrapper" style="margin-top: 1.5em;">
                                <div class="value-title text-tooltip-wrapper">
                                    <span>{{'PanelHeadlinePwhAbgesenkt_T' | translate: getDisplayValue("PwhEffektiv")}}</span>
                                    <app-help-icon hinweisId="Speicher.Sidebar.AbgesenkterBetrieb"></app-help-icon>
                                </div>
                            </div>
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'VorlaufAbgesenkt_T' | translate}} </span>
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getDisplayValue("VorlaufEffektiv")}}</span>
                                </div>
                            </div>
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'PwhAbgesenkt_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getDisplayValue("PwhEffektiv")}}</span>
                                </div>
                            </div>
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'ZirkulationsverlustAbgesenkt_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getDisplayValue("ZirkulationsverlustAbgesenkt")}}</span>
                                </div>
                            </div>
                            <div class="panel-text-wrapper">
                                <div class="width-wrapper">
                                    <span>{{'BerechneterWertAbgesenkt_T' | translate}}</span>
                                </div>
                                <div class="value-wrapper">
                                    <span>{{getDisplayValue("ValueDin12831Abgesenkt")}}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!-- Bot -->
                <div class="bot-panel-content">
                    <div class="error-message" *ngIf='!isEnoughVersogung'>
                        <i class="icon-warning1"></i>
                        <p class="error-text">{{'ErrorGeringeVorlauftemperatur_D' | translate}}</p>
                    </div>
                    <div class="result-box">
                        <span class="result-title">{{'Result_T' | translate}}</span>
                        <span class="result-content">
                            {{'EnergiespeichervolumenGewählt_T' | translate : getDisplayValue("ErgebnisLiter")}}
                        </span>
                        <button psp-button
                        (click)="onClickSave()"
                        [disabled]="isSaveInvalid() || !isEnoughVersogung"
                            class="e-btn e-small"
                        >{{'UebernehmenBtn_T' | translate}}</button>
                    </div>
                </div>
            </div>

    </div>
    <div class="content" content>
        <div class="text-tooltip-wrapper">
            <span class="content-title">{{'Headline_T' | translate}}</span>
            <app-help-icon hinweisId="Speicher.Summenliniendiagramm"></app-help-icon>
        </div>
        <div class="inner-content" style="margin-top: 1em">

            <!-- Exact Slider -->
            <div *ngIf="selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp == EnergiespeicherErmittlungstyp.Exakt">
                <div class="text-tooltip-wrapper">
                    <span class="titletext" style="font-size: 16px;">{{'ExactSliderTitle_T' | translate}}</span>
                    <app-help-icon hinweisId="Speicher.Detail.Volumenanpassung"></app-help-icon>
                </div>

                <div style="display: flex;">
                    <div class="sliders">
                        <div class="energy-storage-volume">
                            <app-slider-text-combo
                                class="slider-text-combo"
                                style="margin-left: 0em;"
                                [sliderMin]="100"
                                [sliderMax]="5000"
                                [sliderStep]="100"
                                [textboxMin]="100"
                                [textboxMax]="99999"
                                [textboxStep]="100"
                                [textboxDecimals]="0"
                                [textboxClass]="'slider-input'"
                                [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                [showLabel]='false'
                                [(value)]='selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter'
                                (valueChange)="calculateValues()"
                        ></app-slider-text-combo>
                            <!--<div class="slider-wrapper">

                                <ejs-slider
                                    min=0 max=20000 [step]=100
                                    [(value)]='selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter'
                                    (change)="calculateValues()"
                                ></ejs-slider>
                            </div>
                            <div class="text-box-wrapper">
                                <ejs-textbox
                                    class=' slider-input'
                                    type="number"
                                    min=0
                                    max=20000
                                    [(value)]="selectedProject.ProjectValues.Energiespeicher.ErmittlungsESExakt.VolumenDirekteingabeLiter"
                                    floatLabelType="Never"
                                    (change)="calculateValues()"
                                ></ejs-textbox>
                            </div>-->
                            <span style="margin-left: 1em;">{{'#Generic.Unit.Liter_T' | translate}}</span>
                        </div>
                    <div class="text-tooltip-wrapper">
                        <span>{{'AnteilBereitschaftsvolumen_T' | translate}}</span>
                        <app-help-icon hinweisId="Speicher.Bereitschaftsvolumen"></app-help-icon>
                </div>
                        <div class="attendance-volume">
                            <app-slider-text-combo
                                class="slider-text-combo"
                                style="margin-left: 0em;"
                                [sliderMin]="0"
                                [sliderMax]="100"
                                [sliderStep]="1"
                                [textboxMin]="0"
                                [textboxMax]="100"
                                [textboxStep]="1"
                                [textboxDecimals]="2"
                                [textboxClass]="'slider-input'"
                                [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                [showLabel]='false'
                                [(value)]='selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent'
                                (valueChange)="calculateValues()"
                        ></app-slider-text-combo>
                            <!--<div class="slider-wrapper">
                                <ejs-slider min=1 max=100 [step]=1
                                    [(value)]='selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent'
                                    (change)="calculateValues()"
                                ></ejs-slider>
                            </div>
                            <div class="text-box-wrapper">
                                <ejs-textbox class=' slider-input' type="number" min=0 max=20000
                                    [(value)]="selectedProject.ProjectValues.Energiespeicher.AnteilBereitschaftsvolumenProzent"
                                    floatLabelType="Never"
                                    (change)="calculateValues()"
                                ></ejs-textbox>
                            </div>-->
                            <span style="margin-left: 1em;">{{'#Generic.Unit.Percent_T' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Chart PWH60 -->
            <div class="panel-values">
                <div class="text-tooltip-wrapper" style="margin-top: 2em;">
                    <span class="titletext" style="font-size: 16px;">{{'Summenliniendiagramm_T' | translate: getDisplayValue("Vorlauf"): getDisplayValue("Temperatur")}}</span>
                    <app-help-icon hinweisId="Speicher.Summenliniendiagramm.Abgesenkt"></app-help-icon>
                </div>
            </div>
            <div style="margin-top: 1em; width: 100%;">
                <ejs-chart style="margin-top: 1em; border: 1px solid lightgray; " height="100%" width="100%"
                    [primaryYAxis]='graphData.primaryYAxis'
                    [primaryXAxis]='graphData.primaryXAxis'
                    [palettes]='graphData.palette'
                    [legendSettings]='graphData.legendSettings'
                >
                    <e-series-collection>
                        <e-series
                            [dataSource]='graphData.chartDataDemand'
                            type='Line'
                            xName='Minute'
                            yName='Energy'
                            width=2
                            name="{{'Bedarfskennlinie_T' | translate}}"
                            [animation]='graphData.animation'
                        ></e-series>
                        <e-series
                            [dataSource]='graphData.chartDataQsto'
                            type='Line'
                            xName='Minute'
                            yName='Energy'
                            width=2
                            name="{{'QstoOn_T' | translate}}"
                            [animation]='graphData.animation'
                        ></e-series>
                        <e-series
                            [dataSource]='graphData.chartDataSupply'
                            type='Line'
                            xName='Minute'
                            yName='Energy'
                            width=2
                            name="{{'Versorgungskennlinie_T' | translate}}"
                            [animation]='graphData.animation'
                        ></e-series>
                    </e-series-collection>
                </ejs-chart>
            </div>

            <!-- Chart Abgesenkt -->
            <div *ngIf="selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung" style="margin-top: 1em;">
                <div class="text-tooltip-wrapper">
                    <span class="titletext" style="font-size: 16px;">{{'SummenliniendiagrammAbgesenkt_T' | translate: getDisplayValue("VorlaufAbgesenkt"): getDisplayValue("TemperaturAbgesenkt")}}</span>
                    <app-help-icon hinweisId="Speicher.Summenliniendiagramm.Standard"></app-help-icon>
                </div>
                <ejs-chart
                    style="margin-top: 1em; border: 1px solid lightgray; "
                    height="100%"
                    width="100%"
                    [primaryYAxis]='graphData.primaryYAxis'
                    [primaryXAxis]='graphData.primaryXAxis'
                    [palettes]='graphData.palette'
                    [legendSettings]='graphData.legendSettings'
                >
                    <e-series-collection>
                        <e-series
                            [dataSource]='graphData.chartDataDemand'
                            type='Line'
                            xName='Minute'
                            yName='Energy'
                            width=2
                            name="{{'Bedarfskennlinie_T' | translate}}"
                            [animation]='graphData.animation'
                        ></e-series>
                        <e-series
                            [dataSource]='graphData.chartDataQstoAbgesenkt'
                            type='Line'
                            xName='Minute'
                            yName='Energy'
                            width=2
                            name="{{'QstoOn_T' | translate}}"
                            [animation]='graphData.animation'
                        ></e-series>
                        <e-series
                            [dataSource]='graphData.chartDataSupplyAbgesenkt'
                            type='Line'
                            xName='Minute'
                            yName='Energy'
                            width=2
                            name="{{'Versorgungskennlinie_T' | translate}}"
                            [animation]='graphData.animation'
                        ></e-series>
                    </e-series-collection>
                </ejs-chart>
            </div>
        </div>
    </div>

</viega-collapsible-panel>
