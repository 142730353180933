import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { log } from 'src/core-lib/logging';
import { IChangeRoutePayload } from './shell-messages/IChangeRoutePayload';
import { IClientShellMessage } from './shell-messages/IClientShellMessage';
import { IPermissionsChangedMessage } from './shell-messages/IPermissionsChangedPayload';
import { IRequestClosePayload } from './shell-messages/IRequestClosePayload';
import { IShellMessage } from './shell-messages/IShellMessage';

/**
 * Sends and receives messages from client applications hosted in this shell.
 */
export class AppShellHostService
{
    private clientFrames: { [clientId: string]: HTMLIFrameElement } = {};

    /**
     * Subscribe to this in order to receive messages from the app shell host.
     */
    public hostMessagesObservable: Observable<IClientShellMessage>;

    constructor()
    {
        this.hostMessagesObservable = fromEvent(window, 'message')
            .pipe(
                map((args: MessageEvent) =>
                {
                    if (!args.data.type || !args.data.type.startsWith('Psp'))
                        return null;

                    const clientId: string = this.clientIdByEventSource(args.source);
                    if (!clientId)
                        return null;

                    return {
                        clientId,
                        ...args.data,
                    };
                }),
                filter(shellMsg => shellMsg !== null)
            );
    }

    private clientIdByEventSource(eventSource: MessageEventSource): string
    {
        return Object.keys(this.clientFrames).find(clientId => this.clientFrames[clientId].contentWindow === eventSource);
    }

    /**
     * Adds the iframe registration of an consuming client application so that messages can be sent to it.
     */
    public registerClient(iFrame: HTMLIFrameElement, clientId: string): void
    {
        this.clientFrames[clientId] = iFrame;
    }

    /**
     * Removes the iframe registration of an consuming client application.
     */
    public unregisterClient(clientId: string): void
    {
        delete this.clientFrames[clientId];
    }

    /**
     * Tells a client application's router to navigate to the given route.
     */
    public changeRoute(clientId: string, targetRoute: string): void
    {
        this.sendMessageToClient(clientId,
        {
            type: 'PspChangeRoute',
            payload:
            {
                targetRoute
            } as IChangeRoutePayload
        });
    }

    /**
     * Notifies a client application about a permission or user id change.
     */
    public notifyPermissionsChanged(clientId: string, newUserIdentity?: string): void
    {
        this.sendMessageToClient(clientId,
        {
            type: 'PspPermissionsChanged',
            payload:
            {
                newUserIdentity
            } as IPermissionsChangedMessage
        });
    }

    /**
     * Notifies a client application that the active assortment of the current user has changed.
     */
    public notifyActiveAssortmentChange(clientId: string): void
    {
        this.sendMessageToClient(clientId,
        {
            type: 'PspNotifyActiveAssortmentChange',
        });
    }

    /**
     * Notifies a client application that the named assortments of the current user have changed.
     */
    public notifyNamedAssortmentsChange(clientId: string): void
    {
        this.sendMessageToClient(clientId,
        {
            type: 'PspNotifyNamedAssortmentsChange',
        });
    }

    /**
     * Sends a close request to the client application. It is expected to answer with a 'CloseSelf' message.
     */
    public requestClose(clientId: string, isForce?: boolean): void
    {
        this.sendMessageToClient(clientId,
        {
            type: 'PspRequestClose',
            payload:
            {
                isForce
            } as IRequestClosePayload
        });
    }

    private sendMessageToClient(clientId: string, messageObj: IShellMessage): void
    {
        const clientFrame: HTMLIFrameElement = this.clientFrames[clientId];
        if (!clientFrame)
        {
            log.warning({ clientId, message: messageObj }, 'Tried to send message to a clientId without iframe registration.');
            return;
        }

        clientFrame.contentWindow.postMessage(messageObj, '*');
    }
}
