import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaseDataDialogComponent } from './base-data-dialog.component';


@NgModule({
    imports:
    [
       SharedModule
    ],
    declarations:
    [
        BaseDataDialogComponent
    ],
    exports:[
        BaseDataDialogComponent

    ]


})
export class BaseDataDialogModule { }