import { COMPONENT_CONTEXT } from './constants';
import { LangService } from '../ej2/services/LangService';
import { UserStorageService } from '../ej2/services/UserStorageService';

export function provideContext(contextKey: string)
{
    return [
        { provide: COMPONENT_CONTEXT, useValue: contextKey },
        LangService,
        UserStorageService,
    ];
}
