import { L10n, merge } from '@syncfusion/ej2-base';

export const localization = {
    'de': {
        'psp-contextual-help': {
            'DocumentationLink': 'Zugehörige Hilfeseite öffnen'
        },
    },
    'en-US': {
        'psp-contextual-help': {
            'DocumentationLink': 'Open Help Page'
        },
    },
    'en-GB': {
        'psp-contextual-help': {
            'DocumentationLink': 'Open Help Page'
        },
    }
};

merge(localization['en-US'], localization['en-GB']);
