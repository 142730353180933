import { Injectable } from '@angular/core';
import { NumberFormatOptions } from '@syncfusion/ej2-base';
import { LangService } from 'src/core-lib/ej2/services/LangService';

export type UnitTypes =
    'DegreeCelsius' |   // x °C
    'Liter' |           // x Liter
    'LiterShort' |      // x l
    'LiterPerDay' |     // x l/Tag
    'LiterPerSecond'|   // x l/s
    'LiterPerMinute' |  // x l/min
    'LiterPerHour' |    // x l/h
    'Minutes' |         // x min
    'Percent' |         // x %
    'Centimeter' |      // x cm
    'KiloWatts' |       // x kW
    'Watts' |           // x W
    'Hektopascal' |     // x hPa
    'DiameterNominal' | // DN x
    'WattsPerMinute' |  // x W/m
    'HektopascalInBar'| // hPa (mbar)
    'Hektopascal';      // hPa

@Injectable()
export class ValueFormatterService
{
    constructor(
        private langService: LangService
    ) {}

    public formatNumber(value: number, unit?: UnitTypes, options?: NumberFormatOptions, ceilValue: boolean = false): string
    {
        // If desired, make sure to always round up before calling the langService.FormatNumber method as it will otherwise round normally
        if(ceilValue) {
            /* Select default decimals the same way as the called method:
            *  "Der Standardwert für einfache Zahlen ist die größere Zahl von minimumFractionDigits und 3"
            *    -> https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
            */
            var decimals = options?.maximumFractionDigits ?? (Math.max(3, options?.minimumFractionDigits ?? 0));
            if(decimals == 0) {
                value = Math.ceil(value); // Ceil to next integer
            } else if(decimals > 0){
                let power = Math.pow(10, decimals); // Determine how many places to move the decimal seperator up
                value =  Math.ceil(value * power) / power; // Ceil the scaled up value and move the decimal seperator back down
            }
        }

        // Format number string from value
        let formattedNumber: string = this.langService.formatNumber(value, options);
        if (unit)
            formattedNumber = this.langService.getString('#Generic.UnitFormats.' + unit).replace('{0}', formattedNumber);

        return formattedNumber;
    }

    public formatDteName(dte: '40' | '70' | '100' | string): string
    {
        return this.langService.getString('#Generic.ProductNameFormats.Dte').replace('{0}', dte);
    }

    public formatUfcName(ufc: '40' | '70' | '100' | string): string
    {
        return this.langService.getString('#Generic.ProductNameFormats.Ufc').replace('{0}', ufc);
    }
}
