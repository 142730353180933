import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { select } from '@syncfusion/ej2-base';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { DimensioningSchematicType, ProjectService } from 'src/app/core/ProjectService';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { log } from 'src/core-lib/logging';

@Component({
    selector: 'app-dimensioning-schematic',
    templateUrl: './dimensioning-schematic.component.html',
    styleUrls: ['./dimensioning-schematic.component.scss'],
})
export class DimensioningSchematicComponent implements AfterViewInit
{
    // This style block is injected into the iframe as the svg is loaded.
    private static svgStyles: string = Object.freeze(
    `
        @font-face {
            font-family: "viega-thin";
            src: url("assets/iframe-fonts/viega-thin.eot?#iefix");
            src: local("?"),url("assets/iframe-fonts/viega-thin.eot?#iefix") format("eot"),url("assets/iframe-fonts/viega-thin.woff2") format("woff2"),url("assets/iframe-fonts/viega-thin.woff") format("woff"),url("assets/iframe-fonts/viega-thin.ttf") format("truetype");
        }

        body {
            margin: 0;
        }

        ::selection {
            background-color: #ffed00;
        }

        .schematic.xml-svg-img text {
            font-family: "viega-thin", Helvetica, Roboto, Arial, sans-serif;
        }
    `);

    /** The aspect factor between the width and height of the svg image. */
    private loadedImageVAspect: number;

    protected isImageLoaded: boolean = false;

    protected schematicType: DimensioningSchematicType = 'WholeProject';

    /**
     * We use an iFrame to host the svg for several reasons:
     *   - "id" attributes of the svg wont collide with our ids in the regular html
     *   - an iframe has its own separated css styling (the current styling of the site would corrupt the image)
     */
    @ViewChild('svgContainer')
    public iframeElRef: ElementRef;

    public constructor(
        protected langService: LangService,
        protected projectService: ProjectService,
        protected aggregatorDataService: AggregatorDataService,
        private elementRef: ElementRef
    ) {}

    public async ngAfterViewInit(): Promise<any>
    {
        await this.loadImage();
    }

    private async loadImage(): Promise<any>
    {
        if (!this.iframeElRef)
            return;

        const locale = this.langService.currentLocale;

        const iframeEl: HTMLIFrameElement = this.iframeElRef.nativeElement;
        const svgXmlRaw = await this.projectService.getSchematic(this.schematicType, locale);
        if (!svgXmlRaw.startsWith("<svg"))
            throw new Error('Expected the image to be an xml based svg image.');

        const loadedHandler = () =>
        {
            this.onImageLoaded();
            iframeEl.removeEventListener('load', loadedHandler);
        };
        iframeEl.addEventListener('load', loadedHandler);
        iframeEl.contentWindow.document.open();
        iframeEl.contentWindow.document.write(`<style>${DimensioningSchematicComponent.svgStyles}</style>`);
        iframeEl.contentWindow.document.write(svgXmlRaw);
        iframeEl.contentWindow.document.close();
    }

    @HostListener('window:resize', ['$event'])
    private window_resize(): void
    {
        this.autoSizeRelativeToWidth();
    }

    /**
     * Calculates a new height for this component based on its current width.
     */
    public autoSizeRelativeToWidth(): void
    {
        const selfEl: HTMLDivElement = this.elementRef.nativeElement;
        const desiredHeight = Math.round(selfEl.offsetWidth * this.loadedImageVAspect);
        selfEl.style.height = `${desiredHeight}px`;
    }

    public async refreshImage(): Promise<void>
    {
        this.isImageLoaded = false;
        await this.loadImage();
    }

    protected onImageLoaded(): void
    {
        const iframeEl: HTMLIFrameElement = this.iframeElRef.nativeElement;
        const svgEl: SVGElement = select('svg', iframeEl.contentWindow.document);
        svgEl.classList.add('schematic', 'xml-svg-img');

        this.loadedImageVAspect = svgEl.clientHeight / svgEl.clientWidth;
        this.autoSizeRelativeToWidth();
        this.isImageLoaded = true;
    }

    protected setTextElementValue(textId: string, textValue: string): void
    {
        const iframeEl: HTMLIFrameElement = this.iframeElRef.nativeElement;
        const svgTextEl: SVGTextElement = select(`text[id='${textId}']`, iframeEl.contentWindow.document);
        if (!svgTextEl)
        {
            log.warning({ textId }, 'SVG Text element was not found.');
            return;
        }

        svgTextEl.innerHTML = textValue;
    }
}
