export class PimStatic
{
    /**
     * The name of the request header meant to contain the psp identity for authorization purposes.
     */
    public static readonly identityRequestHeader = 'X-Identity';

    /**
     * The name of the always psp system identity which should always exist.
     */
    public static readonly pspPrivilegedSystemIdentity = 'PspLocal;System';

    /**
     * Indicates the locale of the label file which should be used as the base data file for label data.
     * Other language will override the label data of the base data.
     */
    public static readonly BaseLocale = 'en-US';

    /**
     * Indicates the locale used in frontends by default if no other locale can be determined.
     */
    public static readonly FallbackLocale = 'en-US';

    /**
     * A Syncfusion conform date format string representing the format DateTimeOffsets are returned with from the server.
     */
    public static readonly ODataDateResponseFormat = '';

    public static readonly TooltipDelayMs = 500;
}
