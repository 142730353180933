<viega-collapsible-panel>
    <div class="panel-content" panel-content>
            <div class="top-panel-content">
                <viega-panel-header
                    [stepTitle]="title"
                    [stepDescription]="description"
                >
                </viega-panel-header>
            </div>

    </div>


    <div class="content" content>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</viega-collapsible-panel>

<ng-template #content>
    <div class="content">
        <div class="overview-top-content">
            <div class="text-tooltip-wrapper">
                <span class="top-content-title"><b>{{'Headline_T' | translate}}</b></span>
                <app-help-icon hinweisId="Projektliste.Übersicht"></app-help-icon>
            </div>
            <div class="right-button">
                <button psp-button class="e-btn new-project-btn" iconPosition="none" #ButtonInstance (click)="openBaseDialog()" customCss="e-control e-psp-button e-lib e-btn pspc-button">
                    <span class="" ><i class="icon-plus"></i>{{'NewProjektBtn_T' | translate}}</span>
                </button>
            </div>
        </div>

        <div class="overview-table">
            <div class="control-section ">
                <ejs-grid #grid  clipMode='EllipsisWithTooltip' [dataSource]='data' height='35em' (dataBound)="dataBound($event)" [searchSettings]="" (created)='gridCreated()' [toolbar]="gridToolbarOptions" [allowSorting]='true' [sortSettings]="gridSortOptions" >
                    <e-columns>
                        <e-column headerTextAlign="Left" clipMode="EllipsisWithTooltip" [customAttributes]="{class: 'field-pointer'}" field='ProjectName' headerText="{{'Table.HeadlineProjektname_T' | translate}}" width='20%' textAlign='Left'>
                            <ng-template #template let-data>
                                <div (click)="rowSelected(data)" class=" project-name-wrapper" >
                                    <span class="project-name">{{data.ProjectName}}</span>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column headerTextAlign="Left" clipMode="EllipsisWithTooltip"  width='20%' textAlign='Left' field="ProjectBuildSection" headerText="{{'Table.HeadlineBauabschnitt_T' | translate}}">
                            <ng-template #template let-data>
                                <span>{{data.ProjectBuildSection}}</span>
                            </ng-template>
                        </e-column>
                        <e-column  headerTextAlign="Left" clipMode="EllipsisWithTooltip" field='ProjectNumber' headerText="{{'Table.HeadlineProjektnummer_T' | translate}}" width='20%' textAlign='Left'></e-column>
                        <e-column fheaderTextAlign="Left" clipMode="EllipsisWithTooltip"  field='CreatedUtc' headerText="{{'Table.HeadlineErstellt_T' | translate}}" width='15%' format="yMd" textAlign='Left'></e-column>
                        <e-column headerTextAlign="Left" clipMode="EllipsisWithTooltip"  field='LastUpdatedUtc' headerText="{{'Table.HeadlineGeändert_T' | translate}}" width='20%' format='yMd' textAlign='Left'></e-column>
                        <e-column headerText=' ' width='2.5%' format="yMd" textAlign='Left' clipMode="Clip">
                            <ng-template #template let-data>
                                <div>
                                    <a class="icon-copy overview-icon" (click)="copyElement(data.ProjectId)">
                                    </a>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column headerText=' ' width='2.5%' textAlign='Left' clipMode="Clip">
                            <ng-template #template let-data>
                                <div>
                                    <a class="icon-close overview-icon" (click)="removeElement(data.ProjectId)">
                                    </a>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</ng-template>


<app-base-data-dialog
    #baseDataDialog
    (closedCompletely)="resetDialogs($event)"
    (dialogPristine)="checkDialogsPristine()"
    target="app-overview"
    [buttons]="baseDataButtons"
    [headerText]="baseDataHeader"
    [editingProject]="newProject"
    (projectEdited)="setSelectedProjectValues($event)"
></app-base-data-dialog>

<app-structure-dialog
    #structureDialog
    changeWarning="{{'#Overview.StructureDialogWarning_SD' | translate}}"
    (closedCompletely)="resetDialogs($event)"
    (dialogPristine)="checkDialogsPristine()"
    target='app-overview'
    [(selectedStructuresOther)]="selectedStructuresOther"
    [(selectedStructureResidential)]="selectedStructureResidential"
    [buttons]='structureButtons'
    [headerText]='structureHeader'
    [projectName]="newProject.ProjectName"
></app-structure-dialog>

<app-config-dte-dialog
    #configDteDialog
    changeWarning="{{'#Overview.ConfigDteDialogWarning_SD' | translate}}"
    (closedCompletely)="resetDialogs($event)"
    (dialogPristine)="checkDialogsPristine()"
    [(dteUfcChecked)]="isDteUfcChecked"
    target="app-overview"
    [buttons]='configDteButtons'
    [headerText]='configDteHeader'
    [projectName]="newProject.ProjectName"
></app-config-dte-dialog>

<app-config-details-dialog
    #configDetailsDialog
    changeWarning="{{'#Overview.ConfigDetailsWarning_SD' | translate}}"
    (closedCompletely)="resetDialogs($event)"
    (dialogPristine)="checkDialogsPristine()"
    [(isPipeChecked)]="isPipeChecked"
    [(isEnergyChecked)]="isEnergyChecked"
    target="app-overview" [buttons]='configDetailsButtons'
    [headerText]='configDetailsHeader'
    [projectName]="newProject.ProjectName"
></app-config-details-dialog>


<ejs-dialog
    #deleteProject
    target='app-overview'
    header="{{'DeleteProject.Headline_T' | translate}}"
    [buttons]='deleteProjectButtons'
    [visible]='false'
    [isModal]=true
    [closeOnEscape]='false'
    [showCloseIcon]='true'
    [width]='dialogWidth'
    [position]='dialogPosition'
>
    <ng-template #content>
            <span [innerHtml]="'DeleteProject.Description_D_Html' | translate"></span>
    </ng-template>
</ejs-dialog>

<ejs-dialog #copyProject target='app-overview' header="{{'CopyProject.Headline_T' | translate}}" [buttons]='copyProjectButtons' [visible]='false' [isModal]=true [closeOnEscape]='false' [showCloseIcon]='true' [width]='dialogWidth' [position]='dialogPosition'>
    <ng-template #content>
            <span [innerHtml]="'CopyProject.Description_D_Html' | translate"></span>
    </ng-template>
</ejs-dialog>

<ejs-dialog #closeDialog target='app-overview' header="{{'CloseDialog.Headline_T' | translate}}" [buttons]='closeDialogButtons' [visible]='false' [isModal]=true [closeOnEscape]='false' [showCloseIcon]='false' width='40em' [position]='dialogPosition'>
    <span>{{'CloseDialog.Description_D_Html' | translate}}</span>
</ejs-dialog>

