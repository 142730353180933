import { Ajax } from '@syncfusion/ej2-base';
import { log } from 'src/core-lib/logging';
import { environment } from 'src/environments/environment';
import { IIdentityPermission, IRuntimeInfo } from './IRuntimeInfo';
import { PimStatic } from '../utils/PimStatic';
import { ServiceStatic } from '../utils/ServiceStatic';

export interface IIdentityInfo
{
    identityId?: string;
    identityIdHashed?: string;
    permissionIds?: { [permissionId: string]: boolean };
    groupIds: { [groupId: string]: boolean };
    eMail: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    tags: string[];
    defaultLocale: string;
}

export class RuntimeService
{
    private static cachedRuntimeInfo: IRuntimeInfo;
    private static runtimeInfoRefreshPromise: Promise<void>;

    public get isRuntimeInfoLoaded(): boolean
    {
        return RuntimeService.cachedRuntimeInfo !== undefined;
    }

    public get runtimeInfo(): IRuntimeInfo | undefined
    {
        return RuntimeService.cachedRuntimeInfo;
    }

    /**
     * Downloads a runtime info from the server if it's not available yet. If a download is already running
     * returns a promise which resolves when it is completed. The promise resolves right away if a runtime
     * info is already available.
     */
    public ensureRuntimeInfo(): Promise<void>
    {
        if (!this.runtimeInfo)
            return this.refreshRuntimeInfo();
        else
            return Promise.resolve();
    }

    public refreshRuntimeInfo(): Promise<void>
    {
        if (RuntimeService.runtimeInfoRefreshPromise)
            return RuntimeService.runtimeInfoRefreshPromise;

        const endpoint = environment.backendRootUrl + ServiceStatic.runtimeInfoEndpoint;
        const request = new Ajax(endpoint, 'GET');
        request.beforeSend = () =>
            request.httpRequest.setRequestHeader(PimStatic.identityRequestHeader, PimStatic.pspPrivilegedSystemIdentity);

        RuntimeService.runtimeInfoRefreshPromise = request.send()
            .then((responseData: any) =>
            {
                RuntimeService.cachedRuntimeInfo = this.parseRuntimeData(responseData);
                delete RuntimeService.runtimeInfoRefreshPromise;
            })
            .catch((error: Error) =>
            {
                log.fatal({ endpoint, error: error.message }, 'Failed to receive runtime information from backend.');
                return Promise.reject(error);
            });

        return RuntimeService.runtimeInfoRefreshPromise;
    }

    /**
     * Returns the value of a configuration setting that has been published by the backend.
     *
     * @param defaultValue Setting this to undefined will throw an error if the config setting is not set. Otherwise will return this value.
     */
    public getConfigValue(settingKey: string, defaultValue: any = undefined): any
    {
        this.throwIfRuntimeInfoMissing();

        const configValue = this.runtimeInfo.Config[settingKey];
        if (configValue === undefined)
        {
            if (defaultValue === undefined)
                throw new Error(`Config setting "${settingKey}" from backend is not set.`);

            return defaultValue;
        }

        return configValue;
    }

    private throwIfRuntimeInfoMissing()
    {
        if (!this.runtimeInfo)
            throw new Error('The runtime information object has not been retrieved from the backend yet.');
    }

    private parseRuntimeData(rawData: string): IRuntimeInfo
    {
        try
        {
            return JSON.parse(rawData);
        }
        catch (error)
        {
            throw new Error('Failed to parse json runtime data received from backend.');
        }
    }
}
