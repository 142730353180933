import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Injector, Renderer2, ViewContainerRef } from '@angular/core';
import { ComponentBase, ComponentMixins, IComponentBase, setValue, Template } from 'src/core-lib/angular/util';
import { PspNotifyIcon } from 'src/core-lib/ej2/components/navigation/psp-notify-icon/PspNotifyIcon';

// properties declared here can be changed by the user in the storyboard UI
export const propertyArgTypes =
{
    hintType: { control: { type: 'radio', options: ['None', 'Unread', 'Urgent'] } },
    iconTooltip: { control: 'text' },
    iconCssClass: { control: 'text' },
    dropDownCssClass: { control: 'text' },
    cssClass: { control: 'text' },
    popupWidth: { control: 'text' },
    popupHeight: { control: 'text' },
};

// declare some properties as bindable angular inputs
const inputBindings =
[
    'itemDataSource',
    'iconTooltip',
    'iconCssClass',
    'cssClass',
    'hintType',
    'headerTemplate',
    'itemTemplate',
    'footerTemplate',
    'popupWidth',
    'popupHeight',
];

// prevent storybook from updating these properties
const changeIgnoredProperties: string[] = [];

// declare some properties as bindable angular outputs
const outputBindings =
[
    'beforeOpenEvent',
    'openEvent',
    'closeEvent',
    'clickEvent',
    'selectEvent',
    'createdEvent',
    'destroyedEvent',
];

@Component({
    selector: 'psp-notify-icon',
    template: '',
    inputs: inputBindings,
    outputs: outputBindings,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@ComponentMixins([ComponentBase])
export class PspNotifyIconComponent extends PspNotifyIcon implements IComponentBase
{
    /** @ignore */
    public tagObjects: any = [];

    /** @ignore */
    public context : any;

    /** @ignore */
    public tags: string[] = [];

    /**
     * Accepts the template design and assigns it to the footer container of the popup list.
     * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
     * @default null
     */
    @ContentChild('itemTemplate')
    @Template()
    public itemTemplate: any;

    /**
     * Accepts the template design and assigns it to the header container of the popup list.
     * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
     * @default null
     */
    @ContentChild('headerTemplate')
    @Template()
    public headerTemplate: any;

    /**
     * Accepts the template design and assigns it to the header container of the popup list.
     * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
     * @default null
     */
    @ContentChild('footerTemplate')
    @Template()
    public footerTemplate: any;

    protected changeIgnoredPropertyMap: { [propertyName: string]: boolean };

    public constructor(
        private ngEle: ElementRef,
        private srenderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    )
    {
        super();

        this.element = this.ngEle.nativeElement;

        this.changeIgnoredPropertyMap = {};
        changeIgnoredProperties.forEach(prop => this.changeIgnoredPropertyMap[prop] = true);

        this.registerEvents(outputBindings);
        this.addTwoWay.call(this, []);
        setValue('currentInstance', this, viewContainerRef);
        this.context  = new ComponentBase();
    }

    /** @ignore */
    public ngOnInit(): void
    {
        this.context.ngOnInit(this);
    }

    /** @ignore */
    public ngAfterViewInit(): void
    {
        this.context.ngAfterViewInit(this);
    }

    /** @ignore */
    public ngOnDestroy(): void
    {
        this.context.ngOnDestroy(this);
    }

    /** @ignore */
    public ngAfterContentChecked(): void
    {
        this.context.ngAfterContentChecked(this);
    }

    /** @ignore */
    public registerEvents: (eventList: string[]) => void;

    /** @ignore */
    public addTwoWay: (propList: string[]) => void;
}
