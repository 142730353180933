import { log } from 'src/core-lib/logging';
import { RuntimeService } from './RuntimeService';
import { ServiceLocator } from './ServiceLocator';

export class UrlResolverService
{
    private runtimeService: RuntimeService;
    private readonly frontendFallbackRoot: string;
    private readonly urlMappings: { [sourceUrl: string]: string };

    public constructor()
    {
        this.runtimeService = ServiceLocator.get(RuntimeService);

        this.frontendFallbackRoot = this.runtimeService.getConfigValue('UrlResolverService:FrontendFallbackRoot');
        const mappingRules: string[] = this.runtimeService.getConfigValue('UrlResolverService:FrontendMappingRules', []);

        this.urlMappings = {};
        mappingRules.forEach(ruleItem =>
        {
            const ruleComponents: string[] = ruleItem.split('|');

            const sourceUrl = ruleComponents[0];
            const destUrl = ruleComponents[1];
            this.urlMappings[sourceUrl] = destUrl;
        });
    }

    public resolveServiceUrl(url: string): string
    {
        if (!url.startsWith("//"))
            return url;

        let resolvedUrl: string;

        var matchingKey = Object.keys(this.urlMappings).find(sourceUrl => url.startsWith(sourceUrl));
        const isMatch: boolean = matchingKey !== undefined;
        if (isMatch)
            resolvedUrl = this.urlMappings[matchingKey] + url.substring(matchingKey.length);
        else
            resolvedUrl = this.frontendFallbackRoot + url.substring(2);

        resolvedUrl = resolvedUrl.replace('{origin}', location.origin);
        return resolvedUrl;
    }
}
