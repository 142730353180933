import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { StructureItemComponent } from '../structure-item/structure-item.component';
import { StructureDialogComponent } from './structure-dialog.component';


@NgModule({
    imports:
    [
       SharedModule
    ],
    declarations:
    [
        StructureDialogComponent,
        StructureItemComponent

    ],
    exports:[
        StructureDialogComponent,
        StructureItemComponent


    ]


})
export class StructureDialogModule { }