import { Injectable } from '@angular/core';
import { DailyNeedEn12831UsageRow } from '../DteufcAggregatorServiceApi/dailyneeden12831usagerow.complex';
import { MathLogger } from './MathLogger';

export interface IHotWaterNeedDinEn12831Params
{
    nutzung: DailyNeedEn12831UsageRow;
    nutzungsabhaengigAnzahl: number;
    flaechenbezogenAnzahl?: number;
}

/**
 * Hot water (Warmwasser) related mathematic functions.
 *
 * Sources of mathematical formulas:
 * - 210716-V1.0 Erfassungsbogen_DTE-UFC-Speicher.xlsm
 * - 210709-KRA-V1.2-12831-211011.xlsm
 *
 * rauch@klaus-rauch.de
 */
@Injectable()
export class HotWaterMath
{
    /**
     * Kaltwassertemperatur
     */
    public static readonly Pwc = 10; // °C

    /**
     * Should be called for each building type.
     * Ergebnis in l/Tag.
     */
    public warmwassertagesbedarfFromNutzungDinEn12831(params: IHotWaterNeedDinEn12831Params): number
    {
        const logger = new MathLogger('WarmwassertagesbedarfFromNutzungDinEn12831');
        logger.logInputs(params);

        const bedarfNutzungsabhaengigLTag = params.nutzungsabhaengigAnzahl * params.nutzung.SpezifischerBedarfLiterTag;
        const bedarfFlaechenbezogenLTag = (params.flaechenbezogenAnzahl || 0) * (params.nutzung.SpezifischerBedarfFlaecheLiterTag || 0);
        const bedarfSumme = bedarfNutzungsabhaengigLTag + bedarfFlaechenbezogenLTag;

        if (params.flaechenbezogenAnzahl && !params.nutzung.SpezifischerBedarfFlaecheLiterTag)
            logger.logWarning('FlächenbezogenAnzahl is given even though SpezifischerBedarfFlaecheLiterTag is unset.');

        logger.logResult(
        {
            bedarfNutzungsabhaengigLTag,
            bedarfFlaechenbezogenLTag,
            bedarfSumme,
        });
        return bedarfSumme;
    }

    /**
     * Should be called for each building type.
     * Ergebnis in kWh/Tag
     */
    public energiebedarfProTag(warmwasserTagesbedarf: number, bedarfsTemperatur: number): number
    {
        const x = (1000 - 0.005 * Math.pow(bedarfsTemperatur - 4, 2)) / 1000;
        const result = (warmwasserTagesbedarf * x * 4.19 * (bedarfsTemperatur - HotWaterMath.Pwc)) / 3600;

        return result;
    }
}
