import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { ProjectConfigurationItemComponent } from '../project-configuration-item/project-configuration-item.component';
import { UserService } from 'src/app/core/UserService';

@Component({
  selector: 'app-config-dte-dialog',
  templateUrl: './config-dte-dialog.component.html',
  styleUrls: ['./config-dte-dialog.component.scss'],
  providers: [provideContext("ConfigDteDialog")],
})
export class ConfigDteDialogComponent implements OnInit {

    @ViewChild('dialog') public dialog: DialogComponent;

    @ViewChild('Dte') public dte: ProjectConfigurationItemComponent;
    @ViewChild('DteUfc') public dteufc: ProjectConfigurationItemComponent;


    @Input() target: string;
    @Input() buttons: any; //Typ???
    @Input() headerText: string; //Immer gleich???

    dialogWidth: string = "55%"; //Immer gleich???
    dialogHeight: string = "90vh"; //Immer gleich???
    dialogPosition: PositionDataModel = {X: 'center', Y: 'center'}

    @Input() projectName: string;

    @Input() dteUfcChecked: boolean;
    _dteUfcChecked: boolean;
    @Output() dteUfcCheckedChange = new EventEmitter<boolean>();

    @Output() closedCompletely = new EventEmitter<any>()
    isClosedWithoutSave: boolean = true;

    canBeClosed: boolean = false;

    @Input() isConfigDetailsDialogPristine: boolean;

    @Output() dialogPristine = new EventEmitter<any>()

    @Input() changeWarning: string;

    imageDte: string;
    imageDteUfc: string;


    constructor(
        private aggregatorDataService: AggregatorDataService,
        public userService: UserService
    ){

    }

    ngOnInit(): void {
       var images = this.aggregatorDataService.aggregatorData.Tables.ImageAssetRows;

       this.imageDte = images.find(i => i.ImageId == 'Projektkonfiguration.DteStandardbetrieb').ImageUrlLoc;
       this.imageDteUfc = images.find(i => i.ImageId == 'Projektkonfiguration.DteMitTemperaturabsenkung').ImageUrlLoc;

        this.resetDialog();
    }

    onClickConfigDte(index:number){
        if(index == 1){
            this.dte.itemCheckBox.checked = false;
            this.dteufc.itemCheckBox.checked = true;
            this._dteUfcChecked = true;
        }
        else{
            this.dteufc.itemCheckBox.checked = false;
            this.dte.itemCheckBox.checked = true;
            this._dteUfcChecked = false;
        }
    }

    emitChange(){
        this.dteUfcChecked = this._dteUfcChecked;
        this.dteUfcCheckedChange.emit(this.dteUfcChecked);
    }

    onOverlayClick(){
        this.dialog.hide();
    }

    onCloseDialog(){
        if(this.isClosedWithoutSave){
            this.closedCompletely.emit();
            this.resetDialog();
        }
    }

    onBeforeClose(event){
        if(this.target == 'app-overview'){
            if(this.isClosedWithoutSave){
                if(!this.canBeClosed){
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
        else{
            if(this.isClosedWithoutSave){
                if(!this.canBeClosed && (!this.checkIfPristine() || !this.isConfigDetailsDialogPristine)){
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
    }

    resetDialog(){
        if(this.target == 'app-overview'){
            this._dteUfcChecked = false;
        }
        else{
            this._dteUfcChecked = this.dteUfcChecked;
        }
    }

    checkIfPristine(): boolean{
        if (this._dteUfcChecked != this.dteUfcChecked) {
            return false;
        }
        else{
            return true;
        }
    }

}
