<ejs-dialog #dialog
    (close)="onCloseDialog()"
    (beforeClose)="onBeforeClose($event)"
    (open)="onOpenDialog()"
    [target]="target"
    (overlayClick)="onOverlayClick()"
    [buttons]='buttons'
    [header]='headerText'
    [isModal]=true
    [closeOnEscape]='false'
    [visible]='false'
    [height]='dialogHeight' [minHeight]="dialogHeight"
    [showCloseIcon]='true'
    [width]='dialogWidth' [position]='dialogPosition'>
    <ng-template #header>
        <div class="text-tooltip-wrapper text-tooltip-wrapper-header">
            <span>{{headerText}}</span>
            <app-help-icon hinweisId="Projektstammdaten"></app-help-icon>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="base-data" >
            <div class="base-data-form form-fields yellow-effect" >
                <div class="project-information" [formGroup]="projectInformationFields.group">
                    <div class="app-form-columns-container">
                        <div class="app-form-column">
                            <psp-form-field *localVar="projectInformationFields.fieldMap['ProjectName'] as field" [formField]="field" >
                                 <input
                                     [formControl]="field.control"
                                     [id]="field.fieldName"
                                     [name]="field.fieldName"
                                     type="text"
                                     class="e-input"
                                     spellcheck="false"
                                     [placeholder]="inputPlaceholder"
                                     enableHtmlSanitizer="true"
                                     >
                             </psp-form-field>
                             <psp-form-field *localVar="projectInformationFields.fieldMap['ConstructionPhase'] as field" [formField]="field" >
                                 <input
                                     [formControl]="field.control"
                                     [id]="field.fieldName"
                                     [name]="field.fieldName"
                                     type="text"
                                     class="e-input"
                                     spellcheck="false"
                                     [placeholder]="inputPlaceholder"
                                     >
                             </psp-form-field>
                             <psp-form-field *localVar="projectInformationFields.fieldMap['ProjectId'] as field" [formField]="field" >
                                 <input
                                     [formControl]="field.control"
                                     [id]="field.fieldName"
                                     [name]="field.fieldName"
                                     type="text"
                                     class="e-input"
                                     spellcheck="false"
                                     [placeholder]="inputPlaceholder"
                                     >
                             </psp-form-field>
                        </div>
                        <div class="app-form-column project-description">
                            <psp-form-field *localVar="projectInformationFields.fieldMap['Description'] as field" [formField]="field" >
                                <textarea
                                    [formControl]="field.control"
                                    [id]="field.fieldName"
                                    [name]="field.fieldName"
                                    type="text"
                                    class="e-input"
                                    spellcheck="true"
                                    rows="4"
                                    [placeholder]="inputPlaceholder"
                                    >
                                    </textarea>
                            </psp-form-field>
                        </div>
                    </div>
                </div>
                    <div class="app-form-column">
                        <div class="base-data-tab">
                            <ejs-tab [animation]="tabsAnimation" heightAdjustMode="Content"  (selected)="tabSelected($event)">
                                <e-tabitems>
                                    <ng-container *ngFor="let c of editingProject.Contacts let i = index">
                                        <e-tabitem>
                                            <ng-template #headerText>
                                                <div style = "display: flex">
                                                    {{tabHeaders[i]}}
                                                    <div *ngIf="projectContactInformationFields[i].group.valid" class="tab-icon-wrapper">
                                                        <span class="icon-success"></span>
                                                    </div>
                                                    <div *ngIf="!projectContactInformationFields[i].group.valid" class="tab-icon-wrapper">
                                                        <span class="icon-failure"></span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template #content>
                                            <div class="contact-information" [formGroup]="projectContactInformationFields[i].group">
                                                <psp-form-field *localVar="projectContactInformationFields[i].fieldMap[('Name_' + i.toString())] as field" [formField]="field">
                                                    <input
                                                        [formControl]="field.control"
                                                        [id]="field.fieldName"
                                                        [name]="field.fieldName"
                                                        class="e-input"
                                                        spellcheck="false"
                                                        type="text"
                                                        [placeholder]="inputPlaceholder"
                                                        >
                                                </psp-form-field>

                                                <psp-form-field *localVar="projectContactInformationFields[i].fieldMap[('Address_' + i.toString())] as field" [formField]="field">
                                                    <input
                                                        [formControl]="field.control"
                                                        [id]="field.fieldName"
                                                        [name]="field.fieldName"
                                                        class="e-input"
                                                        spellcheck="false"
                                                        type="text"
                                                        [placeholder]="inputPlaceholder">
                                                </psp-form-field>

                                            <div class="app-form-columns-container">
                                                <div class="app-form-column country-input">
                                                    <psp-form-field helpMessageHtml="123" *localVar="projectContactInformationFields[i].fieldMap[('Country_' + i.toString())] as field" [formField]="field">
                                                        <input
                                                            [formControl]="field.control"
                                                            [id]="field.fieldName"
                                                            [name]="field.fieldName"
                                                            class="e-input"
                                                            spellcheck="false"
                                                            type="text"
                                                            [placeholder]="inputPlaceholder"
                                                            [readonly]="i === 0"
                                                            [class.readonly-input]="i === 0"
                                                            >

                                                    </psp-form-field>
                                                </div>
                                                <div class="app-form-column zip-input">
                                                    <psp-form-field *localVar="projectContactInformationFields[i].fieldMap[('Zip_' + i.toString())] as field" [formField]="field">
                                                        <input
                                                            [formControl]="field.control"
                                                            [id]="field.fieldName"
                                                            [name]="field.fieldName"
                                                            class="e-input"
                                                            spellcheck="false"
                                                            type="text"
                                                            [placeholder]="inputPlaceholder">
                                                    </psp-form-field>
                                                </div>
                                                <div class="app-form-column">
                                                    <psp-form-field *localVar="projectContactInformationFields[i].fieldMap[('City_' + i.toString())] as field" [formField]="field">
                                                        <input
                                                            [formControl]="field.control"
                                                            [id]="field.fieldName"
                                                            [name]="field.fieldName"
                                                            class="e-input"
                                                            spellcheck="false"
                                                            type="text"
                                                            [placeholder]="inputPlaceholder">
                                                    </psp-form-field>
                                                </div>
                                            </div>

                                            <psp-form-field *localVar="projectContactInformationFields[i].fieldMap[('Phone_' + i.toString())] as field" [formField]="field">
                                                <input
                                                    [formControl]="field.control"
                                                    [id]="field.fieldName"
                                                    [name]="field.fieldName"
                                                    class="e-input"
                                                    spellcheck="false"
                                                    type="text"
                                                    [placeholder]="inputPlaceholder">
                                            </psp-form-field>

                                            <psp-form-field *localVar="projectContactInformationFields[i].fieldMap[('Email_' + i.toString())] as field" [formField]="field">
                                                <input
                                                    [formControl]="field.control"
                                                    [id]="field.fieldName"
                                                    [name]="field.fieldName"
                                                    class="e-input"
                                                    spellcheck="false"
                                                    type="text"
                                                    [placeholder]="inputPlaceholder">
                                            </psp-form-field>
                                            </div>
                                        </ng-template>
                                    </e-tabitem>
                                </ng-container>
                            </e-tabitems>
                        </ejs-tab>
                    </div>
                </div>
            </div>

        </div>
    </ng-template>
</ejs-dialog>

<ejs-dialog #errorDialog  [target]='target' (overlayClick)="onOverlayClickError()" [header]='errorDialogHeader' [isModal]=true [closeOnEscape]='false' [visible]='false' [showCloseIcon]='true' [width]='errorDialogWidth' [position]='dialogPosition'>
    <ng-template #content>
        <span>{{'ErrorDialog.Content_SD' | translate}}</span>
    </ng-template>
</ejs-dialog>


