import { Injectable } from '@angular/core';
import { StorageEnergyEfficiencyCategoryRow } from '../DteufcAggregatorServiceApi/storageenergyefficiencycategoryrow.complex';
import { MathLogger } from './MathLogger';

export interface IMinuteValuesEnergyDemandParams
{
    /**
     * One per building type.
     */
    demands: {
        /**
         * Array with length = 23 where one item represents the
         * usage factor (0-100) of one hour of the day.
         */
        tageslastgangAnteile: number[];

        summeEnergiebedarfKwProTag: number;
    }[];
}

export interface IMinuteValuesEnergyDemandResults
{
    /**
     * Array with length = 1440.
     * Array index 0 = first minute of day
     * Array index 1439 = last minute of day
     */
    minutenwerteEnergiebedarf: number[];

    /**
     * Array with length = 1440.
     * Array index 0 = first minute of day
     * Array index 1439 = last minute of day
     */
    minutenwertekwHAnteil: number[];
}

export interface IHeatGeneratorParam
{
    leistungWWBereitungKiloWatt: number;
    verzoegerungNachheizungMinuten: number;
}

export interface IStorageValuesParams
{
    vorlauftemperatur: number;
    storageEnergyEfficiency: StorageEnergyEfficiencyCategoryRow;
    energiebedarf: IMinuteValuesEnergyDemandResults;
    waermeerzeuger: IHeatGeneratorParam[];

    /**
     * 1-100
     */
    anteilBereitschaftsvolumenPerecentage: number;

    zirkulationsverlustleistungWatt: number;
    energiespeicherVolumenLiterDirekteingabe?: number;
}

export interface IStorageValuesResult
{
    energiespeicherVolumenLiter: number;
    energiespeicherVolumenLiterDin: number;
    gesamtleistungWaermeerzeugerKw: number;

    /**
     * Use in Summenliniendiagramm for "Qsto ON"
     * Array with length = 1440.
     * Array index 0 = first minute of day
     * Array index 1439 = last minute of day
     */
    minutenwerteQstoOn: number[];

    /**
     * Use in Summenliniendiagramm for "Versorgungskennlinie"
     * Array with length = 1440.
     * Array index 0 = first minute of day
     * Array index 1439 = last minute of day
     */
    minutenwerteVersorgungskennlinie: number[];
}

/**
 * Storage (Speicherauslegung) related mathematic functions.
 *
 * Sources of mathematical formulas:
 * - 210709-KRA-V1.2-12831-211011.xlsm
 *
 * rauch@klaus-rauch.de
 */
@Injectable()
export class StorageMath
{
    private static readonly eintrittstemperaturSpeicher = 25;
    private static readonly waermekapazitaetWasser = 4.19;

    /**
     * Use in Summenliniendiagramm for "Bedarfskennlinie"
     * Array index 0 = first minute of day
     * Array index 1439 = last minute of day
     */
    public minutenwerteEnergiebedarf(params: IMinuteValuesEnergyDemandParams): IMinuteValuesEnergyDemandResults
    {
        const hoursPerDay = 24;
        const minutesPerDay = 1440;

        const logger = new MathLogger('MinutenwerteEnergiebedarf');
        logger.logInputs(params);

        let energiebedarfTotal = 0;
        params.demands.forEach(demand =>
        {
            if (demand.tageslastgangAnteile.length !== hoursPerDay)
                throw new Error(`Expected every demand to have a tageslastgangAnteil array with exactly ${hoursPerDay} items.`);

            if (Number((demand.tageslastgangAnteile.reduce((a, b) => a + b)).toFixed(0)) !== 100)
                throw new Error('Expected all tageslastgang anteile to result in a total factor of 100%.');

            energiebedarfTotal += demand.summeEnergiebedarfKwProTag;
        });

        const kwHStundenanteile: number[] = new Array(hoursPerDay);
        for (let i = 0; i < hoursPerDay; i++)
        {
            kwHStundenanteile[i] = 0;
            params.demands.forEach(demand =>
            {
                const stundenanteil = demand.summeEnergiebedarfKwProTag * (demand.tageslastgangAnteile[i] / 100);
                kwHStundenanteile[i] += stundenanteil;
            });
        }

        logger.logIntermediateResult(
        {
            energiebedarfKwProTag: energiebedarfTotal,
            kwHStundenanteile
        });

        const minutenwerteEnergiebedarf: number[] = new Array(minutesPerDay);
        const minutenwertekwHAnteil: number[] = new Array(minutesPerDay);
        let hourCurrent = -1;
        let minutenanteilCurrent = 0;
        let summeEnergiebedarfCurrent = 0;
        for (let i = 0; i < minutesPerDay; i++)
        {
            const hour = Math.trunc(i / 60);
            if (hour !== hourCurrent)
            {
                minutenanteilCurrent = kwHStundenanteile[hour] / 60;
                hourCurrent = hour;
            }

            summeEnergiebedarfCurrent += minutenanteilCurrent;
            minutenwerteEnergiebedarf[i] = summeEnergiebedarfCurrent;
            minutenwertekwHAnteil[i] = minutenanteilCurrent;
        }

        logger.logResult(
        {
            summeEnergiebedarfKwProTag: summeEnergiebedarfCurrent,
            minutenanteile: minutenwerteEnergiebedarf.length,
        });

        return { minutenwerteEnergiebedarf, minutenwertekwHAnteil };
    }

    public speicherwerteFromEnergiebedarf(params: IStorageValuesParams): IStorageValuesResult
    {
        const logger = new MathLogger('SpeicherwerteFromEnergiebedarf');
        const energiebedarfSumme = params.energiebedarf.minutenwerteEnergiebedarf[params.energiebedarf.minutenwerteEnergiebedarf.length - 1];
        logger.logInputs({
            vorlauftemperatur: params.vorlauftemperatur,
            summeEnergiebedarfKwProTag: energiebedarfSumme,
            storageEnergyEfficiency: params.storageEnergyEfficiency,
            energiespeicherVolumenLiterDirekteingabe: params.energiespeicherVolumenLiterDirekteingabe,
            waermeerzeuger: params.waermeerzeuger
        });

        let energiespeicherVolumenLiter: number;
        let energiespeicherVolumenLiterDin: number;
        let qStoMax: number;
        if (params.energiespeicherVolumenLiterDirekteingabe)
        {
            energiespeicherVolumenLiter = params.energiespeicherVolumenLiterDirekteingabe;
            qStoMax = this.qStoMaxFromSpeichervolumenDirekteingabe(params.energiespeicherVolumenLiterDirekteingabe, params.vorlauftemperatur, StorageMath.eintrittstemperaturSpeicher);
            energiespeicherVolumenLiterDin = this.energiespeicherVolumenLiter(this.qStoMaxFromEnergiebedarf(params.energiebedarf.minutenwerteEnergiebedarf), params.vorlauftemperatur, StorageMath.eintrittstemperaturSpeicher);
        }
        else
        {
            qStoMax = this.qStoMaxFromEnergiebedarf(params.energiebedarf.minutenwerteEnergiebedarf);
            energiespeicherVolumenLiter = this.energiespeicherVolumenLiter(qStoMax, params.vorlauftemperatur, StorageMath.eintrittstemperaturSpeicher);
            energiespeicherVolumenLiterDin = energiespeicherVolumenLiter;
        }

        const verlustleistungSpeicherKw: number = this.verlustleistungSpeicherKw(energiespeicherVolumenLiter, params.storageEnergyEfficiency);
        const verlustleistungSpeicherQwStoT: number = verlustleistungSpeicherKw / 60;

        const nennNachheizleistungQnKwh: number = params.waermeerzeuger
            .map(w => w.leistungWWBereitungKiloWatt)
            .reduce((a, b) => a + b);

        const verzoegerungNachheizungMinuten: number = params.waermeerzeuger
            .map(w => (w.leistungWWBereitungKiloWatt / nennNachheizleistungQnKwh) * w.verzoegerungNachheizungMinuten)
            .reduce((a, b) => a + b);

        const verlustleistungWaermeverteilungKwMinute: number = (params.zirkulationsverlustleistungWatt / 1000) / 60;

        const speicherMinutenwerte = this.speicherMinutenwerte(
            params.energiebedarf,
            qStoMax,
            verzoegerungNachheizungMinuten,
            verlustleistungSpeicherQwStoT,
            verlustleistungWaermeverteilungKwMinute,
            nennNachheizleistungQnKwh,
            params.anteilBereitschaftsvolumenPerecentage / 100
        );

        const result: IStorageValuesResult =
        {
            energiespeicherVolumenLiter,
            energiespeicherVolumenLiterDin,
            gesamtleistungWaermeerzeugerKw: nennNachheizleistungQnKwh,
            minutenwerteQstoOn: speicherMinutenwerte.minutenwerteQstoOn,
            minutenwerteVersorgungskennlinie: speicherMinutenwerte.minutenwerteVersorgungskennlinie
        };
        logger.logResult(
            {
                energiespeicherVolumenLiter,
                leistungWwBereitungGesamtKw: nennNachheizleistungQnKwh,
                minutenwerteQstoOn: speicherMinutenwerte.minutenwerteQstoOn.length,
                minutenwerteVersorgungskennlinie: speicherMinutenwerte.minutenwerteVersorgungskennlinie.length
            }
        );
        return result;
    }

    private speicherMinutenwerte(
        minutenwerte: IMinuteValuesEnergyDemandResults,
        qStoMax: number,
        verzoegerungNachheizungMinuten: number,
        verlustleistungSpeicherQwStoT: number,
        verlustleistungWaermeverteilungKwhMin: number,
        nennNachheizleistungQnKwh: number,
        anteilBereitschaftsvolumenFactor: number
    ):
    {
        minutenwerteQstoOn: number[],
        minutenwerteVersorgungskennlinie: number[]
    }
    {
        const logger = new MathLogger('SpeicherMinutenwerte');
        logger.logInputs({
            qStoMax,
            verzoegerungNachheizungMinuten,
            verlustleistungSpeicherQwStoT,
            verlustleistungWaermeverteilungKwhMin,
            nennNachheizleistungQnKwh
        });
        const nennNachheizleistungQnKwhMin = nennNachheizleistungQnKwh / 60;

        const minutesPerDay = 1440;
        if (minutenwerte.minutenwerteEnergiebedarf.length !== minutesPerDay)
            throw new Error(`Input array must have a length of ${minutesPerDay}.`);

        const qStoOn = qStoMax * anteilBereitschaftsvolumenFactor;
        const qStoStart = qStoOn;

        logger.logIntermediateResult({ qStoOn });

        const minutenwerteQstoOn: number[] = new Array(minutesPerDay);
        const minutenwerteVerbrauchskennlinie: number[] = new Array(minutesPerDay);
        minutenwerteQstoOn[0] = qStoOn + minutenwerte.minutenwerteEnergiebedarf[0];
        minutenwerteVerbrauchskennlinie[0] = qStoOn + minutenwerte.minutenwerteEnergiebedarf[0];
        let qStoCurrent: number = qStoStart;
        let verzoegerungLTagCurrent: number = 0;
        for (let i = 1; i < minutesPerDay; i++)
        {
            const verzoegerungLTagPrev = verzoegerungLTagCurrent;
            if (qStoCurrent < qStoOn)
                verzoegerungLTagCurrent++;
            else if (qStoCurrent > qStoMax)
                verzoegerungLTagCurrent = 0;

            const minutenwertKwHAnteil: number = minutenwerte.minutenwertekwHAnteil[i - 1];
            qStoCurrent = qStoCurrent - minutenwertKwHAnteil - (verlustleistungSpeicherQwStoT + verlustleistungWaermeverteilungKwhMin);
            if (verzoegerungLTagPrev > verzoegerungNachheizungMinuten)
                qStoCurrent += nennNachheizleistungQnKwhMin;

            const minutenwertEnergiebedarf: number = minutenwerte.minutenwerteEnergiebedarf[i];
            minutenwerteQstoOn[i] = qStoOn + minutenwertEnergiebedarf;
            minutenwerteVerbrauchskennlinie[i] = qStoCurrent + minutenwertEnergiebedarf;
        }

        logger.logResult(
        {
            minutenwerteQstoOn: minutenwerteQstoOn.length,
            minutenwerteVersorgungskennlinie: minutenwerteQstoOn.length,
        });

        return {
            minutenwerteQstoOn,
            minutenwerteVersorgungskennlinie: minutenwerteVerbrauchskennlinie
        };
    }

    private qStoMaxFromEnergiebedarf(minutenwerteEnergiebedarf: number[]): number
    {
        const logger = new MathLogger('QStoMaxFromEnergiebedarf');
        logger.logInputs({
            minutenwerteEnergiebedarf: minutenwerteEnergiebedarf.length
        });

        const minutesPerDay = 1440;
        if (minutenwerteEnergiebedarf.length !== minutesPerDay)
            throw new Error(`Input array must have a length of ${minutesPerDay}.`);

        const summeEnergiebedarfKwProTag = minutenwerteEnergiebedarf[minutesPerDay - 1];
        const mittlereSteigungBedarfskennlinie = summeEnergiebedarfKwProTag / minutesPerDay;
        logger.logIntermediateResult({ mittlereSteigungBedarfskennlinie });

        let maxDifference = 0;
        let minDifference = 9999999;
        for (let i = 0; i < minutesPerDay; i++)
        {
            const mittlereSteigungAktuell = mittlereSteigungBedarfskennlinie * (i + 1);
            const difference = minutenwerteEnergiebedarf[i] - mittlereSteigungAktuell;

            maxDifference = Math.max(maxDifference, difference);
            minDifference = Math.min(minDifference, difference);
        }
        minDifference = Math.abs(minDifference);
        maxDifference = Math.abs(maxDifference);

        const result = minDifference + maxDifference;

        logger.logIntermediateResult({ minDifference, maxDifference });
        logger.logResult({ qStoMax: result });
        return result;
    }

    private energiespeicherVolumenLiter(
        qStoMax: number,
        vorlauftemperatur: number,
        eintrittstemperaturSpeicher: number
    )
    {
        const logger = new MathLogger('EnergiespeicherVolumenLiter');
        logger.logInputs({
            qStoMax,
            vorlauftemperatur,
            eintrittstemperaturSpeicher
        });

        const x = (1000 - 0.005 * Math.pow(vorlauftemperatur - 4, 2)) / 1000;
        const result = (qStoMax * 3600) / (x * StorageMath.waermekapazitaetWasser * (vorlauftemperatur - eintrittstemperaturSpeicher));

        logger.logResult({ energiespeicherVolumenLiter: result });
        return result;
    }

    private qStoMaxFromSpeichervolumenDirekteingabe(
        energiespeicherVolumenLiterDirekteingabe: number,
        vorlauftemperatur: number,
        eintrittstemperaturSpeicher: number
    )
    {
        const logger = new MathLogger('QStoMaxFromSpeichervolumenDirekteingabe');
        logger.logInputs({
            energiespeicherVolumenLiterDirekteingabe,
            vorlauftemperatur,
            eintrittstemperaturSpeicher
        });

        const x = (1000 - 0.005 * Math.pow(vorlauftemperatur - 4, 2)) / 1000;
        const result = energiespeicherVolumenLiterDirekteingabe * x * StorageMath.waermekapazitaetWasser / 3600 * (vorlauftemperatur - eintrittstemperaturSpeicher);

        logger.logResult({ qStoMax: result });
        return result;
    }

    private verlustleistungSpeicherKw(
        energiespeicherVolumenLiter: number,
        storageEnergyEfficiency: StorageEnergyEfficiencyCategoryRow
    )
    {
        const logger = new MathLogger('VerlustleistungSpeicher');
        logger.logInputs({
            energiespeicherVolumenLiter,
            storageEnergyEfficiency
        });

        const a: number = storageEnergyEfficiency.VerlustleistungFormelKonstanteA;
        const b: number = storageEnergyEfficiency.VerlustleistungFormelKonstanteB;
        const c: number = storageEnergyEfficiency.VerlustleistungFormelKonstanteC;
        const result = (a + (b * Math.pow(energiespeicherVolumenLiter, c))) / 1000;

        logger.logResult({ verlustleistungSpeicherKw: result });
        return result;
    }
}
