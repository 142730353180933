<div class="component-container">
    <div class="container"  (click)="icon_clicked($event)">
        <div class="thumbnail">
            <img [src]="imagePath" width="232" Height="137">
            <div *ngIf="isSelected; then selected"></div>
        </div>
        <div class="button-container" [class.darker]='unSelected'>
            <div class="button-title">
                <div style="width: 176px;">
                    <h3 style="color:#272727">{{title}}</h3>
                </div>

                <div class="icon-big">
                    <i class="icon-chevron-thin-right"></i>
                </div>
            </div>
            <div style="width: 193px; margin-top: 1em;">
                <span style="font-size: 14.5px;">{{description}}</span>
            </div>
        </div>
    </div>
</div>
<ng-template #selected>
    <div class="selected">
        <i class="icon-check1" style="color: #FFED00; vertical-align: middle;"></i>
    </div>
</ng-template>