import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HotWaterDemandComponent } from './hot-water-demand.component';



@NgModule({
  declarations: [
    HotWaterDemandComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class HotWaterDemandModule { }
