import { Injectable } from '@angular/core';
import { Internationalization } from '@syncfusion/ej2-base';
import { LangService } from './LangService';

@Injectable()
export class GlobalizationService
{
    // Syncfusion conform format string for ISO date time representations
    // note that the time zone indicator 'Z' is constant though
    private static DateTimeOffsetFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";

    private _globalize: Internationalization;
    private get globalize(): Internationalization
    {
        let syncfusionLocale = this.langService.currentLocale;
        if (syncfusionLocale === 'de-DE')
            syncfusionLocale = 'de';

        return this._globalize || new Internationalization(syncfusionLocale);
    }

    public constructor(
        private langService: LangService
    ) {}

    /**
     * Converts a DateTimeOffset value representation in the format, as it is usually received
     * from service backends, to a JavaScript Date object.
     *
     * @param dateTimeOffset A date time offset in the format yyyy-MM-dd'T'HH:mm:ss'Z'
     * @param asUniversalTime true to take the date time as is, false to convert it to the local time zone of the user.
     */
    public dateTimeOffsetToDate(dateTimeOffset: string, asUniversalTime: boolean = false): Date
    {
        const result: Date = this.globalize.parseDate(dateTimeOffset, { format: GlobalizationService.DateTimeOffsetFormat });
        if (result === null)
            throw new Error(`Parsing iso date '${dateTimeOffset}' has failed.`);

        if (asUniversalTime)
            return result;

        return new Date(+result - (result.getTimezoneOffset() * 60 * 1000));
        // return new Date(+result - (new Date().getTimezoneOffset() * 3600000));
    }

    /**
     * Takes a Javascript date object and converts it into a ISO UTC date string in the format yyyy-MM-dd'T'HH:mm:ss'Z'
     * to be processed by backend services.
     */
    public dateToUtcDateTimeOffset(date: Date): string
    {
        return date.getUTCFullYear() +
            '-' + GlobalizationService.paddedNumber(date.getUTCMonth() + 1) +
            '-' + GlobalizationService.paddedNumber(date.getUTCDate()) +
            'T' + GlobalizationService.paddedNumber(date.getUTCHours()) +
            ':' + GlobalizationService.paddedNumber(date.getUTCMinutes()) +
            ':' + GlobalizationService.paddedNumber(date.getUTCSeconds()) +
            'Z';
    }

    /**
     * Converts a Date object to a human readable psp standard representation.
     */
    public dateToHumanReadableString(date: Date, type: 'date' | 'time' | 'dateTime'): string
    {
        return this.globalize.formatDate(date, { type, skeleton: 'short' });
    }

    private static paddedNumber(number: number): string
    {
        if (number < 10)
            return '0' + number;

        return number.toString();
    }
}
