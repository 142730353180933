import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'viega-panel-header',
    templateUrl: './panel-header.component.html',
    styleUrls: ['./panel-header.component.scss']
})
export class PanelHeaderComponent implements OnInit {
    @Input()
    public stepTitle: string;

    @Input()
    public stepText: string;

    @Input()
    public stepDescription: string;

    @Input()
    public stepDescriptionHelpId: string;

    @Input()
    public stepBackLabel: string;

    @Input()
    public titleHelpId: string;
    
    // @Input()
    // public maxHeight: string;

    @Output()
    public stepBackClicked = new EventEmitter<MouseEvent>();

    constructor() {}

    public ngOnInit(): void {}

    public stepBack_clicked(event: MouseEvent) {
        this.stepBackClicked.emit(event);
    }
}
