import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import * as _ from 'lodash';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { Rohrauslegung } from 'src/app/core/BackendApi/rohrauslegung.complex';
import { DteRow } from 'src/app/core/DteufcAggregatorServiceApi/dterow.complex';
import { PipeSpecRow } from 'src/app/core/DteufcAggregatorServiceApi/pipespecrow.complex';
import { PipeSystemRow } from 'src/app/core/DteufcAggregatorServiceApi/pipesystemrow.complex';
import { SectionState } from 'src/app/core/Enums';
import { IDimensioningParams, IRohrdimensionFromAuslegungsparameterResults, PipeDiameterMath } from 'src/app/core/math/PipeDiameterMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

export class PipeRow{
    nameLoc: string;
    rohrlaenge: number;
    anzahlBoegen: number;
    zusaetzlicherDruckverlust: number;
}

@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.scss'],
  providers: [provideContext("Pipeline")],
})
export class PipelineComponent implements OnInit {

@ViewChild('systemDropdown') public SystemDropdown: DropDownListComponent;

steptext = this.langService.getString('PanelStepText_T');
stepTitle = this.langService.getString('PanelHeadline_T');
stepBack = this.langService.getString('PanelStepBackLabel_T');
stepDescription = this.langService.getString('PanelDescription_D');


productGroupData;
pipeSystems = []
pipeSpecs: PipeSpecRow[] = [];

fields = { text:"DisplayNameLoc", value:"PipeSystemId" }


description: string;
imgData: string;

selectedDte;
selectedDteRow: DteRow;
selectedProject: ProjectEntity;
selectedPipeSystemRow: PipeSystemRow;
systemId: string;

maxVolumenstrom: number;
maxDruckverlust: number = 150;

resultsVorlauf: IRohrdimensionFromAuslegungsparameterResults = {
    nennweite: 0, druckverlustHpa: 0
};

resultsMitte: IRohrdimensionFromAuslegungsparameterResults= {
    nennweite: 0, druckverlustHpa: 0
};
resultsUnten: IRohrdimensionFromAuslegungsparameterResults= {
    nennweite: 0, druckverlustHpa: 0
};

druckverlustGesamt: number;
druckverlustGesamtFormatted: string;

rows: PipeRow[];


constructor(
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private DataService: ProjectDataService,
    private aggregatorDataService: AggregatorDataService,
    private pipeMath: PipeDiameterMath,
    private valueFormatterService: ValueFormatterService,
    private langService: LangService
    ) { }

ngOnInit(): void {
    this.selectedProject = this.activatedRoute.snapshot.data['project'];

    //TODO später löschen:
    if (!this.selectedProject.ProjectValues) {
        this.selectedProject.ProjectValues = {}
    }
    if (!this.selectedProject.ProjectValues.Dte) {
        this.selectedProject.ProjectValues.Dte = {};
    }

    this.selectedDte = this.selectedProject.ProjectValues.Dte.Ergebnis ? this.selectedProject.ProjectValues.Dte.Ergebnis : 70;
    this.selectedDteRow = this.aggregatorDataService.aggregatorData.Tables.DteRows.find(x => Number(x.Dte) == this.selectedDte)
    this.maxVolumenstrom = this.selectedDteRow.MaxVolumenstromPrimaerseite;

    this.pipeSpecs = this. aggregatorDataService.aggregatorData.Tables.PipeSpecRows;

    this.pipeSystems = this.aggregatorDataService.aggregatorData.Tables.PipeSystemRows;
    this.productGroupData = this.aggregatorDataService.aggregatorData.Entities.ProductGroups;

    if (!this.selectedProject.ProjectValues.Rohrauslegung) {
        this.selectedProject.ProjectValues.Rohrauslegung = {};
    }

    if (this.selectedProject.ProjectValues.Rohrauslegung.Rohrleitungssystem) {
        this.systemId = this.selectedProject.ProjectValues.Rohrauslegung.Rohrleitungssystem;
        this.selectedPipeSystemRow = this.pipeSystems.find(x => x.PipeSystemId == this.systemId)
        this.loadSystemData(this.selectedPipeSystemRow.ViegaProductGroupId);
    }

    this.rows = [
        new PipeRow(),
        new PipeRow(),
        new PipeRow()
    ];

    this.rows[0].nameLoc = this.langService.getString('Vorlauf_T');
    this.rows[1].nameLoc = this.langService.getString('RücklaufMitte_T');
    this.rows[2].nameLoc = this.langService.getString('RücklaufUnten_T');

    this.rows[0].rohrlaenge = 0;
    this.rows[0].anzahlBoegen = 0;
    this.rows[0].zusaetzlicherDruckverlust = 0;
    this.rows[1].rohrlaenge = 0;
    this.rows[1].anzahlBoegen = 0;
    this.rows[1].zusaetzlicherDruckverlust = 0;
    this.rows[2].rohrlaenge = 0;
    this.rows[2].anzahlBoegen = 0;
    this.rows[2].zusaetzlicherDruckverlust = 0;


    if (this.selectedPipeSystemRow) {
        this.calculateVorlauf();
        this.calculateMitte();
        this.calculateUnten();
        this.calculateDruckverlustGesamt();
    }

}

public ngAfterViewInit(): void
{
    this.setSliderValues();
    if (this.selectedPipeSystemRow) {
        this.calculateVorlauf();
        this.calculateMitte();
        this.calculateUnten();
        this.calculateDruckverlustGesamt();
    }
}

setSliderValues(){
    //Vorlauf
    if (this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeVorlauf) {
        this.rows[0].rohrlaenge = this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeVorlauf;
    }
    else{
        this.rows[0].rohrlaenge = 0;
    }

    if (this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenVorlauf) {
        this.rows[0].anzahlBoegen = this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenVorlauf;
    }
    else{
        this.rows[0].anzahlBoegen = 0;
    }

    if (this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustVorlauf) {
        this.rows[0].zusaetzlicherDruckverlust = this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustVorlauf;
    }
    else{
        this.rows[0].zusaetzlicherDruckverlust = 0;
    }

    //Ruecklauf mitte
    if (this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufMitte) {
        this.rows[1].rohrlaenge = this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufMitte;
    }
    else{
        this.rows[1].rohrlaenge = 0;
    }

    if (this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufMitte) {
        this.rows[1].anzahlBoegen = this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufMitte;
    }
    else{
        this.rows[1].anzahlBoegen = 0;
    }

    if (this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufMitte) {
        this.rows[1].zusaetzlicherDruckverlust = this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufMitte;
    }
    else{
        this.rows[1].zusaetzlicherDruckverlust = 0;
    }

    //Ruecklauf unten
    if (this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufUnten) {
        this.rows[2].rohrlaenge = this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufUnten;
    }
    else{
        this.rows[2].rohrlaenge = 0;
    }

    if (this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufUnten) {
        this.rows[2].anzahlBoegen = this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufUnten;
    }
    else{
        this.rows[2].anzahlBoegen = 0;
    }

    if (this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufUnten) {
        this.rows[2].zusaetzlicherDruckverlust = this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufUnten;
    }
    else{
        this.rows[2].zusaetzlicherDruckverlust = 0;
    }
}

getDisplayMaxVolumenstrom(){
    return this.valueFormatterService.formatNumber(this.maxVolumenstrom, 'LiterPerMinute');
}

getDisplayDte(){
    return this.valueFormatterService.formatDteName(this.selectedDte);
}

getDruckverlustGesamt(){
    return this.valueFormatterService.formatNumber(this.druckverlustGesamt, 'Hektopascal')
}

getResultsVorlaufNennweite(){
    return this.resultsVorlauf.nennweite.toString();
}

getResultsMitteNennweite(){
    return this.resultsMitte.nennweite.toString();
}

getResultsUntenNennweite(){
    return this.resultsUnten.nennweite.toString();
}
getMaxDruckverlust(){
    return this.valueFormatterService.formatNumber(150, 'Hektopascal')
}

onChangeDropdown(args){
    this.selectedPipeSystemRow = args.itemData;

    this.loadSystemData(args.itemData.ViegaProductGroupId)

    this.calculateVorlauf();
    this.calculateMitte();
    this.calculateUnten();
    this.calculateDruckverlustGesamt();
}

loadSystemData(productGroupId: string){
    this.description = this.productGroupData[productGroupId].ProductGroupDetails.ShortDescriptionHtml;
    // this.description = this.description.replace(/;/g, '\n')

    this.imgData = this.productGroupData[productGroupId].ProductGroupDetails.Images.Representative.Formats.Png.Resolutions.Px1024.Url

}

calculateValues(i: number){
    if (this.selectedPipeSystemRow) {
        switch (i) {
            case 0:
                this.calculateVorlauf();
                break;
            case 1:
                this.calculateMitte();
                break;
            case 2:
                this.calculateUnten();
                break;
            default:
                break;
        }

        this.calculateDruckverlustGesamt();
    }
    else{
        //TODO Ergebnisse auf 0
        this.resultsVorlauf.nennweite = 0;
        this.resultsMitte.nennweite = 0;
        this.resultsUnten.nennweite = 0;
    }
}

calculateVorlauf(){
    var params: IDimensioningParams = {
        volumenstromLMin: this.maxVolumenstrom,
        temperatur: 55,
        rohrlaengeMeter: parseInt(this.rows[0].rohrlaenge as any, 10),
        anzahlBoegen: parseInt(this.rows[0].anzahlBoegen as any, 10),
        zusaetzlicherDruckverlust: parseInt(this.rows[0].zusaetzlicherDruckverlust as any, 10),
        pipeSystem: this.selectedPipeSystemRow,
        pipeSpecs: this.pipeSpecs
    }

    this.resultsVorlauf = this.pipeMath.rohrdimensionFromAuslegungsparameter(params);
}

calculateMitte(){
    var params: IDimensioningParams = {
        volumenstromLMin: this.maxVolumenstrom,
        temperatur: 55,
        rohrlaengeMeter: parseInt(this.rows[1].rohrlaenge as any, 10),
        anzahlBoegen: parseInt(this.rows[1].anzahlBoegen as any, 10),
        zusaetzlicherDruckverlust: parseInt(this.rows[1].zusaetzlicherDruckverlust as any, 10),
        pipeSystem: this.selectedPipeSystemRow,
        pipeSpecs: this.pipeSpecs
    }

    this.resultsMitte = this.pipeMath.rohrdimensionFromAuslegungsparameter(params);
}

calculateUnten(){
    var params: IDimensioningParams = {
        volumenstromLMin: this.maxVolumenstrom,
        temperatur: 55,
        rohrlaengeMeter: parseInt(this.rows[2].rohrlaenge as any, 10),
        anzahlBoegen: parseInt(this.rows[2].anzahlBoegen as any, 10),
        zusaetzlicherDruckverlust: parseInt(this.rows[2].zusaetzlicherDruckverlust as any, 10),
        pipeSystem: this.selectedPipeSystemRow,
        pipeSpecs: this.pipeSpecs
    }

    this.resultsUnten = this.pipeMath.rohrdimensionFromAuslegungsparameter(params);
}

calculateDruckverlustGesamt(){
    this.druckverlustGesamt = this.pipeMath.druckverlustGesamt(
    {
        druckverlustHpaVorlauf: this.resultsVorlauf.druckverlustHpa,
        druckverlustHpaRlMitte: this.resultsMitte.druckverlustHpa,
        druckverlustHpaRlUnten: this.resultsUnten.druckverlustHpa,
    });
    this.druckverlustGesamtFormatted = this.valueFormatterService.formatNumber(this.druckverlustGesamt, 'Hektopascal', { maximumFractionDigits: 0 });
}

isContinueDisabled(){
    if (
        this.druckverlustGesamt > this.maxDruckverlust
        || !this.resultsVorlauf.nennweite
        || !this.resultsMitte.nennweite
        || !this.resultsUnten.nennweite
        || !this.selectedPipeSystemRow
        ) {
        return true;
    }
    return false;
}

goBack() {
    this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
}

public roundTo = function(num: number, places: number) {
    return Math.ceil(num * (10 ** places)) / (10 ** places);
  };

navigateNext(){

    if(this.selectedProject.ProjectValues.Rohrauslegung){
        //create base object of changeable option for comparisson
        let temp = {
            Rohrleitungssystem: this.selectedPipeSystemRow.PipeSystemId,
            rows: this.rows
        }
        //create project object of changeable options
        let projectTemp: {Rohrleitungssystem: string, rows: PipeRow[]} = {
            Rohrleitungssystem: this.selectedProject.ProjectValues.Rohrauslegung.Rohrleitungssystem,
            rows: [
                {nameLoc: this.langService.getString('Vorlauf_T'),
                 rohrlaenge: this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeVorlauf,
                 anzahlBoegen: this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenVorlauf,
                 zusaetzlicherDruckverlust: this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustVorlauf},
                {nameLoc: this.langService.getString('RücklaufMitte_T'),
                 rohrlaenge: this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufMitte,
                 anzahlBoegen: this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufMitte,
                 zusaetzlicherDruckverlust: this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufMitte},
                {nameLoc: this.langService.getString('RücklaufUnten_T'),
                 rohrlaenge: this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufUnten,
                 anzahlBoegen: this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufUnten,
                 zusaetzlicherDruckverlust: this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufUnten}
            ]
        }
        if(JSON.stringify(temp) === JSON.stringify(projectTemp)){
            this.selectedProject.SectionStates.Rohrauslegung = SectionState.Set;
            this.DataService.saveCurrentProject()
                    .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
            return;
        }
    }

    //TODO rows speichern
        this.selectedProject.SectionStates.Rohrauslegung = SectionState.Set;


        if(this.selectedProject.SectionStates.Ufc == SectionState.Set)
            this.selectedProject.SectionStates.Ufc = SectionState.Check;


        if(this.selectedProject.SectionStates.BuildingTypeWarmwasserStates){
            this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.forEach(b => {
                if(b.Value == SectionState.Set){
                    b.Value = SectionState.Check;
                }
            });
        }

        if (this.selectedProject.SectionStates.Energiespeicher === SectionState.Set) {
            this.selectedProject.SectionStates.Energiespeicher = SectionState.Check;
        }



    this.selectedProject.ProjectValues.Rohrauslegung.Rohrleitungssystem = this.selectedPipeSystemRow.PipeSystemId;

    this.saveSliderValues();

    this.selectedProject.ProjectValues.Rohrauslegung.VerlustHpa = this.druckverlustGesamt;
    this.selectedProject.ProjectValues.Rohrauslegung.PrimaerseiteVolumenstrom = this.maxVolumenstrom;

    this.selectedProject.ProjectValues.Rohrauslegung.ErgebnisVorlauf = this.resultsVorlauf.nennweite;
    this.selectedProject.ProjectValues.Rohrauslegung.ErgebnisRuecklaufMitte = this.resultsMitte.nennweite;
    this.selectedProject.ProjectValues.Rohrauslegung.ErgebnisRuecklaufUnten = this.resultsUnten.nennweite;




    this.DataService.saveCurrentProject()
        .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
    };

  saveSliderValues(){
    this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeVorlauf = this.rows[0].rohrlaenge;
    this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenVorlauf = this.rows[0].anzahlBoegen;
    this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustVorlauf = this.rows[0].zusaetzlicherDruckverlust;

    this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufMitte = this.rows[1].rohrlaenge;
    this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufMitte = this.rows[1].anzahlBoegen;
    this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufMitte = this.rows[1].zusaetzlicherDruckverlust;

    this.selectedProject.ProjectValues.Rohrauslegung.RohrlängeRuecklaufUnten = this.rows[2].rohrlaenge;
    this.selectedProject.ProjectValues.Rohrauslegung.AnzahlBoegenRuecklaufUnten = this.rows[2].anzahlBoegen;
    this.selectedProject.ProjectValues.Rohrauslegung.DruckverlustRuecklaufUnten = this.rows[2].zusaetzlicherDruckverlust;
  }
}



