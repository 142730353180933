import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-example-page',
  templateUrl: './example-page.component.html',
  styleUrls: ['./example-page.component.scss']
})
export class ExamplePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goBack() {
    window.history.back();
    // this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
  }

    navigateNext(){
    }

}
