
<!--<object
    #svgObj
    data="https://pspstlocal1sandbox.blob.core.windows.net/development/temporary/DTE-2WT%20V2%20txt.svg?sv=2020-08-04&st=2021-11-26T06%3A24%3A31Z&se=9999-11-27T06%3A24%3A00Z&sr=b&sp=r&sig=0uoZMHGUz20GaLnUe8LZiUJwrwR1a1W1eg3j48wSZsw%3D"
    type="image/svg+xml"
    width="600"
    height="193"
></object>-->
<!--<div #svgContainer></div>-->

<iframe #svgContainer scrolling="no"></iframe>
