<ng-template #content>
    <ng-content select="[content]" class="content"></ng-content>
</ng-template>

<header
    class="desktop desktop-header"
    *ngIf="!isMobile || forceMode === 'desktop'; else mobile"
    [class.mobile]="forceMode === 'desktop'"
    [class.hidden]="forceMode === 'mobile'"
>
    <div class="top-bar">
        <span class="title">
            <a *ngIf="titleHref" href="http://www.viega.de/">{{appTitle}}</a>
            <span *ngIf="!titleHref">{{appTitle}}</span>
        </span>
        <div class="desktop-menu-items-wrapper">
            <ng-content select="[menu-items]"></ng-content>
        </div>
    </div>

    <a (click)="logo_clicked()">
        <viega-logo></viega-logo>
    </a>
    <div class="bottom-content-wrapper">
        <span
            class="page-title"
            [class.hidden]="pageTitleMode === 'mobile-only'"
        >
            {{ pageTitle }}
        </span>
        <div class="left-content-wrapper">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
        
    </div>
</header>

<ng-template #mobile>
    <header
        class="mobile mobile-header"
        [class.desktop]="forceMode === 'mobile'"
        [class.hidden]="forceMode === 'desktop'"
    >
        <a (click)="logo_clicked()">
            <viega-logo></viega-logo>
        </a>
        <div class="bottom-content-wrapper">
            <span
                *ngIf="pageTitle"
                class="page-title"
                [class.hidden]="pageTitleMode === 'desktop-only'"
            >
                {{ pageTitle }}
            </span>
            <div class="left-content-wrapper">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
            <a
                class="burger-button"
                [class.hidden]="disableBurgerMenu"
                (click)="setSidebarStatus(true)"
            ></a>
        </div>
        
    </header>
</ng-template>

<aside
    class="mobile"
    [class.expanded]="isMobileMenuExpanded"
    [class.desktop]="forceMode === 'mobile'"
    [class.hidden]="forceMode === 'desktop'"
>
    <div class="header">
        <viega-logo></viega-logo>
        <span
            class="page-title"
            [class.hidden]="pageTitleMode !== 'mobile-only'"
        >
            {{ pageTitle }}
        </span>
    </div>
    <div class="bottom-content">
        <ng-content select="[mobile-panel-bottom]"></ng-content>
    </div>
</aside>

<div
    class="page-wrapper"
    [class.hidden]="!isMobileMenuExpanded"
    (click)="setSidebarStatus(false)"
></div>
