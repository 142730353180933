import { PropertyRead } from '@angular/compiler';
import { Event, EmitType, EventHandler, Component, INotifyPropertyChanged, Property, addClass, removeClass, select, detach } from '@syncfusion/ej2-base';
import { events } from './events';
import { IHeaderLink, IPspRoofSiteHeaderModel } from './IPspRoofSiteHeaderModel';

export class PspRoofSiteHeader extends Component<HTMLElement> implements INotifyPropertyChanged
{
    private titleEl: HTMLAnchorElement;
    private linkListEl?: HTMLUListElement;

    @Property('Header Title')
    public titleLinkText?: string;

    @Property()
    public titleLinkHref?: string;

    @Property([])
    public links?: IHeaderLink[];

    @Property(true)
    public hideOnMobile: boolean;

    @Property()
    public cssClass?: string;

    /**
     * Raised when the component has been created and rendered.
     */
    @Event()
    public createdEvent?: EmitType<{}>;

    /**
     * Raised when the component has been destroyed.
     */
    @Event()
    public destroyedEvent?: EmitType<{}>;

    constructor(options?: IPspRoofSiteHeaderModel, element?: string | HTMLElement)
    {
        super(options, element);
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-roof-site-header';
    }

    protected preRender(): void
    {
        // NOP
    }

    protected render(): void
    {
        addClass([this.element], 'pspc-roof-site-header');
        if (this.cssClass)
            addClass([this.element], this.cssClass.split(' '));

        if (!this.titleEl)
        {
            this.titleEl = this.createElement('a') as HTMLAnchorElement;
            addClass([this.titleEl], 'psp-ssh-title');
            this.element.appendChild(this.titleEl);
        }
        this.titleEl.innerText = this.titleLinkText;
        this.titleEl.href = this.titleLinkHref;
        this.titleEl.target = "_blank";

        if (this.links && this.links.length > 0)
        {
            this.linkListEl = this.createElement('ul') as HTMLUListElement;
            addClass([this.linkListEl], 'psp-ssh-links');

            this.links.forEach((link, idx) =>
            {
                if (idx > 0)
                {
                    const headerLinkSeparatorEl = this.createElement('li') as HTMLLIElement;
                    headerLinkSeparatorEl.className = "psp-ssh-link-separator";
                    this.linkListEl.appendChild(headerLinkSeparatorEl);
                }

                const headerLinkItemEl = this.createElement('li') as HTMLLIElement;
                const headerLinkAnchorEl = this.createElement('a') as HTMLAnchorElement;
                headerLinkAnchorEl.innerText = link.text;
                if(link.href){
                    headerLinkAnchorEl.href = link.href;
                }
                headerLinkAnchorEl.target = "_blank";
                if(link.click){
                    headerLinkAnchorEl.onclick = link.click;
                }
                headerLinkItemEl.appendChild(headerLinkAnchorEl);

                this.linkListEl.appendChild(headerLinkItemEl);
            });

            this.element.appendChild(this.linkListEl);
        }
        else if (this.linkListEl)
        {
            detach(this.linkListEl);
            delete this.linkListEl;
        }

        if (this.hideOnMobile)
            addClass([this.element], 'psp-hide-on-mobile');
        else
            removeClass([this.element], 'psp-hide-on-mobile');

        this.wireEvents();
        this.trigger(events.created, {});
    }

    protected wireEvents(): void
    {
        // NOP
    }

    protected unwireEvents(): void
    {
        // NOP
    }

    /** @ignore */
    public onPropertyChanged(newProp: IPspRoofSiteHeaderModel, oldProp: IPspRoofSiteHeaderModel): void
    {
        for (const propertyName of Object.keys(newProp))
        {
            const oldValue = oldProp[propertyName];
            const newValue = newProp[propertyName];

            switch (propertyName)
            {
                case 'cssClass':
                    if (oldValue)
                        removeClass([this.element], oldValue.split(' '));
                    if (newValue)
                        addClass([this.element], newValue.split(' '));

                    break;

                case 'titleLinkText':
                case 'titleLinkHref':
                case 'links':
                    this.render();
                    break;

                case 'hideOnMobile':
                    if (newValue)
                        addClass([this.element], 'psp-hide-on-mobile');
                    else
                        removeClass([this.element], 'psp-hide-on-mobile');
            }
        }
    }

    protected getPersistData(): string
    {
        return '';
    }

    public destroy(): void
    {
        this.unwireEvents();
        this.trigger(events.destroyed, {});
        super.destroy();
    }
}
