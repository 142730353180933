// this will usually be an angular injector, but can also be any other
export interface IInjector {
    get(token: any, notFoundValue?: any): any;
}

export class ServiceLocator
{
    private static injector: IInjector;

    public static get<T>(token: any): T
    {
        if (!this.injector)
            throw new Error('The injector service has not been configured yet.');

        const result: any = ServiceLocator.injector.get(token);
        if (!result)
            throw new Error(`Failed to inject service for token "${token}".`);

        return result;
    }

    public static setInjector(injector: IInjector)
    {
        ServiceLocator.injector = injector;
    }
}
