<ejs-dialog #dialog (close)="onCloseDialog()" (beforeClose)="onBeforeClose($event)" (open)="isClosedWithoutSave = true" (overlayClick)="onOverlayClick()" [target]='target' [buttons]='buttons' [header]='headerText' [isModal]=true [closeOnEscape]='false' [visible]='false' [minHeight]="dialogHeight" [height]='dialogHeight' [showCloseIcon]='true' [width]='dialogWidth' [position]='dialogPosition'>
    <ng-template #header>
        <div class="text-tooltip-wrapper text-tooltip-wrapper-header">
            <span>{{headerText}}</span>
            <app-help-icon hinweisId="Projektkonfiguration.Gebäudewahl"></app-help-icon>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="subtitle">
            <span>{{'Content_SD' | translate: projectName}}</span>
        </div>
        <div class="structures">
            <ng-container  *ngFor="let s of structures">
                <app-structure-item [itemChecked]="checkIfChecked(s.BuildingId)" (stateChanged)="onChangeStructures(s.BuildingId, s.DisplayNameLoc)" class="structure" [itemTitle]="s.DisplayNameLoc" [imgSrc]="s.DisplayImageUrlLoc"></app-structure-item>
            </ng-container>
        </div>
        <div class="error" *ngIf="_selectedStructuresOther.length < 1 && !_selectedStructureResidential; else empty">
            <p><span class="icon-warning"></span> {{'ErrorGebaeudetyp_SD' | translate}}</p>
        </div>
        <ng-template #empty>
           <p>&nbsp;</p>
        </ng-template>
        <div >
            <p>
                <span class="icon-warning"></span>
                {{changeWarning}}
            </p>
        </div>
    </ng-template>
</ejs-dialog>