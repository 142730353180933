<viega-collapsible-panel>
    <div class="panel-content" panel-content>
        <div class="top-panel-content">
            <viega-panel-header
                [stepText]="stepText"
                [stepBackLabel]="stepBack"
                [stepTitle]="stepTitle"
                [stepDescription]="stepDescription"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>

            <div class="panel-text-wrapper">
                <div class="width-wrapper">
                    {{'PanelDescriptionWarmwasser_T' |translate}}
                </div>
                <div class="value-wrapper">
                    {{getWwDisplay()}}
                </div>
            </div>

        </div>
        <div class="bot-panel-content">
            <div class="result-box">
                <button psp-button [disabled]="!(editData.LeistungDirekteingabeKw > 0)" class="e-btn e-small e-icon-right" style="width: 100%; flex-flow: row; justify-content: center;" (click)="navigateNext()">{{'WeiterBtn_T' | translate}}</button>
            </div>
        </div>
    </div>

    <div class="content" content>
        <span class="content-title">{{'Headline_T' | translate}}</span>
        <div class="content-wrapper">
            <span>{{'DescriptionSliderLeistungWarmeerzeuger_D' | translate}}</span>
            <div class="power-wrapper">
                <app-slider-text-combo
                class="slider-text-combo"
            style="margin-left: 0em;"
                [sliderMin]="0"
                [sliderMax]="10000"
                [sliderStep]="1"
                [textboxMin]="0"
                [textboxMax]="10000"
                [textboxStep]="1"
                [textboxDecimals]="2"
                [textboxClass]="'slider-input'"
                [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                [showLabel]='false'
                [(value)]='editData.LeistungDirekteingabeKw'
            ></app-slider-text-combo>
                <!--<div class="slider-wrapper">
                    <ejs-slider [(value)]="editData.LeistungDirekteingabeKw" min=0 max=10000></ejs-slider>
                </div>
                <div class="input-wrapper">
                    <ejs-textbox class="slider-input" type="number" [(value)]="editData.LeistungDirekteingabeKw">

                    </ejs-textbox>

                </div>-->
                <span class="unit" style="margin-left: 1em;">{{'#Generic.Unit.KiloWatts_T' | translate}}</span>
            </div>

        </div>
    </div>
</viega-collapsible-panel>
