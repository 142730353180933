import { PropertyRead } from '@angular/compiler';
import { Event, EmitType, EventHandler, Component, INotifyPropertyChanged, Property, addClass, removeClass, select } from '@syncfusion/ej2-base';
import { events } from './events';
import { IPspSiteHeaderModel } from './IPspSiteHeaderModel';

export class PspSiteHeader extends Component<HTMLElement> implements INotifyPropertyChanged
{
    private logoEl: HTMLImageElement;
    private containerEl: HTMLDivElement;

    @Property('assets/viega-logo.svg')
    public logoImageSrc?: string;

    @Property()
    public cssClass?: string;

    @Event()
    public logoClickEvent: EmitType<MouseEvent>;

    /**
     * Raised when the component has been created and rendered.
     */
    @Event()
    public createdEvent?: EmitType<{}>;

    /**
     * Raised when the component has been destroyed.
     */
    @Event()
    public destroyedEvent?: EmitType<{}>;

    constructor(options?: IPspSiteHeaderModel, element?: string | HTMLElement)
    {
        super(options, element);
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-site-header';
    }

    protected preRender(): void
    {
        // NOP
    }

    protected render(): void
    {
        addClass([this.element], 'pspc-site-header');
        if (this.cssClass)
            addClass([this.element], this.cssClass.split(' '));

        this.logoEl = document.createElement('IMG') as HTMLImageElement;
        this.logoEl.className = 'pspc-site-header-logo';
        this.logoEl.src = this.logoImageSrc;
        this.element.appendChild(this.logoEl);

        this.containerEl = document.createElement('DIV') as HTMLDivElement;
        this.containerEl.className = 'pspc-site-header-content';
        this.element.appendChild(this.containerEl);

        this.wireEvents();
        this.trigger(events.created, {});
    }

    protected wireEvents(): void
    {
        EventHandler.add(this.logoEl, 'click', this.logo_Click, this);
    }

    protected unwireEvents(): void
    {
        EventHandler.remove(this.logoEl, 'click', this.logo_Click);
    }

    private logo_Click(args: MouseEvent): void
    {
        this.trigger(events.logoClick, args);
    }

    /** @ignore */
    public onPropertyChanged(newProp: IPspSiteHeaderModel, oldProp: IPspSiteHeaderModel): void
    {
        for (const propertyName of Object.keys(newProp))
        {
            const oldValue = oldProp[propertyName];
            const newValue = newProp[propertyName];

            switch (propertyName)
            {
                case 'cssClass':
                    if (oldValue)
                        removeClass([this.element], oldValue.split(' '));
                    if (newValue)
                        addClass([this.element], newValue.split(' '));

                    break;

                case 'logoImageSrc':
                    this.logoEl.src = this.logoImageSrc;
                    break;
            }
        }
    }

    protected getPersistData(): string
    {
        return '';
    }

    public destroy(): void
    {
        this.unwireEvents();
        this.trigger(events.destroyed, {});
        super.destroy();
    }
}
