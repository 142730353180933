import { NgModule } from '@angular/core';
import { RuntimeService } from 'src/core-lib/ej2/services/RuntimeService';
import { TimeoutService } from 'src/core-lib/ej2/services/TimeoutService';
import { GuidService } from 'src/core-lib/ej2/services/GuidService';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { AppShellClientService } from 'src/core-lib/ej2/services/shell-services/AppShellClientService';
import { AppStateService } from './AppStateService';
import { AppShellHostService } from 'src/core-lib/ej2/services/shell-services/AppShellHostService';
import { HttpClientModule } from '@angular/common/http';
import { UrlResolverService } from 'src/core-lib/ej2/services/UrlResolverService';
import { ODataHttpService } from 'src/core-lib/ej2/services/ODataHttpService';
import { UserStorageService } from 'src/core-lib/ej2/services/UserStorageService';
import { GenericDialogService } from 'src/core-lib/ej2/services/GenericDialogService';
import { ToastService } from 'src/core-lib/ej2/services/ToastService';
import { ErrorProtocolService } from 'src/core-lib/ej2/services/ErrorProtocolService';
import { COMPONENT_CONTEXT } from 'src/core-lib/angular/constants';
import { CirculationFlowMath } from './math/CirculationFlowMath';
import { UserService } from './UserService';
import { DteMath } from './math/DteMath';
import { HotWaterMath } from './math/HotWaterMath';
import { PipeDiameterMath } from './math/PipeDiameterMath';
import { StorageMath } from './math/StorageMath';
import { UfcMath } from './math/UfcMath';
import { VolumetricFlowRateMath } from './math/VolumetricFlowRateMath';
import { AggregatorDataService } from './AggregatorDataService';
import { ValueFormatterService } from './ValueFormatterService';
import { FileDownloadService } from 'src/core-lib/ej2/services/FileDownloadService';
import { ProjectService } from './ProjectService';
import { GlobalizationService } from 'src/core-lib/ej2/services/GlobalizationService';

@NgModule(
{
    imports:
    [
        HttpClientModule,
    ],
    declarations: [],
    exports: [],
    providers:
    [
        // core lib services
        RuntimeService,
        UrlResolverService,
        TimeoutService,
        GuidService,
        LangService,
        UserStorageService,
        GenericDialogService,
        ODataHttpService,
        AppShellClientService,
        AppStateService,
        AppShellHostService,
        ToastService,
        ErrorProtocolService,
        ProjectService,
        FileDownloadService,
        GlobalizationService,
        { provide: COMPONENT_CONTEXT, useValue: "" },

        // app specific services
        AggregatorDataService,
        UserService,
        ValueFormatterService,
        CirculationFlowMath,
        DteMath,
        UfcMath,
        HotWaterMath,
        PipeDiameterMath,
        StorageMath,
        VolumetricFlowRateMath,
    ],
})
export class CoreModule
{
    static forRoot()
    {
        return {
            ngModule: CoreModule,
        };
    }
}
