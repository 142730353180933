import { saveAs } from 'file-saver';

/**
 * Exposes methods helping with issuing direct downloads of files in the user's
 * browser.
 */
export class FileDownloadService
{
    public issueDownload(fileUrlOrBlob: string | Blob, fileName?: string): void
    {
        saveAs(fileUrlOrBlob, fileName);
    }
}
