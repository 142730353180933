import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface ISegment {
    label: string;
    route: string;
}

export interface IDemandsIndexChangeEvent {
    newStepIndex: number;
    newRoute: string;
}

@Component({
    selector: 'viega-step-bar',
    templateUrl: './step-bar.component.html',
    styleUrls: ['./step-bar.component.scss'],
})
export class StepBarComponent {
    @Input()
    public segments: ISegment[];
    public hoverIndex: number = -1;

    /** The index of the segment the user is currently viewing. */
    @Input()
    public activeIndex: number = 0;

    /** The index of the segment which represents the current progress / step. */
    @Input()
    public currentIndex: number = 0;

    /** The index of the segment the user could possibly skip to. */
    @Input()
    public skipAllowedIndex: number = 0;

    /** The label displayed before the number of the step. */
    @Input()
    public stepLabelMobile: string;

    @Output()
    public demandsIndexChange = new EventEmitter<IDemandsIndexChangeEvent>();

    public segment_click(segment: ISegment, index: number) {
        if (index > this.skipAllowedIndex){
            return;
        }

        if (this.activeIndex !== index) {
            this.demandsIndexChange.emit({ newStepIndex: index , newRoute: segment.route});
            this.activeIndex = index;
        }
    }

    public segment_mouseover(index: number) {
        this.hoverIndex = index;
    }

    public segment_mouseout(index: number) {
        this.hoverIndex = -1;
    }

    public step_back() {
        const prev = this.activeIndex - 1;
        const prevSegment = this.segments[prev];
        this.segment_click(prevSegment, prev);
    }

    public step_forward() {
        const next = this.activeIndex + 1;
        const nextSegment = this.segments[next];
        this.segment_click(nextSegment, next);
    }

    isNumber(n: number): boolean {
        return !Number.isNaN(n);
    }
}
