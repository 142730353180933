import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RadioButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-inputs';
import { ProjectDataService } from 'src/app/core/project-data.service';
//Wird nicht benutzt
@Component({
  selector: 'app-further-details',
  templateUrl: './further-details.component.html',
  styleUrls: ['./further-details.component.scss']
})
export class FurtherDetailsComponent implements OnInit {
  @ViewChild('radio1') public option1Radio: RadioButtonComponent;
  @ViewChild('radio2') public option2Radio: RadioButtonComponent;
  @ViewChild('radio3') public option3Radio: RadioButtonComponent;
  @ViewChild('radio4') public option4Radio: RadioButtonComponent;
  public data: any = [
    {pwh: "Im Keller verlegt [11 W/m]", lineLength: 11, result: 121},
    {pwh: "im Schacht verlegt [7 W/m]", lineLength: 7, result: 49},
    {pwh: "Nutzbereich [7 W/m]", lineLength: 5, result: 35}
  ]

  dropdownData = ["l/s", "l/min"]
  buttonDisabled: boolean = true;
  sectionOneSelected: boolean = false;
  sectionTwoSelected: boolean = false;
  dteSelected: boolean = true;
  ufcSelected: boolean = true;
  ufc: number;

  selectedProject;

  CheckSections(){
    if(this.sectionOneSelected && this.sectionTwoSelected){
      this.buttonDisabled = false;
    }
    else{
      this.buttonDisabled = true;
    }
  }
  constructor(private readonly router: Router, private DataService: ProjectDataService) { }
  changeOption2(args: ChangeEventArgs){
    this.sectionOneSelected = true;
    this.option2Radio.checked = true;
    this.option2Radio.value = 'true';
    this.CheckSections();
  }
  changeOption3(args: ChangeEventArgs){
    this.sectionOneSelected = true;
    this.option3Radio.checked = true;
    this.option3Radio.value = 'true';
    this.CheckSections();
  }
  changeOption4(args: ChangeEventArgs){
    this.sectionOneSelected = true;
    this.option4Radio.checked = true;
    this.option4Radio.value = 'true';
    this.CheckSections();
  }
  changeSecondOption(args: ChangeEventArgs)
  {
    this.sectionTwoSelected = true;
    this.CheckSections();
  }
  ngOnInit(): void {
    this.selectedProject = this.DataService.getCurrentProject();
    if(this.selectedProject.projectValues.DTE != 0){
      this.dteSelected = true;
    }
    else{
      this.dteSelected = false;
    }
    if(this.selectedProject.projectValues.UFC != 0){
      this.ufcSelected = true;
      this.ufc = this.selectedProject.projectValues.UFC;
    }
    else{
      this.ufcSelected = false;
    }
  }
  goBack() {
    window.history.back();
  }
  navigateNext(){
    this.router.navigate([`/heat-generaing-types/${this.selectedProject.ProjectId}`]);
  }

}
