import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Allows to define context variables within templates, e.g.
 * <div *localVar="'foo' as myVariable">
 *    <span>{{myVariable}}</span>
 * </div>
 */
@Directive({
    selector: '[localVar]',
})
export class VarDirective
{
    @Input()
    set localVar(context: any)
    {
        this.context.$implicit = this.context.localVar = context;
        this.updateView();
    }

    public context: any = {};

    constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    public updateView()
    {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}
