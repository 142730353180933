import { AfterViewInit, Component, IterableDiffers, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangedEventArgs, SliderChangeEventArgs } from '@syncfusion/ej2-angular-inputs';
import { editAlert } from '@syncfusion/ej2-spreadsheet';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { OtherBuilding } from 'src/app/core/BackendApi/otherbuilding.complex';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { ResidentialBuilding } from 'src/app/core/BackendApi/residentialbuilding.complex';
import { Tageslastgang } from 'src/app/core/BackendApi/tageslastgang.complex';
import { Warmwasser } from 'src/app/core/BackendApi/warmwasser.complex';
import { DailyLoadCourseProfileValuesRow } from 'src/app/core/DteufcAggregatorServiceApi/dailyloadcourseprofilevaluesrow.complex';
import { SectionState, WarmwasserErmittlungstyp } from 'src/app/core/Enums';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
  selector: 'app-daily-load-profile',
  templateUrl: './daily-load-profile.component.html',
  styleUrls: ['./daily-load-profile.component.scss'],
  providers: [provideContext("DailyLoadProfile")],
})
export class DailyLoadProfileComponent implements OnInit, AfterViewInit {

    title = this.langService.getString('PanelHeadline_T');
    description = this.langService.getString('PanelDescription_D');
    stepBack = this.langService.getString('PanelStepBackLabel_T');
    stepText = this.langService.getString('PanelStepText_T');

    selectedProject: ProjectEntity;
    currentBuildingType: string;

    percent = this.langService.getString('#Generic.Unit.Percent_T')

    ddFields = { text: 'DisplayNameLoc', value: 'ProfileId' }
    profiles = [];
    currentInputType: string;

    // iterableDiffer;
    totalArrayValue = 0;

    data: DailyLoadCourseProfileValuesRow[];
    leftTableData: DailyLoadCourseProfileValuesRow[];
    rightTableData: DailyLoadCourseProfileValuesRow[];

    primaryYAxis: object;
    primaryXAxis: object;
    animation: object;
    palette: string[];

    isCheckRed: boolean = false;
    tempData: DailyLoadCourseProfileValuesRow[];

    constructor(
      private activatedRoute: ActivatedRoute,
      private readonly router: Router,
        private iterableDiffers: IterableDiffers,
        private aggregatorDataService: AggregatorDataService,
        private langService: LangService,
        private DataService: ProjectDataService,
        private formatter: ValueFormatterService
        ) { }
    ngAfterViewInit(): void {
        if(this.tempData){
            let i = 0;
            this.leftTableData = [];
            this.rightTableData = [];
            this.tempData.forEach(x => {if(x.Hour < 12){this.leftTableData.push(x)} else {this.rightTableData.push(x)}})
        }
    }

    ngOnInit(): void {

        
        let urlProject = this.activatedRoute.snapshot.data['project'];
        this.selectedProject = this.DataService.TempProject;
        if(!this.selectedProject){
            if(urlProject){
                this.router.navigate([`/project-view/${urlProject.ProjectId}`]);
            } else {
                this.router.navigate([`/home`]);
            }
            return;
        }

        this.currentBuildingType = this.activatedRoute.snapshot.data['buildingType'];
        if(!this.currentBuildingType || (this.selectedProject.ResidentialBuilding?.BuildingType != this.currentBuildingType
            && !this.selectedProject.OtherBuildings?.some(b => b.BuildingType == this.currentBuildingType))
        ){
            this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`])
        }



        this.profiles = this.aggregatorDataService.aggregatorData.Tables.DailyLoadCourseProfileRows;

        let building = this.getBuilding(this.currentBuildingType);
        let currentInputType = this.getInputType(building);
        let { data, leftTableData, rightTableData } = this.getData(building, currentInputType);
        
        this.currentInputType = currentInputType;
        this.data = data;
        this.leftTableData = leftTableData;
        this.rightTableData = rightTableData;

        if(this.data && this.currentInputType == "Manu.Eingabe"){
            this.leftTableData = [];
            this.rightTableData = [];
            let i = 0;
            this.tempData = JSON.parse(JSON.stringify(this.data));
            this.data.forEach(x => {if(i < 12){this.leftTableData.push({ProfileId: x.ProfileId, Hour: x.Hour, Amount: 0})} else {this.rightTableData.push({ProfileId: x.ProfileId, Hour: x.Hour, Amount: 0})}; i++})

        }
        else{
            this.leftTableData = this.aggregatorDataService.aggregatorData.Tables.DailyLoadCourseProfileValuesRows.filter(x => x.ProfileId === this.currentInputType && x.Hour < 12)
            this.rightTableData = this.aggregatorDataService.aggregatorData.Tables.DailyLoadCourseProfileValuesRows.filter(x => x.ProfileId === this.currentInputType && x.Hour > 11)
            this.data = this.leftTableData.concat(this.rightTableData);
        }

        this.checkValues();

        this.animation = { enable: false};
        this.palette = ["#5181e8", "#ed8332", "#919191", "#5e5e5e", "#5e5e5e", "#5e5e5e"];
        this.primaryXAxis = {
        // interval: 1,
        // labelFormat: 'C'
        valueType: 'Category',
        title: this.langService.getString('XAxisUhrzeit_T')
        };
        this.primaryYAxis = {
            // interval: 1,
            // labelFormat: 'C'
            title: this.langService.getString('YAxisAnteil_T', undefined, this.langService.getString('#Generic.Unit.Percent_T'))
            };
    }

    getBuilding(buildingType: string) {
        if (this.selectedProject.ResidentialBuilding?.BuildingType == buildingType) {
            return this.selectedProject.ResidentialBuilding;
        } else {
            return this.selectedProject.OtherBuildings.find(b => b.BuildingType == buildingType);
        }
    }
    
    getInputType(building: ResidentialBuilding | OtherBuilding) {
        if (building && building.Warmwasser && building.Warmwasser.Tageslastgang) {
            return building.Warmwasser.Tageslastgang.Vorgabetyp;
        } else {
            return this.aggregatorDataService.aggregatorData.Tables.BuildingTypeRows.find(r => r.BuildingId == this.currentBuildingType).VorauswahlTageslastgangProfileId;
        }
    }
    
    getData(building: ResidentialBuilding | OtherBuilding, currentInputType: string) {
        if (currentInputType == 'Manu.Eingabe') {
            return { 
                data: building.Warmwasser.Tageslastgang.Anteile.map((a, i) => ({ Amount: a, Hour: i, ProfileId: currentInputType })),
                leftTableData: null,
                rightTableData: null
            };
        } else {
            return { 
                data: null,
                leftTableData: this.aggregatorDataService.aggregatorData.Tables.DailyLoadCourseProfileValuesRows.filter(x => x.ProfileId === currentInputType && x.Hour < 12),
                rightTableData: this.aggregatorDataService.aggregatorData.Tables.DailyLoadCourseProfileValuesRows.filter(x => x.ProfileId === currentInputType && x.Hour > 11)
            };
        }
    }

    axisLabelRender(args){
        if(args.axis.name == "primaryXAxis"){
            args.text = args.value + "-" + (args.value+1)
        }
    }

    checkValues(){
      var leftTableValues = this.leftTableData.map(function(o) {return o.Amount;});
      var rightTableValues = this.rightTableData.map(function(o) {return o.Amount;});
      var sum = leftTableValues.reduce(function(a, b){
        return a + b;
      }, 0);
      var sum2 = rightTableValues.reduce(function(a, b){
        return a + b;
      }, 0);
      this.totalArrayValue = Number(sum) + Number(sum2);
      this.data = this.leftTableData.concat(this.rightTableData);
      this.checkRed();
    }

    public sliderChangeLeftTable(args: any, index: number) {
      this.leftTableData[index].Amount = Number(args);
      this.checkValues();
    }

    public sliderChangeRightTable(args: any, index: number) {
      this.rightTableData[index].Amount = Number(args);
      this.checkValues();
    }

    goBack() {
      window.history.back();
    }

    checkRed(){
      if(this.totalArrayValue > 100 || this.totalArrayValue < 100){
        this.isCheckRed = true;
      }
      else{
        this.isCheckRed = false;
      }
    }

    onChangeInputType(args){
        var value = args.value;

        this.leftTableData = this.aggregatorDataService.aggregatorData.Tables.DailyLoadCourseProfileValuesRows.filter(x => x.ProfileId === value && x.Hour < 12)
        this.rightTableData = this.aggregatorDataService.aggregatorData.Tables.DailyLoadCourseProfileValuesRows.filter(x => x.ProfileId === value && x.Hour > 11)

        this.currentInputType = value;

        this.checkValues();
    }

    getWwDisplay(){
        if (this.currentBuildingType == this.selectedProject.ResidentialBuilding?.BuildingType) {
            return this.formatter.formatNumber(this.selectedProject.ResidentialBuilding.Warmwasser.WarmwasserErgebnisLTag, 'Liter', {maximumFractionDigits: 0}, false)
        }
        else {
            return this.formatter.formatNumber(this.selectedProject.OtherBuildings.find(x => x.BuildingType == this.currentBuildingType).Warmwasser.WarmwasserErgebnisLTag, 'Liter', {maximumFractionDigits: 0}, false)
        }
    }

    navigateNext(){
        let anteile: number[] = this.data.map(d => {return d.Amount})
        console.log(this.currentInputType);
        let tageslastgang: Tageslastgang = {
            Vorgabetyp: this.currentInputType,
            Anteile: anteile
        }
        let savedProject: ProjectEntity = this.activatedRoute.snapshot.data['project'];
        if (this.selectedProject.ResidentialBuilding?.BuildingType == this.currentBuildingType) {
            this.selectedProject.ResidentialBuilding.Warmwasser.Tageslastgang = tageslastgang;
        }
        else {
            this.selectedProject.OtherBuildings.find(x => x.BuildingType == this.currentBuildingType).Warmwasser.Tageslastgang = tageslastgang;
        }

        let changed = true;
        let currentTemp:Warmwasser;
        let savedTemp: Warmwasser;

        if(this.selectedProject.ResidentialBuilding?.BuildingType == this.currentBuildingType){
            currentTemp = this.selectedProject.ResidentialBuilding.Warmwasser;
            savedTemp = savedProject.ResidentialBuilding.Warmwasser;

        }
        else{
            currentTemp = this.selectedProject.OtherBuildings.find(x => x.BuildingType == this.currentBuildingType).Warmwasser;
            savedTemp = savedProject.OtherBuildings.find(x => x.BuildingType == this.currentBuildingType).Warmwasser;
        }
        if(savedTemp && currentTemp && JSON.stringify(savedTemp.ErmittlungWarmwasserExakt) === JSON.stringify(currentTemp.ErmittlungWarmwasserExakt)
            && JSON.stringify(savedTemp.Tageslastgang) === JSON.stringify(currentTemp.Tageslastgang))
            {
                this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.find(s => s.Key == this.currentBuildingType).Value = SectionState.Set;
                this.DataService.saveTempProject().then(_=>
                    this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`])
                    );
                return;
            }




        var buildingWwState = this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.find(b => b.Key == this.currentBuildingType);
            buildingWwState.Value = SectionState.Set;
        if(this.selectedProject.SectionStates.Energiespeicher == SectionState.Set)
            this.selectedProject.SectionStates.Energiespeicher = SectionState.Check;


        this.selectedProject.ProjectValues.WarmwasserErgebnisLtag = (
            this.selectedProject.ResidentialBuilding != null && this.selectedProject.ResidentialBuilding.Warmwasser != null
                ? this.selectedProject.ResidentialBuilding.Warmwasser.WarmwasserErgebnisLTag : 0
        ) + (
            this.selectedProject.OtherBuildings != null ?
            this.selectedProject.OtherBuildings
                .filter(b => b.Warmwasser != null)
                .reduce((sum, b) => sum + b.Warmwasser.WarmwasserErgebnisLTag, 0) : 0
        )



        this.DataService.saveTempProject().then(_=>
            this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`])
            );

    }
}
