import { Component, EventEmitter, Inject, Output, ViewChild, OnInit } from '@angular/core';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { Wohnungsgruppe } from 'src/app/core/BackendApi/wohnungsgruppe.complex';
import { ApartmentGroupFacilityRow } from 'src/app/core/DteufcAggregatorServiceApi/apartmentgroupfacilityrow.complex';
import { PspFormFieldGroup } from 'src/core-lib/angular/components/forms/PspFormFieldGroup';
import { PspFormField } from 'src/core-lib/angular/components/forms/PspFormField';
import { Validators } from '@angular/forms';
import { ZweitBadTyp } from 'src/app/core/Enums';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { BlurEventArgs, ChangeEventArgs } from '@syncfusion/ej2-inputs/src';

@Component({
    selector: 'app-wohnungsgruppe-dialog-data',
    templateUrl: './wohnungsgruppe-dialog-data.component.html',
    styleUrls: ['./wohnungsgruppe-dialog-data.component.scss'],
})
export class WohnungsgruppeDialogData implements OnInit{

    public ngOnInit(): void {
        this.calculateMinBelegungszahl();
    }

    invalidFieldsDialogButtons = [{
        'click': this.onCloseInvalidFieldsDialog.bind(this),
        buttonModel: {
            content: this.langService.getString('InvalidDialogUeberpruefenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
    }]

    //Output
    @Output() saveNewEditDialog = new EventEmitter<Object>();
    @Output() editItem = new EventEmitter<Object>();

    //ViewChild
    @ViewChild('invalidFieldsDialog') public invalidFieldsDialog: DialogComponent;

    // Config Variables
    public readonly austattungFields: { text: string, value: string };
    public readonly zweitbadTypesFields: { text: string, value: string };
    public readonly formFields: PspFormFieldGroup;
    public readonly zweitbadTypes: { label: string, value: ZweitBadTyp }[];

    // Edit Data Variables
    public currentElement: Wohnungsgruppe;
    public currentElementAusstattungsIDs: string[];
    public currentElementZweitbadAusstattungsIDs: string[];
    public editElementIndex: number;
    public calculatedMin: number = 2;
    public maxDefinedRaumZahl: number = 7;

    // Internal Variables
    private readonly allAvailableFacilities: ApartmentGroupFacilityRow[];
    public readonly availableErstbadFacilities: ApartmentGroupFacilityRow[];
    public availableZweitbadFacilities: ApartmentGroupFacilityRow[];
    private availableFacilities: ApartmentGroupFacilityRow[];
    private edit: boolean;

    @ViewChild(DialogComponent) public dialog: DialogComponent;

    // Constructors
    constructor(
        private langService: LangService,
        protected aggregatorDataService: AggregatorDataService
    ){
        this.zweitbadTypes = [
            { label: this.langService.getString('ZweitBadTypes.Keins_T'), value: ZweitBadTyp.None },
            { label: this.langService.getString('ZweitBadTypes.Zwei_T'), value: ZweitBadTyp.ZweitesBad },
            { label: this.langService.getString('ZweitBadTypes.GaesteMit_T'), value: ZweitBadTyp.GästezimmerMitBwUndDu },
            { label: this.langService.getString('ZweitBadTypes.GaesteOhne_T'), value: ZweitBadTyp.GästezimmerOhneBwUndDu }
        ];

        this.availableFacilities = this.aggregatorDataService.aggregatorData.Tables.ApartmentGroupFacilityRows;

        this.allAvailableFacilities = this.availableFacilities;
        this.availableErstbadFacilities = this.availableFacilities.filter(f => f.ZapfstellenbedarfWVErstbad != null)
 
        this.zweitbadTypesFields = { text: "label", value: "value" };
        this.austattungFields = { text: "DisplayNameLoc", value: "FacilityId" };
        
        this.currentElement = {
            Anzahl: 0,
            Bezeichnung: null,
            Ausstattungen: null,
            Belegungszahl: 0,
            IsKleinwohnungen: false,
            Raumzahl: 0,
            ZweitBadTyp: null,
            AusstattungenZweitbad: null
        };
        this.currentElementAusstattungsIDs = null;
        this.currentElementZweitbadAusstattungsIDs = null;
        
        this.formFields = new PspFormFieldGroup([
            new PspFormField(
            {
                fieldName: 'Name',
                displayName: this.langService.getString('FormFields.Name_T'),
                formState: {
                    value: this.currentElement.Bezeichnung,
                    disabled: false
                },
                displayAsRequired: true,
                validatorOrOpts: [Validators.required, Validators.pattern("[\\w\\d\-\.()/\\!\"'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*"), Validators.minLength(1),Validators.maxLength(50)],
            }),
            new PspFormField(
            {
                fieldName: 'groupAmount',
                displayName: "",
                formState: {
                    value: this.currentElement.Anzahl,
                    disabled: false
                },
                displayAsRequired: false,
                validatorOrOpts: Validators.min(1)
            }),
            new PspFormField(
                {
                    fieldName: 'groupAmountLabel',
                    displayName: this.langService.getString('FormFields.GroupAmount_T'),
                    formState: {
                        value: this.currentElement.Anzahl,
                        disabled: false
                    },
                    displayAsRequired: true,
                }),
            new PspFormField(
            {
                fieldName: 'groupChecked',
                displayName: ' ',
                formState: {
                    value: this.currentElement.IsKleinwohnungen,
                    disabled: false
                },
                displayAsRequired: false,

            }),
            new PspFormField(
            {
                fieldName: 'roomAmount',
                displayName: this.langService.getString('FormFields.RoomAmount_T'),
                formState: {
                    value: this.currentElement.Raumzahl,
                    disabled: false
                },
                displayAsRequired: false,
            }),
            new PspFormField(
            {
                fieldName: 'belegAmount',
                displayName: this.langService.getString('FormFields.BelegAmount_T'),
                formState: {
                    value: this.currentElement.Belegungszahl,
                    disabled: false
                },
                displayAsRequired: false,
                helpMessageHtml: this.aggregatorDataService.aggregatorData.Tables.TooltipRows.find(x => x.TooltipId == "Bedarfsvolumenstrom.Wohngebäude.Wohnungsgruppen.Belegungszahl").TooltipContentHtmlLoc

            }),
            new PspFormField(
            {
                fieldName: 'groupEquipment',
                displayName: this.langService.getString('FormFields.GroupEquipment_T'),
                formState: {
                    value: this.currentElement.Ausstattungen,
                    disabled: false
                },
                displayAsRequired: true,
                validatorOrOpts: Validators.required,
            }),
            new PspFormField(
            {
                fieldName: 'groupSecondBath',
                displayName: this.langService.getString('FormFields.GroupSecondBath_T'),
                formState: {
                    value: this.currentElement.ZweitBadTyp,
                    disabled: false
                },
                displayAsRequired: false,
                helpMessageHtml: this.aggregatorDataService.aggregatorData.Tables.TooltipRows.find(x => x.TooltipId == "Bedarfsvolumenstrom.Wohngebäude.Wohnungsgruppen.ZweitesBad").TooltipContentHtmlLoc

            }),
            new PspFormField(
            {
                fieldName: 'groupSecondBathEquipment',
                displayName: this.langService.getString('FormFields.GroupSecondBathEquipment_T'),
                formState: {
                    value: this.currentElement.AusstattungenZweitbad
                },
                displayAsRequired: false,
            }),
        ]);
    }
        // Methods
        public clearEditElement(){
        
            this.editElementIndex = null;
            this.currentElement = {
                Anzahl : 0,
                Ausstattungen : null,
                AusstattungenZweitbad : null,
                Belegungszahl : 0,
                Bezeichnung : null,
                IsKleinwohnungen : false,
                Raumzahl : 0,
                ZweitBadTyp : 0,
            }
            this.currentElementAusstattungsIDs = null;
            this.currentElementZweitbadAusstattungsIDs = null;
        }
        public editElement(gruppe: Wohnungsgruppe, index: number): void {
            this.editElementIndex = index;
            this.currentElement.Anzahl = gruppe.Anzahl;
            this.currentElement.Ausstattungen = gruppe.Ausstattungen;
            this.currentElement.Belegungszahl = gruppe.Belegungszahl;
            this.currentElement.Bezeichnung = gruppe.Bezeichnung;
            this.currentElement.IsKleinwohnungen = gruppe.IsKleinwohnungen;
            this.currentElement.Raumzahl = gruppe.Raumzahl;
            this.textboxValue = gruppe.Anzahl;
            this.currentElement.ZweitBadTyp = gruppe.ZweitBadTyp;
            this.currentElement.AusstattungenZweitbad = gruppe.AusstattungenZweitbad;
            this.currentElementAusstattungsIDs = gruppe.Ausstattungen != null ? gruppe.Ausstattungen.map(a => a.Typ) : null;
            this.currentElementZweitbadAusstattungsIDs = gruppe.AusstattungenZweitbad != null ? gruppe.AusstattungenZweitbad.map(a => a.Typ) : null;
            switch(this.currentElement.ZweitBadTyp){
                case ZweitBadTyp.ZweitesBad:
                    this.availableZweitbadFacilities = this.allAvailableFacilities.filter(f => f.ZapfstellenbedarfWVZweitbad != null)
                    break;
                case ZweitBadTyp.GästezimmerMitBwUndDu:
                    this.availableZweitbadFacilities = this.allAvailableFacilities.filter(f => f.ZapfstellenbedarfWVGaesteBwDu != null)
                    break;
                case ZweitBadTyp.GästezimmerOhneBwUndDu:
                    this.availableZweitbadFacilities = this.allAvailableFacilities.filter(f => f.ZapfstellenbedarfWVGaesteOhneBwDu != null)
                    break;
            }
            this.edit = true;
            this.dialog.show();
        }
        public getEditedElement(): Wohnungsgruppe {
            // Clone current edit element
            let clone: Wohnungsgruppe = JSON.parse(JSON.stringify(this.currentElement));
    
            // Map selected austattungs ids to their entities
            if(this.currentElementAusstattungsIDs != null){
                clone.Ausstattungen = this.currentElementAusstattungsIDs
                    .map(a => this.allAvailableFacilities.find(f => f.FacilityId == a))
                    .map(f => { return {
                        Typ: f.FacilityId,
                        Bedarf: f.ZapfstellenbedarfWVErstbad,
                        IsCustom: false
                    }});
            }
    
            // Map selected zweitbad austattungs ids to their entities
            if(this.isZweitbadConfigured() && this.currentElementZweitbadAusstattungsIDs != null){
                clone.AusstattungenZweitbad = this.currentElementZweitbadAusstattungsIDs
                .map(a => this.allAvailableFacilities.find(f => f.FacilityId == a))
                .map(f => {
                    let bedarf: number;
                    switch(clone.ZweitBadTyp){
                        case ZweitBadTyp.ZweitesBad: bedarf = f.ZapfstellenbedarfWVZweitbad; break;
                        case ZweitBadTyp.GästezimmerMitBwUndDu: bedarf = f.ZapfstellenbedarfWVGaesteBwDu; break;
                        case ZweitBadTyp.GästezimmerOhneBwUndDu: bedarf = f.ZapfstellenbedarfWVGaesteOhneBwDu; break;
                    }
                    return {
                        Typ: f.FacilityId,
                        Bedarf: bedarf,
                        IsCustom: false
                    }
                });
            }
            else{
                clone.AusstattungenZweitbad = null;
            }
    
            // Return modified clone
            return clone;
        }
        public isZweitbadConfigured(): boolean {
            return this.currentElement.ZweitBadTyp != null && this.currentElement.ZweitBadTyp != ZweitBadTyp.None;
        }
    
    
        // Change Events
        public onZweitbadTypChanged(): void {
            // Clear current selection
            console.log(this.currentElementZweitbadAusstattungsIDs);
            this.currentElementZweitbadAusstattungsIDs = null;
    
            // Refresh the list of available ausstattungen
            switch(this.currentElement.ZweitBadTyp){
                case ZweitBadTyp.None:
                    this.availableZweitbadFacilities = [];
                case ZweitBadTyp.ZweitesBad:
                    this.availableZweitbadFacilities = this.allAvailableFacilities.filter(f => f.ZapfstellenbedarfWVZweitbad != null)
                    break;
                case ZweitBadTyp.GästezimmerMitBwUndDu:
                    this.availableZweitbadFacilities = this.allAvailableFacilities.filter(f => f.ZapfstellenbedarfWVGaesteBwDu != null)
                    break;
                case ZweitBadTyp.GästezimmerOhneBwUndDu:
                    this.availableZweitbadFacilities = this.allAvailableFacilities.filter(f => f.ZapfstellenbedarfWVGaesteOhneBwDu != null)
                    break;
            }
        }

        public show(): void {
            // Reset edit values
            this.clearEditElement();

            if(this.formFields.fields.some(x => x.control.dirty && x.control.touched)){
                this.formFields.fields.forEach(x => {x.control.reset()});
            }
            this.textboxValue = 0;
            this.sliderValue = 0;
            // update maxDefinedRaumZahl - get from table
            this.maxDefinedRaumZahl = Math.max(...this.aggregatorDataService.aggregatorData.Tables.Din4708ResidentialRoomOccupancyRows.map(o => o.RoomCount));
            this.calculateMinBelegungszahl();

            console.log(this.textboxValue, this.sliderValue, this.currentElement.Anzahl)
            this.edit = false;
            this.dialog.show();
        }

        public hide(): void {
            this.dialog.hide();
        }
        public onDialogBackdropClick(): void {
            this.dialog.hide();
        }
    
        public getEditDialogHeader(){
            if(this.editElementIndex == null){
                return this.langService.getString('ExaktTypRadio.DialogHeaderNeu_T');
            }
            else{
                return this.langService.getString('ExaktTypRadio.DialogHeader_T')
            }
        }

        sliderValue: number = 0;
        sliderMin: number = 0;
        sliderMax: number = 100;
        textboxValue: number = 0;
        lastChangeWasSlider: boolean = false;
        textboxMin: number = 0;
        textboxMax: number = 999;
        
         // Events
         public onSliderChanged(args: ChangeEventArgs) {
            if(this.sliderValue == null){
                this.sliderValue = this.sliderMin;
            }
            this.textboxValue = this.sliderValue;
            this.onChanged();
        }

        public onTextboxChanged(args: ChangeEventArgs) {
            // Change empty textbox to min value
            if(this.textboxValue == null) {
                this.textboxValue = this.textboxMin;
            }
    
            // Handle change
            if(args.isInteracted){
                this.lastChangeWasSlider = false;
                this.sliderValue = this.textboxValue
                this.onChanged();
            }
        }
        public onTextboxBlur(args: BlurEventArgs) {
            this.onTextboxChanged({ isInteracted: false });
        }

        private onChanged() {
            this.currentElement.Anzahl = this.lastChangeWasSlider ? this.sliderValue : this.textboxValue;
        }

        public calculateMinBelegungszahl(){
            console.log(this.currentElement.IsKleinwohnungen)
            if (this.currentElement.Raumzahl > this.maxDefinedRaumZahl) {
                this.calculatedMin = 0;
            } 
            else if( this.aggregatorDataService.aggregatorData.Tables.Din4708ResidentialRoomOccupancyRows.find(x => x.RoomCount == this.currentElement.Raumzahl)){
                if (this.currentElement.IsKleinwohnungen) {
                    this.calculatedMin = this.aggregatorDataService.aggregatorData.Tables.Din4708ResidentialRoomOccupancyRows.find(x => x.RoomCount == this.currentElement.Raumzahl).BelegungszahlKleinwohnung;
                }
                else {
                    this.calculatedMin = this.aggregatorDataService.aggregatorData.Tables.Din4708ResidentialRoomOccupancyRows.find(x => x.RoomCount == this.currentElement.Raumzahl).Belegungszahl;
                }
            }
            else{
                this.calculatedMin = 0;
            }
        }

        public raumzahlChanged() {
            var oldMin = this.calculatedMin;
            this.calculateMinBelegungszahl();
    
            // set Belegungszahl to max value
            if (this.calculatedMin === 0 && this.currentElement.Raumzahl > this.maxDefinedRaumZahl) {
                this.currentElement.Belegungszahl = this.aggregatorDataService.aggregatorData.Tables.Din4708ResidentialRoomOccupancyRows.find(x =>
                    x.RoomCount == this.maxDefinedRaumZahl).Belegungszahl;
                return;
            }
            else if (this.calculatedMin !== 0 && (this.calculatedMin < oldMin || oldMin === 0)) {
            // adjust Belegungszahl - if you decrease Raumzahl, calculatedMin is getting smaller and so should Belegungszahl 
                this.currentElement.Belegungszahl = this.calculatedMin;
            }
        }

        public getCalculatedMin(): string {
            // in case there is no norm defined for the Raumzahl
            // https://viega.atlassian.net/browse/PIMVIEOOOO-5453
            if (this.calculatedMin === 0) {
                return "-";
            }
            if(!this.currentElement.Belegungszahl ||
                (this.currentElement.Belegungszahl && this.currentElement.Belegungszahl < this.calculatedMin)){
                this.currentElement.Belegungszahl = this.calculatedMin;
            }
            return this.calculatedMin.toString();
        }

        public onEditDialogSave(): void {
            // Validate
            if(this.formFields.group.invalid || (this.isZweitbadConfigured() && !this.currentElementZweitbadAusstattungsIDs?.length)) {
                this.formFields.group.markAllAsTouched();
                this.invalidFieldsDialog.show();
                return;
            }

            this.currentElement = this.getEditedElement();
            this.dialog.hide();
            console.log(this.currentElement)
            if(this.edit){
                console.log(this.currentElement);
                this.editItem.emit(this.currentElement);
            }
            else{
                this.saveNewEditDialog.emit(this.currentElement);
            }
        }

        public onCloseInvalidFieldsDialog(): void {
            this.invalidFieldsDialog.hide();
        }
}