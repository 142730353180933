import { NgModule } from '@angular/core';
import { PspHelpNotifyIconComponent } from './psp-help-notify-icon.component';

@NgModule(
{
    declarations:
    [
        PspHelpNotifyIconComponent,
    ],
    exports:
    [
        PspHelpNotifyIconComponent,
    ],
})
export class PspHelpNotifyIconModule {}
