import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { OtherBuilding } from 'src/app/core/BackendApi/otherbuilding.complex';
import { ResidentialBuilding } from 'src/app/core/BackendApi/residentialbuilding.complex';
import { BuildingTypeRow } from 'src/app/core/DteufcAggregatorServiceApi/buildingtyperow.complex';
import { IStructureData } from 'src/app/core/project-interfaces';
import { provideContext } from 'src/core-lib/angular/context-util';

@Component({
  selector: 'app-structure-dialog',
  templateUrl: './structure-dialog.component.html',
  styleUrls: ['./structure-dialog.component.scss'],
  providers: [provideContext('StructureDialog')]
})
export class StructureDialogComponent implements OnInit {

    @ViewChild('dialog') public dialog: DialogComponent;

    @Input() target: string;
    @Input() buttons: any; //Typ???
    @Input() headerText: string;
    @Input() projectName: string;

    structures: BuildingTypeRow[];

    _selectedStructuresOther: OtherBuilding[];
    @Input() selectedStructuresOther: OtherBuilding[];
    @Output() selectedStructuresOtherChange = new EventEmitter<OtherBuilding[]>();

    _selectedStructureResidential: ResidentialBuilding;
    @Input() selectedStructureResidential: ResidentialBuilding;
    @Output() selectedStructureResidentialChange = new EventEmitter<ResidentialBuilding>();

    @Output() closedCompletely = new EventEmitter<any>();
    isClosedWithoutSave: boolean = true;
    canBeClosed: boolean = false;

    @Output() dialogPristine = new EventEmitter<any>()


    dialogWidth: string = "55%"; //Immer gleich???
    dialogHeight: string = "90vh"; //Immer gleich???
    dialogPosition: PositionDataModel = {X: 'center', Y: 'center'}

    @Input() changeWarning: string;



   constructor(private aggregatorDataService: AggregatorDataService) { }

    ngOnInit(): void {
        this.resetDialog();

        this.structures = this.aggregatorDataService.aggregatorData.Tables.BuildingTypeRows;
    }

    checkIfChecked(structureTitle: string): boolean{
        if (structureTitle == 'Wohngebäude') {
            return this._selectedStructureResidential ? true : false;
        }
        else{
            // return this._selectedStructures.some(s => s.id === structureTitle);
            return this._selectedStructuresOther.some(s => s.BuildingType == structureTitle);
        }
    }



    onChangeStructures(id: string, name: string){
        if (id == 'Wohngebäude') {
            if (!this._selectedStructureResidential) {
                this._selectedStructureResidential = {
                    BuildingType: id,
                }
            }
            else{
                this._selectedStructureResidential = null;
            }
        }
        else{
            if(!this._selectedStructuresOther.some(s => s.BuildingType === id)){
                this._selectedStructuresOther.push(
                    {
                        BuildingType: id,
                    }
                );
            }
            else{
                var index = this._selectedStructuresOther.map(function(e) {return e.BuildingType}).indexOf(id);
                this._selectedStructuresOther.splice(index, 1)
            }

        }

    }

    emitSelectedStructures(){
        if (this._selectedStructureResidential) {
            this.selectedStructureResidential = JSON.parse(JSON.stringify(this._selectedStructureResidential));
            this.selectedStructureResidentialChange.emit(this.selectedStructureResidential);
        }

        if (this._selectedStructuresOther && this._selectedStructuresOther.length > 0) {
            this.selectedStructuresOther = this._selectedStructuresOther.slice();
            this.selectedStructuresOtherChange.emit(this.selectedStructuresOther);
        }
    }

    onOverlayClick(){
        this.dialog.hide();
    }

    onCloseDialog(){
        if(this.isClosedWithoutSave){
            this.closedCompletely.emit();
            this.resetDialog();
        }
    }

    onBeforeClose(event){
        if(this.target == 'app-overview'){
            if(this.isClosedWithoutSave){
                if(!this.canBeClosed){ //TODO ! oder nicht
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
        else{
            if(this.isClosedWithoutSave){
                if(!this.canBeClosed && !this.checkIfPristine()){ //TODO ! oder nicht
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
    }

    resetDialog(){
        if(!this.selectedStructuresOther){
            this._selectedStructuresOther = [];
        } else {
            this._selectedStructuresOther = this.selectedStructuresOther.slice();
        }
        if(!this.selectedStructureResidential){
            this._selectedStructureResidential = null;
        } else {
            this._selectedStructureResidential = JSON.parse(JSON.stringify(this.selectedStructureResidential));
        }
    }

    checkIfPristine(): boolean{
        if (this.selectedStructureResidential && !this._selectedStructureResidential
            || this._selectedStructureResidential && !this.selectedStructureResidential) {
            return false;
        }


        if(this._selectedStructuresOther.some(_s => !this.selectedStructuresOther.some(s => s.BuildingType == _s.BuildingType )) ||
        this.selectedStructuresOther.some(s => !this._selectedStructuresOther.some(_s => _s.BuildingType == s.BuildingType ))){
            return false;
        }
        else{
            return true;
        }

    }

}
