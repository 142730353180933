import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { DteRow } from 'src/app/core/DteufcAggregatorServiceApi/dterow.complex';
import { UfcRow } from 'src/app/core/DteufcAggregatorServiceApi/ufcrow.complex';
import { SectionState } from 'src/app/core/Enums';
import { CirculationFlowMath } from 'src/app/core/math/CirculationFlowMath';
import { UfcMath } from 'src/app/core/math/UfcMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
  selector: 'app-select-calculation-ufc',
  templateUrl: './select-calculation-ufc.component.html',
  styleUrls: ['./select-calculation-ufc.component.scss'],
  providers: [provideContext('SelectCalculationUfc')]
})
export class SelectCalculationUfcComponent implements OnInit {

    stepTitle = this.langService.getString('StepTitle_T');
    stepBack = this.langService.getString('StepBack_T');
    stepDescription = this.langService.getString('StepDescription_D');


    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private DataService: ProjectDataService,
        private formatter: ValueFormatterService,
        private aggregatorService : AggregatorDataService,
        private ufcMath: UfcMath,
        private langService: LangService,
        private circuMath: CirculationFlowMath
    ) { }

    zirkulationsvolumenstrom = 0;
    filter = 0;
    dte;
    ufc;

    selectedProject: ProjectEntity;

    ngOnInit(): void {
      this.selectedProject = this.activatedRoute.snapshot.data['project'];

      this.zirkulationsvolumenstrom = this.selectedProject.ProjectValues.Zirkulationsvolumenstrom.ZirkulationsvolumenstromBerechnetLMin;
      if(!this.zirkulationsvolumenstrom){ //Für Testzwecke
          this.zirkulationsvolumenstrom = 10
      }

      this.dte = this.selectedProject.ProjectValues.Dte.Ergebnis;
      if (this.dte == '0') { //Für Testzwecke
        this.dte = '70'
    }

    var dteRow = this.aggregatorService.aggregatorData.Tables.DteRows.find(x => x.Dte == this.dte)

    var ufcRows = this.aggregatorService.aggregatorData.Tables.UfcRows;
    var ufcRow = this.ufcMath.ufcFromDte(dteRow, ufcRows, this.zirkulationsvolumenstrom)
    this.ufc = ufcRow.Ufc;

    this.filter = this.circuMath.filtervolumenstromFromZirkulationsvolumenstrom(this.zirkulationsvolumenstrom, ufcRow);
}


    goBack() {
        this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
    }

    getDteDisplay(){
        return this.formatter.formatDteName(this.dte);
    }

    getUfcDisplay(){
        return this.formatter.formatUfcName(this.ufc);
    }

    getZirkuDisplay(){
        return this.formatter.formatNumber(this.zirkulationsvolumenstrom, 'LiterPerMinute', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    }

    getFilterDisplay(){
        return this.formatter.formatNumber(this.filter, 'LiterPerMinute', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    }


    navigateNext(){
      {
        if(this.selectedProject.ProjectValues.Dte.Ufc && +this.selectedProject.ProjectValues.Dte.Ufc.toFixed(4) == +(Number.parseFloat(this.ufc)).toFixed(4)
         && +this.selectedProject.ProjectValues.Filtervolumenstrom.toFixed(4) == +this.filter.toFixed(4)){
             this.selectedProject.SectionStates.Ufc = SectionState.Set;
            this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
            return;
        }
        this.selectedProject.ProjectValues.Dte.Ufc = this.ufc;
        this.selectedProject.ProjectValues.Filtervolumenstrom = this.filter;

        this.selectedProject.SectionStates.Ufc = SectionState.Set;

        if(this.selectedProject.SectionStates.BuildingTypeWarmwasserStates){
            this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.forEach(b => {
                if(b.Value == SectionState.Set){
                    b.Value = SectionState.Check;
                }
            });
        }

        if(this.selectedProject.SectionStates.Energiespeicher === SectionState.Set){
          this.selectedProject.SectionStates.Energiespeicher = SectionState.Check;
        }

        this.DataService.saveCurrentProject()
            .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
      }

    }

}
