export class TimeoutService
{
    public setTimeout(time: number, callback: () => void): number
    {
        return window.setTimeout(callback, time);
    }

    public clearTimeout(timeoutId: number)
    {
        window.clearTimeout(timeoutId);
    }

    public setInterval(time: number, callback: () => void): number
    {
        return window.setInterval(callback, time);
    }

    public clearInterval(intervalId: number)
    {
        window.clearInterval(intervalId);
    }
}
