import { Injectable } from '@angular/core';
import { ButtonPropsModel, Dialog } from '@syncfusion/ej2-angular-popups';
import { LangService } from './LangService';
import { ServiceLocator } from './ServiceLocator';

@Injectable()
export class GenericDialogService
{
    private langService: LangService;

    public constructor()
    {
        this.langService = ServiceLocator.get(LangService);
    }

    public showSaveChangesDialog(
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<boolean>
    {
        return this.showSimpleYesNoDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.SaveChanges.Header_T'),
            customDescription || this.langService.getString('#Generic.Dialogs.SaveChanges.Description_D_Html'),
            this.langService.getString('#Generic.Dialogs.SaveChanges.SaveBtn_T'),
            this.langService.getString('#Generic.Dialogs.SaveChanges.CancelBtn_T'),
            dialogWidth,
            target
        );
    }

    /**
     * @param itemLabel A short string describing the element to remove, e.g. its name or type.
     */
    public showRemoveOneDialog(
        itemLabel: string,
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<boolean>
    {
        return this.showSimpleYesNoDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.RemoveOne.Header_T'),
            customDescription || this.langService.getString('#Generic.Dialogs.RemoveOne.Description_D_Html', undefined, itemLabel),
            this.langService.getString('#Generic.Dialogs.RemoveOne.RemoveBtn_T'),
            this.langService.getString('#Generic.Dialogs.RemoveOne.CancelBtn_T'),
            dialogWidth,
            target
        );
    }

    public showRemoveAllDialog(
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<boolean>
    {
        return this.showSimpleYesNoDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.RemoveAll.Header_T'),
            customDescription || this.langService.getString('#Generic.Dialogs.RemoveAll.Description_D_Html'),
            this.langService.getString('#Generic.Dialogs.RemoveAll.RemoveBtn_T'),
            this.langService.getString('#Generic.Dialogs.RemoveAll.CancelBtn_T'),
            dialogWidth,
            target
        );
    }

    public showDiscardChangesDialog(
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<boolean>
    {
        return this.showSimpleYesNoDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.DiscardChanges.Header_T'),
            customDescription || this.langService.getString('#Generic.Dialogs.DiscardChanges.Description_D_Html'),
            this.langService.getString('#Generic.Dialogs.DiscardChanges.DiscardBtn_T'),
            this.langService.getString('#Generic.Dialogs.DiscardChanges.CancelBtn_T'),
            dialogWidth,
            target
        );
    }

    /**
     * @returns A promise that resolves to true, if overwrite was chosen, otherwise false.
     */
    public showWriteConflictDialog(
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<boolean>
    {
        let description = customDescription;
        if (!description)
        {
            description = this.langService.getString('#Generic.Dialogs.ItemWriteConflict.Description_D_Html');
            description += '<br>' + this.langService.getString('#Generic.Dialogs.ItemWriteConflict.TwoWayCallToAction_D_Html');
        }

        return this.showSimpleYesNoDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.ItemWriteConflict.Header_T'),
            description,
            this.langService.getString('#Generic.Dialogs.ItemWriteConflict.ReloadBtn_T'),
            this.langService.getString('#Generic.Dialogs.ItemWriteConflict.CancelBtn_T'),
            dialogWidth || '550px',
            target
        );
    }

    public showThreeWayWriteConflictDialog(
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<'reload' | 'overwrite' | 'cancel'>
    {
        let description = customDescription;
        if (!description)
        {
            description = this.langService.getString('#Generic.Dialogs.ItemWriteConflict.Description_D_Html');
            description += '<br>' + this.langService.getString('#Generic.Dialogs.ItemWriteConflict.ThreeWayCallToAction_D_Html');
        }

        return this.showSimpleDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.ItemWriteConflict.Header_T'),
            description,
            [
                this.langService.getString('#Generic.Dialogs.ItemWriteConflict.ReloadBtn_T'),
                this.langService.getString('#Generic.Dialogs.ItemWriteConflict.OverwriteBtn_T'),
                this.langService.getString('#Generic.Dialogs.ItemWriteConflict.CancelBtn_T')
            ],
            dialogWidth || '700px',
            target
        ).then((buttonResult: number) =>
        {
            if (buttonResult === 0)
                return 'reload';
            else if (buttonResult === 1)
                return 'overwrite';
            else
                return 'cancel';
        });
    }

    public showPermissionMissingDialog(
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<any>
    {
        return this.showSimpleDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.Unpermitted.Header_T'),
            customDescription || this.langService.getString('#Generic.Dialogs.Unpermitted.Description_D_Html'),
            [this.langService.getString('#Generic.Dialogs.Unpermitted.OkBtn_T')],
            dialogWidth,
            target
        );
    }

    public showServerErrorDialog(
        httpStatusCode: number,
        errorDetails: string,
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<any>
    {
        return this.showSimpleDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.ServerError.Header_T'),
            customDescription || this.langService.getString(
                '#Generic.Dialogs.ServerError.Description_D_Html', undefined, httpStatusCode.toString(), errorDetails),
            [this.langService.getString('#Generic.Dialogs.ServerError.OkBtn_T')],
            dialogWidth,
            target
        );
    }

    /**
     * @param itemLabel A short string describing the element to remove, e.g. its name or type.
     */
    public showItemNotFoundDialog(
        itemLabel: string,
        customHeader?: string,
        customDescription?: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<any>
    {
        return this.showSimpleDialog(
            customHeader || this.langService.getString('#Generic.Dialogs.ItemNotFound.Header_T'),
            customDescription || this.langService.getString('#Generic.Dialogs.ItemNotFound.Description_D_Html', undefined, itemLabel),
            [this.langService.getString('#Generic.Dialogs.ItemNotFound.OkBtn_T')],
            dialogWidth,
            target
        );
    }

    public showSimpleYesNoDialog(
        header: string,
        description: string,
        yesBtnText: string,
        noBtnText: string,
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<boolean>
    {
        return this.showSimpleDialog(
            header,
            description,
            [yesBtnText, noBtnText],
            dialogWidth,
            target
        ).then((buttonResult: number) => buttonResult === 0); // true only if primary button was clicked
    }

    public showErrorDetailsDialog(
        errorDetails: string,
        dialogWidth: string | number = '800px',
        target?: string | HTMLElement
    ): Promise<boolean>
    {
        const description = this.langService.getString('#Generic.Dialogs.ErrorDetails.Description_D_Html');

        return this.showSimpleDialog(
            this.langService.getString('#Generic.Dialogs.ErrorDetails.Header_T'),
            `<p>${description}</p><pre>${errorDetails}</pre>`,
            ['OK'],
            dialogWidth,
            target
        ).then((buttonResult: number) => buttonResult === 0); // true only if primary button was clicked
    }

    /**
     * @param buttonNames The display names of the buttons to render. The first button is always primary.
     * @returns A promise which resolves with the index of the button clicked. -1 if no button was clicked.
     */
    public showSimpleDialog(
        header: string,
        description: string,
        buttonNames: string[],
        dialogWidth?: string | number,
        target?: string | HTMLElement
    ): Promise<number>
    {
        let dialogResult: number = -1;

        return new Promise((resolve, reject) =>
        {
            const modalDivEl = document.createElement('div');
            document.body.appendChild(modalDivEl);

            let dialog: Dialog = new Dialog(
            {
                width: dialogWidth || '450px',
                header: header,
                content: description,
                target: target || document.body,
                isModal: true,
                animationSettings: { effect: 'Fade', duration: 200 },
                buttons: buttonNames.map((buttonName, buttonIdx) =>
                ({
                    click: () =>
                    {
                        dialogResult = buttonIdx;
                        dialog.hide();
                    },
                    buttonModel: { content: buttonName, isPrimary: buttonIdx === buttonNames.length - 1 }
                })),
                overlayClick: () => dialog.hide(),
                close: () =>
                {
                    resolve(dialogResult);

                    dialog.destroy();
                    modalDivEl.parentElement.removeChild(modalDivEl);
                }
            }, modalDivEl);
            dialog.show();
        });
    }
}
