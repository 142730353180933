import { Component, Input, OnInit } from '@angular/core';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';

@Component({
  selector: 'app-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss']
})
export class HelpIconComponent implements OnInit {

    @Input() hinweisId: string;

    text: string;

    constructor(private aggregatorDataService: AggregatorDataService) { }

    ngOnInit(): void {

        try {
            this.text = this.aggregatorDataService.aggregatorData.Tables.TooltipRows.find(x => x.TooltipId == this.hinweisId).TooltipContentHtmlLoc;
        } catch (error) {
            console.log(this.hinweisId)
        }
    }

}
