import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaseDataDialogModule } from '../base-data-dialog/base-data-dialog.module';
import { ConfigDetailsDialogModule } from '../config-details-dialog/config-details-dialog.module';
import { ConfigDteDialogModule } from '../config-dte-dialog/config-dte-dialog.module';
import { ProjectConfigurationItemComponent } from '../project-configuration-item/project-configuration-item.component';
import { StructureDialogModule } from '../structure-dialog/structure-dialog.module';
import { StructureItemComponent } from '../structure-item/structure-item.component';
import { OverviewComponent } from './overview.component';

@NgModule({
    imports:
    [
       SharedModule,
       BaseDataDialogModule,
       StructureDialogModule,
       ConfigDteDialogModule,
       ConfigDetailsDialogModule

    ],
    declarations:
    [
        OverviewComponent,

    ],
    exports: [
        OverviewComponent,

    ]

})
export class OverviewModule { }