<ejs-slider
    [min]='sliderMin'
    [max]='sliderMax'
    [step]='sliderStep'
    [(value)]='sliderValue'
    (change)="onSliderChanged($event)"
    [ngClass]='sliderClass'
    [ngStyle]='sliderStyle'
></ejs-slider>
<ejs-numerictextbox
    class="combo-component-textbox"
    [strictMode]="true"
    format="#,###.##"
    [decimals]="textboxDecimals"
    [validateDecimalOnType]="true"
    [min]=textboxMin
    [max]=textboxMax
    [step]=textboxStep
    floatLabelType="Never"
    [(value)]='textboxValue'
    (blur)="onTextboxBlur($event)"
    (change)="onTextboxChanged($event)"
    [ngClass]='textboxClass'
    [ngStyle]='textboxStyle'
></ejs-numerictextbox>
<span class="slider-text-combo-label" *ngIf="showLabel" [ngStyle]='labelStyle' [ngClass]='labelClass'>{{labelText}}</span>