import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { BaseDataDialogComponent } from '../base-data-dialog/base-data-dialog.component';
import { StructureDialogComponent } from '../structure-dialog/structure-dialog.component';
import { ConfigDteDialogComponent } from '../config-dte-dialog/config-dte-dialog.component';
import { ConfigDetailsDialogComponent } from '../config-details-dialog/config-details-dialog.component';
import { OtherBuilding } from 'src/app/core/BackendApi/otherbuilding.complex';
import { ResidentialBuilding } from 'src/app/core/BackendApi/residentialbuilding.complex';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { SectionState } from 'src/app/core/Enums';
import { KeyValueInt } from 'src/app/core/BackendApi/keyvalueint.complex';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { BuildingTypeRow } from 'src/app/core/DteufcAggregatorServiceApi/buildingtyperow.complex';
import { ChangeEventArgs } from '@syncfusion/ej2-buttons';
import { ReportAttachmentRow } from 'src/app/core/DteufcAggregatorServiceApi/reportattachmentrow.complex';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { ProjectService } from 'src/app/core/ProjectService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { DimensioningSchematicComponent } from 'src/app/shared/dimensioning-schematic/dimensioning-schematic.component';
import { WebTrekkService } from 'src/app/core/WebTrekkService';
import { UserService } from 'src/app/core/UserService';
import { GenericDialogService } from 'src/core-lib/ej2/services/GenericDialogService';
import { RuntimeService } from 'src/core-lib/ej2/services/RuntimeService';
import { FileDownloadService } from 'src/core-lib/ej2/services/FileDownloadService';
export interface reportOption {
    Row: ReportAttachmentRow;
    Checked: boolean;
}

@Component({
    selector: 'app-project-view',
    templateUrl: './project-view.component.html',
    styleUrls: ['./project-view.component.scss'],
    providers: [provideContext('ProjectView'),]
})


export class ProjectViewComponent implements OnInit {

    @ViewChild('baseDataDialog')
    public BaseDataDialog: BaseDataDialogComponent;

    @ViewChild("structureDialog")
    public StructureDialog: StructureDialogComponent;

    @ViewChild("configDteDialog")
    public ConfigDteDialog: ConfigDteDialogComponent;

    @ViewChild("configDetailsDialog")
    public ConfigDetailsDialog: ConfigDetailsDialogComponent;

    @ViewChild("closeDialog")
    public CloseDialogDialog: DialogComponent;

    @ViewChild("reportDialog")
    public ReportDialog: DialogComponent;

    @ViewChild("reportNoDataDialog")
    public ReportNoDataDialog: DialogComponent;

    @ViewChild("reportErrorDialog")
    public ReportErrorDialog: DialogComponent;

    @ViewChild(DimensioningSchematicComponent)
    public Schematic: DimensioningSchematicComponent;

    @ViewChild('grid') public grid: GridComponent;

    stepTitle = this.langService.getString('stepTitle_T');
    stepBackLabel = this.langService.getString('stepBack_T');
    structureDialogChangeWarning = this.langService.getString('StructureDialogChangeWarning_SD');
    configDteDialogChangeWarning = this.langService.getString('ConfigDteDialogChangeWarning_SD');
    configDetailsDialogChangeWarning = this.langService.getString('ConfigDetailsDialogChangeWarning_SD');

    public SectionState = SectionState;

    selectedObject: any;
    selectedIndex: number = 0;

    isDemandOpen = true;
    isWaterOpen = true;

    dialogPosition: PositionDataModel = { X: 'center', Y: 'center' }

    baseDataHeader = this.langService.getString('BaseDataHeader_T');

    structureHeader = this.langService.getString('StructureHeader_T');

    configDteHeader = this.langService.getString('ConfigDteHeader_T');

    configDetailsHeader = this.langService.getString('ConfigDetailsHeader_T');

    buildings: BuildingTypeRow[];

    selectedStructuresOther: OtherBuilding[];
    selectedStructureResidential: ResidentialBuilding;

    isDteUfcChecked: boolean = false;
    isPipeChecked: boolean;
    isEnergyChecked: boolean;

    isConfigDteDialogPristine: boolean;
    isConfigDetailsDialogPristine: boolean = true;

    data = [];

    selectedProject: ProjectEntity;

    allOptionsChecked: boolean = true;

    reportOptions: reportOption[];

    reportOptionPipe: boolean = true;

    isGenerating: boolean;
    isGenerated: boolean;
    downloadFileName: string;
    fileUrlOrBlob: string | Blob;
    fileSize: number;

    generationCancellationToken: { isCancellationRequested: boolean };

    notYetSetLabel = this.langService.getString('NotYetSetLabel_T');
    checkRequiredLabel = this.langService.getString('CheckRequiredLabel_T');

    onChangeAllOptions(args: ChangeEventArgs) {

        if (args.checked) {
            this.reportOptions.forEach((o) => {
                o.Checked = true;
            });
            this.reportOptionPipe = true;
        }
        else {
            this.reportOptions.forEach((o) => {
                o.Checked = false
            });
            this.reportOptionPipe = false;
        }
    }

    onChangeOption(args: ChangeEventArgs) {
        if (args.checked) {
            this.allOptionsChecked = (!this.reportOptions.some(o => !o.Checked) && this.reportOptionPipe);
        } else {
            this.allOptionsChecked = !(this.reportOptions.some(o => !o.Checked) || !this.reportOptionPipe);
        }
    }

    changeOpenState(o) {
        if (o === "Bedarfsvolumenstrom") {
            this.isDemandOpen = !this.isDemandOpen;
        }
        else {
            this.isWaterOpen = !this.isWaterOpen;
        }
    }



    goBack() {
        this.router.navigate([`/overview/`]);
    }

    isOdd(num) {
        return num % 2;
    }

    getAltRow(index: number) {
        if (this.isOdd(index) === 0) {
            return true;
        }
        else {
            return false;
        }
    }


    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private DataService: ProjectDataService,
        private FormatterService: ValueFormatterService,
        private aggregator: AggregatorDataService,
        private langService: LangService,
        private projectService: ProjectService,
        private userService: UserService,
        private tracker: WebTrekkService,
        private dialogService: GenericDialogService,
        private runtimeService: RuntimeService,
        private FileDownloadService: FileDownloadService,
    ) {

    }

    public async ngOnInit(): Promise<void> {
        this.selectedProject = this.activatedRoute.snapshot.data['project'];
        

        this.isDteUfcChecked = this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung;
        this.isEnergyChecked = this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher;
        this.isPipeChecked = this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung;

        this.selectedStructuresOther = this.selectedProject.OtherBuildings;
        this.selectedStructureResidential = this.selectedProject.ResidentialBuilding;


        this.data.push({ OrientationFrom: 'Bedarfsvolumenstrom', Label: this.langService.getString('Bedarfsvolumenstrom_T'), TooltipInfo: "Test123", Value: 0, Unit: "l/min" });
        this.data.push({ OrientationFrom: 'Zirkulationsvolumen', Label: this.langService.getString('Zirkulationsvolumen_T'),  TooltipInfo: "Test123", Value: 0 });
        this.data.push({ OrientationFrom: 'DurchflussTrinkwassererwaermer', Label: this.langService.getString('DurchflussTrinkwassererwaermer_T'),  TooltipInfo: "Test123" });
        if (this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung) {
            this.data.push({ OrientationFrom: 'Rohrleitung', Label: this.langService.getString('Rohrleitung_T'), TooltipInfo: "Test123" });
        }
        if (this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung) {
            this.data.push({ OrientationFrom: 'Ultrafiltrationsmodul', Label: this.langService.getString('Ultrafiltrationsmodul_T'), TooltipInfo: "Test123" });
        }

        if (this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher) {
            this.data.push({ OrientationFrom: 'WarmwasserTagesbedarf', Label: this.langService.getString('WarmwasserTagesbedarf_T'), TooltipInfo: "Test123" });
            this.data.push({ OrientationFrom: 'Energiespeichervolumen', Label: this.langService.getString('Energiespeichervolumen_T'), TooltipInfo: "Test123" });
        }

        var options = this.aggregator.aggregatorData.Tables.ReportAttachmentRows;
        this.reportOptions = options.map(o => ({ Checked: true, Row: o }))

        this.buildings = this.aggregator.aggregatorData.Tables.BuildingTypeRows;

        this.DataService.resetTempProject();

    }


    openDemandSiteOther(buildingType: string) {
        this.router.navigate([`/demand-volumetric-flow-other/${this.selectedProject.ProjectId}/${buildingType}`]);
    }
    openDemandSiteResidential(buildingType: string) {
        this.router.navigate([`/demand-volumetric-flow-residential/${this.selectedProject.ProjectId}/${buildingType}`]);
    }

    openWarmWaterSite(buildingType: any) {
    this.router.navigate([`/hot-water-demand/${this.selectedProject.ProjectId}/${buildingType}`]);
    }

    stepBack() {
        this.router
            .navigate(['/overview']);
    }

    getHinweisId(orientation: string): string {
        switch (orientation) {
            case 'Bedarfsvolumenstrom' :
                return "Projektstatus.Bedarfsvolumenstrom"
            case 'Zirkulationsvolumen':
                return "Projektstatus.Zirkulationsvolumenstrom"
            case 'DurchflussTrinkwassererwaermer' :
                return "Projektstatus.Dte"
            case 'Rohrleitung':
                return "Projektstatus.Rohrleitung"
            case 'Ultrafiltrationsmodul':
                return "Projektstatus.Ufc"
            case 'WarmwasserTagesbedarf':
                return "Projektstatus.WarmwasserTagesbedarf"
            case 'Energiespeichervolumen':
                return "Projektstatus.Energiespeichervolumen"
            default:
                break;
        }
    }

    getDisplayName(type: string): string {
        return this.buildings.find(b => b.BuildingId == type).DisplayNameLoc;
    }

    getDisplayValue(index: string): string {
        switch (index) {
            case 'Bedarfsvolumenstrom':
                if(this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.some(s => s.Value == SectionState.Check)){
                    return this.checkRequiredLabel;
                }
                if (!this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin) {
                    return this.notYetSetLabel;
                }
                return this.FormatterService.formatNumber(
                    this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin,
                    'LiterPerMinute',
                    { maximumFractionDigits: 0 }
                );
            case 'Zirkulationsvolumen':
                if(this.selectedProject.SectionStates.Zirkulationsvolumenstrom == SectionState.Check) {
                    return this.checkRequiredLabel;
                }
                if (!this.selectedProject.ProjectValues.Zirkulationsvolumenstrom) {
                    return this.notYetSetLabel;
                }
                return this.FormatterService.formatNumber(
                    this.selectedProject.ProjectValues.Zirkulationsvolumenstrom.ZirkulationsvolumenstromBerechnetLMin,
                    'LiterPerMinute',
                    { maximumFractionDigits: 1, minimumFractionDigits: 1}
                );
            case 'DurchflussTrinkwassererwaermer':
                if(this.selectedProject.SectionStates.Dte == SectionState.Check) {
                    return this.checkRequiredLabel;
                }
                if (!this.selectedProject.ProjectValues.Dte || !this.selectedProject.ProjectValues.Dte.Ergebnis) {
                    return this.notYetSetLabel;
                }
                return this.FormatterService.formatDteName(
                    this.selectedProject.ProjectValues.Dte.Ergebnis.toString()
                );
            case 'Rohrleitung':
                if(this.selectedProject.SectionStates.Rohrauslegung == SectionState.Check) {
                    return this.checkRequiredLabel;
                }
                if (!this.selectedProject.ProjectValues.Rohrauslegung) {
                    return this.notYetSetLabel;
                }
                return '';
            case 'Ultrafiltrationsmodul':
                if(this.selectedProject.SectionStates.Ufc == SectionState.Check) {
                    return this.checkRequiredLabel;
                }
                if (!this.selectedProject.ProjectValues.Dte || !this.selectedProject.ProjectValues.Dte.Ufc) {
                    return this.notYetSetLabel;
                }
                return this.FormatterService.formatUfcName(
                    this.selectedProject.ProjectValues.Dte.Ufc.toString()
                );
            case 'WarmwasserTagesbedarf':
                if(this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.some(s => s.Value == SectionState.Check)) {
                    return this.checkRequiredLabel;
                }
                if (!this.selectedProject.ProjectValues.WarmwasserErgebnisLtag) {
                    return this.notYetSetLabel;
                }
                return this.FormatterService.formatNumber(
                    this.selectedProject.ProjectValues.WarmwasserErgebnisLtag,
                    'Liter',
                    { maximumFractionDigits: 0 },
                    false
                );
            case 'Energiespeichervolumen':
                if(this.selectedProject.SectionStates.Energiespeicher == SectionState.Check) {
                    return this.checkRequiredLabel;
                }
                if (!this.selectedProject.ProjectValues.Energiespeicher) {
                    return this.notYetSetLabel;
                }
                return this.FormatterService.formatNumber(
                    this.selectedProject.ProjectValues.Energiespeicher.VolumenErgebnisLiter,
                    'Liter',
                    { maximumFractionDigits: 0 },
                    false
                );
        }
    }

    public formatBuildingBvsResult(value: number): string {
        if (!value) {
            return  this.langService.getString('FormatBuildingBvsResult_T');
        }
        return this.FormatterService.formatNumber(
            value,
            'LiterPerMinute',
            { maximumFractionDigits: 0 },
            false
        )
    }
    public formatBuildingWwResult(value: number, buildingType: string): string {
        if (this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.find(b => b.Key == buildingType).Value == SectionState.Check) {
            return this.checkRequiredLabel;
        }
        if (!value) {
            return this.notYetSetLabel
        }
        return this.FormatterService.formatNumber(
            value,
            'Liter',
            { maximumFractionDigits: 0 },
            false
        )
    }

    calculate(index: string) {
        switch (index) {
            case 'Zirkulationsvolumen':
                this.router
                    .navigate([`/circulation-volumetric-flow/${this.selectedProject.ProjectId}`]);
                break;
            case 'DurchflussTrinkwassererwaermer':
                // this.DataService.setBedarf(this.bedarfsvolumenstrom) //??? TODO Denis fragen
                if (this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung) {
                    this.router
                        .navigate([`/dte-temperature/${this.selectedProject.ProjectId}`]);
                }
                else {
                    this.router
                        .navigate([`/dte/${this.selectedProject.ProjectId}`]);
                }
                break;
            case 'Rohrleitung':
                this.router
                    .navigate([`pipeline/${this.selectedProject.ProjectId}`]);
                break;
            case 'Ultrafiltrationsmodul':
                this.router
                    .navigate([`/select-calculation-ufc/${this.selectedProject.ProjectId}`]);
                break;
            case 'Energiespeichervolumen':
                this.router
                    .navigate([`/heat-generating-types/${this.selectedProject.ProjectId}`]);
                break;
            default:
                break;
        }


    }

    public getBuildingBvsIconClass(buildingType: string): string {
        return this.getIconClassFromSectionState(
            this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.find(b => b.Key == buildingType).Value
        );
    }
    public getBuildingWwIconClass(buildingType: string): string {
        return this.getIconClassFromSectionState(
            this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.find(b => b.Key == buildingType).Value
        );
    }

    public getIconClass(category: string): string {
        switch (category) {
            case 'Bedarfsvolumenstrom':
                if (this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin > 140) {
                    return 'icon-close';
                }
                if (this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.some(b => b.Value == SectionState.Check))
                    return 'icon-priority_high';
                if (this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.some(b => b.Value == SectionState.Empty))
                    return 'icon-edit-pencil';
                else
                    return 'icon-check2'
            case 'WarmwasserTagesbedarf':
                if (this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.some(b => b.Value == SectionState.Check))
                    return 'icon-priority_high';
                if (this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.some(b => b.Value == SectionState.Empty))
                    return 'icon-edit-pencil';
                else
                    return 'icon-check2'
            case 'Zirkulationsvolumen': return this.getIconClassFromSectionState(this.selectedProject.SectionStates.Zirkulationsvolumenstrom);
            case 'DurchflussTrinkwassererwaermer': return this.getIconClassFromSectionState(this.selectedProject.SectionStates.Dte);
            case 'Rohrleitung': return this.getIconClassFromSectionState(this.selectedProject.SectionStates.Rohrauslegung);
            case 'Ultrafiltrationsmodul': return this.getIconClassFromSectionState(this.selectedProject.SectionStates.Ufc);
            case 'Energiespeichervolumen': return this.getIconClassFromSectionState(this.selectedProject.SectionStates.Energiespeicher);
        }
    }
    private getIconClassFromSectionState(state: SectionState): string {
        switch (state) {
            case SectionState.Set: return 'icon-check2';
            case SectionState.Empty: return 'icon-edit-pencil';
            case SectionState.Check: return 'icon-priority_high';
        }
    }


    openBaseDataDialog = (): void => {
        this.BaseDataDialog.dialog.show();
    }

    setSelectedProjectValues(args) {
        var project = args;

        this.selectedProject.ProjectName = project.informationFields.ProjectName;
        this.selectedProject.ProjectBuildSection = project.informationFields.ConstructionPhase;
        this.selectedProject.ProjectNumber = project.informationFields.ProjectId;
        this.selectedProject.ProjectDescription = project.informationFields.Description;

        for (let j = 0; j < 4; j++) {
            this.selectedProject.Contacts[j].Name = project.contactFields[j]["Name_" + j];
            this.selectedProject.Contacts[j].Address = project.contactFields[j]["Address_" + j];
            this.selectedProject.Contacts[j].Country = project.contactFields[j]["Country_" + j];
            this.selectedProject.Contacts[j].Zip = project.contactFields[j]["Zip_" + j];
            this.selectedProject.Contacts[j].City = project.contactFields[j]["City_" + j];
            this.selectedProject.Contacts[j].Phone = project.contactFields[j]["Phone_" + j];
            this.selectedProject.Contacts[j].Email = project.contactFields[j]["Email_" + j];
        }
    }

    saveBaseDataDialog = (): void => {
        this.BaseDataDialog.isClosedWithoutSave = false;

        if (this.BaseDataDialog.checkFormsValid()) {
            this.BaseDataDialog.emitEditedProject();
            this.DataService.saveCurrentProject()
                .then(_ => this.BaseDataDialog.dialog.hide());
        }
        else {
            this.BaseDataDialog.errorDialog.show();
        }
    }


    openConfigDteDialog = (): void => {
        this.isConfigDetailsDialogPristine = true;
        this.isConfigDteDialogPristine = true;
        this.ConfigDteDialog.dialog.show();
    }

    closeConfigDteDialog(){
        this.ConfigDteDialog.dialog.hide();
    }

    closeBaseDataDialog = (): void => {

        this.BaseDataDialog.dialog.hide();
    }


    openStructureDialog = (): void => {
        this.StructureDialog.dialog.show();
    }

    closeStructureDialog(){
        this.StructureDialog.dialog.hide();
    }

    openSaveStructureDialog() {
        this.StructureDialog.isClosedWithoutSave = false;

        if (this.StructureDialog._selectedStructuresOther.length > 0 || this.StructureDialog._selectedStructureResidential) {
            this.saveStructureDialog();
            this.StructureDialog.dialog.hide();
        }
    }

    openReportDialog() {
        if (!this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.some(b => b.Value != SectionState.Set)
            && this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin <= 140
            && this.selectedProject.SectionStates.Zirkulationsvolumenstrom != SectionState.Check
            && this.selectedProject.SectionStates.Dte != SectionState.Check
            && (!this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung || this.selectedProject.SectionStates.Rohrauslegung != SectionState.Check)
            && (!this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung || this.selectedProject.SectionStates.Ufc != SectionState.Check)
            && (!this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher || this.selectedProject.SectionStates.Energiespeicher != SectionState.Check)
            && (!this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher || !this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.some(b => b.Value == SectionState.Check))
        ) {
            this.ReportDialog.show();
        }
        else {
            this.ReportNoDataDialog.show();
        }

    }

    closeReportNoDataDialog() {
        this.ReportNoDataDialog.hide()
    }

    closeReportErrorDialog() {
        this.isGenerating = false;
        this.isGenerated = false;
        this.ReportErrorDialog.hide();
    }


    saveStructureDialog = (): void => {
        if (this.StructureDialog._selectedStructuresOther.length > 0 || this.StructureDialog._selectedStructureResidential) {


            if (this.selectedProject.SectionStates.Zirkulationsvolumenstrom == SectionState.Set)
                this.selectedProject.SectionStates.Zirkulationsvolumenstrom = SectionState.Check;

            if (this.selectedProject.SectionStates.Dte == SectionState.Set)
                this.selectedProject.SectionStates.Dte = SectionState.Check;

            if (this.selectedProject.SectionStates.Rohrauslegung == SectionState.Set)
                this.selectedProject.SectionStates.Rohrauslegung = SectionState.Check;

            if (this.selectedProject.SectionStates.Ufc == SectionState.Set)
                this.selectedProject.SectionStates.Ufc = SectionState.Check;

            if (this.selectedProject.SectionStates.Energiespeicher == SectionState.Set)
                this.selectedProject.SectionStates.Energiespeicher = SectionState.Check;


            // ---------- v States v ----------

            // Store current building specifig states
            let previousStatesBVS: KeyValueInt[] = this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates; // KeyValueSectionState
            let previousStatesWW: KeyValueInt[] = this.selectedProject.SectionStates.BuildingTypeWarmwasserStates; // KeyValueSectionState

            // Clear building specific states
            this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates = [];
            this.selectedProject.SectionStates.BuildingTypeWarmwasserStates = [];

            // Add all states for the newly selected other buildings
            if (this.StructureDialog._selectedStructuresOther) {
                this.StructureDialog._selectedStructuresOther.forEach(o => {
                    let previousBVS = previousStatesBVS.find(p => p.Key == o.BuildingType);
                    this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.push({
                        Key: o.BuildingType,
                        Value: previousBVS != null ? previousBVS.Value : SectionState.Empty
                    });

                    let previousWW = previousStatesWW.find(p => p.Key == o.BuildingType);
                    this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.push({
                        Key: o.BuildingType,
                        Value: previousWW != null ? previousWW.Value : SectionState.Empty
                    });
                });
            }

            // Add states for the newly selected residential building
            if (this.StructureDialog._selectedStructureResidential) {
                let previousBVS = previousStatesBVS.find(p => p.Key == this.StructureDialog._selectedStructureResidential.BuildingType);
                this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.push({
                    Key: this.StructureDialog._selectedStructureResidential.BuildingType,
                    Value: previousBVS != null ? previousBVS.Value : SectionState.Empty
                });

                let previousWW = previousStatesWW.find(p => p.Key == this.StructureDialog._selectedStructureResidential.BuildingType);
                this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.push({
                    Key: this.StructureDialog._selectedStructureResidential.BuildingType,
                    Value: previousWW != null ? previousWW.Value : SectionState.Empty
                });
            }

            // ---------- v Buildings v ----------

            // Store current buildings
            let previousBuildingsOther = this.selectedProject.OtherBuildings != null ? this.selectedProject.OtherBuildings : [];
            let previousBuildingResidential = this.selectedProject.ResidentialBuilding;

            // Clear project buildings
            this.selectedProject.OtherBuildings = null;
            this.selectedProject.ResidentialBuilding = null;

            // Add all new buildings with their previous data if available
            if (this.StructureDialog._selectedStructuresOther) {
                this.selectedProject.OtherBuildings = [];
                this.StructureDialog._selectedStructuresOther.forEach(o => {
                    if (previousBuildingsOther.some(po => po.BuildingType == o.BuildingType)) {
                        this.selectedProject.OtherBuildings.push(previousBuildingsOther.find(po => po.BuildingType == o.BuildingType));
                    } else {
                        this.selectedProject.OtherBuildings.push(o);
                    }
                });
            }
            if (this.StructureDialog._selectedStructureResidential) {
                if (previousBuildingResidential) {
                    this.selectedProject.ResidentialBuilding = previousBuildingResidential;
                } else {
                    this.selectedProject.ResidentialBuilding = this.StructureDialog._selectedStructureResidential;
                }
            }

            //Bedarfsvolumenstrom summieren
            this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin = (
                this.selectedProject.ResidentialBuilding != null && this.selectedProject.ResidentialBuilding.Bedarfsvolumenstrom != null
                    ? this.selectedProject.ResidentialBuilding.Bedarfsvolumenstrom.BedarfsvolumenstromErgebnisLMin : 0
            ) + (
                    this.selectedProject.OtherBuildings != null ?
                        this.selectedProject.OtherBuildings
                            .filter(b => b.Bedarfsvolumenstrom != null)
                            .reduce((sum, b) => sum + b.Bedarfsvolumenstrom.BedarfsvolumenstromErgebnisLMin, 0) : 0
                )

            //Warmwasser summieren
            this.selectedProject.ProjectValues.WarmwasserErgebnisLtag = (
                this.selectedProject.ResidentialBuilding != null && this.selectedProject.ResidentialBuilding.Warmwasser != null
                    ? this.selectedProject.ResidentialBuilding.Warmwasser.WarmwasserErgebnisLTag : 0
            ) + (
                    this.selectedProject.OtherBuildings != null ?
                        this.selectedProject.OtherBuildings
                            .filter(b => b.Warmwasser != null)
                            .reduce((sum, b) => sum + b.Warmwasser.WarmwasserErgebnisLTag, 0) : 0
                )

                var projectAmount = 0;
                if(this.selectedProject.ResidentialBuilding){
                    projectAmount = 1;
                }
                if(this.selectedProject.OtherBuildings){
                    projectAmount = projectAmount + this.selectedProject.OtherBuildings.count();
                }
                this.tracker.trackActionCustom("layout", "buildingChanged",
                {
                    userId: this.userService.userInfo.UserId,
                    intern: this.userService.isAuthenticated,
                    projectId: this.selectedProject.ProjectId,
                    projectSite: this.selectedProject.Contacts ? {Zip: this.selectedProject.Contacts[0].Zip, Country: this.selectedProject.Contacts[0].Country, City: this.selectedProject.Contacts[0].City, Address: this.selectedProject.Contacts[0].Address} : null,
                    buildingAmount: projectAmount
                });

            this.DataService.saveCurrentProject()
                .then(_ => {
                    this.StructureDialog.emitSelectedStructures();
                    this.StructureDialog.dialog.hide();
                });
        }
    }

    // onSelectedStructuresChange(args){
    //     this.selectedProject.structureData = args;
    //     this.grid.refresh();
    // }

    dialogNextConfigDteToConfigDetails() {
        this.ConfigDteDialog.isClosedWithoutSave = false;

        this.isConfigDteDialogPristine = this.ConfigDteDialog.checkIfPristine();

        this.ConfigDteDialog.dialog.hide();
        this.ConfigDetailsDialog.dialog.show();
    }

    dialogBackCDetailsToConfigDte() {
        this.ConfigDetailsDialog.isClosedWithoutSave = false;

        this.isConfigDetailsDialogPristine = this.ConfigDetailsDialog.checkIfPristine();

        this.ConfigDetailsDialog.dialog.hide();
        this.ConfigDteDialog.dialog.show();
    }


    saveConfig = (): void => {
        this.ConfigDetailsDialog.isClosedWithoutSave = false;
        this.isConfigDetailsDialogPristine = this.ConfigDetailsDialog.checkIfPristine();

        this.ConfigDetailsDialog.emitChecked();
        this.ConfigDteDialog.emitChange();

        // Update section states

        if(!this.isConfigDteDialogPristine || !this.isConfigDetailsDialogPristine){



            this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.forEach(s => {
                if (s.Value != SectionState.Empty) {
                    s.Value = SectionState.Check;
                }
            });

            if(this.selectedProject.SectionStates.Zirkulationsvolumenstrom != SectionState.Empty){
                this.selectedProject.SectionStates.Zirkulationsvolumenstrom = SectionState.Check;
            }

            if(this.selectedProject.SectionStates.Dte != SectionState.Empty){
                this.selectedProject.SectionStates.Dte = SectionState.Check;
            }

            if(this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung && this.selectedProject.SectionStates.Rohrauslegung != SectionState.Empty){
                this.selectedProject.SectionStates.Rohrauslegung = SectionState.Check;
            }

            if(this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung && this.selectedProject.SectionStates.Ufc != SectionState.Empty){
                this.selectedProject.SectionStates.Ufc = SectionState.Check;
            }

            if (this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher) {
                this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.forEach(s => {
                    if (s.Value != SectionState.Empty) {
                        s.Value = SectionState.Check;
                    }
                });

                if (this.selectedProject.SectionStates.Energiespeicher != SectionState.Empty) {
                    this.selectedProject.SectionStates.Energiespeicher = SectionState.Check;
                }
            }

        }

        // Save new config
        this.selectedProject.ConfigurationData = {
            Konfiguration_DTE_AuslegungRohrleitung: this.isPipeChecked,
            Konfiguration_DTE_Temperaturabsenkung: this.isDteUfcChecked,
            Konfiguration_Energiespeicher: this.isEnergyChecked
        };

        this.tracker.trackActionCustom("layout", "configChanged",
        {
            userId: this.userService.userInfo.UserId,
            intern: this.userService.isAuthenticated,
            projectId: this.selectedProject.ProjectId,
            projectSite: this.selectedProject.Contacts ? {Zip: this.selectedProject.Contacts[0].Zip, Country: this.selectedProject.Contacts[0].Country, City: this.selectedProject.Contacts[0].City, Address: this.selectedProject.Contacts[0].Address} : null,
            selectedDte: this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung ? "DTE Standardbetrieb" : "DTE Temperaturabsenkung",
            energySelected: this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher ? "True" : "False",
            pipeSelected: this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung ? "True" : "False"
        });
        this.DataService.saveCurrentProject()
            .then(_ => {
                this.data = [];
                this.data.push({ OrientationFrom: 'Bedarfsvolumenstrom', Label: this.langService.getString('Bedarfsvolumenstrom_T'), TooltipInfo: "Test123", Value: 0, Unit: "l/min" });
                this.data.push({ OrientationFrom: 'Zirkulationsvolumen', Label: this.langService.getString('Zirkulationsvolumen_T'),  TooltipInfo: "Test123", Value: 0 });
                this.data.push({ OrientationFrom: 'DurchflussTrinkwassererwaermer', Label: this.langService.getString('DurchflussTrinkwassererwaermer_T'),  TooltipInfo: "Test123" });
                if (this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung) {
                    this.data.push({ OrientationFrom: 'Rohrleitung', Label: this.langService.getString('Rohrleitung_T'), TooltipInfo: "Test123" });
                }
                if (this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung) {
                    this.data.push({ OrientationFrom: 'Ultrafiltrationsmodul', Label: this.langService.getString('Ultrafiltrationsmodul_T'), TooltipInfo: "Test123" });
                }

                if (this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher) {
                    this.data.push({ OrientationFrom: 'WarmwasserTagesbedarf', Label: this.langService.getString('WarmwasserTagesbedarf_T'), TooltipInfo: "Test123" });
                    this.data.push({ OrientationFrom: 'Energiespeichervolumen', Label: this.langService.getString('Energiespeichervolumen_T'), TooltipInfo: "Test123" });
                }

                this.Schematic.refreshImage();
                this.ConfigDetailsDialog.dialog.hide();

            });
    }

    checkButtonState(category: string): boolean {
        switch (category) {
            case 'Bedarfsvolumenstrom':
                return true;
            case 'Zirkulationsvolumen':
                return !this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.some(s => s.Value != SectionState.Set) && this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin <= 140;
            case 'DurchflussTrinkwassererwaermer':
                return this.selectedProject.SectionStates.Zirkulationsvolumenstrom == SectionState.Set;
            case 'Rohrleitung':
                return this.selectedProject.SectionStates.Dte == SectionState.Set;
            case 'Ultrafiltrationsmodul':
                return (!this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung && this.selectedProject.SectionStates.Dte == SectionState.Set)
                    || (this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung && this.selectedProject.SectionStates.Rohrauslegung == SectionState.Set)
            case 'WarmwasserTagesbedarf':
                return (this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung && this.selectedProject.SectionStates.Ufc == SectionState.Set)
                    || (!this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung && this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung && this.selectedProject.SectionStates.Rohrauslegung == SectionState.Set)
                    || (!this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung && !this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung && this.selectedProject.SectionStates.Dte == SectionState.Set);

            case 'Energiespeichervolumen':
                return !this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.some(s => s.Value != SectionState.Set);
            default:
                return false;
        }
    }

    closeReportDialog() {
        this.ReportDialog.hide();
    }


    startGeneratingReport() {
        this.isGenerating = true;

        if(!this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung){
            this.reportOptionPipe = false;
        }

        let attachments = this.reportOptions.filter(o => o.Checked == true).map(o => o.Row.AnhangId);
        let locale = this.langService.currentLocale;

        this.selectedProject.LastUpdatedUtc = new Date(this.selectedProject.LastUpdatedUtc);

        this.generationCancellationToken = { isCancellationRequested: false };
        this.projectService.generateReport(locale, attachments, this.reportOptionPipe, this.allOptionsChecked, this.generationCancellationToken)
            .then(success => {
                if (success[0]) {
                    let containsAll = this.allOptionsChecked;
                    let fileType = (!this.reportOptionPipe && !this.reportOptions.some(o => o.Checked)) ? 'application/pdf' : 'application/zip';

                    this.downloadFileName = this.projectService.getDownloadFileName(fileType, containsAll)
                    this.isGenerating = false;
                    this.isGenerated = true;
                    this.fileUrlOrBlob = success[1];

                    if(this.fileUrlOrBlob instanceof Blob){
                        this.fileSize = this.fileUrlOrBlob.size;
                        this.fileSize = this.fileSize / 1024 / 1024;
                        this.fileSize = Math.round(this.fileSize * 100) / 100;
                    }


                    this.selectedProject.ReportVersion += 1;
                    this.DataService.saveCurrentProject().then(
                        project => this.selectedProject = project
                    );
                    var projectAmount = 0;
                    if(this.selectedProject.ResidentialBuilding){
                        projectAmount = 1;
                    }
                    if(this.selectedProject.OtherBuildings){
                        projectAmount = projectAmount + this.selectedProject.OtherBuildings.count();
                    }
                    this.tracker.trackActionCustom("layout", "configChanged",
                    {
                        userId: this.userService.userInfo.UserId,
                        intern: this.userService.isAuthenticated,
                        projectId: this.selectedProject.ProjectId,
                        projectSite: this.selectedProject.Contacts ? {Zip: this.selectedProject.Contacts[0].Zip, Country: this.selectedProject.Contacts[0].Country, City: this.selectedProject.Contacts[0].City, Address: this.selectedProject.Contacts[0].Address} : null,
                        selectedDte: this.selectedProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung ? "DTE Standardbetrieb" : "DTE Temperaturabsenkung",
                        energySelected: this.selectedProject.ConfigurationData.Konfiguration_Energiespeicher ? "True" : "False",
                        pipeSelected: this.selectedProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung ? "True" : "False",
                        stateBedarfsvolumenstrom:  this.selectedProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.every(x => x.Value == SectionState.Set) ? "Set" : "Not Set",
                        stateZirkulationsvolumenstrom: this.selectedProject.SectionStates.Zirkulationsvolumenstrom == SectionState.Set ? "Set" : "Not Set",
                        stateDTE: this.selectedProject.SectionStates.Dte == SectionState.Set ? "Set" : "Not Set",
                        stateUFC: this.selectedProject.SectionStates.Ufc == SectionState.Set ? "Set" : "Not Set",
                        stateRohrleitung: this.selectedProject.SectionStates.Rohrauslegung == SectionState.Set ? "Set" : "Not Set",
                        stateWarmwassertagesbedarf: this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.every(x => x.Value == SectionState.Set) ? "Set" : "Not Set",
                        stateSpeicher: this.selectedProject.SectionStates.Energiespeicher == SectionState.Set ? "Set" : "Not Set",
                        selectedBedarfsvolumenstrom: this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin,
                        selectedDTE: this.selectedProject.ProjectValues.Dte.Ergebnis,
                        selectedUFC: this.selectedProject.ProjectValues.Dte.Ufc,
                    });
                }
            }).catch(e => {
                this.ReportErrorDialog.show();
                this.tracker.trackActionCustom("layout", "error1",
                    {
                        userId: this.userService.userInfo.UserId,
                        intern: this.userService.isAuthenticated,
                        projectId: this.selectedProject.ProjectId,
                        projectSite: this.selectedProject.Contacts ? {Zip: this.selectedProject.Contacts[0].Zip, Country: this.selectedProject.Contacts[0].Country, City: this.selectedProject.Contacts[0].City, Address: this.selectedProject.Contacts[0].Address} : null,
                        endpoint: "pdfGeneration"
                    });
            }).finally(() => {
                this.isGenerating = false
            });
    }

    cancelGenerating() {
        if (this.generationCancellationToken != null) {
            this.generationCancellationToken.isCancellationRequested = true;
        }
    }

    onCloseReportDialog() {
        if (this.isGenerating == true) {
            this.cancelGenerating();
        }

        this.isGenerating = false;
        this.isGenerated = false;

        this.allOptionsChecked = true;
        this.reportOptionPipe = true;
        this.reportOptions.forEach(o => o.Checked = true)
    }

    onOverlayClickReport() {
        this.ReportDialog.hide();
    }

    checkBaseDataPristine(){
        this.CloseDialogDialog.show();
    }

    checkStructurePristine(){
        // if(!this.StructureDialog.checkIfPristine()){
            this.CloseDialogDialog.show();
        // }
    }

    checkConfigPristine(){
        if(!this.ConfigDteDialog.checkIfPristine() || !this.ConfigDetailsDialog.checkIfPristine()){
            this.CloseDialogDialog.show();
        }
    }

    resetConfigDialog(){

        this.ConfigDteDialog.resetDialog();
        this.ConfigDetailsDialog.resetDialog();
    }

    downloadFile() {
        this.FileDownloadService.issueDownload(this.fileUrlOrBlob, this.downloadFileName);
    }


    confirmCloseDialog(){// TODO nicht so elegant gelöst: Später als Switch mit string-Variable, die immer beim open-Event auf z.B."basedata" gesetzt wird
        this.BaseDataDialog.canBeClosed = true;
        this.StructureDialog.canBeClosed = true;
        this.ConfigDteDialog.canBeClosed = true;
        this.ConfigDetailsDialog.canBeClosed = true;

        this.CloseDialogDialog.hide();
        this.BaseDataDialog.dialog.hide();
        this.StructureDialog.dialog.hide();
        this.ConfigDteDialog.dialog.hide();
        this.ConfigDetailsDialog.dialog.hide();

        this.BaseDataDialog.canBeClosed = false;
        this.StructureDialog.canBeClosed = false;
        this.ConfigDteDialog.canBeClosed = false;
        this.ConfigDetailsDialog.canBeClosed = false;
    }

    cancelCloseDialog(){
        this.CloseDialogDialog.hide();
    }


    baseDataButtons = [
        {
            'click': this.closeBaseDataDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('BaseDataAbbrechenBtn_T'),
                isPrimary: false
            }
        },
        {
            'click': this.saveBaseDataDialog.bind(this),
            buttonModel: {
                content:  this.langService.getString('BaseDataUebernehmenBtn_T')+'&nbsp;<span class="icon-angle-right"></span>',
                isPrimary: false,
                cssClass: 'e-primary'
            }
        }
    ];

    structureButtons = [
        {
            'click': this.closeStructureDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('StructureAbbrechenBtn_T'),
                isPrimary: false
            }
        },
        {
            'click': this.openSaveStructureDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('StructureUebernehmenBtn_T')+'&nbsp;<span class="icon-angle-right"></span>',
                isPrimary: false,
                cssClass: 'e-primary'
            }
        }
    ];

    configDteButtons = [
        {
            'click': this.closeConfigDteDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('ConfigDteAbbrechenBtn_T'),
                isPrimary: false
            }
        },
        {
            'click': this.dialogNextConfigDteToConfigDetails.bind(this),
            buttonModel: {
                content: this.langService.getString('ConfigDteWeiterBtn_T')+'&nbsp;<span class="icon-angle-right"></span>',
                isPrimary: false,
                cssClass: 'e-primary'
            }
        }
    ];

    configDetailsButtons = [

        {
            'click': this.dialogBackCDetailsToConfigDte.bind(this),
            buttonModel: {
                content: '<span class="icon-angle-left"></span> &nbsp;'+this.langService.getString('ConfigDetailsZurueckBtn_T'),
                isPrimary: false
            }
        },
        {
            'click': this.saveConfig.bind(this),
            buttonModel: {
                content: this.langService.getString('ConfigDetailsUebernehmenBtn_T'),
                isPrimary: false,
                cssClass: 'e-primary'
            }
        }
    ];

    closeDialogButtons = [
        {
            'click': this.cancelCloseDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('CloseAbbrechenBtn_T'),
            }
        },
        {
            'click': this.confirmCloseDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('CloseVerwerfenBtn_T'),
                isPrimary: false,
                cssClass: 'e-primary'
            }
        }
    ]


    reportErrorButtons = [
        {
            'click': this.closeReportErrorDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('ReportErrorSchliessenBtn_T'),
                isPrimary: false
            }
        },
    ];

    reportNoDataButtons = [
        {
            'click': this.closeReportNoDataDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('ReportNoDataSchliessenBtn_T'),
                isPrimary: false
            }
        },
    ];

    getSelectedProjectProjectValuesRohrauslegungErgebnisVorlauf(){
        return "DN" + this.selectedProject.ProjectValues.Rohrauslegung.ErgebnisVorlauf.toString();
    }

    getSelectedProjectProjectValuesRohrauslegungErgebnisRuecklaufMitte(){
        return "DN" + this.selectedProject.ProjectValues.Rohrauslegung.ErgebnisRuecklaufMitte.toString();
    }

    getSelectedProjectProjectValuesRohrauslegungErgebnisRuecklaufUnten(){
        return "DN" + this.selectedProject.ProjectValues.Rohrauslegung.ErgebnisRuecklaufUnten.toString();
    }


}
