<viega-collapsible-panel>
    <div class="panel-content" panel-content>
        <div class="top-panel-content">
            <viega-panel-header
                stepText="Schritt x / X"
                stepBackLabel="Schritt zurück"
                stepTitle=""
                stepDescription=""
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>
        </div>
        <div class="bot-panel-content">
            <div class="result-box">
                <button psp-button class="e-btn e-small e-icon-right" style="width: 100%; flex-flow: row; justify-content: center;" (click)="navigateNext()">Weiter <span class="icon-chevron-thin-right e-btn-icon"></span></button>
            </div>
        </div>
    </div>

    <div class="content" content>
        <span class="content-title">Titel der Seite</span>

    </div>
</viega-collapsible-panel>