<viega-collapsible-panel>
    <div class="panel-content" panel-content>
            <viega-panel-header
                stepText="&nbsp;"
                [stepBackLabel]="label_stepBack"
                [stepTitle]="label_title"
                (stepBackClicked)="goBack()"
            >
            </viega-panel-header>
            <div class="content-wrapper">
                <!-- Top-Wrapper -->
                <div class="top-content-wrapper">
                    <!-- Top -->
                    <div class="top-panel-content">
                            <p class="description-text" *ngIf="label_description" >
                                <span [innerHTML]="label_description"></span>&nbsp;
                            </p>
                    </div>
                    <!-- Middle -->
                    <div class="mid-panel-content">

                    </div>
                </div>
                <!-- Bot -->
                <div class="bot-panel-content">
                    <div class="error-message" *ngIf="!isAnyDteAvailable()">
                        <i class="icon-warning1"></i><p class="error-text">{{'NoFittingDteError_SD' | translate}}</p>
                    </div>

                    <div class="result-box">
                        <span class="result-title">{{'PanelResultHeadline_T' | translate}}</span>
                        <span class="result-content">{{'PanelResultZirkulationsverlustleistung_T' | translate : getDisplayResultWattage()}}</span>
                        <span class="result-content">{{'PanelResultZirkulationsvolumenstrom_T' | translate : getDisplayResultFlow()}}</span>
                        <button psp-button class="e-btn e-small" style="width: 100%; flex-flow: row; justify-content: center;" [disabled]='!isSaveValid()' (click)="navigateNext()">{{'UebernehmenBtn_T' | translate}} </button>
                    </div>
                </div>
            </div>
    </div>

    <div class="content" content>
        <div style="display: flex; align-items: center;">
            <span class="content-title">{{'Headline_T' | translate}}</span>
            <app-help-icon hinweisId="Zirkulationsvolumenstrom.ErgänzungBeschreibung"></app-help-icon>
        </div>
        <div >
            <div class="inner-content" style="display: flex;">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #circulationDirectRadio
                        label="{{'RadioZirkulationsvolumenstrom_T' | translate}}"
                        name="option"
                        [checked]="isCurrentBerechnungstyp(ZirkulationsVSBerechnungstyp.VolumenstromDirekt)"
                        (change)="setBerechnungstyp(ZirkulationsVSBerechnungstyp.VolumenstromDirekt)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Zirkulationsvolumenstrom.Direkteingabe"></app-help-icon>
                </div>
            </div>
                <div [hidden]="!circulationDirectRadio.checked" >
                    <div class="direct-entry-wrapper">
                        <app-slider-text-combo
                            class="slider-text-combo"
                            [sliderMin]="minDirectFlowSlider"
                            [sliderMax]="maxDirectFlowSlider"
                            [sliderStep]="stepDirectFlow"
                            [textboxMin]="minDirectFlowText"
                            [textboxMax]="maxDirectFlowText"
                            [textboxStep]="stepDirectFlow"
                            [textboxClass]="'slider-input'"
                            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                            [textboxDecimals]="decimalsDirectFlowText"
                            [showLabel]='false'
                            [(value)]='enteredDirectFlow'
                            (valueChange)="calculateResult()"
                        ></app-slider-text-combo>
                        <div style="margin-left: 1em; width: 8em;">
                            <ejs-dropdownlist
                                [dataSource]='DIRECT_SELECTABLE_UNITS'
                                [fields]="{ text: 'label', value: 'value' }"
                                [value]='selectedDirectFlowUnit'
                                class="unit-dropdown"
                                (change)="setDirectEntryUnit($event.value)"
                            ></ejs-dropdownlist>
                        </div>
                    </div>
                </div>
            <div class="inner-content" style="display: flex;">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #circulationCalculateRadio
                        label="{{'RadioZirkulationsverlustleistung_T' | translate}}"
                        [checked]="isCurrentBerechnungstyp(ZirkulationsVSBerechnungstyp.VerlustleistungDirekt)"
                        (change)="setBerechnungstyp(ZirkulationsVSBerechnungstyp.VerlustleistungDirekt)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Zirkulationsvolumenstrom.AnhandVerlustleistung"></app-help-icon>
                </div>
            </div>
                <div [hidden]="!circulationCalculateRadio.checked">

                    <app-slider-text-combo
                        class="slider-text-combo"
                        [sliderMax]="20000"
                        [sliderStep]="500"
                        [textboxMax]="100000"
                        [textboxClass]="'slider-input'"
                        [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                        [textboxDecimals]="0"
                        [showLabel]='true'
                        labelText="{{'#Generic.Unit.Watts_T' | translate}}"
                        [(value)]='enteredDirectWattage'
                        (valueChange)="calculateResult()"
                    ></app-slider-text-combo>
                </div>
            <div class="inner-content">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #circulationTableRadio
                        label="{{'RadioDVGW553_T' | translate}}"
                        name="option"
                        [checked]="isCurrentBerechnungstyp(ZirkulationsVSBerechnungstyp.DVGW553)"
                        (change)="setBerechnungstyp(ZirkulationsVSBerechnungstyp.DVGW553)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Zirkulationsvolumenstrom.Dvgw553"></app-help-icon>
                </div>
            </div>
            <div [hidden]="!circulationTableRadio.checked">
                <div class="inner-content" style="margin-left: 2em;">
                    <div style="display: flex;">
                        <div style="width: 80%;">
                            <div class="datas-table">
                                <div class="control-section">
                                    <table class="pipe-table">
                                        <thead>
                                            <tr>
                                                <th style="min-width: 14em;">{{'RadioDVGW553.Table.HeadlineLeistung_T' | translate}}</th>
                                                <th>{{'RadioDVGW553.Table.HeadlineLänge_T' | translate}}</th>
                                                <th class="result-column" style="text-align: end;">{{'RadioDVGW553.Table.HeadlineVerlustleistung_T' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let row of calculateFromPipesRows">
                                                <td>{{row.description}}</td>
                                                <td>
                                                    <app-slider-text-combo
                                                        class="slider-text-combo"
                                                        [sliderMax]="200"
                                                        [sliderStep]="5"
                                                        [textboxMax]="10000"
                                                        [textboxClass]="'slider-input'"
                                                        [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                                        [textboxDecimals]="0"
                                                        [showLabel]='true'
                                                        labelText="{{'#Generic.Unit.Meter_T' | translate}}"
                                                        [(value)]='row.lengthValue'
                                                        (valueChange)="calculateResult()"
                                                    ></app-slider-text-combo>

                                                </td>
                                                <td class="result-column" >{{row.resultWatts}} {{'#Generic.Unit.Watts_T' | translate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</viega-collapsible-panel>

