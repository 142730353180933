export enum ZirkulationsVSErmittlungstyp {
    None = 0,
    AusDte = 1,
    Abweichend = 2,
}

export enum ZirkulationsVSBerechnungstyp {
    None = 0,
    VolumenstromDirekt = 1,
    VerlustleistungDirekt = 2,
    DVGW553 = 3,
}

export enum WwBerechnungValuesBerechnungstyp {
    None = 0,
    Nutzungsabhaengig = 1,
    Flaechenbezogen = 2,
}

export enum WarmwasserExaktErmittlungstyp {
    None = 0,
    Direkt = 1,
    DIN12831 = 2,
}

export enum WarmwasserErmittlungstyp {
    None = 0,
    Grob = 1,
    Exakt = 2,
}

export enum GleichzeitigkeitResidential {
    None = 0,
    DIN4708 = 1,
    TUDresden = 2,
}

export enum EnergiespeicherErmittlungstyp {
    None = 0,
    Grob = 1,
    Exakt = 2,
}

export enum Energielabel {
    APlus = 0,
    A = 1,
    B = 2,
    C = 3,
}

export enum DimensioningSchematicType {
    WholeProject = 0,
    Dte = 1,
    DteUfc = 2,
    Ufc = 3,
}

export enum BedarfsvolumenstromErmittlungstypOther {
    None = 0,
    BvsDirekt = 1,
    Grob = 2,
    Exakt = 3,
}

export enum BedarfsvolumenstromErmittlungstyp {
    None = 0,
    BvsDirekt = 1,
    KennzahlDirekt = 2,
    Grob = 3,
    Exakt = 4,
}

export enum ZVSDirektEinheit {
    LiterSekunde = 0,
    LiterMinute = 1,
    KgStunde = 2,
    KgMinute = 3
}

export enum SectionState
{
    Empty = 0,
    Set = 1,
    Check = 2
}

export enum Komfortstufe {
    Stufe1 = 0,
    Stufe2 = 1,
    Stufe3 = 2
}

export enum ZweitBadTyp {
    None = 0,
    ZweitesBad = 1,
    GästezimmerMitBwUndDu = 2,
    GästezimmerOhneBwUndDu = 3
}