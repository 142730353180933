import { Injectable } from '@angular/core';
import { DteRow } from '../DteufcAggregatorServiceApi/dterow.complex';
import { UfcRow } from '../DteufcAggregatorServiceApi/ufcrow.complex';
import { DteMath } from './DteMath';
import { MathLogger } from './MathLogger';

export interface ICirculationFlowFromPipesParams
{
    leitungKellerMeter: number;
    leitungSchachtMeter: number;
    leitungNutzbereichMeter: number;
}

export interface ICirculationFlowFromPipesResult
{
    leitungKellerWatt: number;
    leitungSchachtWatt: number;
    leitungNutzbereichWatt: number;
    zirukulationsvolumenstromLiterStunde: number;
}

/**
 * Circulation flow (Zirkulationsvolumenstrom) related mathematic functions.
 *
 * Sources of mathematical formulas:
 * - 210716-V1.0 Erfassungsbogen_DTE-UFC-Speicher.xlsm
 * - 211221-KR Schema Auswahl DTE V1.2.xlsx
 *
 * rauch@klaus-rauch.de
 */
@Injectable()
export class CirculationFlowMath
{
    private static readonly LeitungKellerWattMeter = 11;
    private static readonly LeitungSchachtWattMeter = 7;
    private static readonly LeitungNutzbereichWattMeter = 7;
    public static readonly Dichte = 0.985; // kg/l
    private static readonly KapazitätWasser = 1.163; //Wh/kgK

    /**
     * Result in liter/hour.
     */
    public zirkulationsvolumenstromFromVerlustleistung(verlustleistungKiloWatt: number): number
    {
        const logger = new MathLogger('ZirkulationsvolumenstromFromVerlustleistung');
        logger.logInputs(
        {
            verlustleistungKiloWatt,
            temperaturspreizungPwhPwhc: DteMath.TemperaturspreizungPwhPwhc,
            kapazitätWasser: CirculationFlowMath.KapazitätWasser,
            dichte: CirculationFlowMath.Dichte,
        });

        const result = verlustleistungKiloWatt * 1000 / (CirculationFlowMath.KapazitätWasser * DteMath.TemperaturspreizungPwhPwhc * CirculationFlowMath.Dichte);
        logger.logResult({ zirkulationsvolumenstrom: result });

        return result;
    }

    /**
     * Result in kilowatt.
     */
    public verlustleistungFromZirkulationsvolumenstrom(zirkulationsvolumenstromLMin: number): number
    {
        const logger = new MathLogger('VerlustleistungFromZirkulationsvolumenstrom');
        logger.logInputs(
        {
            zirkulationsvolumenstromLMin,
            emperaturspreizungPwhPwhc: DteMath.TemperaturspreizungPwhPwhc,
            kapazitätWasser: CirculationFlowMath.KapazitätWasser,
        });

        const result = zirkulationsvolumenstromLMin * (CirculationFlowMath.KapazitätWasser * DteMath.TemperaturspreizungPwhPwhc) / 1000;
        logger.logResult({ verlustleistungKiloWatt: result });

        return result;
    }

    /**
     * Result in Watt.
     */
    public verlustleistungAbgesenktFromVerlustleistung(pwhStandardbetrieb: number, pwhCAbgesenkt: number, verlustleistungStandardbetriebWatt: number): number
    {
        const logger = new MathLogger('VerlustleistungAbgesenktFromVerlustleistung');
        logger.logInputs(
        {
            pwhStandardbetrieb,
            pwhCAbgesenkt,
            temperaturspreizungPwhPwhc: DteMath.TemperaturspreizungPwhPwhc,
            umgebungstemperatur: DteMath.Umgebungstemperatur,
            verlustleistungStandardbetriebWatt: verlustleistungStandardbetriebWatt,
        });

        const reduzierteVerlustleistungFactor = (pwhCAbgesenkt - DteMath.Umgebungstemperatur) / (pwhStandardbetrieb - DteMath.TemperaturspreizungPwhPwhc - DteMath.Umgebungstemperatur);
        const result = verlustleistungStandardbetriebWatt * reduzierteVerlustleistungFactor;
        logger.logResult({ reduzierteVerlustleistungFactor, verlustleistungAbgesenktKiloWatt: result });

        return result;
    }

    /**
     * Result in liter/minute.
     */
    public zirkulationsvolumenstromFromMassenstrom(massenstromKgMin: number): number
    {
        const logger = new MathLogger('ZirkulationsvolumenstromFromMassenstrom');
        logger.logInputs({ massenstromKgMin });

        const result = massenstromKgMin / CirculationFlowMath.Dichte;
        logger.logResult({ zirkulationsvolumenstromLMin: result });

        return result;
    }

    /**
     * Result in kg/min.
     */
    public massenstromFromZirkulationsvolumenstrom(zirkulationsvolumenstromLMin: number): number
    {
        const logger = new MathLogger('MassenstromFromZirkulationsvolumenstrom');
        logger.logInputs({ zirkulationsvolumenstromLMin });

        const result = zirkulationsvolumenstromLMin * CirculationFlowMath.Dichte;
        logger.logResult({ massenstromKgMin: result });

        return result;
    }

    /**
     * Result in liter/hour.
     */
    public zirkulationsvolumenstromFromLeitungenDVGW553(params: ICirculationFlowFromPipesParams): ICirculationFlowFromPipesResult
    {
        const logger = new MathLogger('ZirkulationsvolumenstromFromLeitungenDVGW553');
        logger.logInputs(Object.assign({}, params,
        {
            emperaturspreizungPwhPwhc: DteMath.TemperaturspreizungPwhPwhc,
            kapazitätWasser: CirculationFlowMath.KapazitätWasser,
            dichte: CirculationFlowMath.Dichte
        }));

        const leitungKellerWatt = params.leitungKellerMeter * CirculationFlowMath.LeitungKellerWattMeter;
        const leitungSchachtWatt = params.leitungSchachtMeter * CirculationFlowMath.LeitungSchachtWattMeter;
        const leitungNutzbereichWatt = params.leitungNutzbereichMeter * CirculationFlowMath.LeitungNutzbereichWattMeter;
        const gesamtWatt = leitungKellerWatt + leitungSchachtWatt + leitungNutzbereichWatt;

        logger.logIntermediateResult(
        {
            leitungKellerWatt,
            leitungSchachtWatt,
            leitungNutzbereichWatt,
            gesamtWatt,
        });

        const result: ICirculationFlowFromPipesResult =
        {
            leitungKellerWatt: leitungKellerWatt,
            leitungSchachtWatt: leitungSchachtWatt,
            leitungNutzbereichWatt: leitungNutzbereichWatt,
            zirukulationsvolumenstromLiterStunde: gesamtWatt / (CirculationFlowMath.KapazitätWasser * DteMath.TemperaturspreizungPwhPwhc * CirculationFlowMath.Dichte),
        };
        logger.logResult(result);

        return result;
    }

    /**
     * Requires a dte / ufc to be chosen in order to be calculated properly.
     * Result in liter/minute.
     */
    public filtervolumenstromFromZirkulationsvolumenstrom(zirkulationsvolumenstromLMin: number, ufc: UfcRow)
    {
        const logger = new MathLogger('FiltervolumenstromFromZirkulationsvolumenstrom');
        logger.logInputs({ zirkulationsvolumenstrom: zirkulationsvolumenstromLMin });

        const filtervolumenstrom = Math.max(zirkulationsvolumenstromLMin * 0.27, ufc.MinFiltervolumenstromLMin);
        logger.logResult({ filtervolumenstrom: filtervolumenstrom });

        return filtervolumenstrom;
    }

    /**
     * If this method returns an empty array, we can't let the user progress with the configured zirkulationsvolumenstrom.
     */
    public compatibleDtes(zirkulationsvolumenstromLMin: number, dteRows: DteRow[]): DteRow[]
    {
        return dteRows.filter(dte =>
            zirkulationsvolumenstromLMin >= dte.MinZirkulationsvolumenstromLMin &&
            zirkulationsvolumenstromLMin <= dte.MaxZirkulationsvolumenstromLMin
        );
    }
}
