// labels to improve the default localization of dropdowns
export const localization = {
    'de': {
        'listbox': {
            'selectAllText': 'Alles auswählen',
            'unSelectAllText': 'Alles auswählen',
            'moveUp': 'Nach oben bewegen',
            'moveDown': 'Nach unten bewegen',
            'moveTo': 'Verschieben nach',
            'moveFrom': 'Verschieben von',
            'moveAllTo': 'Alle verschieben nach',
            'moveAllFrom': 'Alle verschieben von'
        },
    }
};
