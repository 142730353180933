import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { ComponentBase, ComponentMixins, IComponentBase, setValue } from 'src/core-lib/angular/util';
import { PspButton } from 'src/core-lib/ej2/components/buttons/psp-button/PspButton';

// properties declared here can be changed by the user in the storyboard UI
export const propertyArgTypes = {
    textPosition: { control: { type: 'radio', options: ['center', 'left', 'right', 'top', 'bottom'] } },
    iconPosition: { control: { type: 'radio', options: ['left', 'right'] } },
    spinnerPosition: { control: { type: 'radio', options: ['left', 'right'] } },
    isDisabled: { control: 'boolean' },
    showSpinner: { control: 'boolean' },
    content: { control: 'text' },
    customIconCss: { control: 'text' },
    tooltipContent: { control: 'text' },
    tooltipPosition: { control: { type: 'select', options: ['TopLeft', 'TopCenter', 'TopRight', 'BottomLeft', 'BottomCenter', 'BottomRight', 'LeftTop', 'LeftCenter', 'LeftBottom', 'RightTop', 'RightCenter', 'RightBottom'] } },
    cssClass: { control: 'text', description: 'Try "e-primary", "e-bigger", "e-small" or "psp-flat".' },
};

// prevent storybook from updating these properties
const changeIgnoredProperties: string[] = [];

// declare some properties as bindable angular outputs
const outputBindings = [
    'createdEvent',
    'destroyedEvent'
];

@Component({
    selector: '[psp-button]',
    template: '<ng-content></ng-content>',
    inputs: [
        'cssClass',
        'textPosition',
        'iconPosition',
        'spinnerPosition',
        'isDisabled',
        'showSpinner',
        'content',
        'customIconCss',
        'tooltipContent',
        'tooltipPosition',
        'cssClass',
    ],
    outputs: outputBindings,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@ComponentMixins([ComponentBase])
export class PspButtonComponent extends PspButton implements IComponentBase, OnInit, OnDestroy, AfterViewInit, AfterContentChecked
{
    /** @ignore */
    public tagObjects: any = [];

    /** @ignore */
    public context : any;

    /** @ignore */
    public tags: string[] = [];

    protected changeIgnoredPropertyMap: { [propertyName: string]: boolean };

    public constructor(
        private ngEle: ElementRef,
        private srenderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    )
    {
        super();

        this.element = this.ngEle.nativeElement;

        this.changeIgnoredPropertyMap = {};
        changeIgnoredProperties.forEach(prop => this.changeIgnoredPropertyMap[prop] = true);

        this.registerEvents(outputBindings);
        this.addTwoWay.call(this, []);
        setValue('currentInstance', this, viewContainerRef);
        this.context  = new ComponentBase();
    }

    /** @ignore */
    public ngOnInit(): void
    {
        this.context.ngOnInit(this);
    }

    /** @ignore */
    public ngAfterViewInit(): void
    {
        this.context.ngAfterViewInit(this);
    }

    /** @ignore */
    public ngOnDestroy(): void
    {
        this.context.ngOnDestroy(this);
    }

    /** @ignore */
    public ngAfterContentChecked(): void
    {
        this.context.ngAfterContentChecked(this);
    }

    /** @ignore */
    public registerEvents: (eventList: string[]) => void;

    /** @ignore */
    public addTwoWay: (propList: string[]) => void;
}
