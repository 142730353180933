import { AfterViewInit, ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RadioButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { DdtChangeEventArgs, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-inputs';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { Warmwasser } from 'src/app/core/BackendApi/warmwasser.complex';
import { DailyNeedEn12831UsageRow } from 'src/app/core/DteufcAggregatorServiceApi/dailyneeden12831usagerow.complex';
import { DailyNeedEn12831UsageTypeRow } from 'src/app/core/DteufcAggregatorServiceApi/dailyneeden12831usagetyperow.complex';
import { WarmwasserErmittlungstyp, WarmwasserExaktErmittlungstyp, WwBerechnungValuesBerechnungstyp } from 'src/app/core/Enums';
import { HotWaterMath } from 'src/app/core/math/HotWaterMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
  selector: 'app-hot-water-demand',
  templateUrl: './hot-water-demand.component.html',
  styleUrls: ['./hot-water-demand.component.scss'],
  providers: [provideContext("HotWaterDemand")],
})
export class HotWaterDemandComponent implements OnInit,AfterViewInit {
  @ViewChild('hotWaterManual')
  public HotWaterManualRadio: RadioButtonComponent;

  @ViewChild('hotWaterCalculate')
  public HotWaterCalculateRadio: RadioButtonComponent;

  @ViewChild('structureDetailsDataDDL')
  public dropdownNutzung: DropDownListComponent;

  @ViewChild('structureDataDDL')
  public dropdownNutzungsart: DropDownListComponent;

  stepText = this.langService.getString('PanelStepText_T');
  stepBack = this.langService.getString('PanelStepBackLabel_T');
  stepTitle = this.langService.getString('PanelHeadline_T');
  stepDescription: string = this.langService.getString('PanelDescription_D');

  SliderDescriptionLiterPerDay = this.langService.getString('#Generic.Unit.Liter_T');
  SliderDescriptionDegreeCelsius = this.valueFormatterService.formatNumber(60, 'DegreeCelsius');

  DescriptionNutzungsabhaengigLiterPerDay = this.valueFormatterService.formatNumber(160, 'LiterPerDay'); //!!!
  DescriptionNutzungsabhaengigNumber = this.DescriptionNutzungsabhaengigLiterPerDay.substring(0,3);
  DescriptionNutzungsabhaengigUnit = this.DescriptionNutzungsabhaengigLiterPerDay.substring(4, 9);
  DescriptionNutzungsabhaengigDegreeCelsius = this.valueFormatterService.formatNumber(60, 'DegreeCelsius');

  DescriptionFlaechenbezogenLiterPerDay = this.valueFormatterService.formatNumber(120, 'LiterPerDay'); //!!!
  DescriptionFlaechenbezogenNumber = this.DescriptionFlaechenbezogenLiterPerDay.substring(0,3);
  DescriptionFlaechenbezogenUnit = this.DescriptionFlaechenbezogenLiterPerDay.substring(4, 9);
  DescriptionFlaechenbezogenDegreeCelsius = this.valueFormatterService.formatNumber(60, 'DegreeCelsius');

  hotWaterManualChecked: boolean = false;
  manualValue: number = 0;


  selectedProject: ProjectEntity;
  editData: Warmwasser;
  selectedStructure: string;

  WarmwasserExaktErmittlungstyp = WarmwasserExaktErmittlungstyp;
  WwBerechnungValuesBerechnungstyp = WwBerechnungValuesBerechnungstyp;

  continueDisabled = true;

  calculationTypeData = ["Nutzungsabhängig", "Flächenbezogen"];
  calculationTypeDataPreselect = "Nutzungsabhängig";
  isAreaSelected = false;

  nutzungsarten: DailyNeedEn12831UsageTypeRow[];
  nutzungen: DailyNeedEn12831UsageRow[];
  selectedNutzung: DailyNeedEn12831UsageRow;
  selectedNutzungsart: DailyNeedEn12831UsageTypeRow;

  warmwasserTemp = 0;
  nutzungTemp = 0;
  flaechenTemp = 0;


  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private langService: LangService,
    private valueFormatterService: ValueFormatterService,
    private dataService: ProjectDataService,
    protected aggregatorDataService: AggregatorDataService,
    private hotWaterMath: HotWaterMath,
    private appRef: ApplicationRef
  ) { }
    ngAfterViewInit(): void {
        if(this.HotWaterManualRadio.checked){

            this.editData.ErmittlungWarmwasserExakt.ErmittlungDirekt.WarmwasserDirekt = this.warmwasserTemp;
        }
        if(this.HotWaterCalculateRadio.checked){
        this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter = this.flaechenTemp;
        this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig = this.nutzungTemp;
        }
        this.calculateValues();

        //Initialgröße "Nutzungsart"-Dropdown
        var width = this.getTextWidth(this.selectedNutzungsart.DisplayNameLoc, "bold 14px viega-thin");

        this.dropdownNutzung.element.style.width = width + 120 + "px";

        //Initialgröße "Nutzung"-Dropdown
        var width = this.getTextWidth(this.selectedNutzung.DisplayNameLoc, "bold 14px viega-thin");

        this.dropdownNutzung.element.style.width = width + 120 + "px";
    }

  onChangeManualSlider(args: ChangeEventArgs){
    this.calculateValues();
  }

  calculateValues(){
      switch (this.editData.ErmittlungWarmwasserExakt.Ermittlungstyp) {
        case WarmwasserExaktErmittlungstyp.Direkt:
            this.editData.WarmwasserErgebnisLTag = Number(this.editData.ErmittlungWarmwasserExakt.ErmittlungDirekt.WarmwasserDirekt);
              break;
        case WarmwasserExaktErmittlungstyp.DIN12831:
            if (!this.selectedNutzung.SpezifischerBedarfFlaecheLiterTag) {
                this.editData.WarmwasserErgebnisLTag = this.hotWaterMath.warmwassertagesbedarfFromNutzungDinEn12831({
                    nutzung: this.selectedNutzung,
                    nutzungsabhaengigAnzahl: this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig
                })
            }
            else{
                switch (this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Berechnungstyp) {
                    case WwBerechnungValuesBerechnungstyp.Nutzungsabhaengig:
                        this.editData.WarmwasserErgebnisLTag = this.hotWaterMath.warmwassertagesbedarfFromNutzungDinEn12831({
                            nutzung: this.selectedNutzung,
                            nutzungsabhaengigAnzahl: this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig
                        })

                        break;
                    case WwBerechnungValuesBerechnungstyp.Flaechenbezogen:
                        this.editData.WarmwasserErgebnisLTag = this.hotWaterMath.warmwassertagesbedarfFromNutzungDinEn12831({
                            nutzung: this.selectedNutzung,
                            nutzungsabhaengigAnzahl: null,
                            flaechenbezogenAnzahl: this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter
                        })
                        break;
                    default:
                        break;
                }
            }

            break;
        default:
            this.editData.WarmwasserErgebnisLTag = 0;
            break;
      }

  }

  onChangeErmittlung(typ: WarmwasserExaktErmittlungstyp){

    this.editData.ErmittlungWarmwasserExakt.Ermittlungstyp = typ;

    this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig
    this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter


    this.calculateValues();
  }

  onChangeBerechnung(typ: WwBerechnungValuesBerechnungstyp){

    this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Berechnungstyp = typ;


    this.calculateValues();
  }


  onChangeCalculationTypeData(args: DdtChangeEventArgs){
    if(String(args.value) == "Flächenbezogen"){
      this.isAreaSelected = true;
    }
    else{
      this.isAreaSelected = false;
    }
  }

  onChangeNutzungsart(args: ChangeEventArgs){

    this.selectedNutzungsart = this.nutzungsarten
        .find(n => n.DailyNeedUsageTypeId == this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsart);
        

    if (args.isInteracted) {
        this.nutzungen = this.aggregatorDataService.aggregatorData.Tables.DailyNeedEn12831UsageRows
            .filter(r => r.DailyNeedUsageTypeId == this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsart)

        this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzung = this.nutzungen[0].DailyNeedUsageId;
        console.log(this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzung);
    }

    this.calculateValues();
  }

  onChangeNutzung(args: ChangeEventArgs){
    this.selectedNutzung = this.aggregatorDataService.aggregatorData.Tables.DailyNeedEn12831UsageRows
        .find(r => r.DailyNeedUsageId == this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzung)

    this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig = 0;
    this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter = 0;

    var width = this.getTextWidth(this.selectedNutzung.DisplayNameLoc, "bold 14px viega-thin");

        this.dropdownNutzung.element.style.width = width + 120 + "px";

        console.log(this.selectedNutzung);
    this.calculateValues();
  }

  ngOnInit(): void {
    let urlProject = this.activatedRoute.snapshot.data['project'];
    this.dataService.setTempProject(urlProject);
    this.selectedProject = this.dataService.TempProject;
    if(!this.selectedProject){
        if(urlProject){
            this.router.navigate([`/project-view/${urlProject.ProjectId}`]);
        } else {
            this.router.navigate([`/home`]);
        }
        return;
    }

    this.selectedStructure = this.activatedRoute.snapshot.data['buildingType'];
    if(!this.selectedStructure || (this.selectedProject.ResidentialBuilding?.BuildingType != this.selectedStructure
        && !this.selectedProject.OtherBuildings?.some(b => b.BuildingType == this.selectedStructure))
    ){
        this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`])
    }




    if (this.selectedStructure == this.selectedProject.ResidentialBuilding?.BuildingType) {
        if(!this.selectedProject.ResidentialBuilding.Warmwasser){
            this.selectedProject.ResidentialBuilding.Warmwasser = {
                Ermittlungstyp: WarmwasserErmittlungstyp.Exakt
            }
        }
        this.editData = this.selectedProject.ResidentialBuilding.Warmwasser;

        }
    else{
        if(!this.selectedProject.OtherBuildings.find(x => x.BuildingType == this.selectedStructure).Warmwasser){
            this.selectedProject.OtherBuildings.find(x => x.BuildingType == this.selectedStructure).Warmwasser = {
                Ermittlungstyp: WarmwasserErmittlungstyp.Exakt
            }
        }
        this.editData = this.selectedProject.OtherBuildings
            .find(x => x.BuildingType == this.selectedStructure).Warmwasser;
    }

    if (!this.editData.ErmittlungWarmwasserExakt) {
        this.editData.ErmittlungWarmwasserExakt = {
                ExaktDinValues: {
                    Berechnungstyp: WwBerechnungValuesBerechnungstyp.Nutzungsabhaengig,
                },
                ErmittlungDirekt: {}
        }
    }


    let nutzungsIdStandard = this.aggregatorDataService.aggregatorData.Tables.BuildingTypeRows
        .find(r => r.BuildingId == this.selectedStructure).VorauswahlDailyNeedUsageId;

    this.selectedNutzung = this.aggregatorDataService.aggregatorData.Tables.DailyNeedEn12831UsageRows
        .find(r => r.DailyNeedUsageId == nutzungsIdStandard)

    this.nutzungsarten = this.aggregatorDataService.aggregatorData.Tables.DailyNeedEn12831UsageTypeRows
        .filter(r => r.ForBuildingTypeIds.some(b => b == this.selectedStructure));

        if(!this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsart){

            this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsart = this.selectedNutzung.DailyNeedUsageTypeId;
        }

        this.selectedNutzungsart = this.nutzungsarten.find(a => a.DailyNeedUsageTypeId == this.selectedNutzung.DailyNeedUsageTypeId);

    this.nutzungen = this.aggregatorDataService.aggregatorData.Tables.DailyNeedEn12831UsageRows
        .filter(r => r.DailyNeedUsageTypeId == this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsart)

        if(!this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzung){
            this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzung = this.selectedNutzung.DailyNeedUsageId;
            
        }
        this.selectedNutzung = this.aggregatorDataService.aggregatorData.Tables.DailyNeedEn12831UsageRows
        .find(r => r.DailyNeedUsageId == this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzung);
        console.log(this.selectedNutzung);



        this.warmwasserTemp = this.editData.ErmittlungWarmwasserExakt.ErmittlungDirekt.WarmwasserDirekt;
        this.nutzungTemp = this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig;
        this.flaechenTemp = this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter;
        this.calculateValues()
        this.editData.ErmittlungWarmwasserExakt.ErmittlungDirekt.WarmwasserDirekt = 0;
        this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.Nutzungsabhaengig = 0;
        this.editData.ErmittlungWarmwasserExakt.ExaktDinValues.FlaechenbezogenQMeter = 0;

  }

  getWarmwasserLTag(){
      if (!this.editData.WarmwasserErgebnisLTag) {
          return "-";
      }
      return this.valueFormatterService.formatNumber(this.editData.WarmwasserErgebnisLTag, 'Liter', {maximumFractionDigits: 0}, false)
  }

  public onSelectDropdownNutzungsart(args) {
    // var multiObject = document.getElementById(id).parentElement;
    var width = this.getTextWidth(args.itemData.DisplayNameLoc, "bold 14px viega-thin");

    // 20px added for dropdownIcon width
    this.dropdownNutzung.element.style.width = width + 120 + "px";
    }

  public onSelectDropdownNutzung(args) {
    // var multiObject = document.getElementById(id).parentElement;
    var width = this.getTextWidth(args.itemData.DisplayNameLoc, "bold 14px viega-thin");

    // 20px added for dropdownIcon width
    this.dropdownNutzung.element.style.width = width + 120 + "px";
    }

getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas =
      (this.getTextWidth as any).canvas ||
      ((this.getTextWidth as any).canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
}

getPopupWidth(){
    var longest = this.nutzungen.reduce(
        function (a, b) {
            return a.DisplayNameLoc.length > b.DisplayNameLoc.length ? a : b;
        }
    );

    var canvas =
      (this.getTextWidth as any).canvas ||
      ((this.getTextWidth as any).canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = "bold 14px viega-thin";
    var metrics = context.measureText(longest.DisplayNameLoc);
    return metrics.width + 80 + "px";
}


  goBack() {
        this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
  }

  navigateNext(){
    if (this.selectedProject.ResidentialBuilding?.BuildingType == this.selectedStructure) {
         this.selectedProject.ResidentialBuilding.Warmwasser = this.editData;
    }
    else {
        this.selectedProject.OtherBuildings.find(x => x.BuildingType == this.selectedStructure).Warmwasser = this.editData;
    }

    this.router.navigate([`/daily-load-profile/${this.selectedProject.ProjectId}/${this.selectedStructure}`]);
  }

}
