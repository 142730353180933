import { FormGroup } from '@angular/forms';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { IODataError } from 'src/core-lib/ej2/utils/IODataError';
import { PspFormField } from './PspFormField';
import { ServiceLocator } from 'src/core-lib/ej2/services/ServiceLocator';

export class PspFormFieldGroup
{
    private langService: LangService;

    public group: FormGroup;
    public fields: PspFormField[] = [];
    public fieldMap: { [fieldName: string]: PspFormField } = {};

    /**
     * Represents a form-wide error message.
     */
    public formErrorMsg?: string;

    /**
     * Error message shown when a field is invalid and has no more specific error
     * messages defined.
     */
    public fieldFallbackErrorMsg?: string;

    public constructor(fields: PspFormField[] = [])
    {
        this.langService = ServiceLocator.get(LangService);

        this.group = new FormGroup({});
        this.group.statusChanges.subscribe(this.groupStatusChanged.bind(this));
        fields.forEach(field => this.addField(field));

        this.fieldFallbackErrorMsg = this.langService.getString('#Generic.Forms.FieldErrors.Fallback_SD', null);
    }

    public addField(field: PspFormField): void
    {
        if (this.fieldMap[field.fieldName])
            throw new Error(`A field with name ${field.fieldName} does already exist.`);

        this.group.addControl(field.fieldName, field.control);
        this.fieldMap[field.fieldName] = field;
        this.fields.push(field);
    }

    /**
     * Applies form and field errors by evaluating a odata error response object.
     */
    public setODataModelError(odataError: IODataError): boolean
    {
        let foundInAnyField = false;
        odataError.details?.forEach(errorDetail =>
        {
            const fieldName = errorDetail.target.toLowerCase();
            const field = this.fields.find(f => f.fieldName.toLowerCase() === fieldName);
            if (field)
            {
                field.control.setErrors({ backend: true });
                this.updateFieldError(field);

                foundInAnyField = true;
            }
        });

        return foundInAnyField;
    }

    public setFieldError(fieldName: string, errorMsg?: string): void
    {
        const field: PspFormField = this.fieldMap[fieldName];
        if (!field)
            throw new Error(`A field with name ${fieldName} does not exist.`);

        field.currentErrorMsg = errorMsg;
        field.control.setErrors({ custom: true });
    }

    public setFormError(errorMsg?: string): void
    {
        this.formErrorMsg = errorMsg;
    }

    public reset(): void
    {
        this.fields.forEach(field =>
        {
            delete field.currentErrorMsg;
        });
        delete this.formErrorMsg;
    }

    private groupStatusChanged(newStatus: string): void
    {
        if (newStatus === 'VALID')
        {
            this.reset();
        }
        else if (newStatus === 'INVALID')
        {
            this.fields.forEach((field: PspFormField) => this.updateFieldError(field));
        }
    }

    /**
     * Attempts to set an error message depending on the failed form validatiors.
     */
    private updateFieldError(field: PspFormField): void
    {
        if (field.genericErrorMsg)
        {
            field.currentErrorMsg = field.genericErrorMsg;
            return;
        }

        let newFieldError;
        if (!field.control.valid)
        {
            newFieldError = field.fallbackErrorMsg || this.fieldFallbackErrorMsg;

            if (field.control.errors)
            {
                const errorTypes = Object.keys(field.control.errors);
                if (errorTypes.includes('required'))
                    newFieldError = this.langService.getString('#Generic.Forms.FieldErrors.Required_SD');
            }
        }

        field.currentErrorMsg = newFieldError;
    }
}
