import { DemandVolumetricFlowBaseComponent } from 'src/app/core/DemandVolumetricFlowBaseComponent';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { BedarfsvolumenstromResidential } from 'src/app/core/BackendApi/bedarfsvolumenstromresidential.complex';
import { VolumetricFlowRateMath } from 'src/app/core/math/VolumetricFlowRateMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { ApartmentGroupPresetRow } from 'src/app/core/DteufcAggregatorServiceApi/apartmentgrouppresetrow.complex';
import { Wohnungsgruppe } from 'src/app/core/BackendApi/wohnungsgruppe.complex';
import { ApartmentGroupFacilityRow } from 'src/app/core/DteufcAggregatorServiceApi/apartmentgroupfacilityrow.complex';
import { Ausstattung } from 'src/app/core/BackendApi/ausstattung.complex';
import { DialogComponent } from '@syncfusion/ej2-angular-popups/src/dialog/dialog.component';
import { ZweitBadTyp, GleichzeitigkeitResidential, BedarfsvolumenstromErmittlungstyp, SectionState } from 'src/app/core/Enums';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import * as _ from 'lodash';
import { WohnungsgruppeDialogData } from 'src/app/shared/wohnungsgruppe-dialog-data/wohnungsgruppe-dialog-data.component';

// Component
@Component({
    selector: 'app-demand-volumetric-flow-residential',
    templateUrl: './demand-volumetric-flow-residential.component.html',
    styleUrls: ['./demand-volumetric-flow-residential.component.scss'],
    providers: [provideContext('DemandVolumetricFlowResidential')]
})
export class DemandVolumetricFlowResidentialComponent extends DemandVolumetricFlowBaseComponent implements OnInit, AfterViewInit {
    // Viewchilds
    @ViewChild('gridWohnungsgruppenExakt') public gridWohnungsgruppenExakt: DialogComponent;
    @ViewChild('invalidFieldsDialog') public invalidFieldsDialog: DialogComponent;
    @ViewChild('deleteDialog') public deleteDialog: DialogComponent;
    @ViewChild('editDialog') public editDialog: WohnungsgruppeDialogData;

    stepBack= this.langService.getString('StepBack_T');
    stepDescription = this.langService.getString('StepDescription_D');


    // Enum providers
    public Gleichzeitigkeit = GleichzeitigkeitResidential;

    // Button bindings
    editDialogButtons = [{
        'click': this.onWohnungsgruppeAdd.bind(this),
        buttonModel: {
            content: this.langService.getString('EditDialogUebernehmenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
    }]
    invalidFieldsDialogButtons = [{
        'click': this.onCloseInvalidFieldsDialog.bind(this),
        buttonModel: {
            content: this.langService.getString('InvalidDialogUeberpruefenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
    }]
    deleteDialogButtons = [{
        'click': this.onWohnungsgruppeDelete.bind(this),
        buttonModel: {
            content: this.langService.getString('DeleteDialogUebernehmenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
    }]

    // Public Variables
    public apartmentFacilities: ApartmentGroupFacilityRow[];
    public editingData: BedarfsvolumenstromResidential;
    public editDialogData: WohnungsgruppeDialogData;
    public stepTitle: string;
    public gebaudetyp : string;
    public calculatedMin: number = 2;
    public maxDefinedRaumZahl: number = 7;  // Raumzahl > 7 has no defined norm and '-' should be displayed
    public roomGroup: Wohnungsgruppe[];

    //temp values
    public volumestromTemp = 0;
    public bedarfTemp;
    public grobTemp = [];

    // Internal Variables
    private apartmentGroupPresets: ApartmentGroupPresetRow[];
    private deleteIndex: number;
    private index: number;

    // Constructors/Initializers
    constructor(
        private activatedRoute: ActivatedRoute,
        protected readonly router: Router,
        protected DataService: ProjectDataService,
        protected formatterService: ValueFormatterService,
        protected aggregatorDataService: AggregatorDataService,
        private langService: LangService,
        @Inject(VolumetricFlowRateMath) private bvsMath: VolumetricFlowRateMath
    ) {
        super(router, DataService, formatterService, aggregatorDataService);
    }
    ngAfterViewInit(): void {
        if(this.volumestromTemp != 0){
            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = this.volumestromTemp;
        }
        if(this.grobTemp.length !== 0 && this.editingData.ErmittlungResidentialGrob){
            this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl = this.clone(this.grobTemp);
        }
        if(this.editingData.ErmittlungsTyp == this.ErmittlungsTyp.BvsDirekt && this.editingData.ErmittlungResidentialKennzahlDirekt){
            this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl = 0;
            if(this.editingData.ErmittlungResidentialGrob)
            this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl.forEach(x => x.Value = 0);
        }
        if(this.editingData.ErmittlungsTyp == this.ErmittlungsTyp.KennzahlDirekt &&  this.editingData.ErmittlungBvsDirekt){
            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = 0;
            if(this.editingData.ErmittlungResidentialGrob)
            this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl.forEach(x => x.Value = 0);
        }
        if(this.editingData.ErmittlungsTyp == this.ErmittlungsTyp.Grob){
            if(this.editingData.ErmittlungBvsDirekt)
            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = 0;

            if(this.editingData.ErmittlungResidentialKennzahlDirekt)
            this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl = 0;

            if(this.editingData.ErmittlungResidentialExakt){
                this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen = []
            }
        }
        if(this.editingData.ErmittlungsTyp == this.ErmittlungsTyp.Exakt){
            if(this.editingData.ErmittlungBvsDirekt)
            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = 0;

            if(this.editingData.ErmittlungResidentialKennzahlDirekt)
            this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl = 0;

            if(this.editingData.ErmittlungResidentialGrob){
                this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl.forEach(x => x.Value = 0);
            }
        }

        this.calculateBvs();
    }
    ngOnInit(): void {
        // Init table data
        this.apartmentGroupPresets = this.aggregatorDataService.aggregatorData.Tables.ApartmentGroupPresetRows;
        this.apartmentFacilities = this.aggregatorDataService.aggregatorData.Tables.ApartmentGroupFacilityRows;

        
        // Init project
        this.currentProject = this.activatedRoute.snapshot.data['project'];
        this.gebaudetyp = `${this.aggregatorDataService.aggregatorData.Tables.BuildingTypeRows
            .find(b => b.BuildingId == this.currentProject.ResidentialBuilding.BuildingType).DisplayNameLoc}`
        this.stepTitle = this.langService.getString('StepTitle',undefined, this.gebaudetyp);

        // Load/Init data
        if (this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom) {
            this.editingData = this.clone(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom);
        }
        else {
            this.editingData = { ErmittlungsTyp: BedarfsvolumenstromErmittlungstyp.None };
        }
        
        if(this.editingData.ErmittlungBvsDirekt){
            this.volumestromTemp = this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom;
            this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = 0;
        }
        if(this.editingData.ErmittlungResidentialKennzahlDirekt){
            this.bedarfTemp = this.clone(this.editingData.ErmittlungResidentialKennzahlDirekt);
            this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl = 0;
        }
        if(this.editingData.ErmittlungResidentialGrob){
            this.grobTemp = this.clone(this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl);
            this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl = [];
        }
    }

    // Shared Methods
    public calculateBvs(): void {
        switch(this.editingData.ErmittlungsTyp){
            case BedarfsvolumenstromErmittlungstyp.BvsDirekt:
                this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom = Number(this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom);
                this.editingData.BedarfsvolumenstromErgebnisLMin = this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom;
                break;
            case BedarfsvolumenstromErmittlungstyp.KennzahlDirekt:
                this.calculateBvsByKennzahl();
                break;
            case BedarfsvolumenstromErmittlungstyp.Grob:
                this.calculateBvsGrob();
                break;
            case BedarfsvolumenstromErmittlungstyp.Exakt:
                this.calculateBvsExact();
                break;
            default: this.resetResult(); break;
        }
    }
    public getUnitRow(unitId: string): ApartmentGroupPresetRow {
        return this.apartmentGroupPresets.find(p => p.ApartmentGroupPresetId == unitId);
    }

    // Internal Methods
    protected getBuildingType(): string {
        return this.currentProject.ResidentialBuilding.BuildingType;
    }

    public getEditDialogHeader(){
        if(this.editDialogData.editElementIndex == null){
            return this.langService.getString('ExaktTypRadio.DialogHeaderNeu_T');
        }
        else{
            return this.langService.getString('ExaktTypRadio.DialogHeader_T')
        }
    }

    private resetResult(): void {
        this.editingData.BedarfskennzahlErgebnis = 0;
        this.editingData.BedarfsvolumenstromErgebnisLMin = 0;
    }
    private gleichzeitigkeitFromEnum(gleichzeitigkeit: GleichzeitigkeitResidential) : "TUDresden" | "DIN4708" {
        switch(gleichzeitigkeit) {
            case GleichzeitigkeitResidential.DIN4708: return "DIN4708";
            case GleichzeitigkeitResidential.TUDresden: return "TUDresden";
            default: return null;
        }
    }
    private calculateBvsByKennzahl(): void {
        let gleichzeitigkeit = this.gleichzeitigkeitFromEnum(this.editingData.ErmittlungResidentialKennzahlDirekt.Gleichzeitigkeit);
        if(gleichzeitigkeit == null){
            this.resetResult();
        }
        else{
            this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl = Number(this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl);
            this.editingData.BedarfskennzahlErgebnis = this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl;
            this.editingData.BedarfsvolumenstromErgebnisLMin = Number(this.editingData.BedarfsvolumenstromErgebnisLMin);

            let  result = this.bvsMath.barfsvolumenstromFromBedarfskennzahl(this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl, gleichzeitigkeit);

            this.editingData.BedarfsvolumenstromErgebnisLMin = Number.isNaN(result) ? 0 : result;
        }
    }
    private calculateBvsGrob(): void {
        if(this.editingData.ErmittlungResidentialGrob.Gleichzeitigkeit == null
            || this.editingData.ErmittlungResidentialGrob.Gleichzeitigkeit == GleichzeitigkeitResidential.None
            || !this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl.some(w => w.Value > 0))
        {
            this.resetResult();
            return;
        }

        // Get Wohnungsgruppen from preset amounts
        this.editingData.ErmittlungResidentialGrob.Wohnungsgruppen = this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl.map(wg => {
            var preset = this.getUnitRow(wg.Key);
            wg.Value = Number(wg.Value);
            return {
                Anzahl: wg.Value,
                Bezeichnung: wg.Key,
                Raumzahl: preset.Raumzahl,
                Belegungszahl: preset.Belegungszahl,
                IsKleinwohnungen: preset.IsKleinwohnungen,
                Ausstattungen: this.apartmentFacilities
                .filter(f => preset.FacilityIdsErstesBad.some(i => i == f.FacilityId)).map(f => {
                    return {
                        Typ: f.FacilityId,
                        Bedarf: f.ZapfstellenbedarfWVErstbad,
                        IsCustom: false
                    }
                }),
                ZweitBadTyp: this.zweitbadTypFromString(preset.ZweitesBadTyp),
                AusstattungenZweitbad: preset.ZweitesBadTyp != null && preset.ZweitesBadTyp != 'None' ? this.apartmentFacilities
                .filter(f => preset.FacilityIdsZweitesBad.some(i => i == f.FacilityId)).map(f => {
                    let bedarf: number;
                    switch(this.zweitbadTypFromString(preset.ZweitesBadTyp)){
                        case ZweitBadTyp.ZweitesBad: bedarf = f.ZapfstellenbedarfWVZweitbad; break;
                        case ZweitBadTyp.GästezimmerMitBwUndDu: bedarf = f.ZapfstellenbedarfWVGaesteBwDu; break;
                        case ZweitBadTyp.GästezimmerOhneBwUndDu: bedarf = f.ZapfstellenbedarfWVGaesteOhneBwDu; break;
                    }
                    return {
                        Typ: f.FacilityId,
                        Bedarf: bedarf,
                        IsCustom: false
                    }
                }) : null
            };
        })

        // Calculate from Wohnungsgruppen
        this.calculateBvsFromWohnungsgruppen(
            this.editingData.ErmittlungResidentialGrob.Wohnungsgruppen,
            this.editingData.ErmittlungResidentialGrob.Gleichzeitigkeit
        );
    }
    private calculateBvsExact(): void {
        
        if(this.editingData.ErmittlungResidentialExakt.Gleichzeitigkeit == null
            || this.editingData.ErmittlungResidentialExakt.Gleichzeitigkeit == GleichzeitigkeitResidential.None
            || this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen.length <= 0)
        {
            this.resetResult();
            return;
        }

        // Calculate from Wohnungsgruppen
        this.calculateBvsFromWohnungsgruppen(
            this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen,
            this.editingData.ErmittlungResidentialExakt.Gleichzeitigkeit
        );
    }
    private calculateBvsFromWohnungsgruppen(groups: Wohnungsgruppe[], gleichzeitigkeit: GleichzeitigkeitResidential) {
        if(groups.length <= 0){
            this.resetResult();
        }
        else{
            console.log(groups)
            this.editingData.BedarfskennzahlErgebnis = this.bvsMath.bedarfskennzahlFromWohnungsgruppen(
                groups.map(w => { return {
                    belegungszahl: w.Belegungszahl,
                    wohnungsanzahl: w.Anzahl,
                    zweitesBadTyp: this.zweitbadTypFromEnum(w.ZweitBadTyp),
                    ausstattungen: w.Ausstattungen.map(a => this.apartmentFacilities.find(f => f.FacilityId == a.Typ)),
                    facilitiesZweitesBad: w.AusstattungenZweitbad ? w.AusstattungenZweitbad.map(a => this.apartmentFacilities.find(f => f.FacilityId == a.Typ)) : null
                }})
            );
            this.editingData.BedarfsvolumenstromErgebnisLMin = this.bvsMath.barfsvolumenstromFromBedarfskennzahl(
                this.editingData.BedarfskennzahlErgebnis,
                this.gleichzeitigkeitFromEnum(gleichzeitigkeit)
            )
        }
    }

    // Button/Click Events
    public onClickSelectGleichzeitigkeit(gleichzeitigkeit: GleichzeitigkeitResidential): void {
        // Set Gleichzeitigkeit
        switch(this.editingData.ErmittlungsTyp){
            case BedarfsvolumenstromErmittlungstyp.KennzahlDirekt:
                this.editingData.ErmittlungResidentialKennzahlDirekt.Gleichzeitigkeit = gleichzeitigkeit;
                break;
            case BedarfsvolumenstromErmittlungstyp.Grob:
                this.editingData.ErmittlungResidentialGrob.Gleichzeitigkeit = gleichzeitigkeit;
                break;
            case BedarfsvolumenstromErmittlungstyp.Exakt:
                this.editingData.ErmittlungResidentialExakt.Gleichzeitigkeit = gleichzeitigkeit;
                break;
        }

        // Recalculate
        this.calculateBvs();
    }


    public onClickSave(): void {
            
        //Remove non selected Data for no errors in comparison
        switch(this.editingData.ErmittlungsTyp){
            case(this.ErmittlungsTyp.BvsDirekt):
                if(this.editingData.ErmittlungResidentialExakt)
                    this.editingData.ErmittlungResidentialExakt = null;
                if(this.editingData.ErmittlungResidentialGrob)
                    this.editingData.ErmittlungResidentialGrob = null;
                if(this.editingData.ErmittlungResidentialKennzahlDirekt)
                    this.editingData.ErmittlungResidentialKennzahlDirekt = null;
            break;
            case(this.ErmittlungsTyp.Exakt):
                if(this.editingData.ErmittlungBvsDirekt)
                    this.editingData.ErmittlungBvsDirekt = null;
                if(this.editingData.ErmittlungResidentialGrob)
                    this.editingData.ErmittlungResidentialGrob = null;
                if(this.editingData.ErmittlungResidentialKennzahlDirekt)
                    this.editingData.ErmittlungResidentialKennzahlDirekt = null;
            break;
            case(this.ErmittlungsTyp.Grob):
                if(this.editingData.ErmittlungBvsDirekt)
                    this.editingData.ErmittlungBvsDirekt = null;
                if(this.editingData.ErmittlungResidentialExakt)
                    this.editingData.ErmittlungResidentialExakt = null;
                if(this.editingData.ErmittlungResidentialKennzahlDirekt)
                    this.editingData.ErmittlungResidentialKennzahlDirekt = null;
            break;
            case(this.ErmittlungsTyp.KennzahlDirekt):
                if(this.editingData.ErmittlungBvsDirekt)
                    this.editingData.ErmittlungBvsDirekt = null;
                if(this.editingData.ErmittlungResidentialExakt)
                    this.editingData.ErmittlungResidentialExakt = null;
                if(this.editingData.ErmittlungResidentialGrob)
                    this.editingData.ErmittlungResidentialGrob = null;
            break;
        }
        if(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom){
            let changed = true;
            if(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungsTyp == this.editingData.ErmittlungsTyp){
                switch(this.editingData.ErmittlungsTyp){
                    case(this.ErmittlungsTyp.BvsDirekt):
                        if(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungBvsDirekt.Bedarfsvolumenstrom == this.editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom)
                            changed = false;
                    break;
                    case(this.ErmittlungsTyp.Exakt):
                        if(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungResidentialExakt.Gleichzeitigkeit == this.editingData.ErmittlungResidentialExakt.Gleichzeitigkeit){
                            if(JSON.stringify(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungResidentialExakt.Wohnungsgruppen) === JSON.stringify(this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen))
                            changed = false;
                        }
                    break;
                    case(this.ErmittlungsTyp.Grob):
                        if(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungResidentialGrob.Gleichzeitigkeit == this.editingData.ErmittlungResidentialGrob.Gleichzeitigkeit){
                            if(JSON.stringify(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl) === JSON.stringify(this.editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl))
                                changed = false;
                        }
                    break;
                    case(this.ErmittlungsTyp.KennzahlDirekt):
                        if(this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungResidentialKennzahlDirekt.Gleichzeitigkeit && this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl === this.editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl &&
                            this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom.ErmittlungResidentialKennzahlDirekt.Gleichzeitigkeit == this.editingData.ErmittlungResidentialKennzahlDirekt.Gleichzeitigkeit)
                            changed = false;
                    break;
                }
            }

            if(!changed){
                this.currentProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.find(s => s.Key == this.getBuildingType()).Value = SectionState.Set;
                this.DataService.saveCurrentProject()
                    .then(_ => this.router.navigate([`/project-view/${this.currentProject.ProjectId}`]));
                return;
            }
        }

        // Replace null values in AusstattungenZweitbad with empty arrays
        if(this.editingData.ErmittlungResidentialExakt?.Wohnungsgruppen != null)
            this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen.forEach(x => x.AusstattungenZweitbad == null ? x.AusstattungenZweitbad = [] : x.AusstattungenZweitbad = x.AusstattungenZweitbad);
        
        // Overwrite BVS object in project entity with the new version
        this.currentProject.ResidentialBuilding.Bedarfsvolumenstrom = this.clone(this.editingData);
        console.log(this.editingData)
        // Call base implementation
        this.onClickSaveBase();
    }
    public onClickAddWohnungsgruppe(): void {        
        // Open dialog
        this.editDialog.show();
    }
    public onClickEditWohnungsgruppe(index: number): void {
        // Open dialog
        let currentElement = this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen[index];
        this.editDialog.editElement(currentElement, index);
    }
    public onClickDeleteWohnungsgruppe(index: number): void {
        // Store the index of the element to delete
        this.deleteIndex = index;

        // Show dialog
        this.deleteDialog.show();
    }
    public onCloseInvalidFieldsDialog(): void {
        this.invalidFieldsDialog.hide();
    }

    // Change Events
    public onChangeErmittlungstyp(typ: BedarfsvolumenstromErmittlungstyp): void {
        // Set type
        this.editingData.ErmittlungsTyp = typ;

        // Init type field object
        switch(typ){
            case BedarfsvolumenstromErmittlungstyp.BvsDirekt:
                if(this.editingData.ErmittlungBvsDirekt == null){
                    this.editingData.ErmittlungBvsDirekt = {
                        Bedarfsvolumenstrom: 0
                    }
                }
                break;
            case BedarfsvolumenstromErmittlungstyp.KennzahlDirekt:
                if(this.editingData.ErmittlungResidentialKennzahlDirekt == null){
                    this.editingData.ErmittlungResidentialKennzahlDirekt = {}
                }
                break;
            case BedarfsvolumenstromErmittlungstyp.Grob:
                if(this.editingData.ErmittlungResidentialGrob == null){
                    this.editingData.ErmittlungResidentialGrob = {
                        WohneinheitenGruppeAnzahl: this.apartmentGroupPresets.map(g => { return { Key: g.ApartmentGroupPresetId, Value: 0 }})
                    }
                }
                break;
            case BedarfsvolumenstromErmittlungstyp.Exakt:
                if(this.editingData.ErmittlungResidentialExakt == null){
                    this.editingData.ErmittlungResidentialExakt = {
                        Wohnungsgruppen: []
                    }
                }
                break;
        }

        // Recalculate
        this.calculateBvs();
    }

    // Dialog events
    public onDialogBackdropClick(): void {
        this.editDialog.hide();
    }
    public onWohnungsgruppeAdd($event: Wohnungsgruppe): void {
        if($event.ZweitBadTyp == null){
            $event.ZweitBadTyp = 0;
        }

        // Add new element to edit entity
        this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen.push($event);

        // Update the grid
        this.gridWohnungsgruppenExakt.refresh();

        // Close dialog
        this.editDialog.hide();

        // Recalculate
        this.calculateBvs();
    }
    public onWohnungsgruppeEdit($event: Wohnungsgruppe): void {
        if($event.ZweitBadTyp == null){
            $event.ZweitBadTyp = 0;
        }
        // Replace existing item with the new version
        this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen.splice(this.index, 1, $event);

        // Update the grid
        this.gridWohnungsgruppenExakt.refresh();

        // Recalculate
        this.calculateBvs();
    }
    public onWohnungsgruppeDelete(): void {
        // Validate index
        if(this.deleteIndex == null){
            return;
        }

        // Remove the item
        this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen.splice(this.deleteIndex, 1);
        this.deleteIndex = null;

        // Update the grid
        this.gridWohnungsgruppenExakt.refresh();

        // Close dialog
        this.deleteDialog.hide();

        // Recalculate
        this.calculateBvs();
    }

    // Formatters
    public formatErgebnisBvs(): string {
        return this.formatterService.formatNumber(this.editingData.BedarfsvolumenstromErgebnisLMin, 'LiterPerMinute', { maximumFractionDigits: 0})
    }
    public formatErgebnisKennzahl(): string {
        return this.formatterService.formatNumber(this.editingData.BedarfskennzahlErgebnis, null, { maximumFractionDigits: 0 });
    }
    public formatEquipmentNames(equipments: Ausstattung) {
        if(equipments == null)
            return null;
        return this.apartmentFacilities.find(f => equipments.Typ === f.FacilityId).DisplayNameLoc;
    }
    public formatZweitbadType(type: ZweitBadTyp): string {
        switch(type){
            case ZweitBadTyp.ZweitesBad: return this.langService.getString('ZweitBadTypes.Zwei_T');
            case ZweitBadTyp.GästezimmerMitBwUndDu: return this.langService.getString('ZweitBadTypes.GaesteMit_T');
            case ZweitBadTyp.GästezimmerOhneBwUndDu: return this.langService.getString('ZweitBadTypes.GaesteOhne_T');
            default: return this.langService.getString('ZweitBadTypes.KeinZweitbad_T');
        }
    }

    // Condition Validators
    public isBedarfskennzahlShown(): boolean {
        return this.editingData.ErmittlungsTyp != BedarfsvolumenstromErmittlungstyp.BvsDirekt;
    }
    public isSaveValid(): boolean {
        return (this.editingData.BedarfskennzahlErgebnis || this.editingData.ErmittlungsTyp == BedarfsvolumenstromErmittlungstyp.BvsDirekt)
            && this.editingData.BedarfsvolumenstromErgebnisLMin > 0
            && this.editingData.BedarfsvolumenstromErgebnisLMin <= 140
    }
    public isGleichzeitigkeitErrorShown(): boolean {
        switch(this.editingData.ErmittlungsTyp){
            case BedarfsvolumenstromErmittlungstyp.BvsDirekt:
                return false;
            case BedarfsvolumenstromErmittlungstyp.KennzahlDirekt:
                return this.editingData.ErmittlungResidentialKennzahlDirekt.Gleichzeitigkeit == null;
            case BedarfsvolumenstromErmittlungstyp.Grob:
                return this.editingData.ErmittlungResidentialGrob.Gleichzeitigkeit == null;
            case BedarfsvolumenstromErmittlungstyp.Exakt:
                return this.editingData.ErmittlungResidentialExakt.Gleichzeitigkeit == null;
        }
    }
    public isWohnungsgruppeErrorShown(): boolean {
        return this.editingData.ErmittlungsTyp == BedarfsvolumenstromErmittlungstyp.Exakt
            && (this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen == null
                || this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen.length <= 0
            );
    }
    public isToHighVolumeShown(){
        return this.editingData.BedarfsvolumenstromErgebnisLMin > 140;
    }
    public isToLowVolumeShown(){
        return this.editingData.BedarfsvolumenstromErgebnisLMin < 4;
    }
    public formatDecimalNumber(n: number){
        return n.toString().replace(",", ".");
    }
    public isSelectedGleichzeitigkeit(gleichzeitigkeit: GleichzeitigkeitResidential): boolean {
        switch(this.editingData.ErmittlungsTyp){
            case BedarfsvolumenstromErmittlungstyp.BvsDirekt:
                return false;
            case BedarfsvolumenstromErmittlungstyp.KennzahlDirekt:
                return this.editingData.ErmittlungResidentialKennzahlDirekt.Gleichzeitigkeit == gleichzeitigkeit;
            case BedarfsvolumenstromErmittlungstyp.Grob:
                return this.editingData.ErmittlungResidentialGrob.Gleichzeitigkeit == gleichzeitigkeit;
            case BedarfsvolumenstromErmittlungstyp.Exakt:
                return this.editingData.ErmittlungResidentialExakt.Gleichzeitigkeit == gleichzeitigkeit;
        }
    }
    public isAnyKleinwohnungen(): boolean {
        if(!this.editingData.ErmittlungResidentialExakt || !this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen)
            return false;
        else
            return this.editingData.ErmittlungResidentialExakt.Wohnungsgruppen.some(w => w.IsKleinwohnungen);
    }
}