export const events = {
    beforeOpen: 'beforeOpenEvent',
    open: 'openEvent',
    close: 'closeEvent',
    click: 'clickEvent',
    select: 'selectEvent',
    change: 'changeEvent',
    filtering: 'filteringEvent',
    actionBegin: 'actionBeginEvent',
    actionComplete: 'actionCompleteEvent',
    actionFailure: 'actionFailureEvent',
    dataBound: 'dataBoundEvent',
    created: 'createdEvent',
    destroyed: 'destroyedEvent',
};
