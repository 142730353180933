import { Component, Inject, Output, ViewChild, EventEmitter, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { AggregatorDataService } from "src/app/core/AggregatorDataService";
import { FreieZapfstelle } from "src/app/core/BackendApi/freiezapfstelle.complex";
import { VordefinierteZapfstelle } from "src/app/core/BackendApi/vordefiniertezapfstelle.complex";
import { Vdi6003FacilityRow } from "src/app/core/DteufcAggregatorServiceApi/vdi6003facilityrow.complex";
import { Komfortstufe } from "src/app/core/Enums";
import { ValueFormatterService } from "src/app/core/ValueFormatterService";
import { PspFormField } from "src/core-lib/angular/components/forms/PspFormField";
import { PspFormFieldGroup } from "src/core-lib/angular/components/forms/PspFormFieldGroup";
import { LangService } from "src/core-lib/ej2/services/LangService";

@Component({
    selector: 'app-zapfstellen-dialog-data',
    templateUrl: './zapfstellen-dialog-data.component.html',
    styleUrls: ['./zapfstellen-dialog-data.component.scss'],
    providers: [{ provide: 'facilities', useValue: []}]
})

export class ZapfstellenDialogData implements OnInit {

    //Outputs
    @Output() saveNewZapfstelle = new EventEmitter<Object>();
    @Output() editZapfstelle = new EventEmitter<Object>();

    invalidFieldsDialogButtons = [{
        'click': this.onCloseInvalidFieldsDialog.bind(this),
        buttonModel: {
            content: this.langService.getString('InvalidDialogUeberpruefenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
    }]

    @ViewChild(DialogComponent) public editZapfstelleDialog: DialogComponent;
    @ViewChild('invalidFieldsDialog') public invalidFieldsDialog: DialogComponent;

    // Config Variables
    public readonly typeFormFieldsConfig = { text: "DisplayNameLoc", value: "VdiFacilityId" }
    public readonly comfortValuesConfig = { text: "Label", value: "Key" }
    public readonly typeFormFields: PspFormFieldGroup;
    public readonly comfortFormFields: PspFormFieldGroup;
    public readonly freeFormFields: PspFormFieldGroup;
    public readonly comfortValues = [
        { Key: Komfortstufe.Stufe1, Label:  this.langService.getString('Komfortstufen.Stufe1_T')},
        { Key: Komfortstufe.Stufe2, Label:  this.langService.getString('Komfortstufen.Stufe2_T')},
        { Key: Komfortstufe.Stufe3, Label: this.langService.getString('Komfortstufen.Stufe3_T')}
    ]
    public facilities: Vdi6003FacilityRow[];
    public allFacilities: Vdi6003FacilityRow[];
    public editingItemIsFree: boolean;
    public editingItemIndex: number;

    // Item Data Variables
    public currentComfort: Komfortstufe;
    public currentlyFree: boolean;
    public currentCount: number;
    public currentFlow: number;
    public currentName: string;
    public currentType: string;
    public currentSum: number;
    public helpTextComfort: string;
    public dialogHeader: string = this.langService.getString('DialogsHeaderNeueEntnahmestelle_T');

    // Constructors
    constructor(
        private langService: LangService,
        protected formatterService: ValueFormatterService,
        protected aggregatorDataService: AggregatorDataService,
    ){
        // Init forms
        this.typeFormFields = new PspFormFieldGroup([
            new PspFormField({
                fieldName: 'Auswahl',
                displayName: this.langService.getString('FormFields.Auswahl_T'),
                formState: {
                    value: null,
                    disabled: false
                },
                displayAsRequired: true,
                validatorOrOpts: Validators.required,
            })
        ]);
        this.comfortFormFields = new PspFormFieldGroup([
            new PspFormField(
            {
                fieldName: 'Komfort',
                displayName: this.langService.getString('FormFields.Komfort_T'),
                formState: {
                    value: null,
                    disabled: false
                },
                displayAsRequired: true,
                validatorOrOpts: Validators.required,
                helpMessageHtml: this.helpTextComfort,
            })
        ]);
        this.freeFormFields = new PspFormFieldGroup([
            new PspFormField(
            {
                fieldName: 'Bezeichnung',
                displayName: this.langService.getString('FormFields.Bezeichnung_T'),
                formState: {
                    value: null,
                    disabled: false
                },
                displayAsRequired: true,
                validatorOrOpts: [Validators.required, Validators.pattern("[\\w\\d\-\.()/\\!\"'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*"), Validators.minLength(1),Validators.maxLength(50)],
            })
        ]);
    }

    public ngOnInit(): void {
        this.facilities = this.aggregatorDataService.aggregatorData.Tables.Vdi6003FacilityRows.concat([
            {VdiFacilityId: 'Manual', DisplayNameLoc: this.langService.getString('ExaktTypRadio.DialogDropDownManuelleEingabe_T')}
        ]);
    }

    // Methods
    public zapfstellenTypeHasChanged(): boolean {
        return this.editingItemIsFree == null
            || (this.editingItemIsFree == false && this.currentType == 'Manual')
            || (this.editingItemIsFree == true && this.currentType != 'Manual');
    }
    
    public isAboveNormParameters(): boolean {
        return true;
    }
    public isSaveValid(): boolean {
        switch(this.currentType){
            case null: return false;
            case 'Manual': return this.freeFormFields.group.valid;
            default: return this.comfortFormFields.group.valid;
        }
    }
    public getResultItemFrei(): FreieZapfstelle {
        return {
            BerechnungsflussSummeLiterSek: this.currentSum,
            BerechnungsflussLiterSek: this.currentFlow,
            Bezeichnung: this.currentName,
            Anzahl: this.currentCount
        }
    }
    public getResultItemVordefiniert(): VordefinierteZapfstelle {
        return {
            BerechnungsflussSummeLiterSek: this.currentSum,
            BerechnungsflussLiterSek: this.currentFlow,
            Komfortstufe: this.currentComfort,
            Anzahl: this.currentCount,
            Typ: this.currentType
        }
    }
    public reset(): void {
        this.editingItemIsFree = null;
        this.editingItemIndex = null;
        this.currentlyFree = false;
        this.currentComfort = null;
        this.currentCount = null;
        this.currentName = null;
        this.currentType = null;
        this.currentFlow = 0;
        this.currentSum = 0;

        this.comfortFormFields.group.reset();
        this.freeFormFields.group.reset();
        this.typeFormFields.group.reset();
    }
    public selectFreeItem(item: FreieZapfstelle, index: number): void {
        this.comfortFormFields.group.reset();
        this.typeFormFields.group.reset({ Auswahl: 'Manual' });
        this.freeFormFields.group.reset({ Bezeichnung: item.Bezeichnung });

        this.editingItemIsFree = true;
        this.editingItemIndex = index;

        this.currentlyFree = true;
        this.currentCount = item.Anzahl;
        this.currentFlow = item.BerechnungsflussLiterSek;

        this.recalculate();
    }
    public selectVordefiniertItem(item: VordefinierteZapfstelle, index: number): void {
        this.freeFormFields.group.reset();
        this.typeFormFields.group.reset({ Auswahl: item.Typ });
        this.comfortFormFields.group.reset({ Komfort: item.Komfortstufe });

        this.editingItemIsFree = false;
        this.editingItemIndex = index;

        this.currentlyFree = false;
        this.currentCount = item.Anzahl;
        this.currentComfort = item.Komfortstufe;
        this.currentType = item.Typ;
        this.currentFlow = item.BerechnungsflussLiterSek;

        this.recalculate();
    }
    public recalculate() : void {
        // Fix numbers being stored as strings
        if(this.currentCount)
            this.currentCount = Number(this.currentCount);
        if(this.currentFlow)
            this.currentFlow = Number(this.currentFlow);

        // Calculate
        if(this.currentlyFree){
            this.calculateFree();
        } else {
            this.calculateVorgegeben();
        }
    }
    private calculateVorgegeben(): void {
        if(this.currentComfort != null && this.currentCount != null && this.currentType != null) {
            let facilityInfo = this.facilities.find(f => f.VdiFacilityId == this.currentType);
            switch(this.currentComfort){
                case Komfortstufe.Stufe1: this.currentFlow = facilityInfo.BerechnungsdurchflussKS1; break;
                case Komfortstufe.Stufe2: this.currentFlow = facilityInfo.BerechnungsdurchflussKS2; break;
                case Komfortstufe.Stufe3: this.currentFlow = facilityInfo.BerechnungsdurchflussKS3; break;
            }
            this.currentSum = this.currentCount * this.currentFlow;
        } else {
            this.currentSum = 0;
        }
    }
    private calculateFree(): void {
        if(this.currentCount != null && this.currentFlow != null){
            this.currentSum = this.currentCount * this.currentFlow;
        } else {
            this.currentSum = 0;
        }
    }

    public formatBerechnungsdurchfluss(fluss: number): string {
        if(fluss == null)
            return "";
        else
            return this.formatterService.formatNumber(fluss, 'LiterPerSecond', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }

    // Change events
    public onTypeChanged() {
        this.currentlyFree = this.currentType == 'Manual';
        this.recalculate();
    }
    public onStufeChange(): void {
        this.recalculate();
    } 

    public open(): void {
        this.editZapfstelleDialog.show();
    }

    public close(): void {
        this.editZapfstelleDialog.hide();
    }

    public onEditDialogBackdropClick(): void {
        this.editZapfstelleDialog.hide();
    }

    public onCloseInvalidFieldsDialog(): void {
        this.invalidFieldsDialog.hide();
    }

    //Event Emitters
    public onZapfstelleSave(): void {
        // Validate
        if(!this.isSaveValid()){
            this.typeFormFields.group.markAllAsTouched();
            this.comfortFormFields.group.markAllAsTouched();
            this.freeFormFields.group.markAllAsTouched();
            this.invalidFieldsDialog.show();
            return;
        }

        // Decide wether to save a new or edit an existing item
        if(this.editingItemIndex != null) {
            let result = this.editingItemIsFree ? this.getResultItemFrei() : this.getResultItemVordefiniert();
            console.log(result);
            console.log(this.zapfstellenTypeHasChanged())
            this.editZapfstelleDialog.hide();
            this.editZapfstelle.emit(result);
            
        } else {
            if(this.currentlyFree){
                this.saveNewZapfstelle.emit(this.getResultItemFrei());
                this.editZapfstelleDialog.hide();
            } 
            else {
                this.saveNewZapfstelle.emit(this.getResultItemVordefiniert());
                this.editZapfstelleDialog.hide();
            }
        }
    }
}
