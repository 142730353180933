import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    imports:
    [
        SharedModule,
    ],
    declarations:
    [
        HomeComponent,
    ],
    providers: [],
})
export class HomeModule { }
