<!-- Main page -->
<viega-collapsible-panel>

    <!-- Side panel -->
    <div class="panel-content" panel-content>
            <viega-panel-header
                stepText="&nbsp;"
                [stepBackLabel]="stepBack"
                [stepTitle]="stepTitle"
                (stepBackClicked)="onClickGoBack()">
            </viega-panel-header>

        <div class="content-wrapper">
            <!-- Top-Wrapper -->
            <div class="top-content-wrapper">
                <!-- Top -->
                <div class="top-panel-content">
                        <p class="description-text" *ngIf="stepDescription" >
                            <span [innerHTML]="stepDescription"></span>&nbsp;
                        </p>
                </div>
                <!-- Middle -->
                <div class="mid-panel-content">

                </div>
            </div>
            <!-- Bot -->
            <div class="bot-panel-content">
                <div class="error-message" *ngIf="isGleichzeitigkeitErrorShown()">
                    <i class="icon-warning1"></i>
                    <p class="error-text">{{'ErrorGleichzeitigkeit_SD' | translate}}</p>
                </div>
                <div class="error-message" *ngIf="isWohnungsgruppeErrorShown()">
                    <i class="icon-warning1"></i><p class="error-text">{{'ErrorWohnungsgruppe_SD' | translate}}</p>
                </div>
                <div class="error-message" *ngIf="isToHighVolumeShown()">
                    <i class="icon-warning1"></i>
                    <p class="error-text">{{'ErrorKeineDte_SD' | translate}}</p>
                </div>
                <div class="error-message" *ngIf="isToLowVolumeShown()">
                    <i class="icon-warning1"></i>
                    <p class="error-text">{{'ErrorMinBedarfsvolumenstrom_SD' | translate}}</p>
                </div>

                <div class="result-box">
                    <span class="result-title">{{'Result_T' | translate}}</span>
                    <span *ngIf="isBedarfskennzahlShown()" class="result-content">{{'ResultBedarfskennzahl_T' | translate: formatErgebnisKennzahl()}}</span>
                    <span  class="result-content">{{'ResultBedarfsvolumenstrom_T' | translate: formatErgebnisBvs()}}</span>
                    <button (click)="onClickSave()" [disabled]="!isSaveValid()" style="flex-flow: row; justify-content: center;" psp-button class="e-btn">{{'UebernehmenBtn_T' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Page body -->
    <div class="content" content *ngIf="editingData">
        <div class="text-tooltip-wrapper text-tooltip-wrapper-content-title">
            <span class="content-title">{{'Headline_T' | translate}}</span>
            <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude"></app-help-icon>
        </div>
        <div>
            <!-- BVS Direkt -->
            <div class="inner-content">
                <ejs-radiobutton
                    #BvsDirektTypRadio
                    label="{{'BvsDirektTypRadio.RadioName_T' | translate}}"
                    [checked]="editingData.ErmittlungsTyp == ErmittlungsTyp.BvsDirekt"
                    (change)="onChangeErmittlungstyp(ErmittlungsTyp.BvsDirekt)"
                ></ejs-radiobutton>
            </div>
            <div [hidden]="BvsDirektTypRadio && !BvsDirektTypRadio.checked" *ngIf="editingData.ErmittlungBvsDirekt">

                <app-slider-text-combo
                    class="radioButtonText2"
                    [sliderMin]=0
                    [sliderMax]=140
                    [sliderStep]=1
                    [textboxMin]=0
                    [textboxMax]=999
                    [textboxStep]=1
                    [textboxClass]="'slider-input'"
                    [textboxStyle]="{ 'border-color': 'white !important', 'width': '10em', 'color': 'black !important', 'cursor': 'default', 'margin-left': '1em' }"
                    [textboxDecimals]="0"
                    [showLabel]='true'
                    labelText="{{'#Generic.Unit.LiterPerMinute_T' | translate}}"
                    [labelStyle]="{ 'margin-left': '0.6em' }"
                    [(value)]='editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom'
                    (valueChange)="calculateBvs()"
                ></app-slider-text-combo>
            </div>

            <!-- Kennzahl Direkt -->
            <div class="inner-content">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #KennzahlDirektTypRadio
                        label="{{'KennzahlDirektTypRadio.RadioName_T' | translate}}"
                        [checked]="editingData.ErmittlungsTyp == ErmittlungsTyp.KennzahlDirekt"
                        (change)="onChangeErmittlungstyp(ErmittlungsTyp.KennzahlDirekt)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude.Bedarfskennzahl"></app-help-icon>
                </div>
            </div>
            <div [hidden]="KennzahlDirektTypRadio && !KennzahlDirektTypRadio.checked" *ngIf="editingData.ErmittlungResidentialKennzahlDirekt">
                <div class="inner-content">
                    <div class="text-tooltip-wrapper form-fields">
                        <span class="inner-span e-label ">{{'KennzahlDirektTypRadio.HeadlineGleichzeitigkeit_T' | translate}} </span>
                        <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude.Gleichzeitigkeit"></app-help-icon>
                    </div>
                    <div class="inner-content-radio">
                        <ejs-radiobutton
                            #KennzahlDirektDIN4708Radio
                            (change)="onClickSelectGleichzeitigkeit(Gleichzeitigkeit.DIN4708)"
                            label="{{'KennzahlDirektTypRadio.DinRadioName_T' | translate}}"
                            [checked]="isSelectedGleichzeitigkeit(Gleichzeitigkeit.DIN4708)"
                        ></ejs-radiobutton>
                    </div>
                    <div class="inner-content-radio">
                        <ejs-radiobutton #KennzahlDirektTUDresdenRadio
                            (change)="onClickSelectGleichzeitigkeit(Gleichzeitigkeit.TUDresden)"
                            label="{{'KennzahlDirektTypRadio.TuRadioName_T' | translate}}"
                            [checked]="isSelectedGleichzeitigkeit(Gleichzeitigkeit.TUDresden)"
                        ></ejs-radiobutton>
                    </div>
                    <div>
                        <span class="e-label form-fields">{{'KennzahlDirektTypRadio.SliderHeaderBedarfskennzahl_T' | translate}}</span>
                    <app-slider-text-combo
                    class="radioButtonText2"
                    [sliderMin]=0
                    [sliderMax]=100
                    [sliderStep]=1
                    [textboxMin]=0
                    [textboxMax]=1000
                    [textboxStep]=1
                    [textboxClass]="'slider-input'"
                    [textboxDecimals]="0"
                    [textboxStyle]="{ 'border-color': 'white !important', 'width': '10em', 'color': 'black !important', 'cursor': 'default', 'margin-left': '1em' }"
                    [showLabel]='false'
                    [(value)]='editingData.ErmittlungResidentialKennzahlDirekt.Bedarfskennzahl'
                    (valueChange)="calculateBvs()"
                ></app-slider-text-combo>
                    </div>

                </div>
            </div>

            <!-- Grob -->
            <div class="inner-content">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #GrobTypRadio
                        label="{{'GrobTypRadio.RadioName_T' | translate}}"
                        [checked]="editingData.ErmittlungsTyp == ErmittlungsTyp.Grob"
                        (change)="onChangeErmittlungstyp(ErmittlungsTyp.Grob)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude.GrobErmitteln"></app-help-icon>
                </div>
            </div>
            <div [hidden]="GrobTypRadio && !GrobTypRadio.checked" *ngIf="editingData.ErmittlungResidentialGrob">
                <div class="text-tooltip-wrapper form-fields">
                    <span class="inner-span e-label">{{'GrobTypRadio.HeadlineGleichzeitigkeit_T' | translate}} </span>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude.Gleichzeitigkeit"></app-help-icon>
                </div>
                <div class="inner-content-radio">
                    <ejs-radiobutton
                        #GrobDIN4708Radio
                        (change)="onClickSelectGleichzeitigkeit(Gleichzeitigkeit.DIN4708)"
                        label="{{'GrobTypRadio.DinRadioName_T' | translate}}"
                        [checked]="isSelectedGleichzeitigkeit(Gleichzeitigkeit.DIN4708)"
                    ></ejs-radiobutton>
                </div>
                <div class="inner-content-radio">
                    <ejs-radiobutton
                        #GrobTUDresdenRadio
                        (change)="onClickSelectGleichzeitigkeit(Gleichzeitigkeit.TUDresden)"
                        label="{{'GrobTypRadio.TuRadioName_T' | translate}}"
                        [checked]="isSelectedGleichzeitigkeit(Gleichzeitigkeit.TUDresden)"
                    ></ejs-radiobutton>
                </div>

                <div class="form-fields" style="margin-left: 1.8em; margin-top: 2em; margin-bottom: -1em;">
                    <span class=" e-label">{{'GrobTypRadio.HeadlineWohneinheiten_T' | translate}}</span>
                </div>
                <div class="slider">
                    <div *ngFor="let unit of editingData.ErmittlungResidentialGrob.WohneinheitenGruppeAnzahl" class="flat-wrapper">
                        <div class="label-wrapper">
                            <label>{{getUnitRow(unit.Key).DisplayNameLoc}}
                                <psp-contextual-help
                                    *ngIf="getUnitRow(unit.Key).DescriptionHtmlLoc && getUnitRow(unit.Key).DescriptionHtmlLoc != '[!Value missing]'"
                                    [tooltipTextHtml]="getUnitRow(unit.Key).DescriptionHtmlLoc"
                                    cssClass="icon-help-with-circle">
                                </psp-contextual-help>
                            </label>
                        </div>
                        <app-slider-text-combo
                        class="radioButtonText2"
                        [sliderMin]=0
                        [sliderMax]=100
                        [sliderStep]=1
                        [textboxMin]=0
                        [textboxMax]=999
                        [textboxStep]=1
                        [textboxDecimals]="2"
                        [textboxClass]="'slider-input'"
                        [textboxStyle]="{ 'border-color': 'white !important', 'width': '10em', 'color': 'black !important', 'cursor': 'default', 'margin-left': '1em' }"
                        [showLabel]='false'
                        labelText="{{'#Generic.Unit.LiterPerMinute_T' | translate}}"
                        [labelStyle]="{ 'margin-left': '0.6em', 'width': '37em' }"
                        [(value)]='unit.Value'
                        (valueChange)="calculateBvs()"
                    ></app-slider-text-combo>
                    </div>
                </div>
            </div>

            <!-- Exakt -->
            <div class="inner-content">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #ExaktTypRadio
                        label="{{'ExaktTypRadio.RadioName_T' | translate}}"
                        [checked]="editingData.ErmittlungsTyp == ErmittlungsTyp.Exakt"
                        (change)="onChangeErmittlungstyp(ErmittlungsTyp.Exakt)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude.ExaktErmitteln"></app-help-icon>
                </div>
            </div>
            <div *ngIf=ExaktTypRadio.checked>
                <div class="text-tooltip-wrapper form-fields">
                    <span class="inner-span e-label">{{'ExaktTypRadio.HeadlineGleichzeitigkeit_T' | translate}} </span>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.Wohngebäude.Gleichzeitigkeit"></app-help-icon>
                </div>
                <div class="inner-content-radio">
                    <ejs-radiobutton
                        #ExaktDIN4708Radio
                        (change)="onClickSelectGleichzeitigkeit(Gleichzeitigkeit.DIN4708)"
                        label="{{'ExaktTypRadio.DinRadioName_T' | translate}}"
                        [checked]="isSelectedGleichzeitigkeit(Gleichzeitigkeit.DIN4708)"
                    ></ejs-radiobutton>
                </div>
                <div class="inner-content-radio">
                    <ejs-radiobutton
                        #ExaktTUDresdenRadio
                        (change)="onClickSelectGleichzeitigkeit(Gleichzeitigkeit.TUDresden)"
                        label="{{'ExaktTypRadio.TuRadioName_T' | translate}}"
                        [checked]="isSelectedGleichzeitigkeit(Gleichzeitigkeit.TUDresden)"
                    ></ejs-radiobutton>
                </div>
            </div>
            <div *ngIf="ExaktTypRadio.checked">
                <div class="radioButtonText">
                    <div class="datas-table">
                        <span class="e-label form-fields">{{'ExaktTypRadio.HeadlineWohnungsgruppen_T' | translate}}</span>
                        <div class="control-section">
                            <ejs-grid #gridWohnungsgruppenExakt [dataSource]='editingData.ErmittlungResidentialExakt.Wohnungsgruppen' [allowSorting]='false'>
                                <e-columns>
                                    <e-column headerText="&nbsp;" width="30" textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div class="livingGroupTitle">
                                                <b>{{data.Bezeichnung}}</b>
                                            </div>
                                            <div class="livingGroupCounterTitles">
                                                <span>{{'ExaktTypRadio.Raumzahl_T' | translate}}</span>
                                                <span>{{'ExaktTypRadio.Belegungszahl_T' | translate}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column headerText="{{'ExaktTypRadio.TableHeaderAnzahl_T' | translate}}" width='20' textAlign='Center'>
                                        <ng-template #template let-data>
                                            <div class="text-middle">
                                                <b>{{data.Anzahl}}</b>
                                            </div>
                                            <div class="livingGroupCounterNumbers text-middle">
                                                <span>{{data.Raumzahl}}</span>
                                                <span>{{data.Belegungszahl}}<span
                                                        *ngIf="data.IsKleinwohnungen">*</span></span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column headerText="{{'ExaktTypRadio.TableHeaderAusstattung_T' | translate}}" width='100' textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="flex-flow: wrap;
                                            white-space: normal;
                                            display: flex;">
                                                <span *ngFor="let ausstattung of data.Ausstattungen">{{formatEquipmentNames(ausstattung)}}, </span>
                                            </div>
                                            <div class="top-spacing">
                                                <b>{{formatZweitbadType(data.ZweitBadTyp)}}</b>
                                            </div>
                                            <div  style="flex-flow: wrap;
                                            white-space: normal;
                                            display: flex;">
                                                <span *ngFor="let ausstattung of data.AusstattungenZweitbad">{{formatEquipmentNames(ausstattung)}}, </span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column headerText="&nbsp;" width='20' textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="display: flex;">
                                                <i class="icon-edit-pencil" (click)="onClickEditWohnungsgruppe(data.index)"
                                                    style="cursor: pointer; margin-right: 1em; font-size: 16px;"></i>
                                                <i class="icon-delete" (click)="onClickDeleteWohnungsgruppe(data.index)"
                                                    style="cursor: pointer; margin-right: 1em; font-size: 16px;"></i>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
                <div *ngIf="isAnyKleinwohnungen()" style="margin-left: 2em" class="text-tooltip-wrapper">
                    <span>{{'ExaktTypRadio.Kleinwohnung_T'| translate}}</span>
                    <app-help-icon
                        hinweisId="Bedarfsvolumenstrom.Wohngebäude.Wohnungsgruppen.Überwiegend1u2Zimmerwohnungen">
                    </app-help-icon>
                </div>
                <div class="radioButtonText">
                    <button psp-button class="e-btn" style="flex-flow: row; justify-content: center;"
                        (click)="onClickAddWohnungsgruppe()" [disabled]="editingData.ErmittlungResidentialExakt.Wohnungsgruppen.length> 24">{{'ExaktTypRadio.WohnungsgruppeBtn_T' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</viega-collapsible-panel>

<!-- Add Wohnungsgruppe dialog -->
<app-wohnungsgruppe-dialog-data
#editDialog
(saveNewEditDialog)="onWohnungsgruppeAdd($event)"
(editItem)="onWohnungsgruppeEdit($event)"
></app-wohnungsgruppe-dialog-data>

<!-- Delete Wohnungsgruppe dialog -->
<ejs-dialog
    #deleteDialog
    target='app-demand-volumetric-flow-residential'
    [buttons]='deleteDialogButtons'
    header="{{'ExaktTypRadio.DeleteDialogHeader_T' | translate}}"
    [visible]='false'
    [isModal]='true'
    [closeOnEscape]='false'
    [showCloseIcon]='true'
    width='50%'
    [position]='dialogPosition'
>
    <ng-template #content>
            <div class="row">
                <span>{{'ExaktTypRadio.DeleteDialogContent_SD' | translate}}</span>
            </div>
    </ng-template>
</ejs-dialog>

<!-- Invalid data dialog -->
<ejs-dialog
    #invalidFieldsDialog
    target='app-demand-volumetric-flow-residential'
    [buttons]='invalidFieldsDialogButtons'
    header="{{'ExaktTypRadio.InvalidDialogHeader_T' | translate}}"
    [visible]='false'
    [isModal]='true'
    [closeOnEscape]='false'
    [showCloseIcon]='true'
    width='50em'
    [position]='dialogPosition'
>
    <ng-template #content>
        <div class="row">
            <span>{{'ExaktTypRadio.InvalidDialogContent_SD' | translate}}</span>
        </div>
    </ng-template>
</ejs-dialog>