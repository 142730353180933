import { AbstractControlOptions, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { IPspFormFieldModel } from './IPspFormFieldModel';

export class PspFormField
{
    /**
     * Name of the field in the entity object.
     */
    public fieldName: string;

    public displayName: string;

    public displayAsRequired?: boolean;

    /**
     * Field indication helper icon displayed along with the display name.
     */
    public displayIconCss?: string;

    /**
     * If set, displays a context help indicator next to the field. This help text is shown if
     * the user interacts with the icon.
     */
    public helpMessageHtml?: string;

    /**
     * If set, the contextual help tooltip will offer the user a hyperlink to the documentation
     * page.
     */
    public helpDocumentationHref?: string;

    public category?: string;

    /**
     * Error message shown when the field is invalid. If unset, an automated message is shown
     * or the fallback message is used.
     */
    public genericErrorMsg?: string;

    /**
     * Error message shown when the field is invalid and no automated error message applies.
     */
    public fallbackErrorMsg?: string;

    public currentErrorMsg?: string;

    public cssClass?: string;

    public control: FormControl;

    public constructor(options: IPspFormFieldModel)
    {
        Object.assign(this, options);
        this.control = new FormControl(options.formState, options.validatorOrOpts);
    }

    public isRequired(): boolean
    {
        return this.displayAsRequired;
    }
}
