import { addClass, Component, INotifyPropertyChanged, Property, removeClass } from '@syncfusion/ej2-base';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-popups';
import { events } from './events';
import { DisplayStyle, IPspLoadingSpinnerModel, LoadingSpinnerSize } from './IPspLoadingSpinnerModel';

export class PspLoadingSpinner extends Component<HTMLElement> implements INotifyPropertyChanged
{
    private spinnerEl: HTMLElement;

    /**
     * Configures size of the loading spinner.
     */
    @Property()
    public size?: LoadingSpinnerSize;

    /**
     * Optimizes the spinner's appearance for dark or light backgrounds.
     */
    @Property()
    public displayStyle?: DisplayStyle;

    /**
     * Additional css class names to be added to the element.
     */
    @Property()
    public cssClass?: string;

    constructor(options?: IPspLoadingSpinnerModel, element?: string | HTMLElement)
    {
        super(options, element);
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-loading-spinner';
    }

    protected preRender(): void
    {
        // NOP
    }

    protected render(): void
    {
        this.element.classList.add('pspc-loading-spinner');
        if (this.cssClass)
            addClass([this.element], this.cssClass.split(' '));

        this.createSpinnerElement();
        showSpinner(this.element);
        this.updateDisplayStyle();

        this.trigger(events.created, {});
    }

    private createSpinnerElement() {
        this.spinnerEl = this.createElement('span', { className: 'e-spinner' });
        this.element.appendChild(this.spinnerEl);

        let spinnerSize = 16;
        if (this.size === 'medium')
            spinnerSize = 32;
        else if (this.size === 'large')
            spinnerSize = 64;

        createSpinner(
            {
                target: this.spinnerEl,
                width: spinnerSize,
                type: 'Material'
            },
            this.createElement
        );
    }

    /** @ignore */
    public onPropertyChanged(newProp: IPspLoadingSpinnerModel, oldProp: IPspLoadingSpinnerModel): void
    {
        for (const propertyName of Object.keys(newProp)) {
            switch (propertyName) {
                case 'cssClass':
                    if (oldProp.cssClass)
                        removeClass([this.element], oldProp.cssClass.split(' '));
                    if (newProp.cssClass)
                        addClass([this.element], newProp.cssClass.split(' '));

                    break;
                case 'displayStyle':
                    this.updateDisplayStyle();

                    break;
                case 'size':
                    hideSpinner(this.element);
                    this.spinnerEl.parentElement.removeChild(this.spinnerEl);

                    this.createSpinnerElement();
                    showSpinner(this.element);

                    break;

            }
        }
    }

    private updateDisplayStyle()
    {
        removeClass([this.element], ['pspc-loading-spinner--light', 'pspc-loading-spinner--dark']);
        if (this.displayStyle === 'light')
            addClass([this.element], 'pspc-loading-spinner--light');
        else
            addClass([this.element], 'pspc-loading-spinner--dark');
    }

    protected getPersistData(): string
    {
        return '';
    }

    public destroy(): void
    {
        this.trigger(events.destroyed, {});
        super.destroy();
    }
}
