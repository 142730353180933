import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'viega-collapsible-panel',
    templateUrl: './collapsible-panel.component.html',
    styleUrls: ['./collapsible-panel.component.scss']
})
export class CollapsiblePanelComponent implements OnInit {
    private _isCollapsed: boolean = false;

    public get isCollapsed(): boolean {
        return this._isCollapsed;
    }

    @Input()
    public set isCollapsed(newValue: boolean) {
        if (this._isCollapsed !== newValue) {
            this._isCollapsed = newValue;
            this.doAnimateCollapse = false;
        }
    }

    @Input()
    public isCollapsible: boolean = true;

    public doAnimateCollapse: boolean = true;

    @Output()
    public collapsed = new EventEmitter<boolean>();

    constructor() {}

    public ngOnInit(): void {}

    public toggleCollapsed() {
        this.doAnimateCollapse = true;

        this._isCollapsed = !this.isCollapsed;
        this.collapsed.emit(this.isCollapsed);
    }
}
