import { NgModule } from '@angular/core';
import { PspContextualHelpComponent } from './psp-contextual-help.component';

@NgModule({
    declarations:
    [
        PspContextualHelpComponent,
    ],
    exports:
    [
        PspContextualHelpComponent,
    ],
})
export class PspContextualHelpModule {}
