<viega-collapsible-panel>
    <div class="panel-content" panel-content>
                <viega-panel-header
                    [stepTitle]="title"
                    [stepBackLabel]="stepBack"
                    [stepText]="stepText"
                    (stepBackClicked)="goBack()"
                >
                </viega-panel-header>

            <div class="content-wrapper">
                <!-- Top-Wrapper -->
                <div class="top-content-wrapper">
                    <!-- Top -->
                    <div class="top-panel-content">
                        <p class="description-text" *ngIf="description" >
                            <span [innerHTML]="description"></span>&nbsp;
                        </p>
                    </div>
                    <!-- Middle -->
                    <div class="mid-panel-content">

                    </div>
                </div>
                <!-- Bot -->
                <div class="bot-panel-content">
                    <div class="result-box">
                        <div class="error-message" *ngIf='isCheckRed'>
                            <i class="icon-warning1"></i><p class="error-text">{{'PanelWarningKonntrollsumme_D' | translate}}</p>
                        </div>
                        <span class="result-title">{{'PanelResultHeadline_T' | translate}}</span>
                        <div class="result-content">
                            <span>{{'PanelResultWarmwassertagesbedarf_T' | translate}} {{getWwDisplay()}}</span>
                        </div>
                        <button psp-button class="e-btn " (click)="navigateNext()" [disabled]='isCheckRed'> <span>{{'UebernehmenBtn_T' | translate}}</span> </button>
                    </div>
                </div>
            </div>
    </div>


    <div class="content" content>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</viega-collapsible-panel>

<ng-template #content>
    <div class="text-tooltip-wrapper">
        <span class="content-title">{{'Headline_T' | translate}}</span>
        <app-help-icon hinweisId="Warmwasser.StundenwerteTageslastgang"></app-help-icon>
    </div>

    <div class="dropdown-wrapper" style="margin-bottom: 3em; width: 20em;">
        <ejs-dropdownlist class="dropdown" [dataSource]='profiles' [(value)]='currentInputType' [fields]="ddFields" (change)="onChangeInputType($event)"></ejs-dropdownlist>
    </div>
    <div class="tables-wrapper">

        <div class="inner-table">
            <ejs-grid  [dataSource]='leftTableData'>
                <e-columns >
                    <e-column field='Hour' headerText="{{'Table.HeadlineUhrzeit_T' | translate}}" width='20%' textAlign='Left'>
                        <ng-template #template let-data>
                            <span>{{data.Hour}} - {{data.Hour + 1}}</span>
                        </ng-template>
                    </e-column>
                    <e-column field='Amount' headerText="{{'Table.HeadlineAnteil_T' | translate : percent}}">
                        <ng-template #template let-data>
                            <div class="radioButtonText">
                                <div class="manual-input-wrapper" *ngIf="currentInputType === 'Manu.Eingabe'">
                                    <app-slider-text-combo
                                            class="slider-text"
                                            style="margin-left: 0em;"
                                            [sliderMin]="0"
                                            [sliderMax]="100"
                                            [sliderStep]="1"
                                            [textboxMin]="0"
                                            [textboxMax]="100"
                                            [textboxStep]="1"
                                            [textboxClass]="'slider-input'"
                                            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                            [textboxDecimals]="2"
                                            [showLabel]='false'
                                            [(value)]='data.Amount'
                                            (valueChange)="sliderChangeLeftTable($event, data.index)"
                                        ></app-slider-text-combo>
                                    <!--<div class="slider-wrapper" >
                                        <ejs-slider #option1 [min]=0 [max]=100 [(value)]="data.Amount" (change)='sliderChangeLeftTable($event, data.index)'></ejs-slider>
                                    </div>
                                     <ejs-textbox class="slider-input" type="number" min=0 max=25 [(value)]='data.Amount' (change)='sliderChangeLeftTable($event, data.index)' floatLabelType="Never" style=" border-color: white !important; width: 6em;color:black !important; cursor: default; margin-left: 1em"></ejs-textbox>-->
                                     <span style="margin-left: 2em">%</span>
                                </div>
                                <div class="structure-input-wrapper" *ngIf="currentInputType !== 'Manu.Eingabe'">
                                    {{data.Amount}} %
                                </div>
                            </div>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>

        <div class="inner-table">
            <ejs-grid  [dataSource]='rightTableData'>
                <e-columns >
                    <e-column field='Hour' headerText="{{'Table.HeadlineUhrzeit_T' | translate}}" width='20%'  textAlign='Left'>
                        <ng-template #template let-data>
                            <span>{{data.Hour}} - {{data.Hour + 1}}</span>
                        </ng-template>
                    </e-column>
                    <e-column field='Amount' headerText="{{'Table.HeadlineAnteil_T' | translate : percent}}">
                        <ng-template #template let-data>
                            <div class="radioButtonText">
                                <div class="manual-input-wrapper" *ngIf="currentInputType === 'Manu.Eingabe'">
                                    <app-slider-text-combo
                                            class="slider-text"
                                            style="margin-left: 0em;"
                                            [sliderMin]="0"
                                            [sliderMax]="100"
                                            [sliderStep]="1"
                                            [textboxMin]="0"
                                            [textboxMax]="100"
                                            [textboxStep]="1"
                                            [textboxClass]="'slider-input'"
                                            [textboxStyle]="{ 'width': '10em', 'color': 'black !important', 'margin-left': '1em' }"
                                            [textboxDecimals]="2"
                                            [showLabel]='false'
                                            [(value)]='data.Amount'
                                            (valueChange)="sliderChangeRightTable($event, data.index)"
                                        ></app-slider-text-combo>
                                    <!--<div class="slider-wrapper" >
                                        <ejs-slider #option2 [min]=0 [max]=100 [(value)]="data.Amount" (change)='sliderChangeRightTable($event, data.index)'></ejs-slider>
                                    </div>-->
                                     <span style="margin-left: 2em">%</span>
                                </div>
                                <div class="structure-input-wrapper" *ngIf="currentInputType !== 'Manu.Eingabe'">
                                    {{data.Amount}} %
                                </div>
                            </div>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
            <div class="text-tooltip-wrapper text-tooltip-wrapper-checksum">
                <span [class.red]='isCheckRed'>{{'Table.Kontrollsumme_T' | translate}} {{totalArrayValue}}%</span>
                <app-help-icon hinweisId="Warmwasser.Stundenwerte.Kontrollsumme"></app-help-icon>
            </div>
        </div>
    </div>

    <div style="margin-top: 5em; height: 400px;">
        <ejs-chart height="80%" width="95%" [palettes]='palette' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' (axisLabelRender)="axisLabelRender($event)">
            <e-series-collection>
                <e-series [dataSource]='data' type='Column' xName='Hour' yName='Amount' [animation]='animation'></e-series>
            </e-series-collection>
        </ejs-chart>
    </div>



</ng-template>