import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreLibModule } from 'src/core-lib/angular/core-lib.module';
import { PspSearchBoxModule } from 'src/core-lib/angular/components/search/psp-search-box/psp-search-box.module';
import { CheckBoxModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { CardModule } from './card';
import { CollapsiblePanelModule } from './collapsible-panel';
import { HeaderModule } from './header';
import { LogoModule } from './logo';
import { SliderViegaModule } from './slider';
import { StartPageModule } from './start-page';
import { PspContextualHelpModule } from 'src/core-lib/angular/components/other/psp-contextual-help/psp-contextual-help.module';
import { SelectImageModule } from './select-image/select-image.module';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { NumericTextBoxModule, SliderAllModule, SliderModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ViegaButtonImageModule } from './viega-button-image/viega-button-image.module';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { StepBarModule } from './step-bar';
import { PspFormsModule } from 'src/core-lib/angular/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DimensioningSchematicComponent } from './dimensioning-schematic/dimensioning-schematic.component';
import { DteSchematicComponent } from './dte-schematic/dte-schematic.component';
import { DteStandardbetriebSchematicComponent } from './dte-standardbetrieb-schematic/dte-standardbetrieb-schematic.component';
import { UfcSchematicComponent } from './ufc-schematic/ufc-schematic.component';
import { PspButtonModule } from 'src/core-lib/angular/components/buttons/psp-button/psp-button.module';
import { SliderTextComboComponent } from './slider-text-combo-component/slider-text-combo.component';
import { HelpIconComponent } from './help-icon/help-icon.component';
import { PanelHeaderComponent } from './collapsible-panel/panel-header/panel-header.component';
import { ZapfstellenDialogData } from './zapfstellen-dialog-data/zapfstellen-dialog-data.component';
import { WohnungsgruppeDialogData } from './wohnungsgruppe-dialog-data/wohnungsgruppe-dialog-data.component';

@NgModule(
{
    imports:
    [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CardModule,
        CollapsiblePanelModule,
        HeaderModule,
        LogoModule,
        SliderViegaModule,
        StartPageModule,

        // core lib and component modules
        CoreLibModule,
        PspSearchBoxModule,
        PspContextualHelpModule,
        SelectImageModule,
        ViegaButtonImageModule,
        PspFormsModule,
        PspButtonModule,

        // syncfusion components
        CheckBoxModule,
        GridModule,
        RadioButtonModule,
        DialogModule,
        TabModule,
        SliderModule,
        TextBoxModule,
        SliderAllModule,
        DropDownListModule,
        MultiSelectModule,
        DialogModule,
        ChartModule,
        NumericTextBoxModule
    ],
    declarations:
    [
        DimensioningSchematicComponent,
        DteSchematicComponent,
        DteStandardbetriebSchematicComponent,
        UfcSchematicComponent,
        SliderTextComboComponent,
        HelpIconComponent,
        PanelHeaderComponent,
        WohnungsgruppeDialogData,
        ZapfstellenDialogData,
    ],
    exports:
    [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CardModule,
        CollapsiblePanelModule,
        HeaderModule,
        LogoModule,
        StartPageModule,
        SliderViegaModule,
        SelectImageModule,
        ViegaButtonImageModule,
        DimensioningSchematicComponent,
        DteSchematicComponent,
        DteStandardbetriebSchematicComponent,
        UfcSchematicComponent,
        SliderTextComboComponent,
        HelpIconComponent,
        PanelHeaderComponent,
        WohnungsgruppeDialogData,
        ZapfstellenDialogData,

        // core lib and component modules
        CoreLibModule,
        PspSearchBoxModule,
        PspContextualHelpModule,
        StepBarModule,
        PspFormsModule,
        PspButtonModule,

        // syncfusion components
        CheckBoxModule,
        GridModule,
        RadioButtonModule,
        DialogModule,
        TabModule,
        SliderModule,
        TextBoxModule,
        SliderAllModule,
        DropDownListModule,
        MultiSelectModule,
        DialogModule,
        ChartModule,
        NumericTextBoxModule
    ],
    providers: [],
})
export class SharedModule {}
