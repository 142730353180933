import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'viega-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {

    @Input()
    public disableShadow: boolean;
}
