import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectViewComponent } from './project-view.component';
import { BaseDataDialogModule } from '../base-data-dialog/base-data-dialog.module';
import { ConfigDteDialogModule } from '../config-dte-dialog/config-dte-dialog.module';
import { ConfigDetailsDialogModule } from '../config-details-dialog/config-details-dialog.module';
import { StructureDialogModule } from '../structure-dialog/structure-dialog.module';
import { PspLoadingSpinnerModule } from 'src/core-lib/angular/components/utility/psp-loading-spinner/psp-loading-spinner.module';

@NgModule({
    imports:
    [
        SharedModule,
        BaseDataDialogModule,
        ConfigDteDialogModule,
        ConfigDetailsDialogModule,
        StructureDialogModule,
        PspLoadingSpinnerModule
    ],
    declarations:
    [
      ProjectViewComponent
    ],
    providers: [],
})
export class ProjectViewModule { }