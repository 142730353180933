import { Injectable } from '@angular/core';
import { DteRow } from '../DteufcAggregatorServiceApi/dterow.complex';
import { UfcRow } from '../DteufcAggregatorServiceApi/ufcrow.complex';
import { MathLogger } from './MathLogger';

/**
 * Ufc (Ultrafiltrationsanlage) related mathematic functions.
 */
@Injectable()
export class UfcMath
{
    public ufcFromDte(dte: DteRow, availableUfcs: UfcRow[], zirkulationsvolumenstromLMin: number): UfcRow | undefined
    {
        console.log(availableUfcs.sort((a, b) => a.MinZirkulationsvolumenstromLMin - b.MinZirkulationsvolumenstromLMin));

        return availableUfcs
            .filter(ufc => dte.CompatibleUfcs.indexOf(ufc.Ufc) !== -1)
            .sort((a, b) => a.MinZirkulationsvolumenstromLMin - b.MinZirkulationsvolumenstromLMin)
            .filter(ufc =>
                zirkulationsvolumenstromLMin >= ufc.MinZirkulationsvolumenstromLMin &&
                zirkulationsvolumenstromLMin <= ufc.MaxZirkulationsvolumenstromLMin
            )
            [0];
    }
}
