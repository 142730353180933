<input
    type="range"
    [attr.id]="inputId"
    [attr.min]="min"
    [attr.max]="max"
    [attr.value]="value"
    [attr.readonly]="disabled"
>

<div
    class="track"
    [class.dragging]="isDragging"
    [class.disabled]="disabled"
    tabindex="0"
    #track
>
    <div class="handle" #handle></div>
</div>
