import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ErmittlungsESGrob } from 'src/app/core/BackendApi/ermittlungsesgrob.complex';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { HeatGeneratorRow } from 'src/app/core/DteufcAggregatorServiceApi/heatgeneratorrow.complex';
import { EnergiespeicherErmittlungstyp, Energielabel } from 'src/app/core/Enums';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
    selector: 'app-heat-generator-power',
    templateUrl: './heat-generator-power.component.html',
    styleUrls: ['./heat-generator-power.component.scss'],
    providers: [provideContext("HeatGeneratorPower")],
})
export class HeatGeneratorPowerComponent implements OnInit {

    // Variables
    public editData: ErmittlungsESGrob;
    public warmwasser: number;
    private defaultHeater: HeatGeneratorRow;
    selectedProject: ProjectEntity;
    stepText = this.langService.getString('PanelStepText_T');
    stepBack = this.langService.getString('PanelStepBackLabel_T');
    stepTitle = this.langService.getString('PanelHeadline_T');
    stepDescription = this.langService.getString('PanelDescription_D');

    // Constructors/Initializers
    constructor(
        private activatedRoute: ActivatedRoute,
        private langService: LangService,
        private readonly router: Router,
        private dataService: ProjectDataService,
        private aggregator: AggregatorDataService,
        private formatter: ValueFormatterService
    ) {

    }
    ngOnInit(): void {
        // Get & Validate Project
        let urlProject = this.activatedRoute.snapshot.data['project'];
        this.selectedProject = this.dataService.TempProject;
        if (!this.selectedProject) {
            if (urlProject) {
                this.router.navigate([`/project-view/${urlProject.ProjectId}`]);
            } else {
                this.router.navigate([`/home`]);
            }
            return;
        }

        // Get default heater values
        this.defaultHeater = this.aggregator.aggregatorData.Tables.HeatGeneratorRows.find(h => h.HeatGeneratorId == 'Grobauslegung');

        // Load & Init if not existing
        this.warmwasser = this.selectedProject.ProjectValues.WarmwasserErgebnisLtag;
        if (!this.selectedProject.ProjectValues.Energiespeicher?.ErmittlungsESGrob) {
            this.selectedProject.ProjectValues.Energiespeicher = {
                Ermittlungstyp: EnergiespeicherErmittlungstyp.None,
                Energielabel: Energielabel.C,
                ErmittlungsESGrob: {
                    LeistungDirekteingabeKw: this.defaultHeater.LeistungWWBereitungKw
                },
                MinutenwerteEnergiebedarf: null,
                MinutenwerteEnergiebedarfAbgesenkt: null,
                MinutenwerteQStoOn: null,
                MinutenwerteQStoOnAbgesenkt: null,
                MinutenwerteVersorgung: null,
                MinutenwerteVersorgungAbgesenkt: null
            }
        }
        this.editData = this.selectedProject.ProjectValues.Energiespeicher.ErmittlungsESGrob;
    }

    getWwDisplay(): string{
        return this.formatter.formatNumber(this.warmwasser, 'Liter')
    }

    public goBack(): void {
        this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
    }

    public navigateNext(): void {
        // Make sure slider/input combo did no mess up the data type
        this.editData.LeistungDirekteingabeKw = Number(this.editData.LeistungDirekteingabeKw);

        // Store Ermittlungstyp & Energielabel
        this.selectedProject.ProjectValues.Energiespeicher.Ermittlungstyp = EnergiespeicherErmittlungstyp.Grob;
        this.selectedProject.ProjectValues.Energiespeicher.Energielabel = Energielabel.C;

        // Set default heat generator
        this.selectedProject.ProjectValues.Energiespeicher.Wärmeerzeuger = [{
            ErzeugerArt: this.defaultHeater.HeatGeneratorId,
            LeistungWarmwasserBereitungKw: this.editData.LeistungDirekteingabeKw,
            VerzoegerungNachheizungMin: this.defaultHeater.VerzoegerungNachheizungMin
        }];

        // Next step
        this.router.navigate([`/result/${this.selectedProject.ProjectId}`]);
    }
}
