<ejs-dialog #dialog (close)="onCloseDialog()" (beforeClose)="onBeforeClose($event)" (open)="onOpenDialog()" (overlayClick)="onOverlayClick()" [target]='target' [buttons]='buttons' [header]='headerText' [isModal]="true" [closeOnEscape]='false' [visible]='false' [minHeight]="dialogHeight" [height]='dialogHeight' [showCloseIcon]='true' [width]='dialogWidth' [position]='dialogPosition'>
    <ng-template #header>
        <div class="text-tooltip-wrapper text-tooltip-wrapper-header">
            <span>{{headerText}}</span>
            <app-help-icon hinweisId="Projektkonfiguration.Step4"></app-help-icon>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="subtitle">
            <span>{{'Content_SD' | translate: projectName}}</span>
        </div>
        <div class="options">
            <app-project-configuration-item
                #PipeProject
                [itemHintId]="'Projektkonfiguration.Rohrleitung'"
                [(itemChecked)]="_isPipeChecked"
                itemTitle="{{'#ConfigDetailsDialog.ItemTitleRohrleitung_T' | translate}}"
                [itemImage]="imageRohrleitung"
                itemDescription="{{'#ConfigDetailsDialog.ContentRohrleitung_D' | translate}}"
            ></app-project-configuration-item>
            <app-project-configuration-item
                #EnergyProject
                [itemHintId]="'Projektkonfiguration.Energiespeicher'"
                [(itemChecked)]="_isEnergyChecked"
                itemTitle="{{'#ConfigDetailsDialog.ItemTitleEnergiespeicher_T' | translate}}"
                [itemImage]="imageSpeicher"
                itemDescription="{{'#ConfigDetailsDialog.ContentEnergiespeicher_D' | translate}}"
            ></app-project-configuration-item>
        </div>
        <div >
            <p>
                <span class="icon-warning"></span>
                {{changeWarning}}
            </p>
        </div>
    </ng-template>
</ejs-dialog>