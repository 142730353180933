import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DimensioningSchematicComponent } from '../dimensioning-schematic/dimensioning-schematic.component';

/**
 * A special schematic container which allows to update certain values immediately.
 */
@Component({
    selector: 'app-dte-standardbetrieb-schematic',
    templateUrl: '../dimensioning-schematic/dimensioning-schematic.component.html',
    styleUrls: ['../dimensioning-schematic/dimensioning-schematic.component.scss'],
})
export class DteStandardbetriebSchematicComponent extends DimensioningSchematicComponent implements OnInit, OnChanges
{
    @Input()
    public dte: string;

    @Input()
    public energiespeicherVorlauftemperatur: string;

    @Input()
    public bedarfsvolumenstrom: string;

    public ngOnInit(): void
    {
        this.schematicType = 'Dte';
    }

    public ngOnChanges(changes: SimpleChanges)
    {
        this.updateTextElements();
    }

    protected onImageLoaded(): void
    {
        super.onImageLoaded();
        this.updateTextElements();
    }

    private updateTextElements(): void
    {
        if (!this.isImageLoaded)
            return;

        this.setTextElementValue('Station_1', this.dte);
        this.setTextElementValue('HVL_1_Grad_1', this.energiespeicherVorlauftemperatur);
        this.setTextElementValue('PWH_1_Liter_1', this.bedarfsvolumenstrom);
    }
}
