import { Routes } from '@angular/router';
import { DailyLoadProfileComponent } from './routes/daily-load-profile/daily-load-profile.component';
import { CirculationVolumetricFlowComponent } from './routes/circulation-volumetric-flow/circulation-volumetric-flow.component';
import { DteTemperatureComponent } from './routes/dte-temperature/dte-temperature.component';
import { DteComponent } from './routes/dte/dte.component';
import { HeatGeneratingTypesComponent } from './routes/heat-generating-types/heat-generating-types.component';
import { HomeComponent } from './routes/home/home.component';
import { HotWaterDemandComponent } from './routes/hot-water-demand/hot-water-demand.component';
import { OverviewComponent } from './routes/overview/overview.component';
import { PipelineComponent } from './routes/pipeline/pipeline.component';
import { ProjectViewComponent } from './routes/project-view/project-view.component';
import { ResultComponent } from './routes/result/result.component';
import { SelectCalculationUfcComponent } from './routes/select-calculation-ufc/select-calculation-ufc.component';
import { SelectCalculationReservoirComponent } from './routes/select-calculation-reservoir/select-calculation-reservoir.component';
import { ExamplePageComponent } from './routes/example-page/example-page.component';
import { FurtherDetailsComponent } from './routes/further-details/further-details.component';
import { HeatGeneratorPowerComponent } from './routes/heat-generator-power/heat-generator-power.component';
import { DemandVolumetricFlowOtherComponent } from './routes/demand-volumetric-flow-other/demand-volumetric-flow-other.component';
import { DemandVolumetricFlowResidentialComponent } from './routes/demand-volumetric-flow-residential/demand-volumetric-flow-residential.component';
import { ProjectResolver } from './core/ProjectResolver';
import { BuildingTypeResolver } from './core/BuildingTypeResolver';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'example', component: ExamplePageComponent},
    { path: 'home', component: HomeComponent },
    { path: 'overview', component: OverviewComponent },
    { path: 'project-view/:id', component: ProjectViewComponent, resolve: { project: ProjectResolver }},
    { path: 'select-calculation-ufc/:id', component: SelectCalculationUfcComponent, resolve: { project: ProjectResolver }},
    { path: 'select-calculation-reservoir/:id', component: SelectCalculationReservoirComponent, resolve: { project: ProjectResolver }},
    { path: 'demand-volumetric-flow-other/:id/:buildingType', component: DemandVolumetricFlowOtherComponent, resolve: { project: ProjectResolver, buildingType: BuildingTypeResolver }},
    { path: 'demand-volumetric-flow-residential/:id/:buildingType', component: DemandVolumetricFlowResidentialComponent, resolve: { project: ProjectResolver, buildingType: BuildingTypeResolver }},
    { path: 'dte/:id', component: DteComponent, resolve: { project: ProjectResolver }},
    { path: 'dte-temperature/:id', component: DteTemperatureComponent, resolve: { project: ProjectResolver }},
    { path: 'result/:id', component: ResultComponent, resolve: { project: ProjectResolver }},
    { path: 'pipeline/:id', component: PipelineComponent, resolve: { project: ProjectResolver }},
    { path: 'hot-water-demand/:id/:buildingType', component: HotWaterDemandComponent, resolve: { project: ProjectResolver, buildingType: BuildingTypeResolver }},
    { path: 'circulation-volumetric-flow/:id', component: CirculationVolumetricFlowComponent, resolve: { project: ProjectResolver }},
    { path: 'daily-load-profile/:id/:buildingType', component: DailyLoadProfileComponent, resolve: { project: ProjectResolver, buildingType: BuildingTypeResolver }},
    { path: 'heat-generating-types/:id', component: HeatGeneratingTypesComponent, resolve: { project: ProjectResolver }},
    { path: 'heat-generator-power/:id', component: HeatGeneratorPowerComponent, resolve: { project: ProjectResolver }}
    // { path: 'further-details', component: FurtherDetailsComponent}
];
