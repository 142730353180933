import { Component, Event, EmitType, INotifyPropertyChanged, Property, addClass, removeClass } from '@syncfusion/ej2-base';
import { Button, ButtonModel } from '@syncfusion/ej2-buttons';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-popups';
import { events } from './events';
import { IconPosition, IPspButtonModel, SpinnerPosition, TextPosition } from './IPspButtonModel';
import { Position, Tooltip } from '@syncfusion/ej2-popups';
import { PimStatic } from 'src/core-lib/ej2/utils/PimStatic';

export class PspButton extends Component<HTMLButtonElement> implements INotifyPropertyChanged
{
    private _wrappedButton: Button;
    private spinnerEl: HTMLElement;
    protected tooltip: Tooltip;

    /**
     * The icon position.
     * If no custom icon is specified, the chevron pointing into the respective direction will be used.
     * @default 'right'
     */
    @Property('right')
    public iconPosition?: IconPosition;

    @Property('left')
    public textPosition?: TextPosition;

    /**
     * Defines class/multiple classes separated by a space of the element.
     * @default ''
     */
    @Property()
    public cssClass?: string;

    /**
     * Defines class/multiple classes separated by a space for the Button that is used to include an icon.
     * The icon of the button defaults to a chevron pointing into the direction which is set by iconPosition, specifing a
     * custom icon here will overwrite the chevron.
     * Set to empty string to show no icon at all.
     * @default undefined
     */
    @Property()
    public customIconCss?: string;

    /**
     * Specifies a value that indicates whether the Button is `disabled` or not.
     * @default false.
     */
    @Property(false)
    public isDisabled?: boolean;

    /**
     * Defines the text content of the Button element.
     * @default ''
     */
    @Property()
    public content?: string;

    /**
     * Whether a loading spinner should be displayed. If an icon is configured, it will be hidden while the spinner is shown.
     */
    @Property()
    public showSpinner: boolean;

    /**
     * The position of the loading spinner.
     */
    @Property()
    public spinnerPosition: SpinnerPosition;

    /**
     * Defines the tooltip text. HTML is supported.
     * @default ''
     */
    @Property(null)
    public tooltipContent?: string;

    /**
     * The placement of the tooltip.
     * @default 'TopCenter'
     */
    @Property('TopCenter')
    public tooltipPosition?: Position;

    /**
     * Raised when the component has been created and rendered.
     */
    @Event()
    public createdEvent?: EmitType<{}>;

    /**
     * Raised when the component has been destroyed.
     */
    @Event()
    public destroyedEvent?: EmitType<{}>;

    constructor(options?: IPspButtonModel, element?: string | HTMLButtonElement)
    {
        super(options, element);
    }

    /** @ignore */
    public getModuleName(): string
    {
        return 'psp-button';
    }

    protected preRender(): void
    {
        // NOP
    }

    protected render(): void
    {
        const setup: ButtonModel = {
            iconPosition: this.getIconPosition(),
            cssClass: this.getCssClass(),
            iconCss: this.getIconCssClass(),
            disabled: this.isDisabled,
            content: this.content,
            isPrimary: false,
            enableHtmlSanitizer: false,
        };

        this._wrappedButton = new Button(setup, this.element);
        this.createSpinner();
        this.setSpinnerPosition(this.spinnerPosition);
        this.setTextPosition(this.textPosition);
        this.setSpecialClasses();
        this.updateSpinAnimation();

        this.setTooltipText(this.tooltipContent);

        this.trigger(events.created, {});
    }

    private createSpinner(): void
    {
        this.spinnerEl = this.createElement('span', { className: 'e-spinner' });

        createSpinner(
            {
                target: this.spinnerEl,
                width: 16,
                type: 'Fabric'
            },
            this.createElement
        );
    }

    private setSpinnerPosition(position: SpinnerPosition): void
    {
        if (position === 'left')
            this.element.insertBefore(this.spinnerEl, this.element.firstChild);
        else
            this.element.appendChild(this.spinnerEl);
    }

    private setTextPosition(position: TextPosition): void
    {
        removeClass([this.element], [
            'pspc-button--text-align-left',
            'pspc-button--text-align-right',
            'pspc-button--text-align-top',
            'pspc-button--text-align-bottom',
        ]);

        addClass([this.element], 'pspc-button--text-align-' + position);
    }

    private setSpecialClasses(): void
    {
        removeClass([this.element], 'pspc-button--no-icon');

        if (this.customIconCss !== undefined)
            addClass([this.element], 'pspc-button--no-icon');
    }

    private updateSpinAnimation()
    {
        if (this.showSpinner)
            showSpinner(this.element);
        else
            hideSpinner(this.element);
    }

    private setTooltipText(tooltipText: string): void
    {
        if (tooltipText)
        {
            if (!this.tooltip)
            {
                this.tooltip = new Tooltip({
                    content: tooltipText,
                    openDelay: PimStatic.TooltipDelayMs,
                    position: this.tooltipPosition,
                    showTipPointer: false,
                });
                this.tooltip.appendTo(this.element);
            }
            else
            {
                this.tooltip.content = tooltipText;
            }
        }
        else if (this.tooltip)
        {
            this.tooltip.destroy();
            this.tooltip.element.parentElement.removeChild(this.tooltip.element);
            delete this.tooltip;
        }
    }

    private getIconPosition()
    {
        return this.iconPosition === 'left' ? 'Left' : 'Right';
    }

    /** @ignore */
    public onPropertyChanged(newProp: IPspButtonModel, oldProp: IPspButtonModel): void
    {
        for (const propertyName of Object.keys(newProp))
        {
            const oldValue = oldProp[propertyName];
            const newValue = newProp[propertyName];

            switch (propertyName) {
                // properties to be passed through to the wrapper
                case 'content':
                    this._wrappedButton[propertyName as string] = newValue;
                    break;

                // name remappings
                case 'isDisabled':
                    this._wrappedButton.disabled = newValue;
                    break;

                case 'cssClass':
                    this._wrappedButton.cssClass = this.getCssClass();
                    break;

                case 'spinnerPosition':
                    this.setSpinnerPosition(newProp.spinnerPosition);
                    this._wrappedButton.cssClass = this.getCssClass();
                    break;

                case 'textPosition':
                    this.setTextPosition(newProp.textPosition);
                    this._wrappedButton.cssClass = this.getCssClass();
                    break;

                case 'iconPosition':
                case 'customIconCss':
                case 'showSpinner':
                    this._wrappedButton.cssClass = this.getCssClass();
                    this._wrappedButton.iconCss = this.getIconCssClass();
                    this._wrappedButton.iconPosition = this.getIconPosition();
                    this.setSpecialClasses();

                    if (propertyName === 'showSpinner')
                        this.updateSpinAnimation();

                    break;

                case 'iconTooltip':
                    this.setTooltipText(newValue);
                    break;

                case 'iconTooltipPosition':
                    if (this.tooltip)
                        this.tooltip.position = newValue;

                    break;
            }
        }
    }

    protected getCssClass(): string
    {
        let cssClass = `pspc-button`;
        if (!this.iconPosition)
            cssClass += ' pspc-button--right';
        else
            cssClass += ` pspc-button--${this.iconPosition}`;

        if (this.showSpinner)
            cssClass += ' pspc-button--spinner';

        if (this.cssClass)
            cssClass += ' ' + this.cssClass;

        if (!this.spinnerPosition)
            cssClass += ' pspc-button--spinner-right';
        else
            cssClass += ` pspc-button--spinner-${this.spinnerPosition}`;

        return cssClass;
    }

    protected getIconCssClass(): string
    {
        if (this.customIconCss === undefined)
        {
            if (!this.iconPosition)
                return 'icon-chevron-thin-left';
            else
                return `icon-chevron-thin-${this.iconPosition}`;
        }

        return this.customIconCss || 'no-icon';
    }

    protected getPersistData(): string
    {
        return '';
    }

    public destroy(): void
    {
        this.trigger(events.destroyed, {});
        super.destroy();
    }
}
