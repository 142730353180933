import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { provideContext } from 'src/core-lib/angular/context-util';

@Component({
  selector: 'app-project-configuration-item',
  templateUrl: './project-configuration-item.component.html',
  styleUrls: ['./project-configuration-item.component.scss'],
  providers: [provideContext("ProjectConfigurationItem")],
})
export class ProjectConfigurationItemComponent implements OnInit {

     @Input() itemTitle;
     @Input() itemImage;
     @Input() itemDescription;
     @Input() itemChecked: boolean;
     @Input() isDisabled: boolean;
     @Input() itemHintId: string;
     @Output() itemCheckedChange = new EventEmitter<boolean>();

     @ViewChild('titleCheckBox') public itemCheckBox: CheckBoxComponent;

     @Output() stateChanged = new EventEmitter<any>();

  constructor() { }



  ngOnInit(): void {
  }


onChange(args){ //Wird noch geändert, wenn DTE Auswahl zu Radio-Buttons gemacht wird; Derzeit noch benötigt
    this.itemCheckedChange.emit(this.itemChecked);
    this.stateChanged.emit(args.checked);
  }

}
