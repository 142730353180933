import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DemandVolumetricFlowOtherComponent } from './demand-volumetric-flow-other.component';



@NgModule({
  declarations: [
      DemandVolumetricFlowOtherComponent
    ],
  imports: [
    SharedModule
  ]
})
export class DemandVolumetricFlowOtherModule { }
