<!-- Main page -->
<viega-collapsible-panel>

    <!-- Side panel -->
    <div class="panel-content" panel-content>
            <viega-panel-header
                stepText="&nbsp;"
                [stepBackLabel]="stepBack"
                [stepTitle]="stepTitle"
                (stepBackClicked)="onClickGoBack()">
            </viega-panel-header>
        <div class="content-wrapper">
            <!-- Top-Wrapper -->
            <div class="top-content-wrapper">
                <!-- Top -->
                <div class="top-panel-content">
                        <p class="description-text" *ngIf="stepDescription" >
                            <span [innerHTML]="stepDescription"></span>&nbsp;
                        </p>
                </div>
            </div>
            <!-- Bot -->
            <div class="bot-panel-content">
                <div class="error-message" *ngIf="isEntnahmestelleErrorShown()">
                    <i class="icon-warning1"></i><p class="error-text">{{'ErrorEntnahmestelle_SD' | translate}}</p>
                </div>
                <div class="error-message" *ngIf="isToHighVolumeShown()">
                    <i class="icon-warning1"></i>
                    <p class="error-text">{{'ErrorKeineDte_SD' | translate}}</p>
                </div>
                <div class="error-message" *ngIf="isToLowVolumeShown()">
                    <i class="icon-warning1"></i>
                    <p class="error-text">{{'ErrorMinBedarfsvolumenstrom_SD' | translate}}</p>
                </div>
                <div class="result-box">
                    <span class="result-title">{{'Result_T' | translate}}</span>
                    <span  class="result-content">{{'ResultBedarfsvolumenstrom_T' | translate: formatErgebnisBvs()}}</span>
                    <button (click)="onClickSave()" [disabled]="!isSaveValid()" style="flex-flow: row; justify-content: center;" psp-button class="e-btn">{{'UebernehmenBtn_T' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Page body -->
    <div class="content" content *ngIf="editingData">
        <div class="text-tooltip-wrapper text-tooltip-wrapper-content-title">
            <span class="content-title">{{'Headline_T' | translate}}</span>
            <app-help-icon hinweisId="Bedarfsvolumenstrom.AndereGebäude"></app-help-icon>
        </div>
        <div>
            <!-- BVS Direkt -->
            <div class="inner-content">
                <ejs-radiobutton
                    #BvsDirektTypRadio
                    label="{{'BvsDirektTypRadio.RadioName_T' | translate}}"
                    [checked]="editingData.ErmittlungsTyp == ErmittlungsTypOther.BvsDirekt"
                    (change)="onChangeErmittlungstyp(ErmittlungsTypOther.BvsDirekt)"
                ></ejs-radiobutton>
            </div>
            <div [hidden]="BvsDirektTypRadio && !BvsDirektTypRadio.checked" *ngIf="editingData.ErmittlungBvsDirekt">
                <div class="radioButtonText">
                    <app-slider-text-combo
                    class="slider-text-combo"
                    style="margin-left:0em;"
                    [sliderMin]="0"
                    [sliderMax]="140"
                    [sliderStep]="1"
                    [textboxMin]="0"
                    [textboxMax]="800"
                    [textboxStep]="1"
                    [textboxClass]="'slider-input'"
                    [textboxStyle]="{ 'width': '9em', 'color':'black !important', 'cursor': 'default', 'margin-left':'1em' }"
                    [textboxDecimals]="0"
                    [showLabel]='false'
                    [(value)]='editingData.ErmittlungBvsDirekt.Bedarfsvolumenstrom'
                    (valueChange)="calculateBvs()"
                ></app-slider-text-combo>
                    <span style="margin-left: 0.6em; width: 37em;">{{'#Generic.Unit.LiterPerMinute_T' | translate}}</span>
                </div>
            </div>

            <!-- Grob -->
            <div class="inner-content">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #GrobTypRadio
                        label="{{'GrobTypRadio.RadioName_T' | translate}}"
                        [checked]="editingData.ErmittlungsTyp == ErmittlungsTypOther.Grob"
                        (change)="onChangeErmittlungstyp(ErmittlungsTypOther.Grob)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.AndereGebäude.GrobErmitteln"></app-help-icon>
                </div>
            </div>
            <div [hidden]="GrobTypRadio && !GrobTypRadio.checked" *ngIf="editingData.ErmittlungOtherGrob">
                <div class="slider">
                    <div *ngFor="let unit of editingData.ErmittlungOtherGrob.WohneinheitenAbnehmerAnzahl" class="flat-wrapper">
                        <div class="label-wrapper">
                            <label style="white-space: nowrap;">{{getUnitRow(unit.Key).DisplayNameLoc}}
                                <psp-contextual-help
                                    *ngIf="getUnitRow(unit.Key).DescriptionHtmlLoc && getUnitRow(unit.Key).DescriptionHtmlLoc != '[!Value missing]'"
                                    [tooltipTextHtml]="getUnitRow(unit.Key).DescriptionHtmlLoc"
                                    cssClass="icon-help-with-circle">
                                </psp-contextual-help>
                            </label>
                        </div>
                        <div class="slider-wrapper">
                            <app-slider-text-combo
                            class="slider-text-combo"
                            [sliderMin]="0"
                            [sliderMax]="100"
                            [sliderStep]="1"
                            [textboxMin]="0"
                            [textboxMax]="999"
                            [textboxStep]="1"
                            [textboxDecimals]="0"
                            [textboxClass]="'slider-input'"
                            [textboxStyle]="{ 'width': '9em', 'color':'black !important', 'cursor': 'default', 'margin-left':'1em' }"
                            [showLabel]='false'
                            [(value)]='unit.Value'
                            (valueChange)="calculateBvs()"
                        ></app-slider-text-combo>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Exakt -->
            <div class="inner-content">
                <div class="text-tooltip-wrapper">
                    <ejs-radiobutton
                        #ExaktTypRadio
                        label="{{'ExaktTypRadio.RadioName_T' | translate}}"
                        [checked]="editingData.ErmittlungsTyp == ErmittlungsTypOther.Exakt"
                        (change)="onChangeErmittlungstyp(ErmittlungsTypOther.Exakt)"
                    ></ejs-radiobutton>
                    <app-help-icon hinweisId="Bedarfsvolumenstrom.AndereGebäude.ExaktErmitteln"></app-help-icon>
                </div>
            </div>
            <div [hidden]="ExaktTypRadio && !ExaktTypRadio.checked" *ngIf="editingData.ErmittlungOtherExakt">
                <div class="radioButtonText">
                    <div style="margin-right: 1.5em" class="text-tooltip-wrapper">
                        <span class="form-fields">{{'ExaktTypRadio.HeadlineGleichzeitigkeit_T' | translate}}</span>
                        <app-help-icon hinweisId="Bedarfsvolumenstrom.AndereGebäude.Gleichzeitigkeit"></app-help-icon>
                    </div>
                    <ejs-dropdownlist
                        class="dropdown"
                        #exaktGleichzeitigkeitDropdown
                        [(value)]="editingData.ErmittlungOtherExakt.Gleichzeitigkeit"
                        [index]=0
                        [dataSource]='gleichzeitigkeitRows'
                        [fields]="gleichzeitigkeitDropdownFields"
                        (change)="calculateBvs()"
                        (select)="onSelect($event)"
                        style="min-width: 100px; width: 270px;"
                        popupWidth="auto"
                    ></ejs-dropdownlist>
                </div>
                <div *ngIf="exaktGleichzeitigkeitDropdown.value == 'Manuell'" class="radioButtonText" style="width: 30em;">
                    <div style="width: 50%">
                        <span>{{'ExaktTypRadio.SliderNameLinear_T' | translate}}</span>
                    </div>
                    <app-slider-text-combo
                            class="slider-text-combo"
                            [sliderMin]="0"
                            [sliderMax]="100"
                            [sliderStep]="5"
                            [textboxMin]="0"
                            [textboxMax]="100"
                            [textboxStep]="1"
                            [textboxDecimals]="2"
                            [textboxClass]="'slider-input'"
                            [textboxStyle]="{ 'width': '9em', 'color':'black !important', 'cursor': 'default', 'margin-left':'1em' }"
                            [showLabel]='false'
                            [(value)]='editingData.ErmittlungOtherExakt.gleichzeitigkeitLinear'
                            (valueChange)="calculateBvs()"
                        ></app-slider-text-combo>
                    <span style="margin-left: 0.6em;">{{'#Generic.Unit.Percent_T' | translate}}</span>
                </div>
            </div>
            <div *ngIf="ExaktTypRadio.checked">
                <div class="radioButtonText">
                    <div class="datas-table">
                        <div class="control-section">
                            <ejs-grid
                                #gridVordefiniert
                                class="grid-norm"
                                [allowSorting]='false'
                                clipMode="EllipsisWithTooltip"
                                [dataSource]='editingData.ErmittlungOtherExakt.ZapfstellenVordefiniert'
                            >   <e-columns>
                                    <e-column field='Anzahl' headerText="{{'ExaktTypRadio.TableHeaderAnzahl_T' | translate}}" textAlign='Left'></e-column>

                                    <e-column clipMode="EllipsisWithTooltip" headerText="{{'ExaktTypRadio.TableHeaderBezeichnung_T'| translate}}" width="25%" textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="display: flex;">
                                                <span>{{getVordefinierteZapfstelleTypeName(data)}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>

                                    <e-column headerText="{{'ExaktTypRadio.TableHeaderBerechnungsdurchfluss_T' | translate}}" textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="display: flex;">
                                                <span>{{formatBerechnungsdurchfluss(data.BerechnungsflussLiterSek)}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>

                                    <e-column headerText="{{'ExaktTypRadio.TableHeaderSumme_T' | translate}}" textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="display: flex;">
                                                <span>{{formatBerechnungsdurchfluss(data.BerechnungsflussSummeLiterSek)}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column headerText="{{'ExaktTypRadio.TableHeaderKomfortstufe_T' | translate}}" textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="display: flex;">
                                                <span>{{data.Komfortstufe + 1}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column width="80px">
                                        <ng-template #template let-data>
                                            <div class="grid-action-icon-container">
                                                <i class="icon-edit-pencil action-icon left"
                                                    (click)="onClickEditZapfstelleVordefiniert(data, data.index)"
                                                ></i>
                                                <i class="icon-delete action-icon"
                                                    (click)="onClickDeleteZapfstelleVordefiniert(data.index)"
                                                ></i>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
                <div class="radioButtonText">
                    <div class="datas-table">
                        <span class="form-fields">{{'ExaktTypRadio.HeadlineNorm_T' | translate}}</span>
                        <div class="control-section">
                            <ejs-grid 
                                #gridFree 
                                class="grid-free" 
                                [allowSorting]='false'
                                [dataSource]='editingData.ErmittlungOtherExakt.ZapfstellenFrei'
                            >   <e-columns>
                                    <e-column field='Anzahl' headerText="{{'ExaktTypRadio.TableHeaderAnzahl_T' | translate}}" textAlign='Left'></e-column>
                                    <e-column clipMode="EllipsisWithTooltip" field='Bezeichnung' headerText="{{'ExaktTypRadio.TableHeaderBezeichnung_T' | translate}}" width="25%" textAlign='Left'></e-column>
                                    <e-column headerText="{{'ExaktTypRadio.TableHeaderBerechnungsdurchfluss_T' | translate}}" textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="display: flex;">
                                                <span>{{formatBerechnungsdurchfluss(data.BerechnungsflussLiterSek)}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column headerText="{{'ExaktTypRadio.TableHeaderSumme_T' | translate}}" textAlign='Left'>
                                        <ng-template #template let-data>
                                            <div style="display: flex;">
                                                <span>{{formatBerechnungsdurchfluss(data.BerechnungsflussSummeLiterSek)}}</span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    <e-column>
                                        <ng-template #template let-data></ng-template>
                                    </e-column>
                                    <e-column width="80px">
                                        <ng-template #template let-data>
                                            <div class="grid-action-icon-container">
                                                <i class="icon-edit-pencil action-icon"
                                                    (click)="onClickEditZapfstelleFrei(data, data.index)"
                                                ></i>
                                                <i class="icon-delete action-icon"
                                                    (click)="onClickDeleteZapfstelleFrei(data.index)"
                                                ></i>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
                <div class="radioButtonText">
                    <div class="error-message" *ngIf="editingData.BedarfsvolumenstromErgebnisLMin > 140">
                        <i class="icon-warning1"></i>
                        <p class="error-text">{{'ExaktTypRadio.ErrorBerechnungsdurchfluss_SD' | translate}}</p>
                    </div>
                </div>
                <div class="radioButtonText">
                    <button psp-button class="e-btn" style="flex-flow: row; justify-content: center;"
                        (click)="onClickAddNewZapfstelle()">{{'ExaktTypRadio.NeueEntnahmestelleBtn_T' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</viega-collapsible-panel>

<!-- Add/Edit dialog -->
<app-zapfstellen-dialog-data
#editDialog
(saveNewZapfstelle)="onZapfstelleAdd($event)"
(editZapfstelle)="onZapfstelleEdit($event)"
></app-zapfstellen-dialog-data>

<!-- Delete dialog -->
<ejs-dialog
    #deleteDialog
    target='app-demand-volumetric-flow-other'
    [buttons]='deleteDialogButtons'
    header="{{'ExaktTypRadio.DeleteDialogHeader_T' | translate}}"
    [visible]='false'
    [isModal]='true'
    [closeOnEscape]='false'
    [showCloseIcon]='true'
    width='50%'
    [position]='dialogPosition'
>
    <ng-template #content>
            <div class="row">
                <span>{{'ExaktTypRadio.DeleteDialogContent_SD' | translate}}</span>
            </div>
    </ng-template>
</ejs-dialog>