// labels to improve the default localization of dropdowns
export const localization = {
    'de': {
        'psp-publication-status': {
            'isPmApproved': 'PM-Freigabe erteilt',
            'isPmApprovedShort': 'PM',
            'isPmApprovedTooltip': 'Freigabe durch Produktmanagement wurde erteilt.',
            'isNonPmApproved': 'PM-Freigabe nicht erteilt',
            'isNonPmApprovedShort': 'PM',
            'isNonPmApprovedTooltip': 'Freigabe durch Produktmanagement wurde noch nicht erteilt.',
            'isOem': 'OEM',
            'isOemShort': 'OEM',
            'isOemTooltip': '',
            'isDeleted': 'Gelöscht',
            'isDeletedTooltip': 'Das "Gelöscht" Kennzeichen ist gesetzt.',
            'isConfidential': 'Geheim',
            'isConfidentialTooltip': 'Nicht veröffentlichtes Produkt. Daten nur für internen Gebrauch!',
        },
        'psp-assortment-status': {
            'assortmentCountSingle': '1 Element im Sortiment',
            'assortmentCount': '{0} Elemente im Sortiment',
            'manageAssortments': 'Sortimente verwalten',
            'resetCurrentAssortment': 'Aktuelles Sortiment zurücksetzen',

            'modelsAddedTooltip': '{0} Modelle hinzugefügt',
            'singleModelAddedTooltip': '1 Modell hinzugefügt',
            'modelsRemovedTooltip': '{0} Modelle entfernt',
            'singleModelRemovedTooltip': '1 Modell entfernt',
            'articlesAddedTooltip': '{0} Artikel hinzugefügt',
            'singleArticleAddedTooltip': '1 Artikel hinzugefügt',
            'articlesRemovedTooltip': '{0} Artikel entfernt',
            'singleArticleRemovedTooltip': '1 Artikel entfernt',
            'productGroupsAddedTooltip': '{0} Produktgruppen hinzugefügt',
            'singleProductGroupAddedTooltip': '1 Produktgruppe hinzugefügt',
            'productGroupsRemovedTooltip': '{0} Produktgruppen entfernt',
            'singleProductGroupRemovedTooltip': '1 Produktgruppe entfernt',

            'dataPackageCountSingle': '1 Element im Datenpaket',
            'dataPackageCount': '{0} Elemente im Datenpaket',
            'manageDataPackages': 'Datenpakete verwalten',
            'resetCurrentDataPackage': 'Aktuelles Datenpaket zurücksetzen',
            'filesAddedTooltip': '{0} Dateien hinzugefügt',
            'singleFileAddedTooltip': '1 Datei hinzugefügt',
            'filesRemovedTooltip': '{0} Dateien entfernt',
            'singleFileRemovedTooltip': '1 Datei entfernt',
        }
    },
    'en-US': {
        'psp-publication-status': {
            'isPmApproved': 'PM-Approved',
            'isPmApprovedShort': 'PM',
            'isPmApprovedTooltip': 'Approval through the product management is given.',
            'isNonPmApproved': 'Not PM-Approved',
            'isNonPmApprovedShort': 'PM',
            'isNonPmApprovedTooltip': 'Approval through the product management is not given.',
            'isOem': 'OEM',
            'isOemShort': 'OEM',
            'isOemTooltip': '',
            'isDeleted': 'Deleted',
            'isDeletedTooltip': 'The "Deleted" flag is set.',
            'isConfidential': 'Confidential',
            'isConfidentialTooltip': 'Unreleased product. Data for internal use only!',
        },
        'psp-assortment-status': {
            'assortmentCountSingle': '1 Element in Assortment',
            'assortmentCount': '{0} Elements in Assortment',
            'manageAssortments': 'Manage Assortments',
            'resetCurrentAssortment': 'Reset Current Assortment',

            'modelsAddedTooltip': '{0} Models Added',
            'singleModelAddedTooltip': '1 Model Added',
            'modelsRemovedTooltip': '{0} Models Removed',
            'singleModelRemovedTooltip': '1 Model Removed',
            'articlesAddedTooltip': '{0} Articles Added',
            'singleArticleAddedTooltip': '1 Article Added',
            'articlesRemovedTooltip': '{0} Articles Removed',
            'singleArticleRemovedTooltip': '1 Article Removed',
            'productGroupsAddedTooltip': '{0} Product Groups Added',
            'singleProductGroupAddedTooltip': '1 Product Group Added',
            'productGroupsRemovedTooltip': '{0} Product Groups Removed',
            'singleProductGroupRemovedTooltip': '1 Product Group Removed',

            'dataPackageCountSingle': '1 Element in Data Package',
            'dataPackageCount': '{0} Elements in Data Package',
            'manageDataPackages': 'Manage Data Packages',
            'resetCurrentDataPackage': 'Reset Current Data Package',
            'filesAddedTooltip': '{0} Files Added',
            'singleFileAddedTooltip': '1 File Added',
            'filesRemovedTooltip': '{0} Files Removed',
            'singleFileRemovedTooltip': '1 File Removed',
        }
    },
    'en-GB': {
        'psp-publication-status': {
            'isPmApproved': 'PM-Approved',
            'isPmApprovedShort': 'PM',
            'isPmApprovedTooltip': 'Approval through the product management is given.',
            'isNonPmApproved': 'Not PM-Approved',
            'isNonPmApprovedShort': 'PM',
            'isNonPmApprovedTooltip': 'Approval through the product management is not given.',
            'isOem': 'OEM',
            'isOemShort': 'OEM',
            'isOemTooltip': '',
            'isDeleted': 'Deleted',
            'isDeletedTooltip': 'The "Deleted" flag is set.',
            'isConfidential': 'Confidential',
            'isConfidentialTooltip': 'Unreleased product. Data for internal use only!',
        },
        'psp-assortment-status': {
            'assortmentCountSingle': '1 Element in Assortment',
            'assortmentCount': '{0} Elements in Assortment',
            'manageAssortments': 'Manage Assortments',
            'resetCurrentAssortment': 'Reset Current Assortment',

            'modelsAddedTooltip': '{0} Models Added',
            'singleModelAddedTooltip': '1 Model Added',
            'modelsRemovedTooltip': '{0} Models Removed',
            'singleModelRemovedTooltip': '1 Model Removed',
            'articlesAddedTooltip': '{0} Articles Added',
            'singleArticleAddedTooltip': '1 Article Added',
            'articlesRemovedTooltip': '{0} Articles Removed',
            'singleArticleRemovedTooltip': '1 Article Removed',
            'productGroupsAddedTooltip': '{0} Product Groups Added',
            'singleProductGroupAddedTooltip': '1 Product Group Added',
            'productGroupsRemovedTooltip': '{0} Product Groups Removed',
            'singleProductGroupRemovedTooltip': '1 Product Group Removed',

            'dataPackageCountSingle': '1 Element in Data Package',
            'dataPackageCount': '{0} Elements in Data Package',
            'manageDataPackages': 'Manage Data Packages',
            'resetCurrentDataPackage': 'Reset Current Data Package',
            'filesAddedTooltip': '{0} Files Added',
            'singleFileAddedTooltip': '1 File Added',
            'filesRemovedTooltip': '{0} Files Removed',
            'singleFileRemovedTooltip': '1 File Removed',
        }
    }
};
